/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import { ICustomDate } from "@Internal/Contracts";
import BaseModel from "./Model";

export default interface BaseCustomer extends BaseModel {
    Email?: string;
    Name: string;
    Company?: string;
    VAT?: string;
    Address?: string;
    Note?: string;
    Active?: boolean;
    CreatedAt?: ICustomDate;
    City?: string;
    Street?: string;
    PostalCode?: string;
    IsStaff?: boolean;

    /* No specific type for now, maybe in the future if necessary. */
    Group?: BaseCustomerGroup;

    InvoicePrefix?: string;
}

export interface BaseCustomerGroup {
    Id: number;
}
