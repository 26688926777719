/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

export default {
    PRICES_title: "Title",
    PRICES_description: "Description",
    PRICES_model: "Model",
    PRICES_value: "Value",
    PRICES_packageSize: "Package size",
    PRICES_recurrent: "Reccurent",
    PRICES_tiers: "Tiers",
    PRICES_tierMinUnits: "Minimal quantity",
    PRICES_tierMaxUnits: "Maximal quantity",
    PRICES_tierUnitPrice: "Unit price",
    PRICES_unitName: "Unit name",
    PRICES_pluralUnitName: "Plural units name",
    PRICES_addPrice: "Add price",
    PRICES_create: "Create a price",
    PRICES_edit: "Update the price",
    PRICES_confirmCreate: "Create the price",
    PRICES_confirmEdit: "Update the price",
    PRICES_addTier: "Add a new tier",
    PRICES_removeTier: "Delete",

    PRICES_fieldName: "Name",
    PRICES_fieldAmount: "Amount",
    PRICES_fieldCurrency: "Currency",
    PRICES_fieldDescription: "Description",
    PRICES_fieldType: "Pricing model",
    PRICES_fieldTypePlaceholder: "-",
    PRICES_unlimitedSymbol: "∞",
    PRICES_unlimited: "Unlimited",
    PRICES_fieldRecurrence: "Recurrence",
    PRICES_fieldFlatFee: "Flat fee",
    PRICES_fieldMinQuantity: "Minimum quantity",
    PRICES_fieldMaxQuantity: "Maximum quantity",
    PRICES_fieldIncluded: "Included by default",
    PRICES_fieldTiers: "Tiers",
    PRICES_fieldQuantityStep: "Quantity Step",

    Prices_columnName: "Name",
    Prices_columnAmount: "Amount",
    Prices_columnCurrency: "Currency",
    Prices_columnDescription: "Description",
    Prices_columnIsRecurrent: "Recurrence",
    Prices_columnModel: "Model",

    PRICE_details: "Price details",
    PRICE_fieldPlaceholderName: "Enter the price name",
    PRICE_fieldPlaceholderDescription: "Enter the price description",
    PRICE_fieldPlaceholderUnit: "Enter the name of the unit",
    PRICE_fieldPlaceholderPluralUnits: "Enter the name of the units",

    PRICES_volume: "Volume",
    PRICES_package: "Package",
    PRICES_unit: "Unit",
    PRICES_graduated: "Graduated",

    PRICES_monthly: "Monthly",
    PRICES_yearly: "Yearly",

    PRICES_recurrenceNo: "No",
    PRICES_recurrenceYes: "Yes",

    PRICES_tierFlatFee: "Flat fee",
    PRICE_updated: "Price successfully updated",

    PRICES_fieldTypeUnit: "Unit",
    PRICES_fieldTypePackage: "Package",
    PRICES_fieldTypeVolume: "Volume",
    PRICES_fieldTypeGraduated: "Graduated",

    SUBSCRIPTIONS_linesRequired: "Please select subscription options",
    SUBSCRIPTIONS_customerRequired: "Please select a customer",

    PRICES_type: "Select a price type",
    PRICES_TypeUsed: "The selected price type is used",
    PRICE_targetNameGeneric: "Generic",
    PRICE_targetDescriptionGeneric: "Generic price",
    PRICE_archiveConfirm: "Are you sure you want to archive this price?"
};
