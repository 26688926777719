/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import { InjectKey, ComponentOptions } from "vue/types/options"
import { createDecorator, VueDecorator } from "vue-class-component";

/* The InjectReactive decorator in Vue property decorator library has a bug that breaks it
   when it's given a default value. This decorator slightly modified version that fixes the issue.
*/

const reactiveInjectKey = "__reactiveInject__";

type InjectOptions = { from?: InjectKey; default?: any };

function InjectReactive( options?: InjectOptions | InjectKey ): VueDecorator {
    return createDecorator( ( componentOptions: ComponentOptions<Vue>, key: string ): void => {
        if( componentOptions.inject === undefined )
            componentOptions.inject = {};

        if( !Array.isArray( componentOptions.inject ) ) {
            const fromKey = options !== undefined ? (options as any).from || options : key;

            const defaultVal = ( !!options && ( options as any ).default ) || undefined;

            if( !componentOptions.computed )
                componentOptions.computed = {};

            componentOptions.computed![ key ] = function(): any {
                const obj = ( this as any )[ reactiveInjectKey ];

                /* This is the line that was modified, the original only checks if a provided
                   dependency exists without making sure the property matching the injected
                   dependency also exists. Otherwise return the provided default value. */
                return obj && obj[ fromKey ] ? obj[ fromKey ] : defaultVal;
            }

            //@ts-ignore
            componentOptions.inject[ reactiveInjectKey ] = { key: reactiveInjectKey }
        }
    })
}

/* Since InjectReactive and ProvideReactive are connected to each other, it's better to have them
   both provided locally, an external libtary update could break the system. */

function ProvideReactive( key?: string | symbol ): VueDecorator {
    return createDecorator( ( componentOptions, k ): void => {
        let provide: any = componentOptions.provide;

        inheritInjected( componentOptions );

        if( needToProduceProvide( provide ) )
            provide = componentOptions.provide = produceProvide( provide );

        provide.managedReactive[ k ] = key || k;
    });
}

function inheritInjected( componentOptions: ComponentOptions<Vue> ): void {
    if( !Array.isArray( componentOptions.inject ) ) {
        componentOptions.inject = componentOptions.inject || {}
        componentOptions.inject[ reactiveInjectKey ] = {
            from: reactiveInjectKey,
            default: {}
        }
    }
}

function needToProduceProvide( original: any ): boolean {
    return typeof original !== "function" || ( !original.managed && !original.managedReactive )
}

interface ProvideObj {
    managed?: { [ k: string ]: any }
    managedReactive?: { [ k: string ]: any }
}

type provideFunc = ( ( this: any ) => object ) & ProvideObj

function produceProvide( original: any ): provideFunc {
    //@ts-ignore
    const provide: provideFunc = function ( this: any ): any {
        let rv = typeof original === "function" ? original.call( this ) : original;

        rv = Object.create( rv || null );

        // set reactive services (propagates previous services "f necessary)
        rv[ reactiveInjectKey ] = Object.create(this[ reactiveInjectKey ] || {})

        for( const i in provide.managed ) {
            if( provide.managed.hasOwnProperty( i ) )
                rv[ provide.managed[ i ] ] = this[ i ];
        }

        for( const i in provide.managedReactive ) {
            if( provide.managedReactive.hasOwnProperty( i ) ) {
                // Duplicates the behavior of `@Provide`
                rv[ provide.managedReactive[ i ] ] = this[ i ];

                Object.defineProperty(
                    rv[ reactiveInjectKey ],
                    provide.managedReactive[ i ],
                    {
                        enumerable: true,
                        get: (): any => this[ i ]
                    }
                )
            }
        }

        return rv
    };

    provide.managed = {};
    provide.managedReactive = {};

    return provide
}

export { ProvideReactive, InjectReactive };
