/* © IBS Group. See COPYRIGHT file for full copyright & licensing details. */

import BaseFilter from "./Base";
import { Filter } from "../../Contracts";

export default class TextFilter extends BaseFilter implements Filter {
    Value: ( string | number | boolean )[] = [];
    Type: string = "text";
    Operator?: "=";

    Copy(): TextFilter {
        const filter = new TextFilter( this.id, this.name, this.placeholder );

        this.Value.forEach( ( value: string | number | boolean ): void => {
            filter.Value.push( value );
        });

        return filter;
    }

    FormatValue(): ( string | number | boolean )[] {
        if( this.Value.length === 0 )
            return [];

        return [ this.Value[ 0 ] ];
    }

    Format(): string | number | boolean {
        let value = this.Value[ 0 ];

        if( value !== undefined && typeof value !== "number" )
            value = value.toString();

        return this.Value.length > 0 ? value : "";
    }
}
