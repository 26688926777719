


import { Component, Emit, Prop, Vue } from "vue-property-decorator";

import { Customer, Subscription } from "App/Models";

import BooleanField from "Web/Components/Common/Input/Boolean.vue";
import Tab from "Web/Components/Tabs/Tab.vue";
import Tabs from "Web/Components/Tabs/Index.vue";

import Form from "Web/Components/Form/Index.vue";
import FormField from "Web/Components/Form/Field.vue";
import FormFieldset from "Web/Components/Form/Fieldset.vue";

import CustomerCard from "./Customer.vue";
import SubscriptionDetails from "./Details.vue";
import QuotaCard from "./Quota.vue"

@Component({
    components: {
        BooleanField,
        CustomerCard,
        Field: FormField,
        Fieldset: FormFieldset,
        Form,
        SubscriptionDetails,
        QuotaCard,
        Tab,
        Tabs
    }
})
export default class SubscriptionForm extends Vue {
    @Prop()
    Record?: Subscription;

    @Prop()
    Customer?: Customer;

    @Emit( "Refresh" )
    private refresh() {}
}
