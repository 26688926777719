


import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";

import { Option } from "Web/Contracts";

import { Identifiable } from "App/Contracts";

function getIndex( list: Identifiable[], item: Identifiable ): number {
    for( let i = 0; i < list.length; ++i )
        if( list[ i ].Id === item.Id )
            return i;

    return -1;
}

@Component
export default class InlineSimpleSelect extends Vue {
    @Prop()
    readonly Options?: Option[];

    @Prop()
    SelectedOption?: Option;

    @Prop() public NoResultMessage!: string;

    @Prop() readonly ClassName!: string;

    @Ref( "options" )
    private readonly options?: HTMLElement[];

    private visibleOptions: Option[] = [];

    /* This variable is used to avoid using positive tabindex values. If you want to highlight
       nothing (For instance the first time we open options list) Set this to -1. */
    private highlighted: number = -1;

    created(): void {
        this.onValuesChange();
    }

    @Watch( "Options" )
    private onValuesChange() {
        if( this.Options === undefined )
            return;

        this.visibleOptions = this.Options;
    }

    private isOptionSelected( option: Option ): boolean {
        if( this.SelectedOption === undefined )
            return false;

        return this.SelectedOption.Id === option.Id;
    }

    private select( option: Option ): void {
        this.highlighted = getIndex( this.visibleOptions, option );
        this.focusHighlighted();

        this.$emit( "Select", option );
    }

    private updateHighlighted( move: number ): void {
        const optionsLength = this.visibleOptions.length;

        /* Change highlighted option on arrow keys down, reset cycle when reaching extremes. */
        if( this.highlighted === 0 && move < 0 ) {
            this.highlighted = optionsLength - 1;

        } else if( this.highlighted === optionsLength - 1 && move > 0 ) {
            this.highlighted = -1;

        } else {
            this.highlighted = this.highlighted + move;
        }

        this.focusHighlighted();
    }

    private async focusHighlighted( index?: number ): Promise<void> {
        if( index !== undefined )
            this.highlighted = index;

        await this.$nextTick();

        if( this.options !== undefined && this.options[ this.highlighted ] !== undefined )
            this.options[ this.highlighted ].focus();

        else
            this.highlighted = -1;
    }
}
