/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import { Dictionary } from "@Internal/Contracts";
import { Field, CustomValue } from "@Internal/Models";
import { Recurrence } from "Shared/Models";
import { $t } from "App/IOC";

let defaultFields: Dictionary<Field> = {};

async function Get(): Promise<Dictionary<Field>> {
    await Promise.resolve();

    if( Object.keys( defaultFields ).length === 0 )
        defaultFields = {
            Name: Field.Translatable( "Name", $t( "PRODUCTS_fieldName" ) ),
            Active: Field.Boolean( "Active", $t( "PRODUCTS_fieldActive" ) ),
            Created: Field.Text( "Created", $t( "PRODUCTS_fieldCreated" ) ),
            Description: Field.Translatable( "Description", $t( "PRODUCTS_fieldDescription" ) ),
            Sequence: Field.Number( "Sequence", $t( "PRODUCTS_fieldSequence" ) ),
            Recurrence: Field.Selection( "Recurrence", $t( "PRODUCTS_fieldRecurrence" ) )
        };

    return defaultFields;
}

function GetRecurrenceOptions(): CustomValue<Recurrence>[] {
    return [
        new CustomValue<Recurrence>(
            $t( "PRODUCTS_recurrenceNo" ), undefined as unknown as Recurrence
        ),

        new CustomValue<Recurrence>( $t( "PRODUCTS_recurrenceYes" ), "Monthly" )
    ];
}

export default {
    Get,
    GetRecurrenceOptions
};
