


import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { RegexEscape, Undefined2Zero } from "@Internal/Utils";

/* Lots of hackery in this component. */

@Component
export default class FormNumberField extends Vue {
    @Prop() public Id!: string;
    @Prop() public Placeholder!: string;
    @Prop() public Value!: number | undefined;

    @Prop( { default: false } )
    public readonly Readonly!: boolean;

    /* The field value on the original record, unlike Value prop, it remains unaffected
       by the mechanisms of the Field component. */
    @Prop() public OriginalValue?: number;

    @Prop( { default: 0 } )
    Precision!: number;

    private value: string = "";

    private format( value: string ): string {
        const decimalSeparator = this.$t( "GLOBAL_decimalSeparator" ).toString();
        const thousandSeparator = this.$t( "GLOBAL_decimalThousandSeparator" ).toString();

        return value.replace( ".", decimalSeparator )
            .replace( /\B(?=(\d{3})+(?!\d))/g, thousandSeparator );
    }

    /* We render 0 and undefined values as an empty string, but only when loading the field value
       directly from the prop. */
    @Watch( "OriginalValue", { immediate: true } )
    private onOriginalValueChange( value: number | undefined ) {
        if( Undefined2Zero( value ) === 0 )
            this.value = "";
    }
    /* Only format and assign the new value if it's diffrent from zero and local value to avoid
       double writing, since v-model already takes care of local value updates, and we want 0 value
       to be visible when it's coming from user input. */
    /* Since we pass a default value for the prop Value, we need our watcher to fire immediately
        upon component creation.  */
    @Watch( "Value", { immediate: true } )
    private onValueChange( value: number ) {
        if( Undefined2Zero( value ) !== 0 && String( value ) !== this.value )
            this.value = value === undefined ? "" : this.format( String( value ) );
    }

    @Watch( "value" )
    private onInternalValueChange( value: string, previousValue: string, changedValue?: string ) {
        const thousandSeparator = this.$t( "GLOBAL_decimalThousandSeparator" ).toString();

        /* Remove thousand separator formatting. */
        value = value.replace( new RegExp( RegexEscape( thousandSeparator ), "g" ), "" );

        /* Limit lent to trillion to prevent the number from being formatted using exponent. */
        if( value.length > 12 ) {
            this.value = previousValue;
            return;
        }

        /* No need to emit the value if it didn't change. */
        if( String( this.Value ) === value )
            return;

        const rawDecimalSeparator = this.$t( "GLOBAL_decimalSeparator" ).toString();

        /* Support both locale separator and JS decimal separator. */
        const separator = "(" + RegexEscape( rawDecimalSeparator ) + "|\\.)";

        const regex = new RegExp(
            "^([0-9]+|([0-9]+" + separator + "[0-9]{0," + this.Precision + "}))$", "g"
        );
        const decimalRegex = new RegExp( separator, "g" );

        /* Black magic to only accept numbers of the following format: 12, 12., 12.5, 12.55 */
        const dots = ( value.match( decimalRegex ) || [] ).length > ( this.Precision > 0 ? 1 : 0 );
        const invalidNumber = value !== "" && ( value.match( regex ) || [] ).length === 0;

        if( dots || invalidNumber ) {
            /* replace() is used to cover the case where we for instance type "5.", the dot gets
               replaced with the locale version. */
            this.value = previousValue;
            return;
        }

        this.value = this.format( value );

        /* If the input field is empty send an undefined value, just like we the value we receive
           from the relevant prop (Value).
           We make sure to replace the locale separator with the dot that JS understands. */

        const processedValue: number | undefined =
            value === "" ? undefined : Number( value.replace( rawDecimalSeparator, "." ) );

        let returnValue = processedValue;

        /* If the value changed from an undefined to a 0 or the other way around, replace it with
           the original value since we don't want it to be counted as a difference by the Form
           component. */
        if(
            Undefined2Zero( this.OriginalValue ) === 0 &&
            Undefined2Zero( processedValue ) === 0 &&
            processedValue !== this.OriginalValue
        )
            returnValue = this.OriginalValue;

        /* Change event is used to trigger when the element has finished changing and the text
           input loses focus. */
        if( changedValue === undefined )
            this.$emit( "Value", returnValue );

        else
            this.$emit(
                "Change",
                ( changedValue === "" ) ? undefined : this.replaceSeparators( changedValue )
            );
    }

    private replaceSeparators( value: string ): number {
        const thousandSeparator = this.$t( "GLOBAL_decimalThousandSeparator" ).toString();
        const rawDecimalSeparator = this.$t( "GLOBAL_decimalSeparator" ).toString();

        /* Remove thousand separator. */
        value = value.replace( new RegExp( RegexEscape( thousandSeparator ), "g" ), "" );

        /* Replace decimal separator with dot to return a valid number. */
        return Number( value.replace( rawDecimalSeparator, "." ) );
    }
}
