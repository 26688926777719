


import { Component, Emit, Vue, Prop } from "vue-property-decorator";

import { Activated, Identifiable, Named } from "App/Contracts";

import Modal from "Web/Components/Common/Modal.vue";

type Action = Identifiable & Named & Activated;

@Component({
    components: {
        Modal
    }
})
export default class PopupWindow extends Vue {
    @Prop( { default: "" } )
    Title!: string;

    @Prop( { default: false } )
    CloseOnAction!: boolean;

    @Prop( { default: () => [] } )
    Actions!: Action[];

    @Prop( { default: true } )
    ShowCancel!: boolean;

    /* We rely on props in order to avoid losing class attribute to Modal wrapper since it doesn't
       render a node of its own. */
    @Prop( { default: "" } )
    ClassName!: string;

    @Prop( { default: false } )
    CloseOnOverlayClick!: boolean;

    private overlayClicked() {
        if( this.CloseOnOverlayClick )
            this.close();
    }

    @Emit( "Close" )
    private close() {}

    @Emit( "Action" )
    private execute( action: Action ) {
        if( this.CloseOnAction )
            this.$emit( "Close" );

        return action.Id;
    }
}
