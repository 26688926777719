


import { Component, Prop, Ref, Vue } from "vue-property-decorator";

import { CustomValue } from "@Internal/Models";
import Form from "Web/Components/Form/Index.vue";
import FormField from "Web/Components/Form/Field.vue";
import PopupWindow from "Web/Components/Popup/Window.vue";

import { Recurrence } from "Shared/Models";

import { Products } from "App";

import { $t, EventBus } from "App/IOC";

import {
    CustomNotification,
    Field,
    NewProduct,
    Product
} from "App/Models";

import {
    Activated,
    Dictionary,
    Identifiable,
    Named
} from "App/Contracts";

@Component({
    components: {
        Field: FormField,
        Form,
        PopupWindow
    }
})
export default class ProductForm extends Vue {
    @Prop() Name?: string;

    @Ref( "form" )
    private readonly form?: Form;

    private action: Identifiable & Named & Activated = {
        Id: "create",
        Name: $t( "PRODUCTS_confirmCreate" ),
        Active: true
    };

    private fields: Dictionary<Field> = {};

    private recurrenceOptions: CustomValue<Recurrence>[] = Products.Fields.GetRecurrenceOptions();

    private record: Product = NewProduct();

    private async beforeCreate(): Promise<void> {
        this.fields = await Products.Fields.Get();
        this.record.Recurrence = this.recurrenceOptions[ 1 ].Value;
    }

    private async create(): Promise<void> {
        if( this.form === undefined )
            return;

        this.action.Active = false;

        const record: Product | undefined = this.form.GetRecord() as Product;

        const [ createdRecord, error ] = await Products.Create( record );

        if( error !== undefined ) {
            EventBus.Publish( "Error", error );
            this.action.Active = true;
            this.canCreate();

            return;
        }

        EventBus.Publish(
            "Notification",
            CustomNotification.Success(
                $t( "PRODUCTS_createSuccessTitle" ),
                $t( "PRODUCTS_createSuccessMessage" )
            )
        );

        this.$emit( "Create", createdRecord );
    }

    private canCreate(): void {
        if( this.form === undefined )
            return;

        const record = this.form.GetRecord() as Product;

        this.action.Active = record.Name !== undefined &&
            record.Sequence !== undefined &&
            record.Sequence > 0;
    }
}
