/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import { CountryCode } from "@IBS/Frontend/Utils";
import { Country } from "App/Models";
import { PhoneCodeCountries } from "App/IOC";

export function GetCountryByCode( countryCode: CountryCode ): Country | undefined {
    for( const country of PhoneCodeCountries ) {
        if( country.ShortName.toLowerCase() === countryCode.toLowerCase() )
            return country;
    }

    return undefined;
}

export * from "Shared/Utils";
export * from "@IBS/Frontend/Utils";
