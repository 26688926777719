/* © Facilogi. See LICENSE file for full copyright & licensing details. */

import { Dictionary } from "@Internal/Contracts";
import { Field } from "@Internal/Models";
import { $t } from "App/IOC";

let defaultFields: Dictionary<Field> = {};

async function Get(): Promise<Dictionary<Field>> {
    await Promise.resolve();

    if( Object.keys( defaultFields ).length === 0 )
        defaultFields = {
            Coupons: Field.Text( "Coupons", $t( "SUBSCRIPTIONS_fieldCoupon" ) )
        };

    return defaultFields;
}

export default {
    Get
};
