/* © IBS Group. See COPYRIGHT file for full copyright & licensing details. */

import Copiable from "../Contracts/Copiable";
import Dictionary from "../Contracts/Dictionary";
import Equatable from "../Contracts/Equatable";
import Identifiable from "../Contracts/Identifiable";

export default class Locale implements Copiable<Locale>, Equatable<Locale>, Identifiable {
    private id: string;
    private shortName: string;
    private name: string;
    private translations: Dictionary<string>;

    constructor( id: string, shortName: string, name: string, translations: Dictionary<string> ) {
        this.id = id;
        this.shortName = shortName;
        this.name = name;
        this.translations = translations;
    }

    get Id(): string { return this.id; }
    get ShortName(): string { return this.shortName; }
    get Name(): string { return this.name; }
    get Translations(): Dictionary<string> { return this.translations; }

    Copy(): Locale {
        /* TODO: Should we copy translations as well? */
        return new Locale( this.id, this.shortName, this.name, this.translations );
    }

    Equals( instance: Locale ): boolean {
        return this.id === instance.id;
    }
}
