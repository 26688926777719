


import { Component, Vue } from "vue-property-decorator";

import { ValidateEmail } from "@Internal/Utils";
import { Users } from "App";
import { I18n, $t } from "App/IOC";

import {
    Password,
    CustomError,
    User,
    Field
} from "App/Models";

import { NewSignIn, SignIn } from "App/YetToBeNamed";

import { Dictionary } from "App/Contracts";

import Form from "Web/Components/Form/Index.vue";
import FormField from "Web/Components/Form/Field.vue";

@Component({
    components: {
        Field: FormField,
        Form
    }
})
export default class SignInForm extends Vue {
    $refs!: Vue[ "$refs" ] & {
        Form: Form,
    };

    private record = NewSignIn();

    private fields: Dictionary<Field> = {
        Email: Field.Text( "Email", $t( "SIGNIN_login" ), { Required: true } ),
        Password: Field.Text( "Password", $t( "SIGNIN_password" ), { Required: true } )
    }

    private formIsValid: boolean = false;

    private passwordShown: boolean = false;
    private error: string = "";
    private busy: boolean = false;

    async execute() {
        if( this.busy )
            return;

        const record = this.$refs.Form.GetRecord() as SignIn;

        this.busy = true;

        const error = await Users.Authenticate(
            record.Email as string,
            record.Password as Password
        );

        this.busy = false;

        if( error !== undefined ) {
            this.error = error.Message;
            return;
        }

        this.$i18n.locale = I18n.Locale.Id;

        const user = await Users.GetCurrent();

        /* Simple field verification to make sure has completed his registration.  */
        if( ( user as User ).FirstName !== undefined )
            this.$router.replace( "/" );
        else
            this.$router.replace( "/user/registration" );
    }

    private canCreate(): void {
        const record = this.$refs.Form.GetRecord() as SignIn;

        this.formIsValid = (
            record.Email !== undefined
            && !( ValidateEmail( record.Email ) instanceof CustomError )
            && record.Password !== undefined
            && !( record.Password.Validate() instanceof CustomError )
        );
    }
}
