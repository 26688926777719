/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import { Decimal } from "App/IOC";
import { Prices } from "App/IOC/Services";
import { Price, PriceTier, Product } from "App/Models";

import Columns from "./Columns";
import Fields from "./Fields";
import Filters from "./Filters";

export function NewPrice( product: Product ): Price {
    return {
        Id: -1,
        Tiers: GetDefaultTiers(),
        FlatFee: true,
        Active: true,
        Included: false,
        QuantityStep: 1,
        Product: product
    };
}

export function GetDefaultTiers(): PriceTier[] {
    return [
        {
            Id: -1,
            MinQuantity: new Decimal( 1 ),
            MaxQuantity: new Decimal( 1 ),
            UnitPrice: new Decimal( 0 )
        }
    ]
}

export default {
    Columns,
    Create: Prices.Create,
    CreateForPlan: Prices.CreateForPlan,
    CreateForProduct: Prices.CreateForProduct,
    Delete: Prices.Delete,
    Fields,
    Filters,
    Find: Prices.Find,
    Get: Prices.Get,
    GetDefaultTiers,
    NewPrice,
    Update: Prices.Update
};
