/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import { Decimal as IDecimal, ICustomDate } from "@Internal/Contracts";
import BaseModel from "../Model";

export default interface BaseInvoice extends BaseModel {
    /* In this case, we're not mapping invoices, therefore we added the `ExernalId` property to
    receive the id returned by stripe directly. */
    ExternalId?: string;
    DownloadURL?: string;
    Name?: string;
    Status?: "Draft" | "Open" | "Paid" | "Uncollectible" | "Void";
    Total?: IDecimal;
    CreatedAt?: ICustomDate;
}
