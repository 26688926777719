/* © IBS Group. See COPYRIGHT file for full copyright & licensing details. */

import Column from "./Column";

export default class PagerSorter {
    static ForColumn(
        column: Column,
        limit?: number,
        descending: boolean = false
    ): PagerSorter {
        const pagerSorter = new PagerSorter( limit, descending );

        pagerSorter.Column = column;

        return pagerSorter;
    }

    Descending: boolean;
    Page: number = 1;
    Column: Column | undefined;
    Limit: number = 10;
    Total: number = 0;

    constructor( limit?: number, descending: boolean = false ) {
        if( limit !== undefined )
            this.Limit = limit;

        this.Descending = descending;
    }

    get Offset(): number {
        return ( this.Page - 1 ) * this.Limit;
    }

    get Pages(): number {
        return Math.ceil( this.Total / this.Limit );
    }

    Reset(): void {
        this.Page = 1;
        this.Total = 0;
    }

    Copy(): PagerSorter {
        const pagerSorter = new PagerSorter();

        pagerSorter.Descending = this.Descending;
        pagerSorter.Page = this.Page;
        pagerSorter.Column = this.Column !== undefined ? this.Column.Copy() : undefined;
        pagerSorter.Limit = this.Limit;
        pagerSorter.Total = this.Total;

        return pagerSorter;
    }
}
