


/* TODO: Split this component into smaller components. */
import { Component, Vue, Watch } from "vue-property-decorator";

import { Subscriptions } from "App";

import {
    Subscription,
    Field,
    CustomNotification
} from "App/Models";

import { Activated, Dictionary, Identifiable, Named } from "App/Contracts";

import { EventBus, $t } from "App/IOC";

import { RecordActions, RecordAction } from "Web/Contracts";

import SubscriptionForm from "./Form.vue";

import Record from "Web/Components/Record.vue";
import PopupWindow from "Web/Components/Popup/Window.vue"

@Component({
    components: {
        SubscriptionForm,
        Record,
        PopupWindow
    }
})
export default class SingleSubscriptionView extends Vue {
    private record?: Subscription = {} as Subscription;

    private fields: Dictionary<Field> = {};

    private firstStepCancellation: boolean = false;
    private secondStepCancellation: boolean = false;
    private editing: boolean = false;
    private loading: boolean = true;
    private slectedAction: RecordAction = {} as RecordAction;
    private confirmCancellationAction: Identifiable & Named & Activated = {
        Id: "confirmCancellation",
        Name: $t( "SUBSCRIPTION_confirmCancellation" ),
        Active: true
    };

    private actions: RecordActions<Subscription> = [
        [
            {
                Id: "RedirectToStripe",
                Title: $t( "SUBSCRIPTION_actionStripeRedirection" ),
                Icon: "link"
            },

            {
                Id: "Unsubscribe",
                Title: $t( "SUBSCRIPTION_actionUnsubscribe" ),
                Icon: "delete_forever",
                Enabled: this.isActionEnabled
            },

            {
                Id: "Cancel",
                Title: $t( "SUBSCRIPTION_actionCancel" ),
                Icon: "delete_forever",
                Enabled: this.isActionEnabled
            }
        ]
    ]

    private async created() {
        this.record = undefined;
        await this.setup();
    }

    private async setup() {
        this.fields = await Subscriptions.Fields.Get();

        await this.getSubscription();
    }

    /* Reset data on Subscription change*/
    private resetData() {
        this.fields = {};
    }

    @Watch( "$route" )
    private async onRouteParametersChange( from: any, to: any ) {
        if( to.name !== "subscription" || from.params.id === to.params.id )
            return;

        this.resetData();
        await this.setup();
    }

    private async getSubscription() {
        const [ record, error ] = await Subscriptions.Get( Number( this.$route.params.id ) );

        if( error !== undefined || record === undefined ) {
            EventBus.Publish( "Error", error );
            return;
        }

        this.record = record as Subscription;
    }

    private get subscriptionPlans(): string {
        return this.record !== undefined ? Subscriptions.GenerateTitle( this.record ) : "";
    }

    private execute( action: RecordAction ): void {
        switch( action.Id ) {
            case "Cancel":
            case "Unsubscribe":
                this.firstStepCancellation = true;
                this.slectedAction = action;

                break;

            case "RedirectToStripe":
                this.redirectToStripeSubscription();
                break;

            default:
                EventBus.Publish( "UnderContruction" );
                break;
        }
    }

    private redirectToStripeSubscription() {
        if( this.record === undefined )
            return;

        window.open( `https://dashboard.stripe.com/id/${this.record.ExternalId}` );
    }

    private async unsubscribe(): Promise<void> {
        if( this.record === undefined )
            return;

        this.confirmCancellationAction.Active = false;

        /* In case if the user chooses to unsubscribe from the subscription immediately we need to
           cancel this subscription, otherwise it will be canceled at the end of its period. */
        const error =
            await Subscriptions.Unsubscribe( this.record, this.slectedAction.Id === "Cancel" );

        if( error !== undefined ) {
            EventBus.Publish( "Error", error );
            return;
        }

        EventBus.Publish( "Notification", CustomNotification.Success(
            this.$t( "SUBSCRIPTION_unsubscribeSuccessTitle" ).toString(),
            this.$t( "SUBSCRIPTION_unsubscribeSuccessMessage" ).toString()
        ));

        /* Remove the last cancellation popups after cancelling the subscription. */
        this.secondStepCancellation = false;
        this.confirmCancellationAction.Active = true;
    }

    private showConfirmationSteps(): void {
        this.firstStepCancellation = false;
        this.secondStepCancellation = true;
    }

    private isActionEnabled(): boolean {
        if( this.record === undefined )
            return false;

        return !Subscriptions.IsCanceled( this.record );
    }
}

