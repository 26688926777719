/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import { Copiable } from "@Internal/Contracts";

import { BaseUser } from "Shared/Models";

export default class User extends BaseUser implements Copiable<User> {
    static New( id: number = -1 ): User {
        return new User( id );
    }

    get Id(): number { return this.id; }

    Copy(): User {
        return new User( this.id, this.Email, this.FirstName, this.LastName );
    }
}
