


import { Component, Emit, Prop, Vue } from "vue-property-decorator";

import { Customers } from "App";
import { Field, Customer, Country } from "App/Models";

import { Dictionary } from "App/Contracts";
import { PhoneCodeCountries } from "App/IOC";

import Autocomplete from "Web/Components/V2/Select/Autocomplete.vue";
import BooleanField from "Web/Components/Common/Input/Boolean.vue";
import Tab from "Web/Components/Tabs/Tab.vue";
import Tabs from "Web/Components/Tabs/Index.vue";

import Form from "Web/Components/Form/Index.vue";
import FormField from "Web/Components/Form/Field.vue";
import FormFieldset from "Web/Components/Form/Fieldset.vue";
import TextField from "Web/Components/Common/Input/Text.vue";

@Component({
    components: {
        Autocomplete,
        BooleanField,
        Field: FormField,
        Fieldset: FormFieldset,
        Form,
        Tab,
        Tabs,
        TextField
    }
})
export default class CustomerForm extends Vue {
    $refs!: Vue[ "$refs" ] & {
        Form: Form
    }

    @Prop()
    Record?: Customer;

    fields: Dictionary<Field> = {};

    mutated: boolean = false;

    private phoneCodeCountries: Country[] = PhoneCodeCountries;

    async mounted(): Promise<void> {
        this.fields = await Customers.Fields.Get();
    }

    async save( record: Customer ): Promise<void> {
        this.$emit( "Save", record );
    }

    @Emit( "Refresh" )
    private refresh(): void {}

    private async getCountries( keyword: string = "" ): Promise<[ Country[], undefined ]> {
        const countries = this.phoneCodeCountries.filter(
            ( country: Country ): boolean =>
                country.Name.toLowerCase().includes( keyword.toLowerCase() )
        );

        return [ countries, undefined ]
    }
}
