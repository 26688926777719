/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import { Dictionary } from "@Internal/Contracts";
import { Field } from "@Internal/Models";
import { $t } from "App/IOC";

let defaultFields: Dictionary<Field> = {};

async function Get(): Promise<Dictionary<Field>> {
    await Promise.resolve();

    if( Object.keys( defaultFields ).length === 0 )
        defaultFields = {
            Name: Field.Translatable( "Name", $t( "PLANS_fieldName" ) ),
            Color: Field.Text( "Color", $t( "PLANS_fieldColor" ) ),
            StartingPrice: Field.Number( "StartingPrice", $t( "PLANS_fieldStartingPrice" ) ),
            Currency: Field.Text( "Currency", $t( "PLANS_fieldCurrency" ) ),
            Description: Field.Translatable( "Description", $t( "PLANS_fieldDescription" ) ),

            PitchSubtitle: Field.Translatable(
                "PitchSubtitle",
                $t( "PLANS_fieldPitchSubtitle" )
            ),

            Pitch: Field.Translatable( "Pitch", $t( "PLANS_fieldPitch" ) ),
            CompatiblePlans: Field.IdentifiableSet(
                "CompatiblePlans",
                $t( "PLANS_fieldCompatiblePlans" )
            ),

            Trial: Field.Boolean( "Trial", $t( "PLANS_fieldTrial" ) )
        };

    return defaultFields;
}

export default {
    Get
};
