/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

export default {
    CUSTOMERS_title: "Clients",
    CUSTOMERS_create: "Créer un client",
    CUSTOMERS_edit: "Modifier le client",
    CUSTOMERS_fieldEmail: "Adresse email du compte",
    CUSTOMERS_fieldName: "Nom du client",
    CUSTOMERS_fieldStreet: "Rue",
    CUSTOMERS_fieldCity: "Ville",
    CUSTOMERS_fieldPostalCode: "Code postal",
    CUSTOMERS_fieldCompany: "Société",
    CUSTOMERS_fieldNote: "Description",
    CUSTOMERS_fieldDescription: "Description",
    CUSTOMERS_fieldCountry: "Pays",
    CUSTOMERS_fieldVAT: "Numéro de VAT",
    CUSTOMERS_fieldIsStaff: "Staff",
    CUSTOMERS_confirmCreate: "Créer le client",
    CUSTOMERS_confirmEdit: "Modifier le client",
    CUSTOMERS_columnEmail: "Email du compte ",
    CUSTOMERS_columnName: "Nom du client",
    CUSTOMERS_columnAddress: "Adresse",
    CUSTOMERS_columnCompany: "Société",
    CUSTOMERS_columnState: "Etat",
    CUSTOMERS_columnCreated: "Créé le",
    GLOBAL_filterCustomerName: "Nom",
    GLOBAL_filterCustomerPlaceholder: "Filtrer par nom",
    CUSTOMERS_filterCompany: "Société",
    CUSTOMERS_filterCompanyPlaceholder: "Filtrer par société",
    CUSTOMERS_filterEmail: "E-mail",
    CUSTOMERS_filterEmailPlaceholder: "Filtrer",

    CUSTOMER_Details: "Détails client",
    CUSTOMER_accountInformation: "Informations sur le compte",
    CUSTOMER_invoiceInformation: "Informations de facturation",
    CUSTOMER_Description: "Description",
    CUSTOMER_addDescription: "Description",
    CUSTOMER_fieldPlaceholderVAT: "Numéro de VAT",
    CUSTOMER_fieldPlaceholderEmail: "Adresse email du compte",
    CUSTOMER_fieldPlaceholderName: "Nom du client",
    CUSTOMER_fieldPlaceholderAddress: "Adresse",
    CUSTOMER_fieldPlaceholderCompany: "Société",
    CUSTOMER_fieldPlaceholderNote: "Description",
    CUSTOMERS_fieldInvoicePrefix: "Préfixe de la facture",

    CUSTOMERS_createSuccessTitle: "Notification",
    CUSTOMERS_createSuccessMessage: "Nouveau client a été créé avec succés",
    CUSTOMERS_deleteConfirm: "Êtes-vous certain de vouloir supprimer ce" +
        " client ? | Êtes-vous certain de vouloir supprimer ces clients ?",
    CUSTOMER_deleteConfirm: "Êtes-vous certain de vouloir supprimer ce client ?",
    CUSTOMERS_cannotDelete: "Certains des clients sélectionnés ne peuvent pas être supprimés",
    CUSTOMERS_label: "Client",
    CUSTOMERS_plural_label: "Clients",
    CUSTOMERS_splashScreenTitle: "Créez votre premier client",
    CUSTOMERS_splashScreenDescription: "Un client de type personne ex. Madame Pauline GERMAIN",

    CUSTOMERS_formChanged: "Pas de changements | Vous avez modifié 1 champ du client | \
    Vous avez modifié {count} champs du client",
    CUSTOMERS_updated: "Client mis à jour",

    CUSTOMERS_endTrial: "Mettre fin à la période d'essai",
    CUSTOMERS_endTrialConfirm:
        "Voulez-vous vraiment mettre fin à la période d'essai pour ce client ? | \
        Voulez-vous vraiment mettre fin à la période d'essai pour ces clients  ?",
    CUSTOMER_endTrial: "La période d'essai est terminée avec succès",

    CUSTOMERS_actionSubscriptions: "Abonnements",
    Customer_taxIdInvalid: "Numéro de VAT non valide"
};
