/* © IBS Group. See COPYRIGHT file for full copyright & licensing details. */

import { Storage } from "App/Contracts";
import { CustomError } from "App/Models";

/* We use Promise.resolve to promisify our methods since localStorage is synchronous. */
export default class LocalStorage implements Storage {

    async Set( key: string, value: string ): Promise<CustomError | undefined> {
        await Promise.resolve();

        try {
            localStorage.setItem( key, value );

        } catch( e ) {
            console.warn( e );
            return new CustomError( 1, e.toString() );
        }

        return undefined;
    }

    async Get( key: string ): Promise<[ string | undefined, CustomError | undefined ]> {
        await Promise.resolve();

        try {
            return [ localStorage.getItem( key ) || "", undefined ];

        } catch( e ) {
            console.warn( e );
            return [ undefined, new CustomError( 1, e.toString() ) ];
        }
    }

    async Remove( key: string ): Promise<CustomError | undefined> {
        await Promise.resolve();

        try {
            localStorage.removeItem( key );

        } catch( e ) {
            console.warn( e );
            return new CustomError( 1, e.toString() );
        }

        return undefined;
    }
}
