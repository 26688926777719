


import { Component, Emit, Prop, Vue } from "vue-property-decorator";

import { CustomError, CustomValue } from "@Internal/Models";

import { Recurrence } from "Shared/Models";

import { Plans, Prices, Products } from "App";
import { EventBus } from "App/IOC";
import { Field, Plan, Price, Product } from "App/Models";

import { Dictionary } from "App/Contracts";

import Tab from "Web/Components/Tabs/Tab.vue";
import Tabs from "Web/Components/Tabs/Index.vue";

import Form from "Web/Components/Form/Index.vue";
import FormField from "Web/Components/Form/Field.vue";
import FormFieldset from "Web/Components/Form/Fieldset.vue";
import BooleanField from "Web/Components/Common/Input/Boolean.vue";
import ProductPlans from "Web/Views/Products/Single/Plans/Index.vue";
import ProductPrices from "Web/Views/Products/Single/Prices/Index.vue";
import PriceTargetForm from "Web/Views/Products/Single/Prices/PriceTarget.vue";

@Component({
    components: {
        BooleanField,
        Field: FormField,
        Fieldset: FormFieldset,
        Form,
        Tab,
        Tabs,
        ProductPlans,
        ProductPrices,
        PriceTargetForm
    }
})
export default class ProductForm extends Vue {
    $refs!: Vue[ "$refs" ] & {
        Form: Form
    }

    @Prop()
    Record?: Product;

    fields: Dictionary<Field> = {};

    mutated: boolean = false;

    private recurrenceOptions: CustomValue<Recurrence>[] = Products.Fields.GetRecurrenceOptions();
    private showPriceForm: boolean = false;

    async mounted() {
        this.fields = await Products.Fields.Get();
    }

    async save( record: Product ): Promise<void> {
        this.$emit( "Save", record );
    }

    private get globalPrices(): Price[] {
        const prices: Price[] = [];

        if( this.Record === undefined )
            return prices;

        this.Record.Prices.forEach( ( price: Price ): void => {
            if( this.Record === undefined )
                return;

            let isGlobal: boolean = true;

            /* Only insert non plan prices. */
            for( const plan of this.Record.Plans ) {
                if( plan.Prices.indexOf( price ) !== -1 )
                    isGlobal = false;
            }

            if( isGlobal ) prices.push( price );
        });

        return prices;
    }

    @Emit( "Refresh" )
    private refresh() {}

    private async createPrice( price: Price, plan?: Plan ): Promise<void> {
        if( this.Record === undefined )
            return;

        let error: CustomError | undefined;

        if( plan !== undefined ) {
            /* We only force product recurrence on newly created prices for plan related prices. */
            price.Recurrence = this.Record.Recurrence;

            [ , error ] = await Prices.CreateForPlan( price, this.Record, plan );

        } else {
            [ , error ] = await Prices.CreateForProduct( price, this.Record );
        }

        if( error !== undefined ) {
            EventBus.Publish( "Error", error );
            return;
        }

        this.$emit( "Refresh" );
    }

    private async createPlan( record: Plan ) {
        if( this.Record === undefined )
            return;

        /* Set the sequence based on existing plans (Put it last by default). */
        const planCount = this.Record.Plans.length;
        if( planCount > 0 )
            record.Sequence = ( this.Record.Plans[ planCount - 1 ].Sequence || 0 ) + 1;

        const [ , error ] = await Plans.Create( record );

        if( error !== undefined ) {
            EventBus.Publish( "Error", error );
            return;
        }

        this.$emit( "Refresh", record );
    }
}
