/* © IBS Group. See COPYRIGHT file for full copyright & licensing details. */
import { TextFormat } from "App/Contracts";
import { Decimal } from "App/IOC";
import { I18n as _I18n } from "App/Models";

class FormatText implements TextFormat {
    private I18n: _I18n;

    constructor( I18n: _I18n ) {
        this.I18n = I18n;
    }

    Number( value: string | number ): string {
        const text: string = typeof value === "string" ? value : value + "";

        const decimalSeparator = this.I18n.$t( "GLOBAL_decimalSeparator" ).toString();
        const thousandSeparator = this.I18n.$t( "GLOBAL_decimalThousandSeparator" ).toString();

        const negative = value < 0 ? "-" : "";
        const [ integer, decimal ] = text.split( "." );
        const unsignedInteger = integer.replace( "-", "" );

        let result = negative + unsignedInteger
            .replace( /\B(?=(\d{3})+(?!\d))/g, thousandSeparator );

        if( decimal !== undefined )
            result += decimalSeparator + decimal;

        return result;
    }

    Abbreviate( value: number ): string {
        const decimalValue = new Decimal( value );

        if( decimalValue.IsNaN() )
            return "";

        if(
            decimalValue.GreaterThanOrEqualTo( 1000 ) &&
            decimalValue.LessThan( 1000000 )
        )
            return decimalValue.DividedBy( 1000 ).Floor() + "K";

        else if( decimalValue.GreaterThanOrEqualTo( 1000000 ) )
            return ( decimalValue.DividedBy( 1000000 ) ).Floor() + "M";

        else
            return value + "";
    }
}

export default FormatText;
