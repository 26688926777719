


import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import InputMask from "primevue/inputmask";

import { ICustomDate } from "App/Contracts";
import { $t, CustomDate, I18n } from "App/IOC";

import { Dates } from "App";

// @ts-ignore
@Component({
    // @ts-ignore
    components: {
        InputMask
    }
})
export default class DateField extends Vue {
    @Prop() public Id!: string;
    @Prop( { default: undefined } ) public Value!: ICustomDate;

    @Prop( { default: false } )
    Readonly!: boolean;

    private value: string = "";

    @Watch( "Value" )
    private onValueChange( value: ICustomDate | undefined ) {
        if( value !== undefined )
            this.value = value.Format( $t( "GLOBAL_default_standard_date_format" ) );
        else
            this.value = "";
    }

    @Watch( "value" )
    private onInternalValueChange( value: string ) {
        /* Little trick to detect if the user has fully filled the date yet. */
        if( value.includes( "_" ) )
            return;

        let date: ICustomDate | undefined;

        /* If the input field is empty send an undefined value, just like we the value we receive
           from the relevant prop (Value). */
        if( value === "" )
            date = undefined;
        else {
            /* Since we're forcing a specific source format, when we will attempt
               to send the formatted value to the backend, the locale format will be used
               (Check FromLocaleFormat implementtaion), which is not good for us, so
               to avoid this, we create a new instance, that will fall back to the default
               source format (Since we didn't specify a format argument), which is compatible with
               the backend. */
            date = Dates.FromLocaleFormat( value );
            date = new CustomDate( date.ToDate(), I18n );
        }

        /* The user provided an invalid date, revert back to the original value. */
        if( date !== undefined && date.toString() === "Invalid Date" ) {
            this.value = "";
            return;
        }

        /* No need to emit the value if it didn't change. */
        if( this.Value !== undefined && this.Value.Format() === value )
            return;

        this.$emit( "Value", date );
    }
}
