


import { Component, Vue, Emit } from "vue-property-decorator";

import Tab from "Web/Components/Tabs/Tab.vue";

@Component
export default class Tabs extends Vue {
    private tabs: Tab[] = [];

    private activeTab: Tab = {} as Tab;

    private mounted() {
        /* Filter out any none Tab components since we depend on their props/data. */
        this.tabs = this.$children.filter( ( child: Vue ) => child instanceof Tab ) as Tab[];

        let defaultTab: Tab | undefined;
        let firstEnabledTab: Tab | undefined;

        /* Find a default tab if there is any, or the first available tab to be used as default
           in case the user didn't specify it explicitely. */
        for( const tab of this.tabs ) {
            if( tab.Default && defaultTab === undefined )
                defaultTab = tab;

            if( !tab.Disabled && firstEnabledTab === undefined )
                firstEnabledTab = tab;
        }

        if( defaultTab )
            this.select( defaultTab );

        else if( firstEnabledTab )
            this.select( firstEnabledTab );
    }

    @Emit( "Select" )
    private select( tab: Tab ) {
        if( tab.Disabled )
            return undefined;

        this.activeTab.Active = false;

        tab.Active = true;

        this.activeTab = tab;
        return tab;
    }
}
