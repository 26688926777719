/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import { BasePriceTarget } from "Shared/Models";

export default interface PriceTarget extends BasePriceTarget {
    DisplayName?: string;
    PriceName?: string;
    PriceDescription?: string;
    PriceUnitName?: string;
    PricePluralUnitName?: string;
}

export function NewPriceTarget( id: number = -1 ): PriceTarget {
    return {
        Id: id
    }
}
