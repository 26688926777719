/* © IBS Group. See COPYRIGHT file for full copyright & licensing details. */

import { ICustomDate } from "../Contracts/CustomDate";
import Identifiable from "../Contracts/Identifiable";
import Metadata from "../Contracts/Metadata";

export default class BaseModel implements Identifiable, Metadata {
    Created?: ICustomDate;
    Creator?: string;
    LastUpdated?: ICustomDate;
    UpdatedBy?: string;

    protected id: number;

    constructor( id: number ) {
        this.id = id;
    }

    get Id(): number { return this.id; }
    set Id( value: number ) { this.id = value; }


    IsNew(): boolean {
        return this.id < 0;
    }
}
