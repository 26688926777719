


import { Component, Vue, Prop } from "vue-property-decorator";

import { Column, PagerSorter } from "App/Models";

@Component
export default class TableHead extends Vue {
    @Prop( { default: [] } )
    Columns!: Column[];

    @Prop( { default: () => new PagerSorter() } )
    Sorter!: PagerSorter;

    @Prop( { default: false } )
    SelectAll!: boolean;

    @Prop( { default: true } )
    Actions!: boolean;

    private toggleSort( column: Column ): void {
        if( !column.Sortable )
            return;

        const sorter = this.Sorter.Copy();

        sorter.Column = column;

        /* We clicked the same column, toggle the sort direction. */
        if( this.Sorter.Column && this.Sorter.Column.Id === sorter.Column.Id )
            sorter.Descending = !sorter.Descending;

        this.$emit( "ToggleSorter", sorter );
    }

    private toggleSelectAll() {
        this.$emit( "SelectAll", !this.SelectAll );
    }
}
