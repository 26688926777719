/* © IBS Group. See COPYRIGHT file for full copyright & licensing details. */

import Feathers from "@feathersjs/feathers";

import { AnnotatedJSON, Dictionary, Filter, HandleError, Identifiable } from "@Internal/Contracts";
import { CustomError, PagerSorter } from "@Internal/Models";
import { Models } from "@Internal";
import { Autobind } from "@Internal/Utils";

import { Service } from "App/Contracts";
import { DeepEqual, EventBus } from "App/IOC";

function CompileFilters( filters: Filter[] ): Dictionary {
    const result: Dictionary = {};

    filters.forEach( ( filter: Filter ): void => {
        result[ filter.Id ] = filter.FormatValue().toString();
    });

    return result;
}

export async function ProcessException( exception: any ): Promise<CustomError> {
    let customError: CustomError;

    /* Hack: To remove when refresh token logic is implemented. */
    if( exception.name === "NotAuthenticated" )
        EventBus.Publish( "InvalidAuthorization" );

    if( exception.Id !== undefined )
        customError = new CustomError( exception.Id, exception.Message );

    else
        customError = new CustomError( "BaseService.Get", String( exception ) );

    return customError;
}

export default class BaseService<T extends Identifiable> implements Service<T> {
    protected service: Feathers.Service<any>;

    constructor( service: Feathers.Service<any> ) {
        this.service = service;
    }

    @Autobind
    async Create( record: T ): Promise<[ T | undefined, CustomError | undefined ]> {
        try {
            const results = await this.service.create( this.serialize( record, true ) );

            return this.deserialize( results );

        } catch( error ) {
            return [ undefined, await ProcessException( error ) ];
        }
    }

    @Autobind
    async Get( id: number ): Promise<[ T | undefined, CustomError | undefined ]> {
        try {
            const record = await this.service.get( id );

            if( record === undefined )
                return [ undefined, undefined ];

            return this.deserialize( record );

        } catch( error ) {
            return [ undefined, await ProcessException( error ) ];
        }
    }

    @Autobind
    async Update(
        originalRecord: T,
        updatedRecord: T
    ): Promise<[ T | undefined, CustomError | undefined ]> {
        const record = this.countDifferentFields( originalRecord, updatedRecord );

        if( Object.keys( record ).length === 0 )
            return [ undefined, new CustomError( -1, $t( "GLOBAL_nothingToUpdate" ) ) ];

        const payload = this.serialize( record, true );

        /* HACK: `undefined` values are unfortunately ommitted by JSON.stringify which is used
           by Featherjs REST client, so we force any `undefined` value to `null` so that
           it can be sent. */
        const replacer = ( key: string, value: any ): boolean =>
            typeof value === "undefined" ? null : value;

        let results: any;

        try {
            results = await this.service.update(
                originalRecord.Id,
                JSON.parse( JSON.stringify( payload, replacer ) )
            );

        } catch( error ) {
            console.log( error );

            return [ undefined, await ProcessException( error ) ];
        }

        return this.deserialize( results );
    }

    @Autobind
    async Delete( record: T ): Promise<CustomError | undefined> {
        let error: CustomError | undefined;

        try {
            const results = await this.service.remove( record.Id );

            [ , error ] = this.deserialize( results );

        } catch( error ) {
            return ProcessException( error );
        }

        return error;
    }

    @Autobind
    async Find(
        filters: Filter[],
        pagerSorter: PagerSorter
    ): Promise<[ T[], PagerSorter, CustomError | undefined ]> {
        const query: Dictionary = {
            ...CompileFilters( filters ),
            Page: pagerSorter.Page,
            Limit: pagerSorter.Limit
        }

        if( pagerSorter.Column !== undefined ) {
            query.Sort = pagerSorter.Column.Id;
            query.Descending = pagerSorter.Descending;
        }

        let results: any;

        try {
            results = await this.service.find( { query } );

        } catch( error ) {
            return [ [], pagerSorter, await ProcessException( error ) ];
        }

        if( results.Error !== undefined )
            return [ [], pagerSorter, new CustomError( results.Error.Id, results.Error.Message ) ];

        pagerSorter = pagerSorter.Copy();
        pagerSorter.Total = results.Pagination.Total;

        const ready: T[] = [];

        /* QUESTION: Would it be a good idea to abstract this away into DeserializeRecords? */
        for( const record of results.Items ) {
            const [ instance, error ] = this.deserialize( record );

            if( error !== undefined )
                return [ [], pagerSorter, error ];

            ready.push( instance as T );
        }

        if( filters.length > 0 || pagerSorter.Limit === 1 )
            return [ ready, pagerSorter, undefined ];

        return [ ready, pagerSorter, undefined ];
    }

    protected serialize<T>( record: T, idOnly?: boolean ): AnnotatedJSON {
        return Models.Serialize( record, idOnly );
    }

    protected deserialize( result: AnnotatedJSON ): HandleError<T> {
        return Models.Deserialize<T>( result );
    }

    private countDifferentFields( originalRecord: T, updatedRecord: T ): T {
        return CountDifferentFields<T>( originalRecord, updatedRecord );
    }
}

export function CountDifferentFields<T>( originalRecord: T, updatedRecord: T ): T {
    const newRecord: Record<string, any> = {};

    for( const [ key, value ] of Object.entries( updatedRecord ) ) {
        if( DeepEqual(
            value as Record<string, any>,
            ( originalRecord as Record<string, any> )[ key ]
        ))
            continue;

        newRecord[ key ] = ( updatedRecord as Record<string, any> )[ key ];
    }

    return newRecord as T;
}
