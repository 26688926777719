var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"container",staticClass:"c2-simple-select",class:{
        'c2-simple-select--is-expanded': _vm.showOptions,
        'c2-simple-select--is-disabled': _vm.Disabled
    }},[_c('div',{staticClass:"c2-simple-select__selected-options",on:{"click":function($event){return _vm.toggleOptions()}}},[_vm._t("SelectedOption",[(_vm.SelectedOption !== undefined)?[_c('span',{staticClass:"c2-simple-select__selected-option",class:[ 'c2-simple-select__selected-option--' + _vm.SelectedOption.Id ]},[_vm._v("\n                    "+_vm._s(_vm.SelectedOption.Name || _vm.SelectedOption.ShortName || _vm.SelectedOption.Id)+"\n                ")])]:_c('span',{staticClass:"c2-simple-select__no-selected-options"},[_vm._v(_vm._s(_vm.Placeholder))])],{"SelectedOptions":_vm.SelectedOption})],2),_c('Modal',[_c('transition',{attrs:{"name":"fade"}},[(_vm.container !== undefined && _vm.showOptions)?_c('div',{directives:[{name:"strict-snap",rawName:"v-strict-snap:bottom-resize",value:({ element: _vm.$refs.container }),expression:"{ element: $refs.container }",arg:"bottom-resize"}],key:'popup'+ _vm.scrollKey,ref:"popup",staticClass:"c2-simple-select__popup",style:({
                /* Since this element is mounted outside this component, its width will
                   be arbitrary. */
                'width': _vm.container.offsetWidth + 'px'
            })},[_c('div',{staticClass:"c2-simple-select__options",attrs:{"id":_vm.Id + 'options'}},[_vm._l((_vm.visibleOptions),function(option,index){return _c('div',{key:option.Id,ref:"options",refInFor:true,staticClass:"c2-simple-select__option",class:{
                        'c2-simple-select__option--is-selected': _vm.isOptionSelected( option ),
                        'c2-simple-select__option--is-highlighted': index === _vm.highlighted
                    },attrs:{"tabindex":0},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }$event.stopPropagation();$event.preventDefault();return _vm.updateHighlighted( -1 )},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }$event.stopPropagation();$event.preventDefault();return _vm.updateHighlighted( 1 )},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();$event.preventDefault();return _vm.select( option )},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }$event.stopPropagation();$event.preventDefault();return _vm.toggleOptions( false )}],"click":function($event){return _vm.select( option )},"mouseover":function($event){return _vm.focusHighlighted( index )}}},[_vm._t("option",[_vm._v(_vm._s(option.Name || option.Id))],{"option":option,"isOptionSelected":_vm.isOptionSelected})],2)}),(_vm.visibleOptions.length === 0)?_c('div',{staticClass:"c2-select__no-options c-simple-select__no-options"},[_vm._v(_vm._s(_vm.$t( "GLOBAL_noResults" )))]):_vm._e()],2)]):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }