


import { Component, Vue, Watch } from "vue-property-decorator";

import { Coupons } from "App";

import {
    Coupon,
    Customer,
    CustomNotification,
    Field,
    Plan,
    Price
} from "App/Models";

import { Dictionary } from "App/Contracts";

import { EventBus, $t } from "App/IOC";

import { RecordActions, RecordAction } from "Web/Contracts";

import CouponForm from "./Form.vue";

import Record from "Web/Components/Record.vue";
import Accordion from "Web/Components/Accordion.vue";

@Component({
    components: {
        Accordion,
        CouponForm,
        Record
    }
})
export default class SingleCouponView extends Vue {
    private record: Coupon = {
        Id: -1,
        Name: "",
        Plans: [],
        GlobalPrices: [],
        Customers: []
    };

    private fields: Dictionary<Field> = {};

    private generalConditions: string = "";
    private showScenarios: boolean = false;
    private editing: boolean = false;
    private loading: boolean = true;

    private actions: RecordActions<Coupon> = [
        [
            {
                Id: "Delete",
                Title: $t( "GLOBAL_actionDelete" ),
                Icon: "delete_forever"
            },
            {
                Id: "Customers",
                Title: $t( "COUPONS_actionCustomers" ),
                Icon: "people"
            }
        ]
    ];

    private async created() {
        await this.setup();
        await this.generateConditions();
    }

    private async setup() {
        this.fields = await Coupons.Fields.Get();

        /* TODO: Handle invalid ID format. */
        await this.getCoupon( true );

        if( this.record.Name === undefined )
            return;

        document.title = this.record.Name;
    }

    /* Reset data on Product change*/
    private resetData() {
        this.fields = {};
        this.record = {
            Id: -1,
            Name: "",
            Plans: [],
            GlobalPrices: [],
            Customers: []
        };
    }

    private async generateConditions(): Promise<void> {
        const [ condition, error ] = await Coupons.GenerateGeneralConditions( this.record );

        if( error !== undefined || condition === undefined ) {
            EventBus.Publish( "Error", error );
            return;
        }

        this.generalConditions = condition;
    }

    /* Refresh data if navigated to a different Product
        diractly from the current page. */
    @Watch( "$route" )
    private onRouteParametersChange( from: any, to: any ) {
        if( to.name !== "coupon" || from.params.id === to.params.id )
            return;
        this.resetData();
        this.setup();
    }

    private async getCoupon( silent: boolean = false ) {
        const [ record, error ] = await Coupons.Get( Number( this.$route.params.id ) );

        if( error !== undefined || record === undefined ) {
            EventBus.Publish( "Error", error );
            return;
        }

        this.prefixWithProductName( this.record.Plans );
        this.prefixWithProductName( this.record.GlobalPrices );
        this.prefixCustomerWithEmail( this.record.Customers );

        this.record = record as Coupon;

        if( !silent )
            this.showUpdateNotification();
    }

    private prefixWithProductName( items: Plan[] | Price[] ): void {
        /* Format plan names with their respective product as prefix. */
        for( const item of items )
            item.Name = item.Product.Name + " - " + item.Name;
    }


    private prefixCustomerWithEmail( customers: Customer[] ): void {
        for( const customer of customers )
            customer.Name = customer.Email + " - " + customer.Name;
    }

    private showUpdateNotification() {
        EventBus.Publish( "Notification", CustomNotification.Success(
            this.$t( "GLOBAL_COUPON" ).toString(), this.$t( "COUPONS_updated" ).toString()
        ));
    }

    private underConstruction() {
        EventBus.Publish( "UnderConstruction" );
    }

    private update( coupon: Coupon, silent: boolean = false ) {
        this.record = coupon;

        if( !silent )
            this.showUpdateNotification();
    }

    private execute( action: RecordAction ): void {
        switch( action.Id ) {
            case "Delete":
                this.delete( this.record as Coupon );
                break;

            case "Customers":
                this.$router.push( `/customers?coupon=${this.record.Id}` );
                break;

            default:
                EventBus.Publish( "UnderConstruction" );
                break;
        }
    }

    private async delete( coupon: Coupon ) {
        EventBus.Publish( "Confirmation", {
            Props: {
                Message: this.$tc( "COUPONS_deleteConfirm", 1 ),
                Actions: [{
                    Id: "delete",
                    Name: this.$t( "GLOBAL_actionDelete" ),
                    Active: true
                }]
            },

            Events: {
                Action: async () => {
                    const error = await Coupons.Delete( coupon );

                    if( error !== undefined )
                        EventBus.Publish( "Error", error );

                    this.$router.replace( "/coupons" );
                }
            }
        })
    }

    @Watch( "record" )
    private onRecordChange( record?: Coupon ) {
        if( record === undefined )
            return;

        setTimeout( () => this.loading = record.Name === undefined, 200 );
    }

    private async save( record: Coupon ): Promise<void> {
        if( this.record === undefined || record === undefined )
            return;

        const [ updatedRecord, error ] = await Coupons.Update( this.record, record );

        if( error !== undefined || updatedRecord === undefined ) {
            EventBus.Publish( "Error", error );
            return;
        }

        this.record = updatedRecord;

        this.prefixWithProductName( this.record.Plans );
        this.prefixWithProductName( this.record.GlobalPrices );
        this.prefixCustomerWithEmail( this.record.Customers );

        this.showUpdateNotification();
    }
}
