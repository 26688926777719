/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

/* TODO: To refactor (import from App/Contracts). */
import { Decimal as IDecimal, ICustomDate } from "@Internal/Contracts";
import { Recurrence } from "../Misc";
import BaseModel from "../Model";
import BasePlan from "../Plan";
import BaseProduct from "../Product";

export default interface BaseSubscriptionLine extends BaseModel {
    Name?: string;
    Quantity: IDecimal;
    Subtotal: IDecimal;
    Archive?: boolean;
    ArchiveDate?: ICustomDate;
}

export interface BaseSubscriptionGroupedLines {
    Product: BaseProduct,
    Plan?: BasePlan,
    Recurrence?: Recurrence,
    Group: BaseSubscriptionLine[],
    Subtotal: IDecimal,
    Description?: string
}
