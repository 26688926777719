/* © Facilogi.  See LICENSE file for full copyright & licensing details. */

import { GlobalConfigurationService } from "App/IOC/Services";

import Fields from "./Fields";

export default {
    Fields,
    Get: GlobalConfigurationService.Get,
    Update: GlobalConfigurationService.Update
}
