


import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class BooleanField extends Vue {
    @Prop( { default: false } )
    Value!: boolean;

    @Prop( { default: false } )
    Disabled!: boolean;

    @Prop( { default: false } )
    readonly Checkbox!: boolean;

    get value(): boolean {
        return this.Value;
    }

    @Watch( "value" )
    private onValueChange( value: boolean ) {
        /* No need to emit the value if it didn't change or it is meant to be disabled. */
        if( this.Value === value || this.Disabled )
            return;

        this.$emit( "Value", value );
    }

    private toggle() {
        this.onValueChange( !this.value );
    }
}
