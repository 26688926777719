


import { Vue, Component, Prop, Watch } from "vue-property-decorator";

interface Overflow {
    top: boolean;
    bottom: boolean;
    right: boolean;
    left: boolean;
}

@Component
export default class Popup extends Vue {

    /* Both references are needed to detect click targets. */
    $refs!: Vue[ "$refs" ] & {
        popup: HTMLInputElement,
        trigger: HTMLButtonElement,
        wrapper: HTMLElement
    };

    @Prop( { default: false } )
    Shown!: boolean;

    /* Apply preset position. */
    @Prop( { default: "" } )
    Alignment!: "" | "left" | "right";

    /* Enable hiding popup on outside click */
    @Prop( { default: true } )
    OutsideClick!: boolean;

    /* To avoid mutating the shown prop. */
    private shown: boolean = false;

    private overflow: Overflow = {
        top: false,
        bottom: false,
        right: false,
        left: false
    };

    beforeDestroy() {
        window.removeEventListener( "mousedown", this.handleOutsideClick );
    }

    Hide() {
        this.shown = false;
    }

    /* Allow external control of visibility. */
    @Watch( "Shown", { immediate: true } )
    private ShownChanged() {
        this.shown = this.Shown;
    }

    @Watch( "shown" )
    private shownChanged( value: boolean ) {
        /* Used to synchronize external shown propery with internal shown state. */
        this.$emit( "Update", value );

        if( !value ) {
            window.removeEventListener( "mousedown", this.handleOutsideClick );

            /* Reset overflow flags */
            this.overflow.top = false;
            this.overflow.bottom = false;
            this.overflow.right = false;
            this.overflow.left = false;

            return;
        }

        this.detectPosition();

        this.$nextTick( () => {
            this.$refs.popup.focus();
            setTimeout( () => {
                window.addEventListener( "mousedown", this.handleOutsideClick );
            }, 10 );
        });

    }

    /* Detect if the popup position is in the top left of the window. */
    private detectPosition() {
        if( !this.shown )
            return;

        this.$nextTick( () => {
            const coordinates = this.$refs.popup.getBoundingClientRect();

            let calculatedHeight: number = 0;

            if( coordinates.height ) {
                calculatedHeight = coordinates.height;

            } else {
                const children = this.$refs.popup.children;

                for( const child of children )
                    calculatedHeight += child.clientHeight;
            }

            this.overflow = {
                bottom: coordinates.top + calculatedHeight > window.innerHeight,
                /* 68px refers to the navigation bar's height */
                top: coordinates.top < document.body.scrollTop - 68,
                right: coordinates.right > window.innerWidth,
                left: coordinates.left < 0
            };
        } );
    }

    private handleOutsideClick( event: Event ) {
        const target = event.target as HTMLElement;
        /* Don't do anything since we clicked inside the popup. */
        if( this.$refs.popup && this.$refs.popup.contains( target ) )
            return;

        /* To close the popup only on second trigger click */
        const outsideOff = !this.OutsideClick && !this.$refs.trigger.contains( target );

        /*  To avoid blinking popup, make sure it shows up only if
            it was invisible when the event was triggered */
        const outsideOn = !this.shown && this.$refs.trigger.contains( target );

        this.shown = outsideOff || outsideOn;
        this.detectPosition();
    }
}
