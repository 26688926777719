


import { Component, Vue } from "vue-property-decorator";

@Component( {} )
export default class SignInActions extends Vue {
    private get inviteDisabled(): boolean {
        return process.env.APP_DISABLE_INVITE === "true";
    }
}
