/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import { Product } from "App/Models";
import { CustomError } from "@Internal/Models";
import { Autobind } from "@Internal/Utils";
import { ProductsService } from "App/Contracts";

import BaseService, { ProcessException } from "./Base";

export default class Products extends BaseService<Product> implements ProductsService {
    @Autobind
    async Archive( record: Product ): Promise<CustomError | undefined> {
        try {
            const results = await this.service.update( record.Id, {
                Active: false
            });

            if( results.Error !== undefined )
                return new CustomError( results.Error.Id, results.Error.Message );

        } catch( error ) {
            return ProcessException( error );
        }

        return undefined;
    }
}
