/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import CustomError from "./CustomError";
import Copiable from "../Contracts/Copiable";
import Equatable from "../Contracts/Equatable";

// eslint-disable-next-line max-len, no-useless-escape, @typescript-eslint/no-inferrable-types
const ValidationRegex: RegExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{9,}/;

export default class Password implements Copiable<Password>, Equatable<Password> {

    static New(): Password {
        return new Password( "" );
    }

    Value: string;

    constructor( value: string ) {
        this.Value = value;
    }

    Validate(): CustomError | undefined {
        if( this.Value.length < 9 ||
            this.Value.length > 30 ||
            !ValidationRegex.test( this.Value )
        )
            return new CustomError( 1, "" );

        return undefined;
    }

    Copy(): Password {
        return new Password( this.Value );
    }

    Equals( instance: Password ): boolean {
        return this.Value === instance.Value;
    }
}
