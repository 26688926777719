/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import { Filter } from "@Internal/Contracts";

let defaultFilters: Filter[] = [];

function Get(): Filter[] {
    if( defaultFilters.length === 0 )
        defaultFilters = [];

    return defaultFilters;
}

export default {
    Get
};
