/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import Configuration from "./Configuration";
import Coupons from "./Coupons";
import Customers from "./Customers";
import Products from "./Products";
import Plans from "./Plans";
import Prices from "./Prices";
import PriceTargets from "./PriceTargets";
import Subscriptions from "./Subscriptions";

export default {
    GLOBAL_appBrand: process.env.APP_BRAND || "",

    GLOBAL_defaultDateFormat: "MMM D, YYYY",
    GLOBAL_defaultDateTimeFormat: "MMM D, YYYY at HH:mm",
    GLOBAL_decimalSeparator: ".",
    GLOBAL_decimalThousandSeparator: ",",
    GLOBAL_default_standard_date_format: "MM/DD/YYYY",
    GLOBAL_defaultStandardDateTimeFormat: "DD/MM/YY HH:mm",

    GLOBAL_sessionExpired: "Your session has expired, please sign in again.",

    SIGNIN_login: "Log in",
    SIGNIN_password: "Password",
    SIGNIN_logIn: "Log in",
    SIGNIN_forgotPassword: "Forgot your password ?",
    SIGNIN_showPassword: "Show password",
    SIGNIN_hidePassword: "Hide password",
    SIGNIN_email: "Email",

    GLOBAL_configuration: "Configuration",
    GLOBAL_products: "Products",
    GLOBAL_PRICE_TARGETS: "Price type",
    MAIN_MENU_dashboard: "Dashboard",
    MAIN_MENU_products: "Products",
    MAIN_MENU_plans: "Plans",
    MAIN_MENU_coupons: "Coupons",
    MAIN_MENU_priceTargets: "Price types",
    MAIN_MENU_customers: "Customers",
    MAIN_MENU_configuration: "Configuration",
    MAIN_MENU_subscriptionPlans: "Subscription plans",
    MAIN_MENU_subscriptions: "Subscriptions",
    MAIN_MENU_invoicing: "Invoicing",

    GLOBAL_actionDelete: "Delete",
    GLOBAL_actionUpdate: "Update",
    GLOBAL_actionDone: "I am done",

    GLOBAL_Language: "Language",

    GLOBAL_warning: "Warning",
    GLOBAL_fillRequiredField: "This field is required",
    GLOBAL_cancel: "Cancel",
    GLOBAL_internalError: "Internal server error",

    GLOBAL_tableActionSelected: "selected",
    GLOBAL_tableActionMultiSelected: "selected",

    GLOBAL_currency: "€",
    GLOBAL_percentage: "%",
    GLOBAL_total: "Total",
    GLOBAL_unknown: "Unknown",
    GLOBAL_formSave: "Save",
    GLOBAL_next: "Next",
    GLOBAL_actions: "Actions",

    GLOBAL_confirm: "Confirm",
    GLOBAL_yes: "Yes",
    GLOBAL_no: "No",
    GLOBAL_Product: "Product",
    GLOBAL_CUSTOMER: "Customer",
    GLOBAL_COUPON: "Coupon",
    GLOBAL_Price: "Price",

    GLOBAL_actionArchive: "Archive",
    GLOBAL_statusActive: "Active",
    GLOBAL_statusArchived: "Archived",

    GLOBAL_filterName: "Name",
    GLOBAL_filterStatus: "Status",
    GLOBAL_filterStatusPlaceholder: "Status",

    GLOBAL_value: "Value",
    GLOBAL_confirmAction: "Yes, I confirm",

    FILTER_or: " <b>or</b> ",
    FILTER_and: "<b>and</b>",
    FILTER_add: "Add a filter",
    FILTER_apply: "Apply",
    FILTER_contains: "Contains",
    FILTER_value: "Value",
    FILTER_filterBy: "Filter by {0}",
    FILTER_and_x_more: " and {0} more",
    FILTER_propertyTagsId: "tag",
    FILTER_propertyTypeId: "type",
    FILTER_propertySubTypeId: "sub-type",

    GLOBAL_noResults: "No results",

    GLOBAL_fieldSequence: "Sequence",

    GLOBAL_configurationCoupons: "Coupons",

    CONFIGURATION_navigationGeneral: "General",
    CONFIGURATION__yearlyCoupon: "Yearly coupon",

    TRANSLATIONS_en: "English",
    TRANSLATIONS_fr: "French",
    TRANSLATIONS_es: "Spanish",
    TRANSLATIONS_translated: "Translated",
    TRANSLATIONS_notTranslated: "Not translated",
    TRANSLATIONS_title: "Value translation",
    TRANSLATIONS_notificationTitle: "Translation",
    TRANSLATIONS_updateTitle: "Edit a translation",
    TRANSLATIONS_updated: "Translation updated successfully",

    ...Configuration,
    ...Coupons,
    ...Customers,
    ...Products,
    ...Plans,
    ...Prices,
    ...PriceTargets,
    ...Subscriptions
};
