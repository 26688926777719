


import { Component, Vue } from "vue-property-decorator";

import { Filter } from "@Internal/Contracts";
import { Coupons, Customers, Filters, Records } from "App";
import { EventBus, $t } from "App/IOC";

import CustomerForm from "./Form.vue";

import { MassActions } from "Web/Contracts";

import RecordsComponent from "Web/Components/Records.vue";

import {
    Column,
    Customer,
    CustomNotification,
    PagerSorter
} from "App/Models";

@Component( {
    components: {
        CustomerForm,
        Records: RecordsComponent
    }
})
export default class CustommersView extends Vue {
    $refs!: Vue[ "$refs" ] & {
        Records: RecordsComponent,
    };

    private columns: Column[] = [];
    private records: Customer[] = [];
    private pagerSorter: PagerSorter = PagerSorter.ForColumn(
        Customers.Columns.GetDefaultSort(),
        undefined,
        true
    );

    private filters = Customers.Filters.Get;

    private massActions: MassActions[] = [
        {
            Id: "Actions",
            Name: $t( "GLOBAL_actions" ),
            ActionGroups: [
                [
                    {
                        Id: "endTrial",
                        ShortName: "delete",
                        Name: $t( "CUSTOMERS_endTrial" ),
                        Multi: true
                    }
                ]
            ]
        }
    ];

    private showForm: boolean = false;

    private async created(): Promise<void> {
        if( this.$route.query.coupon !== undefined )
            await this.setCouponCustomersFilters( String( this.$route.query.coupon ) );

        const [ columns, error ] = await Customers.Columns.Get();

        if( error !== undefined ) {
            EventBus.Publish( "Error", error );
            return;
        }

        this.columns = columns;
    }

    private async update(
        filters: Filter[],
        pagerSorter: PagerSorter,
        resetPagination?: boolean
    ): Promise<void> {
        await this.$nextTick();
        this.$refs.Records.Loading();

        if( resetPagination )
            this.pagerSorter.Page = 1;

        const [ records, updatedPagerSorter, error ] = await Customers.Find( filters, pagerSorter );

        this.records = records;
        this.pagerSorter = updatedPagerSorter;

        if( error !== undefined ) {
            EventBus.Publish( "Error", error );
            return;
        }
    }

    private toggleColumn( column: Column ) {
        Customers.Columns.Toggle( column );
    }

    private executeAction( id: string, rows: Customer[] ): void {
        const urls: string[] = [];
        let index: number = 0;

        switch( id ) {
            case "open":
                this.records.forEach( record =>
                    urls.push( "/customers/" + record.Id )
                );

                index = this.records.findIndex( record => record.Id === rows[ 0 ].Id );

                Records.Navigation.SetList( urls, index );

                this.$router.push( `/customers/${ rows[ 0 ].Id }` );

                break;

            case "endTrial":
                EventBus.Publish( "Confirmation", {
                    Props: {
                        Message: this.$tc( "CUSTOMERS_endTrialConfirm", rows.length ),

                        Actions: [{
                            Id: "endTrial",
                            Name: this.$t( "CUSTOMERS_endTrial" ),
                            Active: true
                        }]
                    },

                    Events: {
                        Action: () => this.endTrial( rows )
                    }
                });

                break;

            default:
                EventBus.Publish( "UnderConstruction" );
                break;
        }
    }

    private async endTrial( records: Customer[] ): Promise<void> {
        this.records = [];

        for( const record of records ) {
            const error = await Customers.EndTrial( record );

            if( error !== undefined ) {
                EventBus.Publish( "Error", error );
                return;
            }

            EventBus.Publish( "Notification", CustomNotification.Success(
                this.$t( "GLOBAL_CUSTOMER" ).toString(), this.$t( "CUSTOMER_endTrial" ).toString()
            ));
        }

        this.refresh();
    }

    private refresh(): void {
        this.showForm = false;
        this.update( [], this.pagerSorter, true );

    }

    private async setCouponCustomersFilters( id: string ): Promise<void> {
        const [ coupon, error ] = await Coupons.Get( Number( id ) );

        if( error !== undefined || coupon === undefined ) {
            EventBus.Publish( "Error", error );
            return;
        }

        await Filters.Assign( "CouponCustomers", this.filters(), "Coupon", [ coupon ] );
    }

    private get id(): string {
        if( this.$route.query.coupon !== undefined )
            return "CouponCustomers";

        return "Customers";
    }
}
