/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import { Dictionary } from "@Internal/Contracts";
import { Field } from "@Internal/Models";

let defaultFields: Dictionary<Field> = {};

async function Get(): Promise<Dictionary<Field>> {
    await Promise.resolve();

    if( Object.keys( defaultFields ).length === 0 )
        defaultFields = {
            Value: Field.Text( "Value", $t( "FILTER_value" ) )
        };

    return defaultFields;
}

export default {
    Get
};
