/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

export default {
    PRODUCTS_title: "Products",
    PRODUCTS_create: "Create a product",
    PRODUCTS_splashScreenTitle: "Create your first product",
    PRODUCTS_splashScreenDescription:
        "A product is the most important element of subscriptions",

    PRODUCTS_label: "Product",
    PRODUCTS_plural_label: "Products",

    PRODUCTS_createSuccessTitle: "Notification",
    PRODUCTS_createSuccessMessage: "New product created successfuly",

    PRODUCTS_columnName: "Name",
    PRODUCTS_columnState: "State",
    PRODUCTS_columnDescription: "Description",
    PRODUCTS_fieldPlaceholderDescription: "Add a description...",
    PRODUCTS_columnCreatedAt: "Created at",
    PRODUCTS_columnCreatedBy: "Created by",
    PRODUCTS_columnSequence: "Sequence",

    PRODUCTS_confirmCreate: "Create a product",
    PRODUCTS_fieldPlaceholderName: "Input product name",
    PRODUCTS_deleteConfirm: "Are you sure you want to delete this product?",
    PRODUCTS_archiveConfirm: "Are you sure you want to archive this product?",

    PRODUCTS_formChanged: "No changes | You have changed {} field of the property | \
    You have changed {count} fields of the property",

    PRODUCT_actions: "Actions",
    PRODUCT_of: "of",

    PRODUCT_Details: "Details",
    PRODUCT_Description: "Description",
    PRODUCT_plans: "Plans",
    PRODUCT_prix: "Prices",

    PRODUCTS_fieldName: "Name",
    PRODUCTS_fieldActive: "Active",
    PRODUCTS_fieldArchived: "Archived",

    PRODUCTS_fieldDescription: "Description",
    PRODUCTS_fieldCreated: "Creation date",
    PRODUCTS_fieldSequence: "Sequence",
    PRODUCTS_fieldPlaceholderSequence: "Specifiy display order...",

    PRODUCTS_addDescription: "Type a description",
    PRODUCTS_addPlan: "Add a plan",
    PRODUCTS_updated: "Product successfully updated",
    PRODUCT_prices: "Global prices",

    PRODUCTS_recurrenceNo: "No",
    PRODUCTS_recurrenceYes: "Yes",
    PRODUCTS_fieldRecurrence: "Recurrence"
};
