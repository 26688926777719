/* © IBS Group. See COPYRIGHT file for full copyright & licensing details. */

import {
    Copiable,
    Equatable,
    Identifiable
} from "../Contracts";

import CustomError from "../Models/CustomError";

export function Autobind(
    // eslint-disable-next-line
    target: any,
    key: string,
    descriptor: PropertyDescriptor
): PropertyDescriptor {
    return {
        get(): void {
            return descriptor.value.bind( this );
        }
    };
}

export function ValidateEmail( value: string ): CustomError | undefined {
    // eslint-disable-next-line max-len, no-useless-escape, @typescript-eslint/no-inferrable-types
    const ValidationRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;

    if( value.length <= 0 || !ValidationRegex.test( value ) )
        return new CustomError( 100, "" );

    return undefined;
}

export function Equals(
    instance1: Equatable<any> | undefined,
    instance2: Equatable<any> | undefined
): boolean {
    if( instance1 !== undefined && instance2 !== undefined ) {
        return instance1.Equals( instance2 );
    }
    else
        /* Compare undefined values. */
        return instance1 === instance2;
}

export function Copy( instance: Copiable<any> | undefined ): any {
    return instance !== undefined ? instance.Copy() : undefined;
}

export function CopySet( existingSet: Copiable<any>[] ): any {
    const newSet: Copiable<any>[] = [];

    existingSet.forEach( ( item: Copiable<any> ): void => {
        newSet.push( item.Copy() );
    });

    return newSet;
}

export function CompareSet(
    set1: Equatable<any>[] | undefined,
    set2: Equatable<any>[] | undefined
): boolean {
    if( set1 === undefined || set2 === undefined )
        return set1 === set2;

    /* A difference in size is an automatic difference. */
    if( set1.length !== set2.length )
        return false;

    const differentItems = set1.filter( ( item: Equatable<any> ): boolean =>
        !set2.some( ( targetItem: Equatable<any> ): boolean => item.Equals( targetItem ) )
    );

    return differentItems.length === 0;
}

export async function Sleep( millisecondsDelay: number ): Promise<void> {
    return new Promise<void>( ( resolve ): number => setTimeout( resolve, millisecondsDelay ) );
}

export function CountDifferentFields(
    instance1: Identifiable,
    instance2: Identifiable,
    primitives: string[],
    identifiables: string[],
    sets: string[]
): number {
    let count = primitives.length + identifiables.length + sets.length;

    /* Using casting to silence TypeScript error. */
    primitives.forEach( ( field: string ): void => {
        count -= Number( ( instance1 as any )[ field ] === ( instance2 as any )[ field ] );
    });

    identifiables.forEach( ( field: string ): void => {
        count -= Number( Equals( ( instance1 as any )[ field ], ( instance2 as any )[ field ] ) );
    });

    sets.forEach( ( field: string ): void => {
        count -= Number(
            CompareSet( ( instance1 as any )[ field ], ( instance2 as any )[ field ] )
        );
    });

    return count;
}

/* Since we can't control received values from the outside world, its best we change them to
   undefined if we get `null`. */
export function Null2Undefined<T = any>( value: T | null ): T | undefined {
    return value === null ? undefined : value;
}

/* The same applies to the opposite, the outside world doesn't support undefined. */
/* We return the empty string because the backend doesn't support the "null" json keyword. */
export function Undefined2Null<T>( value: T ): any {
    return value === undefined ? null : value;
}

/* From: https://gist.github.com/fr-ser/ded7690b245223094cd876069456ed6c */
export function Debounce<F extends( ...params: any[] ) => void>( func: F, delay: number ): F {
    let timeoutID: NodeJS.Timeout;

    return function( this: F, ...args ): void {
        clearTimeout( timeoutID );

        timeoutID = setTimeout( (): void => func.apply( this, args ), delay );
    } as F;
}

export function TrimText( text: string | undefined ): string | undefined {
    return text === undefined ? undefined : text.replace( /\s+/g, " " ).trim();
}

export function RegexEscape( literal_string: string ): string {
    return literal_string.replace( /[-[\]{}()*+!<=:?./\\^$|#\s,]/g, "\\$&" );
}

/* Some external receptors do not accept null as a value for number type fields.
   This insures undefined values are returned as zero instead. */
export function Undefined2Zero( value?: number ): number {
    return value === undefined ? 0 : value;
}

export function Null2Zero( value: number | null ): number {
    return value === null ? 0 : value;
}
