


import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import { Column } from "App/Models";
import { Identifiable, Dictionary } from "App/Contracts";

import FieldBoolean from "Web/Components/Table/Fields/Boolean.vue";
import FieldSubscriptionStatus from "Web/Components/Table/Fields/SubscriptionStatus.vue";
import FieldText from "Web/Components/Table/Fields/Text.vue";
import Status from "Web/Components/Table/Fields/Status.vue";
import FieldCouponGeneralConditions from "Web/Components/Table/Fields/CouponGeneralConditions.vue";
import SubscriptionPlans from "Web/Components/Table/Fields/SubscriptionPlans.vue";

@Component( {
    components: {
        FieldCouponGeneralConditions,
        FieldBoolean,
        FieldSubscriptionStatus,
        FieldText,
        Status,
        SubscriptionPlans
    }
} )
export default class TableBody extends Vue {
    @Prop() public Rows!: Identifiable[];
    @Prop() public Columns!: Column[];

    // eslint-disable-next-line
    @Prop( { default: () => ( row: Identifiable ) => true } )
    IsSelectable!: ( row: Identifiable ) => boolean;

    @Prop( { default: true } )
    Actions!: boolean;

    /* We use a map to get an o(1) performance. Note: Must use Vue.set() whenever this field is
       mutated to maintain Vue reactivity. */
    private selectedRows: Dictionary = {};

    /* Keep track of the last selected */
    private lastSelected: Identifiable | undefined;

    SelectAll( yes: boolean ) {
        /* Clean up all keys to avoid missing entries after pagination. */
        this.selectedRows = {};

        this.Rows.forEach( ( row: Identifiable ) => {
            if( !this.IsSelectable( row ) )
                return;

            Vue.set( this.selectedRows, row.Id, yes );
        });
    }

    @Watch( "Rows" )
    private setSelected() {
        this.selectedRows = {};

        this.Rows.forEach( row => {
            Vue.set( this.selectedRows, row.Id, false );
        });
    }

    private toggleSelection( row: Identifiable ) {
        const selected: boolean = !this.selectedRows[ row.Id ];

        this.lastSelected = selected ? row : undefined;

        Vue.set( this.selectedRows, row.Id, selected );
    }

    /* Handle interval selection on shift click. */
    private intervalSelection( row: Identifiable ) {
        /* Save the previously selected row to be able to build the selection interval. */
        const lastSelected = this.lastSelected;

        /* Update the selection state of this row. */
        this.toggleSelection( row );

        /* If the previous row was unselected/never selected, or this row was unselected,
           then nothing else should happen. */
        if( lastSelected === undefined || this.lastSelected === undefined )
            return;

        this.lastSelected = row;

        const [ start, finish ] = [
            this.Rows.indexOf( lastSelected ),
            this.Rows.indexOf( row )
        ].sort( ( a, b ) => a - b );

        for( let i = start; i <= finish; i++ )
            Vue.set( this.selectedRows, this.Rows[ i ].Id, true );
    }


    @Watch( "selectedRows", { deep: true } )
    private onSelectedRows() {
        const rows: Identifiable[] = [];

        this.Rows.forEach( ( row: Identifiable ) => {
            if( this.selectedRows[ row.Id ] )
                rows.push( row );
        });

        this.$emit( "Select", rows );
    }

    private getComponent( column: Column ): string {
        switch( column.Type ) {
            case "Status":
                return "Status";

            case "SubscriptionPlans":
                return "SubscriptionPlans";

            case "SubscriptionStatus":
                return "FieldSubscriptionStatus";

            case "Boolean":
                return "FieldBoolean";

            case "FieldCouponGeneralConditions":
                return "FieldCouponGeneralConditions";

            default:
                return "FieldText";
        }
    }

    private rowAction( row: Identifiable, action: string ) {
        this.$emit( "RowAction", action, [ row ] );
    }
}
