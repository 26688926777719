/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import BaseCustomer, { BaseCustomerGroup } from "./Base/Customer";
import BaseGlobalConfiguration from "./Base/GlobalConfiguration";
import BaseModel from "./Base/Model";
import BasePlan from "./Base/Plan";
import BaseProduct from "./Base/Product";
import BaseUser from "./Base/User";

import IBasePrice, {
    IsUnitBased,
    IsPackage,
    IsTiered,
    PriceTypes as TPriceTypes
} from "./Base/Price";

import { Recurrence as TRecurrence } from "./Base/Misc";

import BasePriceTarget from "./Base/PriceTarget";

import BaseTier from "./Base/Tier";
import {
    BaseInvoice,
    BaseSubscription as TBaseSubscription,
    BaseSubscriptionCoupon as _BaseSubscriptionCoupon,
    BaseSubscriptionLine,
    BaseSubscriptionGroupedLines,
    DiscountDetails as IDiscountDetails,
    Quote as IQuote,
    SubscriptionStatus as ISubscriptionStatus
} from "./Base/Subscription";

export {
    CustomError,
    CustomNotification,
    Locale,
    Password
} from "@Internal/Models";

import BasePaymentMethod from "./Base/PaymentMethod";
import BaseModelFieldValueTranslation from "./Base/ModelFieldValueTranslation";


export type PriceTypes = TPriceTypes;
export type BasePrice<
    P extends BasePriceTarget,
    K extends BaseProduct,
    T extends BaseTier,
    M extends BasePlan
> = IBasePrice<P, K, T, M>;
export type Recurrence = TRecurrence;
export type SubscriptionStatus = ISubscriptionStatus;
export type BaseSubscription<
    T extends BasePlan<BaseProduct>,
    I extends BaseInvoice,
    J extends BaseCustomer,
    K extends BasePaymentMethod<BaseCustomer>,
    M extends _BaseSubscriptionCoupon,
    N extends BaseSubscriptionLine
> =
    TBaseSubscription<T, I, J, K, M, N>;

export type Quote = IQuote;
export type DiscountDetails = IDiscountDetails;

export type BaseSubscriptionCoupon<
    P extends BasePlan<BaseProduct>,
    T extends BasePrice<BasePriceTarget, BaseProduct, BaseTier, BasePlan>,
    K extends BaseCustomer
> =
    _BaseSubscriptionCoupon<P, T, K>;

export {
    BaseCustomer,
    BaseCustomerGroup,
    BaseModelFieldValueTranslation,
    BaseGlobalConfiguration,
    BaseInvoice,
    BaseModel,
    BasePaymentMethod,
    BasePlan,
    BasePriceTarget,
    BaseProduct,
    BaseSubscriptionLine,
    BaseSubscriptionGroupedLines,
    BaseTier,
    BaseUser,
    IsPackage,
    IsTiered,
    IsUnitBased
};
