


import { Vue, Component, Prop, Watch, Emit } from "vue-property-decorator";
import PerfectScrollbar from "perfect-scrollbar";

import {
    Option,
    OptionRequest
} from "Web/Contracts";

import { Identifiable } from "App/Contracts";

import Popup from "Web/Components/Common/Popup.vue";

function getIndex( list: Identifiable[], item: Identifiable ): number {
    return list.findIndex( listItem => listItem.Id === item.Id );
}

/* Move selected options to the beginning of the options array. */
function sortSelected( options: Option[], selected: Option[] ) {
    const selectedOptions: Option[] = [];
    const nonSelectedOptions: Option[] = [];

    options.forEach( option => {
        if( selected.some(
            selectedOption => selectedOption.Id === option.Id
        ))
            selectedOptions.push( option );
        else
            nonSelectedOptions.push( option )
    });

    return [ ...selectedOptions, ...nonSelectedOptions ];
}

@Component( {
    components: {
        Popup
    }
})
export default class SortedBaseSelect extends Vue {
    $refs!: Vue[ "$refs" ] & {
        options: HTMLElement[],
        filter: HTMLElement,
        popup: Popup,
        optionContainer: HTMLElement
    };

    @Prop() public Id!: string;

    /* Using a function to return the default value is forced by the compiler.
       This is apparently required for array/object props for some reason. */

    /* To fetch data when popup is visible. */
    @Prop()
    GetOptions!: Option[] | OptionRequest;

    @Prop( { default: () => [] } )
    SelectedOptions!: Option[];

    /* Whether we should display a filtering input. */
    @Prop( { default: false } )
    Filter!: boolean;

    /* Delegate filtering operation to parent component */
    @Prop( { default: false } )
    RemoteFilter!: boolean;

    /* Placeholder for the filtering input. */
    @Prop( { default: "-" } )
    Placeholder!: string;

    @Prop( { default: Infinity } )
    MaxSelections!: number;

    @Prop( { default: 0 } )
    MinSelections!: number;

    @Prop( { default: false } )
    SelectAll!: boolean;

    @Prop( { default: false } )
    ShowOptions!: boolean;

    /* TODO: Handle hide on selection flag. */
    @Prop( { default: false } )
    HideOnSelect!: boolean;

    /* Whether to delegate the filtering to GetOptions function */
    @Prop( { default: true } )
    OutsideClick!: boolean;

    /* Should we display the shortened name of the selected option? */
    @Prop( { default: false } )
    ShortName!: boolean;

    /* Whether to use v-bar directive on options wrapper. */
    @Prop() public Scrollable!: boolean;

    visibleOptions: Option[] = [];

    /* Filtering keyword. */
    keyword: string = "";

    /* This variable is used to avoid using positive tabindex values. */
    highlighted: number = 0;

    showOptions: boolean = false;

    /* First empty option in non required selections. */
    noOption = {} as Record<string, any>;

    isLoading: boolean = false;

    mounted() {
        const optionsContainer = document.getElementById( this.Id + "options" );

        if( !optionsContainer ) {
            return;
        }

        new PerfectScrollbar( optionsContainer );
    }

    created() {
        this.optionsChanged();

        if( this.MinSelections === 0 ) {
            this.noOption = { Id: -1, Name: "—" };
        }
    }

    @Watch( "ShowOptions", { immediate: true } )
    async showOptionsChanged() {
        this.showOptions = this.ShowOptions;

        if( this.showOptions )
            this.focusHighlighted();
        else if( this.$refs.filter )
            this.$refs.filter.blur();
    }

    @Watch( "GetOptions" )
    async optionsChanged() {
        if( Array.isArray( this.GetOptions ) ) {
            if( this.GetOptions.length === 0 )
                return;

            let options: Option[];

            /* Sort visible options to show selected options first. */
            if( this.SelectedOptions.length === 0 )
                options = this.GetOptions;
            else
                options = sortSelected( this.GetOptions, this.SelectedOptions );

            this.visibleOptions.splice( 0, Infinity, ...options );

        } else {
            let options: Option[];
            if( this.SelectedOptions.length === 0 )
                options = await this.GetOptions();
            else
                options = sortSelected( await this.GetOptions(), this.SelectedOptions );

            this.visibleOptions.splice( 0, Infinity, ...options );
        }
    }

    @Watch( "keyword" )
    async keywordChanged( keyword: string ) {

        /* Use either fetch function or provided options. */
        if( !Array.isArray( this.GetOptions ) ) {

            this.isLoading = true;

            const options = await this.GetOptions( this.RemoteFilter ? keyword: undefined );
            if( !options ) {
                this.isLoading = false;
                return;
            }

            this.visibleOptions = this.RemoteFilter ? options : options.filter(
                ( option: Option ) =>
                    option.Name.toLowerCase().includes( keyword.toLowerCase() )
            );
            this.isLoading = false;

        } else {
            if( this.GetOptions.length === 0 )
                return;
            this.visibleOptions = this.GetOptions.filter(
                ( option: Option ) =>
                    option.Name.toLowerCase().includes( keyword.toLowerCase() )
            );
        }

        this.highlighted = 0;
    }

    /* To update the external display flag, or trigger
        other events like fetching data. */
    @Emit( "Shown" )
    updateShown( shown?: boolean) {
        return (shown !== undefined) ? shown: this.showOptions;
    }

    /* Handle option selection differently based on MaxSelection. */
    /* Remove disableHide later */
    async select( option: Option ) {
        const options = this.visibleOptions;

        const index = getIndex( this.SelectedOptions, option );

        this.highlighted = getIndex( options, option );
        this.focusHighlighted();

        const selectedOptions = [ ...this.SelectedOptions ];

        if( this.MaxSelections === 1 ) {
            selectedOptions.pop();
            selectedOptions.push( option );
        } else {
            if( index === -1 ) {
                /* Prevent selection over limit. */
                if( this.SelectedOptions.length < this.MaxSelections )
                    selectedOptions.push( option );
            } else
                selectedOptions.splice( index, 1 );
        }
        this.$emit(
            "Select",
            this.MaxSelections === 1 ? selectedOptions[ 0 ] : selectedOptions
        );

        if( this.HideOnSelect ) {
            this.keyword = "";

            setTimeout( () => {
                this.$refs.popup.Hide();
            }, 10 );
        }
    }

    updateHighlighted( move: number ): void {
        const optionsLength = this.visibleOptions.length;

        /* Change highlighted option on arrow keys down, reset cycle when reaching extremes. */
        if( this.highlighted === 0 && move < 0 ) {
            this.highlighted = optionsLength - 1;
        } else if( this.highlighted === optionsLength - 1 && move > 0 ) {
            this.highlighted = 0;
        } else {
            this.highlighted = this.highlighted + move;
        }
        this.focusHighlighted();
    }

    focusHighlighted( index?: number ) {
        if( index !== undefined )
            this.highlighted = index;

        this.$nextTick( () => {
            if( this.Filter && this.$refs.filter )
                this.$refs.filter.focus();

            else if( this.$refs.options.length > this.highlighted
                && this.$refs.options[ this.highlighted ] ) {
                this.$refs.options[ this.highlighted ].focus();

            } else
                this.highlighted = 0;
        });
    }

    /* Handles emitted visibility changes,
        loads data if fetch function is provided. */
    async toggleOptions( value?: boolean ) {
        this.highlighted = 0;

        const currentState = this.showOptions;

        this.showOptions = value !== undefined ? value : !this.showOptions;

        this.updateShown();

        if( this.showOptions ) {
            if( !currentState && !Array.isArray( this.GetOptions ) ) {
                this.isLoading = true;
                if( this.RemoteFilter )
                    this.visibleOptions = await this.GetOptions( this.keyword );
                else {
                    const options = await this.GetOptions();
                    this.visibleOptions = options.filter(
                        ( option: Option ) =>
                            option.Name.toLowerCase().includes( this.keyword.toLowerCase() )
                    );
                }

                this.isLoading = false;
            }

            this.focusHighlighted();

        } else if( this.$refs.filter )
            this.$refs.filter.blur();
    }

    isOptionSelected( option: Option ): boolean {
        if( !this.SelectedOptions )
            return false;

        return this.SelectedOptions.some(
            ( selectedOption: Option ) => selectedOption.Id === option.Id
        );
    }

    selectAll() {
        let selectedOptions: Option[] = [];

        /* If some items aren't selected, select all, otherwise we deselect everything,
           basically a toggle selection feature. */
        if( this.SelectedOptions.length !== this.visibleOptions.length )
            selectedOptions = [ ...this.visibleOptions ];

        this.$emit(
            "Select",
            selectedOptions.slice( 0, this.MaxSelections )
        );
    }

    deselect( option: Option ) {
        const index = getIndex( this.SelectedOptions, option );

        this.highlighted = 0;

        const selectedOptions = [ ...this.SelectedOptions ];

        selectedOptions.splice( index, 1 );

        this.$emit(
            "Select",
            this.MaxSelections === 1 ? selectedOptions[ 0 ] : selectedOptions
        );
    }
}
