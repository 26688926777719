


import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import { Chrome } from "vue-color";

import Modal from "Web/Components/Common/Modal.vue";

@Component({
    components: {
        Chrome,
        Modal
    }
})
export default class ColorInput extends Vue {
    @Prop() public Value!: string;

    @Ref( "container" )
    private readonly container?: HTMLElement;

    @Ref( "toggleButton" )
    private readonly toggleButton?: HTMLElement;

    private value: string = "";

    private showPicker: boolean = false;

    private colors: string[] = [
        "ffaf16", "ff624c", "ff466d", "b44076",
        "00c5a5", "009aac", "9863b7", "2a5198"
    ]

    beforeDestroy(): void {
        window.removeEventListener( "click", this.handleOutsideClick );
    }

    get pickerColor() {
        if( this.colors.indexOf( this.Value ) !== -1 || this.Value === undefined )
            return undefined;
        else
            return this.Value;
    }

    @Watch( "showPicker" )
    private showOptionsChanged( opened: boolean ): void {
        /* This is an optimization to avoid listening to the clicking event when
           the calendar popup is invisible. */

        if( opened ) {
            window.addEventListener( "click", this.handleOutsideClick );
            return;
        }

        window.removeEventListener( "click", this.handleOutsideClick );
    }

    @Watch( "Value" )
    private onValueChange( value: string ) {
        this.value = value;
    }

    @Watch( "value" )
    private onInternalValueChange( value: string ) {
        /* No need to emit the value if it didn't change. */
        if( this.Value === value )
            return;

        /* If the input field is empty send an undefined value, just like we the value we receive
           from the relevant prop (Value). */
        this.$emit( "Value", value === "" ? undefined : value );
    }

    /* TODO: This is repetitive, refactor into a mixin. */
    private handleOutsideClick( event: Event ): void {
        const target = event.target as HTMLElement;

        /* Ignore clicks that are coming from elements inside our component, otherwise we break
           their functionalities. */
        if(
            this.container === undefined ||
            this.toggleButton === undefined ||
            this.container.contains( target ) ||
            this.toggleButton.contains( target )
        )
            return;

        this.showPicker = false;
    }
}
