/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import { Filter } from "@Internal/Contracts";
import { CustomValue, SelectionFilter, TextFilter } from "@Internal/Models";

let defaultFilters: Filter[] = [];

function Get(): Filter[] {
    const statusActive = new CustomValue<boolean>( $t( "PRODUCTS_fieldActive" ), true );
    const statusArchived = new CustomValue<boolean>( $t( "PRODUCTS_fieldArchived" ), false );

    if( defaultFilters.length === 0 ) {
        defaultFilters = [
            new TextFilter(
                "Name",
                $t( "GLOBAL_filterName" ),
                $t( "GLOBAL_filterNamePlaceholder" )
            ),

            new SelectionFilter(
                "Active",
                $t( "GLOBAL_filterStatus" ),
                [
                    statusActive,
                    statusArchived
                ],
                $t( "GLOBAL_filterStatusPlaceholder" )
            )
        ];
    }

    return defaultFilters;
}

export default { Get };
