


import { Vue, Component } from "vue-property-decorator";

import { Locales } from "App";
import { I18n } from "App/IOC";
import { Locale } from "App/Models";


import Ocean from "Web/Components/Common/Ocean.vue";
import SimpleSelect from "Web/Components/V2/Select/Simple.vue";

@Component({
    components: {
        Ocean,
        SimpleSelect
    }
})
export default class User extends Vue {
    private locales: Locale[] = I18n.SupportedLocales;

    private currentLocale: Locale = I18n.Locale;

    private created() {
        this.currentLocale = I18n.Locale;
    }

    private selectLocale( locale: Locale ) {
        this.currentLocale = locale;
        this.$i18n.locale = locale.Id;

        Locales.Cache( locale );
        I18n.Locale = locale;
    }

}
