

import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class PlaceholderCard extends Vue {
    @Prop( { default: false } )
    Shown!: boolean;

    @Prop( { default: 3 } )
    Lines!: number;

    @Prop( { default: true } )
    Picture!: boolean;

    @Prop( { default: true } )
    Title!: boolean;

    @Prop( { default: "" } )
    Id!: string;
}
