/* © IBS Group. See COPYRIGHT file for full copyright & licensing details. */

import BaseFilter from "./Base";
import { Filter } from "../../Contracts";

export default class BooleanFilter extends BaseFilter implements Filter {
    Value: boolean[] = [];
    Type: string = "boolean";

    Copy(): BooleanFilter {
        const filter = new BooleanFilter( this.id, this.name, this.placeholder );

        this.Value.forEach( ( value: boolean ): void => {
            filter.Value.push( value );
        });

        return filter;
    }

    FormatValue(): boolean[] {
        if( this.Value.length === 0 )
            return [];

        return [ this.Value[ 0 ] ];
    }

    Format(): string {
        const value = this.Value[ 0 ];

        return value !== undefined ? value.toString() : "";
    }
}
