


import { Component, Prop, Ref, Vue } from "vue-property-decorator";

import BooleanField from "Web/Components/Common/Input/Boolean.vue";
import Form from "Web/Components/Form/Index.vue";
import FormField from "Web/Components/Form/Field.vue";
import PopupWindow from "Web/Components/Popup/Window.vue";
import FormFieldset from "Web/Components/Form/Fieldset.vue";

import { Customers } from "App";

import { $t, EventBus, PhoneCodeCountries } from "App/IOC";

import {
    CustomNotification,
    Field,
    Customer,
    Country
} from "App/Models";

import {
    Activated,
    Dictionary,
    Identifiable,
    Named
} from "App/Contracts";

@Component({
    components: {
        BooleanField,
        Field: FormField,
        Fieldset: FormFieldset,
        Form,
        PopupWindow
    }
})
export default class CustomerForm extends Vue {
    @Prop() Name?: string;

    @Ref( "form" )
    private readonly form?: Form;

    private action: Identifiable & Named & Activated = {
        Id: "create",
        Name: $t( "CUSTOMERS_confirmCreate" ),
        Active: true
    };

    private fields: Dictionary<Field> = {};

    private record: Customer = {
        Id: -1,
        Name: ""
    };

    private phoneCodeCountries: Country[] = PhoneCodeCountries;

    private differentInvoiceEmail: boolean = true;

    private async beforeCreate(): Promise<void> {
        this.fields = await Customers.Fields.Get();
    }

    private async create(): Promise<void> {
        if( this.form === undefined )
            return;

        this.action.Active = false;

        const record: Customer | undefined = this.form.GetRecord() as Customer;

        const [ createdRecord, error ] = await Customers.Create( record );

        if( error !== undefined ) {
            EventBus.Publish( "Error", error );
            this.action.Active = true;
            this.canCreate();

            return;
        }

        EventBus.Publish(
            "Notification",
            CustomNotification.Success(
                $t( "CUSTOMERS_createSuccessTitle" ),
                $t( "CUSTOMERS_createSuccessMessage" )
            )
        );

        this.$emit( "Create", createdRecord );
    }

    private canCreate(): void {
        if( this.form === undefined )
            return;

        const record = this.form.GetRecord() as Customer;

        this.action.Active = record.Name !== undefined && record.Email !== undefined;
    }

    private async getCountries( keyword: string = "" ): Promise<[ Country[], undefined ]> {
        const countries = this.phoneCodeCountries.filter(
            ( country: Country ): boolean =>
                country.Name.toLowerCase().includes( keyword.toLowerCase() )
        );

        return [ countries, undefined ]
    }
}
