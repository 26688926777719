


/* TODO: Split this component into smaller components. */
import { Component, Vue, Watch } from "vue-property-decorator";

import { TextFilter } from "@Internal/Models";

import { Customers } from "App";

import {
    Customer,
    CustomNotification,
    Field
} from "App/Models";

import { Dictionary } from "App/Contracts";

import { EventBus, $t } from "App/IOC";

import { RecordActions, RecordAction } from "Web/Contracts";

import CustomerForm from "./Form.vue";

import Record from "Web/Components/Record.vue";
import Accordion from "Web/Components/Accordion.vue";

@Component({
    components: {
        Accordion,
        CustomerForm,
        Record
    }
})
export default class SingleCustomerView extends Vue {
    private record: Customer = {
        Id: -1,
        Name: ""
    };

    private fields: Dictionary<Field> = {};

    private showScenarios: boolean = false;
    private editing: boolean = false;
    private loading: boolean = true;

    private actions: RecordActions<Customer> = [
        [
            {
                Id: "Subscriptions",
                Title: $t( "CUSTOMERS_actionSubscriptions" ),
                Icon: "format_list_bulleted"
            }
        ]
    ];

    private created() {
        this.setup();
    }

    private async setup() {
        this.fields = await Customers.Fields.Get();

        /* TODO: Handle invalid ID format. */
        await this.getCustomer( true );

        if( this.record.Name === undefined )
            return;

        document.title = this.record.Name;
    }

    /* Reset data on Product change*/
    private resetData() {
        this.fields = {};
        this.record = {
            Id: -1,
            Name: ""
        };
    }

    /* Refresh data if navigated to a different Product
        diractly from the current page. */
    @Watch( "$route" )
    private onRouteParametersChange( from: any, to: any ) {
        if( to.name !== "customer" || from.params.id === to.params.id )
            return;
        this.resetData();
        this.setup();
    }

    private async save( record: Customer ) {
        if( this.record === undefined || record === undefined )
            return;

        const [ updatedRecord, error ] =
            await Customers.Update( this.record, record );

        if( error !== undefined ) {
            EventBus.Publish( "Error", error );
            return;
        }

        this.record = updatedRecord as Customer;

        this.showUpdateNotification();
    }


    private async getCustomer( silent: boolean = false ) {
        const [ record, error ] = await Customers.Get( Number( this.$route.params.id ) );

        if( error !== undefined || record === undefined ) {
            EventBus.Publish( "Error", error );
            return;
        }

        /* Get plans. */
        const filter = new TextFilter( "CustomerId", "" );
        filter.Value = [ record.Id ];

        this.record = record as Customer;

        if( !silent )
            this.showUpdateNotification();
    }

    private showUpdateNotification() {
        EventBus.Publish( "Notification", CustomNotification.Success(
            this.$t( "GLOBAL_CUSTOMER" ).toString(), this.$t( "CUSTOMERS_updated" ).toString()
        ));
    }

    private underConstruction() {
        EventBus.Publish( "UnderConstruction" );
    }

    private update( customer: Customer, silent: boolean = false ) {
        this.record = customer;

        if( !silent )
            this.showUpdateNotification();
    }

    private execute( action: RecordAction ): void {
        switch( action.Id ) {
            case "Subscriptions":
                this.$router.push( `/subscriptions?customer=${this.record.Id}` );
                break;

            default:
                EventBus.Publish( "UnderConstruction" );
                break;
        }
    }

    @Watch( "record" )
    private onRecordChange( record?: Customer ) {
        if( record === undefined )
            return;

        setTimeout( () => this.loading = record.Name === undefined, 200 );
    }
}
