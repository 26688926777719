/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

export default {
    PRICE_TARGETS_title: "Price types",
    PRICES_description: "Description",
    PRICE_TARGETS_label: "Price type",
    PRICE_TARGETS_plural_label: "Price types",
    PRICE_TARGETS_create: "Create a price type",
    PRICE_TARGETS_confirmCreate: "Create the price type",
    PRICE_TARGETS_confirmUpdate: "Update the price type",

    PRICE_TARGETS_fieldName: "Technical name",
    PRICE_TARGETS_fieldDisplayName: "Internal Name",
    PRICE_TARGETS_fieldDescription: "Internal Description",
    PRICE_TARGETS_fieldPriceName: "Price name",
    PRICE_TARGETS_fieldPriceDescription: "Price description",
    PRICE_TARGETS_fieldPriceUnitName: "Price unit name",
    PRICE_TARGETS_fieldPricePluralUnitName: "Price plural units name",

    PRICE_TARGETS_fieldPlaceholderTechnicalName: "Price type technical name",
    PRICE_TARGETS_fieldPlaceholderName: "Price type name",
    PRICE_TARGETS_fieldPlaceholderDescription: "Price type description",

    PRICE_TARGETS_columnName: "Name",
    PRICE_TARGETS_columnDescription: "Description",

    PRICE_TARGETS_deleteConfirm: "Are you sure you want to delete this price type ? | " +
        "Are you sure you want to delete these price types ?",
    PRICE_TARGETS_cannotDelete: "Some of the selected price types cannot be deleted",

    PRICE_TARGETS_splashScreenTitle: "Create your first price type",
    PRICE_TARGETS_splashScreenDescription: "",

    PRICE_TARGETS_updated: "Price type updated",

    PRICE_TARGET_details: "Details",
    PRICE_TARGET_formChanged: "No changes | You have changed {} field of the price type | " +
        "You have changed {count} fields of the price type",

    PRICE_TARGETS_createSuccessTitle: "Notification",
    PRICE_TARGETS_createSuccessMessage: "New price type created successfully",

    PRICE_TARGET_cannotDeleteHasAssociatedPrices: "You cannot delete this type of price",
    PRICE_TARGET_cannotUpdateHasAssociatedPrices: "You cannot update this type of price",
    PRICE_TARGET_duplicatedName: "You cannot duplicate this price type name"
};
