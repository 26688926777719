/* © IBS Group. See LICENSE file for full copyright & licensing details. */
import { DirectiveOptions } from "vue";

const directive: DirectiveOptions = {
    bind: ( el: HTMLElement, binding ): void => {
        const offset = binding.value;

        el.style.position = "absolute";

        if( offset.top !== undefined ) {
            el.style.top = String( offset.top ) + "px";
        }

        if( offset.right !== undefined ) {
            el.style.right = String( offset.right ) + "px";
        }

        if( offset.bottom !== undefined ) {
            el.style.bottom = String( offset.bottom ) + "px";

        }
        if( offset.left !== undefined ) {
            el.style.left = String( offset.left ) + "px";
        }
    }
};

export default directive;
