/* © Facilogi. See LICENSE file for full copyright & licensing details. */

import { $t } from "App/IOC";
import { Column, CustomError } from "App/Models";
import Columns from "App/UseCases/Columns";

let sort: Column;
let columns: Column[] = [];

function GetDefaultSort(): Column {
    /* To avoid an issue with $t being empty because it has yet to be injected to Ioc because if
       we put this instantiation at the module level here, it will get executed during
       node module importation step, which is before we inject the actual $t() implementation into
       our Ioc (Through Web/Main.ts), so we wrap it in a funtion. */
    if( sort === undefined )
        sort = Column.Static( "LanguageId", $t( "GLOBAL_Language" ) );

    return sort;
}

function getDefault(): Column[] {
    if( columns.length === 0 )
        columns = [
            GetDefaultSort(),
            Column.Static( "Value", $t( "FILTER_value" ) ),
            Column.Static( "Status", $t( "GLOBAL_filterStatus" ) ),
            Column.Static( "UpdateAction", "" )
        ];

    return columns;
}

async function Get(): Promise<[ Column[], CustomError | undefined ]> {
    return await Columns.Get( "ModelFieldValueTranslations", getDefault() );
}

async function Toggle( existingColumn: Column ): Promise<CustomError | undefined> {
    return await Columns.Toggle( "ModelFieldValueTranslations", existingColumn, getDefault() );
}

export default {
    GetDefaultSort,
    Get,
    Toggle
};
