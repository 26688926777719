var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-translation"},[_c('div',{staticClass:"c-form__field-value"},[_c('TextField',{attrs:{"Id":_vm.Id,"Placeholder":_vm.Placeholder,"Value":_vm.Value,"Readonly":_vm.Readonly,"Size":_vm.Size},on:{"Value":function (value) { return _vm.$emit( 'Value', value ); }}}),(_vm.Record !== undefined && _vm.Record.Id !== -1)?_c('i',{staticClass:"material-icons-outlined notranslate",on:{"click":function($event){_vm.showTranslations = true}}},[_vm._v("public")]):_vm._e()],1),(_vm.Record !== undefined && _vm.Record.Id !== -1)?[(_vm.showTranslations)?_c('PopupRecords',{key:_vm.translationsKey,attrs:{"ClassName":"c-translation__popup","Id":'PriceTargetTranslation',"Actions":[{
                Id: 'done',
                Name: _vm.$t( 'GLOBAL_actionDone' ),
                Active: true
            }],"Title":_vm.$t( 'TRANSLATIONS_title' ),"Find":_vm.find,"GetColumns":_vm.getColumns,"PagerSorter":_vm.pagerSorter,"MultiFilter":false,"Pagination":false},on:{"Action":_vm.done,"Close":_vm.done},scopedSlots:_vm._u([{key:"Cell(LanguageId)",fn:function(ref){
            var Row = ref.Row;
return [_c('div',{staticClass:"c-translation__language"},[_c('span',{class:[
                            Row.LanguageId === 'en'
                            ? 'flag flag-gb'
                            : 'flag flag-' + Row.LanguageId
                        ]}),_vm._v("\n\n                    "+_vm._s(_vm.$t( "TRANSLATIONS_" + Row.LanguageId ))+"\n                ")])]}},{key:"Cell(Value)",fn:function(ref){
                        var Row = ref.Row;
return [_vm._v(_vm._s(Row.Value))]}},{key:"Cell(Status)",fn:function(ref){
                        var Row = ref.Row;
return [_c('span',{class:[ 'c-translation__status',
                        Row.Value === ''
                            ? 'c-translation__status--not-valid'
                            : 'c-translation__status--valid'
                        ]}),_c('span',[_vm._v(_vm._s(_vm.$t(
                        Row.Value !== '' ? 'TRANSLATIONS_translated' : 'TRANSLATIONS_notTranslated'
                    )))])]}},{key:"Cell(UpdateAction)",fn:function(ref){
                    var Row = ref.Row;
return [_c('div',{on:{"click":function($event){return _vm.updateTranslation( Row )}}},[_vm._v(_vm._s(_vm.$t( "GLOBAL_actionUpdate" )))])]}}],null,false,3771944168)}):_vm._e(),(_vm.showUpdateTranslation)?_c('PopupWindow',{attrs:{"Title":_vm.$t( 'TRANSLATIONS_updateTitle'  ),"Actions":[ _vm.action ]},on:{"Action":_vm.update,"Close":function($event){_vm.showUpdateTranslation = false}}},[_c('div',{staticClass:"c-translation__value c2-form__field-value"},[_c('span',{class:[
                    _vm.translation.LanguageId === 'en'
                    ? 'flag flag-gb'
                    : 'flag flag-' + _vm.translation.LanguageId
                ]}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.updatedTranslationValue),expression:"updatedTranslationValue"}],attrs:{"type":"text"},domProps:{"value":(_vm.updatedTranslationValue)},on:{"input":function($event){if($event.target.composing){ return; }_vm.updatedTranslationValue=$event.target.value}}})])]):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }