/* © IBS Group. See COPYRIGHT file for full copyright & licensing details. */

import {
    Copiable,
    Equatable,
    Identifiable,
    Named
} from "../Contracts";

export default class CustomValue<T> implements
    Identifiable, Copiable<CustomValue<T>>, Equatable<CustomValue<T>>, Named {

    private name: string;
    private value: T;

    constructor( name: string, value: T ) {
        this.name = name;
        this.value = value;
    }

    get Id(): string { return String( this.value ); }
    get Name(): string { return this.name; }
    get ShortName(): string { return this.Id; }
    get Value(): T { return this.value; }

    toString(): string { return String( this.name ); }

    Copy(): CustomValue<T> {
        return new CustomValue<T>( this.name, this.value );
    }

    Equals( instance: CustomValue<T> ): boolean {
        return this.name === instance.name && this.value === instance.value;
    }
}
