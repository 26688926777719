


import { Component, Prop, Vue, Watch } from "vue-property-decorator";

/* TODO: Replace this with V2 SimpleSelect. */
import SimpleSelect from "Web/Components/Select/Simple.vue";

@Component({
    components: {
        SimpleSelect
    }
})
export default class TextFilterForm extends Vue {
    @Prop( { default: "" } )
    Value!: string;

    private internalValue: string = "";

    private created() {
        this.internalValue = this.Value;
    }

    @Watch( "Value" )
    private onValueChange( value: string ) {
        this.internalValue = value;
    }

    @Watch( "internalValue" )
    private onInternalValueChange( value: string ) {
        this.$emit( "Value", value );
    }
}
