/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import { ICustomDate } from "@Internal/Contracts";

import { Recurrence } from "./Misc";
import BaseModel from "./Model";

export default interface BaseProduct extends BaseModel {
    CreatedAt?: ICustomDate;
    Sequence: number;
    Active: boolean;
    Creator?: string;
    Recurrence?: Recurrence;
}
