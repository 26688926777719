/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import BaseModel from "../Model";
import { ICustomDate, Decimal as IDecimal } from "@Internal/Contracts";

import { Recurrence } from "../Misc";
import BasePlan from "../Plan";
import BaseCoupon from "./Coupon";
import BaseCustomer from "../Customer";
import BasePaymentMethod from "../PaymentMethod";
import BaseProduct from "../Product";
import BaseSubscriptionCoupon from "./Coupon";
import BaseInvoice from "./Invoice";
import BaseSubscriptionLine, { BaseSubscriptionGroupedLines } from "./Line";

type SubscriptionStatus = "Trial" | "Canceled" | "Offer" | "Active" | "Unpaid" | "PastDue" |
"Incomplete" | "Expired";

export interface Quote {
    TaxAmount: IDecimal,
    TaxPercentage: IDecimal,
    AmountSubtotal: IDecimal,
    AmountTotal: IDecimal
}

interface DiscountDetails {
    Expired?: boolean;
    ApplicationDate: ICustomDate;
    ExpirationDate?: ICustomDate;
    FixedAmount?: number;
    Percentage?: number;
    Duration?: number;
}

export interface BaseSubscription<
    P extends BasePlan<BaseProduct>,
    I extends BaseInvoice,
    J extends BaseCustomer,
    K extends BasePaymentMethod<BaseCustomer>,
    M extends BaseCoupon,
    N extends BaseSubscriptionLine
> extends BaseModel {
    CreatedAt?: ICustomDate;
    Note?: string;
    Status?: SubscriptionStatus;
    Plans?: P[];
    Invoices: I[];
    NextInvoice?: I;
    Deadline?: ICustomDate;
    Trial?: number;
    RemainingTrialDays?: number;
    Customer: J;
    PaymentMethod?: K;
    Coupons?: M[];
    Lines: N[];
    GrossTotal: IDecimal;
    DiscountedRecurringTotal: IDecimal;
    DiscountedUserCost: IDecimal;
    LaunchCost: IDecimal;
    GrossLaunchCost: IDecimal;
    Recurrence?: Recurrence;
    FirstPayment?: ICustomDate;
    NextInvoiceDate?: ICustomDate;
    Processing?: boolean;
    PaymentFailureReason?: string;
    RemainingPaymentRetries?: number;
    CancellationDate?: ICustomDate;
    ExternalId?: string;
    DiscountDetails?: DiscountDetails;
}

export {
    BaseInvoice,
    BaseSubscriptionCoupon,
    BaseSubscriptionLine,
    BaseSubscriptionGroupedLines,
    DiscountDetails,
    SubscriptionStatus
}
