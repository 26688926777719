


import { Component, Emit, Prop, Vue } from "vue-property-decorator";

import { CustomError, PagerSorter } from "@Internal/Models";
import InlineSimpleSelect from "@Internal/Vue2/Select/InlineSimple.vue";

import { PriceTargets } from "App";
import { NewPriceTarget, Price, PriceTarget } from "App/Models";
import { Activated, Identifiable, Named } from "App/Contracts";
import { $t, EventBus } from "App/IOC";

import PopupWindow from "Web/Components/Popup/Window.vue";

import PriceForm from "./Form.vue";

@Component({
    components: {
        PriceForm,
        InlineSimpleSelect,
        PopupWindow
    }
})
export default class PriceTargetForm extends Vue {
    @Prop() ExistingPrices?: Price[];

    @Prop( { default: false } )
    readonly Global!: boolean;

    private targetSelected: boolean = false;
    private priceTypes: PriceTarget[] = [];

    private selectedType?: PriceTarget = {} as PriceTarget;

    private loading: boolean = false;

    private action: Identifiable & Named & Activated = {
        Id: "next",
        Name: $t( "GLOBAL_next" ),
        Active: false
    }

    async created(): Promise<void> {
        this.selectedType = undefined;

        const [ records, , error ] = await PriceTargets.Find( [], new PagerSorter() );

        if( error !== undefined )
            EventBus.Publish( "Error", error );

        const customTarget = NewPriceTarget();
        customTarget.Name = $t( "PRICE_targetNameGeneric" );
        customTarget.Description = $t( "PRICE_targetDescriptionGeneric" );
        customTarget.Unique = false;

        records.push( customTarget )

        this.priceTypes = records;

        this.loading = true;
    }

    @Emit( "Close" )
    private close() {}

    private select( target: PriceTarget ) {
        let targetExist: boolean = false;

        if(
            target.Id !== -1 &&
            this.ExistingPrices !== undefined &&
            target.Unique
        ) {
            targetExist = this.ExistingPrices.some(
                ( price: Price ) => (
                    ( price.Target !== undefined ) &&
                    ( price.Target.Id === target.Id )
                )
            );
        }

        if( targetExist ) {
            this.action.Active = false;
            return EventBus.Publish( "Error", CustomError.User( $t( "PRICES_TypeUsed" ) ) );
        }

        this.action.Active = true;
        this.selectedType = target.Id !== -1 ? target : undefined;
    }

    @Emit( "Create" )
    private createPrice() {
        this.close();
    }
}
