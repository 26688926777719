/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

export default {
    CUSTOMERS_title: "Customers",
    CUSTOMERS_create: "Create a customer",
    CUSTOMERS_edit: "Update the customer",
    CUSTOMERS_fieldEmail: "Acount email",
    CUSTOMERS_fieldName: "Customer name",
    CUSTOMERS_fieldStreet: "Street",
    CUSTOMERS_fieldCity: "City",
    CUSTOMERS_fieldPostalCode: "Postal code",
    CUSTOMERS_fieldCompany: "Company",
    CUSTOMERS_fieldNote: "Note",
    CUSTOMERS_fieldDescription: "Note",
    CUSTOMERS_fieldCountry: "Country",
    CUSTOMERS_fieldVAT: "VAT number",
    CUSTOMERS_fieldIsStaff: "Staff",
    CUSTOMERS_confirmCreate: "Create the customer",
    CUSTOMERS_confirmEdit: "Update the customer",
    CUSTOMERS_columnEmail: "Account email",
    CUSTOMERS_columnName: "Name",
    CUSTOMERS_columnAddress: "Address",
    CUSTOMERS_columnCompany: "Company",
    CUSTOMERS_columnNote: "Note",
    CUSTOMERS_columnState: "State",
    CUSTOMERS_columnCreated: "Created at",
    GLOBAL_filterCustomerName: "Name",
    GLOBAL_filterCustomerPlaceholder: "Filter by name",
    CUSTOMERS_filterCompany: "Company",
    CUSTOMERS_filterCompanyPlaceholder: "Filter by company",
    CUSTOMERS_filterEmail: "Email",
    CUSTOMERS_filterEmailPlaceholder: "Filter",

    CUSTOMER_Details: "Customer Details",
    CUSTOMER_accountInformation: "Account information",
    CUSTOMER_invoiceInformation: "Invoice information",
    CUSTOMER_Description: "Note",
    CUSTOMER_addDescription: "Note",
    CUSTOMER_fieldPlaceholderVAT: "VAT number",
    CUSTOMER_fieldPlaceholderEmail: "Email",
    CUSTOMER_fieldPlaceholderName: "Client name",
    CUSTOMER_fieldPlaceholderAddress: "Address",
    CUSTOMER_fieldPlaceholderCompany: "Company",
    CUSTOMER_fieldPlaceholderNote: "Note",
    CUSTOMERS_fieldInvoicePrefix: "Invoice prefix",

    CUSTOMERS_createSuccessTitle: "Notification",
    CUSTOMERS_createSuccessMessage: "New Customer created successfully",
    CUSTOMERS_deleteConfirm: "Are you sure you want to delete this customer ? | \
        Are you sure you want to delete these customers ?",
    CUSTOMER_deleteConfirm: "Are you sure you want to delete this customer ?",
    CUSTOMERS_cannotDelete: "Some of the selected customers cannot be deleted",
    CUSTOMERS_label: "Customer",
    CUSTOMERS_plural_label: "Customers",
    CUSTOMERS_splashScreenTitle: "Create your first customer",
    CUSTOMERS_splashScreenDescription: "A person type customer eg. Mrs Pauline GERMAIN",

    CUSTOMERS_formChanged: "No changes | You have changed {} field of the customer | \
        You have changed {count} fields of the customer",
    CUSTOMERS_updated: "Customer updated",

    CUSTOMERS_endTrial: "End the trial period",
    CUSTOMERS_endTrialConfirm: "Are you sure you want to end trial period for this customer ? | \
        Are you sure you want to end trial period for these customers ?",
    CUSTOMER_endTrial: "The trial period ended successfully",

    CUSTOMERS_actionSubscriptions: "Subscriptions",
    Customer_taxIdInvalid: "Invalid tax number"
};
