


import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import InputMask from "primevue/inputmask";
import Calendar from "primevue/calendar";

import { Dates } from "App";
import { CustomDate } from "App/IOC";
import { ICustomDate } from "App/Contracts";

import Popup from "Web/Components/Common/Popup.vue";

// @ts-ignore
@Component({
    // @ts-ignore
    components: {
        Calendar,
        InputMask,
        Popup
    }
})
export default class TextField extends Vue {
    @Prop() public Id!: string;
    @Prop() public Placeholder!: string;
    @Prop() public Value!: string;

    @Prop( { default: false } )
    Readonly!: boolean;

    private value: Date = new Date();

    @Watch( "Value" )
    private onValueChange( value: ICustomDate ) {
        if( value instanceof CustomDate )
            this.value = value.ToDate();
    }

    @Watch( "value" )
    private onInternalValueChange( value: Date ) {
        const time = Dates.FromDateTime( value ).Format( "HH:mm" );

        /* No need to emit the value if it didn't change. */
        if( this.Value === time )
            return;

        this.value = value;

        this.$emit( "Value", time );
    }
}
