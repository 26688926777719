/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import { BaseProduct } from "Shared/Models";

import Plan from "./Plan";
import Price from "./Price";

export default interface Product extends BaseProduct {
    Name?: string;
    Description?: string;
    Plans: Plan[];
    Prices: Price[];
}

export function NewProduct( id: number = -1 ): Product {
    return {
        Id: id,
        Sequence: 0,
        Plans: [],
        Prices: [],
        Active: true
    };
}
