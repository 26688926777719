/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

export default {
    GLOBAL_CONFIGURATION_title: "General configuration",
    GLOBAL_CONFIGURATION_fieldYearlyCoupon: "Yearly coupon",
    GLOBAL_CONFIGURATION_fieldTrial: "Trial",
    GLOBAL_CONFIGURATION_fieldNote: "Note",
    GLOBAL_CONFIGURATION_addNote: "Type a note",
    GLOBAL_CONFIGURATION_fieldRetries: "Subscription payment retries",
    GLOBAL_CONFIGURATION_fieldMonthlySEPA: "The minimum cost to use a SEPA direct debit monthly",
    GLOBAL_CONFIGURATION_fieldYearlySEPA: "The minimum cost to use a SEPA direct debit annually",
    GLOBAL_CONFIGURATION_fieldPaymentStatementDescription:
        "The business name that will show up on your customer's bank statements",

    GLOBAL_CONFIGURATION_formChanged: "No changes | You have changed {} field of the global " +
        "configuration | You have changed {count} fields of the global configuration",

    GLOBAL_CONFIGURATION_notificationTitle: "Global Configuration",
    GLOBAL_CONFIGURATION_updated: "Global notification updated successfully"
};
