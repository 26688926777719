/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import { Dictionary } from "@Internal/Contracts";
import { Field } from "@Internal/Models";
import { $t } from "App/IOC";

let defaultFields: Dictionary<Field> = {};

async function Get(): Promise<Dictionary<Field>> {
    await Promise.resolve();

    if( Object.keys( defaultFields ).length === 0 )
        defaultFields = {
            Name: Field.Text( "Name", $t( "PRICE_TARGETS_fieldName" ) ),
            DisplayName: Field.Translatable( "DisplayName", $t( "PRICE_TARGETS_fieldDisplayName") ),
            Description: Field.Text( "Description", $t( "PRICE_TARGETS_fieldDescription" ) ),
            PriceName: Field.Translatable( "PriceName", $t( "PRICE_TARGETS_fieldPriceName" ) ),
            PriceDescription: Field
                .Translatable( "PriceDescription", $t( "PRICE_TARGETS_fieldPriceDescription" ) ),

            PriceUnitName: Field
                .Translatable( "PriceUnitName", $t( "PRICE_TARGETS_fieldPriceUnitName" ) ),

            PricePluralUnitName: Field.Translatable(
                "PricePluralUnitName",
                $t( "PRICE_TARGETS_fieldPricePluralUnitName" )
            )
        };

    return defaultFields;
}

export default {
    Get
};
