/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import { Decimal as IDecimal } from "@Internal/Contracts";
import BaseModel from "./Model";

export default interface BaseTier extends BaseModel {
    Sequence?: number | string;
    MinQuantity: IDecimal;
    MaxQuantity: IDecimal;
    UnitPrice: IDecimal;
}
