/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import { Plan, Price } from "App/Models";
import { Products } from "App/IOC/Services";

import Columns from "./Columns";
import Fields from "./Fields";
import Filters from "./Filters";

function PrefixWithProductName( items: Plan[] | Price[] ): void {
    for( const item of items )
        item.Name = item.Product.Name + " - " + item.Name;
}

export default {
    Archive: Products.Archive,
    Columns,
    Create: Products.Create,
    Fields,
    Filters,
    Find: Products.Find,
    Get: Products.Get,
    PrefixWithProductName,
    Update: Products.Update
};
