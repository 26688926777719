/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import BaseModel from "./Model";
import BaseProduct from "./Product";

export default interface BasePlan<P = BaseProduct> extends BaseModel {
    Sequence?: number;
    Active?: boolean;
    StartingPrice?: number;
    Currency?: string;

    Color?: string;
    IsDefault?: boolean;
    Product: P;

    CompatiblePlans: BasePlan[];

    Trial: boolean;
}
