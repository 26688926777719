


import { Component, Prop, Vue } from "vue-property-decorator";

import { Subscription, GroupedLinesTargetByProduct } from "App/Models";
import { Subscriptions } from "App";
import { EventBus } from "App/IOC";

import Tab from "Web/Components/Tabs/Tab.vue";
import Tabs from "Web/Components/Tabs/Index.vue";
import PlaceholderCard from "Web/Components/Placeholders/PlacolderCard.vue";
import Form from "Web/Components/Form/Index.vue";
import FormField from "Web/Components/Form/Field.vue";
import FormFieldset from "Web/Components/Form/Fieldset.vue";

@Component({
    components: {
        Field: FormField,
        Fieldset: FormFieldset,
        Form,
        PlaceholderCard,
        Tab,
        Tabs
    }
})
export default class QuotaCard extends Vue {
    @Prop()
    Record?: Subscription;

    private groupedLines: GroupedLinesTargetByProduct[] = [];
    private loading: boolean = true;

    async created(): Promise<void> {
        await this.groupLines();
    }

    private async groupLines(): Promise<void> {
        if( this.Record === undefined )
            return;

        const [ groupedLines, error ] =
            await Subscriptions.GroupPriceTargetedLinesByProduct( this.Record );

        if( error !== undefined ) {
            EventBus.Publish( "Error", error );
            return;
        }

        this.groupedLines = groupedLines;
        this.loading = false;
    }
}
