


import { Component, Vue, Prop } from "vue-property-decorator";

import { Subscription } from "App/Models";
import { Subscriptions } from "App";

@Component
export default class SubscriptionPlans extends Vue {
    @Prop() public Row!: Subscription;

    private get subscriptionPlans(): string {
        return Subscriptions.GenerateTitle( this.Row );
    }
}
