


import { Component, Vue } from "vue-property-decorator";

import { Configuration, Users } from "App";
import { EventBus } from "App/IOC";
import { Dictionary } from "App/Contracts";
import { CustomError, CustomNotification, User } from "App/Models";

import Popup from "Web/Components/Common/Popup.vue";
import Modal from "Web/Components/Common/Modal.vue";
import UnderConstruction from "Web/Views/UnderConstruction.vue";

@Component({
    components: {
        Modal,
        Popup,
        UnderConstruction
    }
})
export default class Menu extends Vue {
    $refs!: Vue[ "$refs" ] & {
        SignoutPopup: Popup
    }

    private quickCreationForm: string = "";
    private quickCreateTitle: string = "";
    private quickCreateSuccess: string = "";
    private yearlyCouponId?: number;

    private user? = {} as User;

    private lockActions: boolean = false;

    async created() {
        this.user = await Users.GetCurrent();
        await this.getYerlyCoupon();

        EventBus.Subscribe( "LockMenuActions", this.toggleMenuActionsLock );
    }

    private toggleMenuActionsLock( value: boolean ): void {
        this.lockActions = value;
    }

    private async signOut(): Promise<void> {
        this.$refs.SignoutPopup.Hide();
        await Users.SignOut();
        this.$router.replace( "/user/signin" );
    }

    /* Control menu links navigation by partially mimicking <router-link>
       and dropdown visibility through the public Popup Hide method.

       Main reasons for this approach are :
       - Current usecase requires disabled links which are not supported
         by <router-link> element. ( Reference to github pull request about
         this issue: https://github.com/vuejs/vue-router/pull/2098 )

       - Current usecase also requires hiding the dropdown only under
         certain conditions which the former implementation of Popup
         InsideClick couldn't do since it wasn't flexible. */
    private async navigate( path: string, query?: Dictionary<any> ) {
        /* Catch navigation error and ignore "NavigationDuplicated" since there's no argument
           supporting why its's "not allowed". */
        this.$router.push( { path, query } )
            .catch( error => {
                if( error.name !== "NavigationDuplicated" )
                    console.error( error );
            });
    }

    private quickCreate( type: string ) {
        this.quickCreationForm = type + "Form";

        this.quickCreateTitle = this.$t( "GLOBAL_quick" + type + "Title" ).toString();
        this.quickCreateSuccess = this.$t( "GLOBAL_quick" + type + "Created" ).toString();
    }

    private notifyCreation() {
        EventBus.Publish(
            "Notification",
            CustomNotification.Success( this.quickCreateTitle, this.quickCreateSuccess )
        );
    }

    private openHelpWindow() {
        window.open( "https://aide.prospeneo.com" );
    }

    private underConstruction( event: MouseEvent ): void {
        event.preventDefault();

        EventBus.Publish( "UnderConstruction" );
    }

    private async getYerlyCoupon(): Promise<void> {
        const [ configuration, error ] = await Configuration.Get();

        if( error !== undefined || configuration === undefined ) {
            EventBus.Publish( "Error", error );
            return;
        }

        if( configuration.YearlyCoupon === undefined ) {
            EventBus.Publish( "Error", CustomError.User( "COUPON_yearlyNotDefined" ) )
            return;
        }

        this.yearlyCouponId = configuration.YearlyCoupon.Id;
    }
}
