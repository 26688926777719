/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

export default {
    SUBSCRIPTIONS_startingFrom: "à partir de {0}€/mois",
    SUBSCRIPTIONS_startingFromFree: "Gratuit",
    SUBSCRIPTIONS_recurrenceTitleMonthly: "Coût mensuel",
    SUBSCRIPTIONS_recurrenceTitleYearly: "Coût annuel",
    SUBSCRIPTIONS_recurrenceMonthly: "Mensuel",
    SUBSCRIPTIONS_recurrenceYearly: "Annuel",
    SUBSCRIPTIONS_recurrenceUnitMonthly: "mois",
    SUBSCRIPTIONS_recurrenceUnitYearly: "ans",

    SUBSCRIPTIONS_save: "Economisez {0}%",
    SUBSCRIPTIONS_recurrenceInvoicingMonthly: "facturé mensuellement",
    SUBSCRIPTIONS_recurrenceInvoicingYearly: "facturé annuellement",
    SUBSCRIPTIONS_cartNote: "Ceci est un message pour vous prévenir d’une information " +
        "ponctuelle...",
    SUBSCRIPTIONS_validateOffer: "Validez votre offre",

    SUBSCRIPTIONS_salesNotice: "Vous n'arrivez pas à composer votre offre ou si elle est adaptée " +
        "à votre besoin ?",

    SUBSCRIPTIONS_contactSales: "Parler à un commercial",
    SUBSCRIPTIONS_showAllFeatures: "Voir toutes les fonctionnalités",

    SUBSCRIPTIONS_title: "Abonnements",
    SUBSCRIPTIONS_label: "abonnement",
    SUBSCRIPTIONS_create: "Créer un abonnement",
    SUBSCRIPTIONS_plural_label: "abonnements",
    SUBSCRIPTIONS_splashScreenTitle: "Vous n'avez aucun abonnement pour le moment",
    SUBSCRIPTIONS_splashScreenDescription:
        "Pensez à créer des produits et visiter la page de tarification",

    SUBSCRIPTIONS_columnId: "Id",
    SUBSCRIPTIONS_columnCustomerEmail: "Email du compte",
    SUBSCRIPTIONS_columnState: "État",
    SUBSCRIPTIONS_columnLines: "Plans d'abonnement",
    SUBSCRIPTIONS_columnCreatedAt: "Créé le",

    SUBSCRIPTION_Details: "Détails de l'abonnement",
    SUBSCRIPTION_PRODUCTS_PLANS: "Produits et prix",
    SUBSCRIPTION_BILLING: "Facturation",
    SUBSCRIPTION_QUOTA: "Quota",
    SUBSCRIPTIONS_fieldNote: "Description",
    SUBSCRIPTIONS_fieldCoupon: "Bon de réduction",
    SUBSCRIPTION_functionalities: "Fonctionnalités incluses dans cet abonnement.",
    SUBSCRIPTIONS_unlimitedQuantity: "Illimité(e)s",

    SUBSCRIPTIONS_successTitle: "Abonnements",
    SUBSCRIPTIONS_successMessage: "Abonnement créé avec succès, merci de vérifier votre mail",

    SUBSCRIPTIONS_statusOffer: "Offre",
    SUBSCRIPTIONS_statusActive: "Actif",
    SUBSCRIPTIONS_statusIncomplete: "Incomplet",
    SUBSCRIPTIONS_statusIncompleted_Expired: "Expiré",
    SUBSCRIPTIONS_statusPastDue: "En retard",
    SUBSCRIPTIONS_statusTrial: "En cours d'essai",
    SUBSCRIPTIONS_statusCanceled: "Résilié",
    SUBSCRIPTIONS_statusUnpaid: "Impayé",

    SUBSCRIPTION_actionUnsubscribe: "Annuler l'abonnement à la fin de période",
    SUBSCRIPTION_actionCancel: "Annuler l'abonnement immédiatement",
    SUBSCRIPTION_cancelWarning: "Attention !",
    SUBSCRIPTION_confirmCancellation: "Oui je souhaite résilier",
    SUBSCRIPTION_cancellationMessage: "Vous allez annuler l'abonnement de ce client.",
    SUBSCRIPTION_cancellationDeadlineMessage:
        "Après la résiliation la dernière facturation s'arrêtera à la date du {0}",
    SUBSCRIPTION_confirmCancellationMessage: "Vous êtes sûr ?",

    SUBSCRIPTIONS_current: "Abonnement actuel",

    SUBSCRIPTIONS_incompatiblePlan:
        "Ce plan n'est pas compatible avec les autres plans sélectionnés.",

    SUBSCRIPTIONS_recommended: "Recommandé",

    SUBSCRIPTION_download: "Télécharger",
    SUBSCRIPTION_paid: "PAYÉE",
    SUBSCRIPTION_open: "EN ATTENTE",
    SUBSCRIPTION_void: "ANNULÉE",
    SUBSCRIPTION_uncollectible: "NON-RÉGLÉE",

    SUBSCRIPTION_nextPayment: "Prochain réglement le",
    SUBSCRIPTION_cannotEndTrialForSubscription: "Ce client n'a pas d'abonnement d'essai",
    SUBSCRIPTION_actionStripeRedirection: "Rediriger vers la page d'abonnement",
    SUBSCRIPTION_unsubscribeSuccessTitle: "Notification",
    SUBSCRIPTION_unsubscribeSuccessMessage: "Votre abonnement a été programmé pour annulation",
    SUBSCRIPTIONS_unsubscribeConfirm: "Êtes-vous sûr de vouloir annuler cet abonnement? | \
        Êtes-vous sûr de vouloir annuler ces abonnements?",
    SUBSCRIPTIONS_recurrentPriceLine: "{subtotal} {currency} / {recurrence}",
    SUBSCRIPTIONS_globalPriceLine: "{subtotal} {currency}",
    SUBSCRIPTIONS_subscribedPlansSubtitle: "{productName} - {planName}",
    SUBSCRIPTIONS_discountActivated:
        `Cet abonnement bénéficie d'une réduction de {discountValue} sur {discountDuration} à
        compter du {discountDate}`,
    SUBSCRIPTIONS_yearlyDiscountActivated:
        "Cet abonnement bénéficie d'une réduction de {discountValue}",
    SUBSCRIPTIONS_yearlyDiscountExpired:
        "Cet abonnement a bénéficié d'une réduction de {discountValue}",
    SUBSCRIPTIONS_discountExpired:
        `Cet abonnement a bénéficié d'une réduction de {discountValue} sur {discountDuration} à
        compter du {discountDate}`
}
