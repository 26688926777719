/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

export default {
    COUPONS_title: "Bons de réduction",
    COUPONS_create: "Créer un bon de réduction",
    COUPONS_edit: "Modifier le bon de réduction",
    COUPONS_fieldMaxUses: "Nombre de fois",
    COUPONS_fieldName: "Nom du bon de réduction",
    COUPONS_fieldFixedAmount: "Montant de la réduction",
    COUPONS_fieldPercentage: "Pourcentage de réduction",
    COUPONS_fieldOnce: "Once",
    COUPONS_fieldDuration: "Nombre de mois",
    COUPONS_fieldFrom: "Utilisable à partir de",
    COUPONS_fieldTo: "Date limite d'utilisation",
    COUPONS_fieldCode: "Code du bon de réduction",
    COUPONS_fieldFirstSubscriptionOnly: "Utilisable uniquement lors d'une premiére commande",
    COUPONS_confirmCreate: "Créer le bon de réduction",
    COUPONS_confirmEdit: "Modifier le bon de réduction",
    COUPONS_columnName: "Bon de réduction",
    COUPONS_columnGeneralConditions: "Conditions générales",
    COUPONS_columnExpire: "Expire le",
    COUPONS_columnMaxUses: "Max utilisations",
    COUPONS_columnUses: "Utilisations",
    COUPONS_columnCreatedAt: "Créé le",

    COUPONS_discountType: "Type de réduction",
    COUPONS_discountTypePercentage: "Réduction en pourcentage",
    COUPONS_discountTypeFix: "Réduction d'un montant fixe",
    COUPONS_discountByCustomers: "Limiter à un client spécifique",
    COUPONS_once: "Une fois",
    COUPONS_limited: "Plusieurs mois",
    COUPONS_forever: "Permanente",
    COUPONS_details: "Détails",
    COUPON_discountNo: "Pas de réduction",
    COUPON_discountPercentage: "{percentage}% de réduction pendant {duration} mois",
    COUPON_discountFixedAmount: "{fixedAmount}€ de réduction pendant {duration} mois",
    COUPON_discountYearlyPercentage: "{percentage}% de réduction annuelle",
    COUPON_discountYearlyFixedAmount: "{fixedAmount}€ de réduction annuelle",
    COUPONS_fieldPlans: "Ajouter des plans",
    COUPONS_fieldGlobalPrices: "Ajouter des prix globaux",
    COUPONS_fieldCustomers: "S'applique à tous les clients par défaut",
    COUPONS_fieldActive: "Actif",
    COUPONS_applyOnProducts: "Appliquer à des produits spécifiques",
    COUPONS_fieldPlaceholderMaxUses: "Nombre maximum d'utilisation",
    COUPONS_fieldPlaceholderName: "Nom",
    COUPONS_fieldPlaceholderFixedAmount: "Montant de la réduction",
    COUPONS_fieldPlaceholderPercentage: "Pourcentage de réduction",
    COUPONS_fieldPlaceholderOnce: "Once",
    COUPONS_fieldPlaceholderDuration: "ex. 1",
    COUPONS_fieldPlaceholderFrom: "",
    COUPONS_fieldPlaceholderTo: "",
    COUPONS_fieldPlaceholderCode: "Code unique de rachat",

    COUPONS_createSuccessTitle: "Notification",
    COUPONS_createSuccessMessage: "Nouveau bon de réduction a été créé avec succés",

    COUPONS_deleteConfirm: "Êtes-vous certain de vouloir supprimer ce" +
        " bon de réduction ? | Êtes-vous certain de vouloir supprimer ces bons de réduction ?",

    COUPONS_cannotDelete: "Ce bon de réduction ne peut pas être supprimé",
    COUPONS_cannotUpdate: "Vous ne pouvez pas mettre à jour le bon de réduction",

    COUPONS_label: "bon de réduction",
    COUPONS_plural_label: "bons de réduction",
    COUPONS_splashScreenTitle: "Créez votre premier bon de réduction",

    COUPONS_splashScreenDescription: "Un bon de réduction peut être utilisé pour encourager vos " +
        "clients.",

    COUPONS_formChanged: "Pas de changements | Vous avez modifié 1 champ du bon de réduction | \
        Vous avez modifié {count} champs du bon de réduction",

    COUPONS_updated: "Bon de réduction mis à jour",

    COUPONS_validity: "Validité",
    COUPONS_associatePlans: "Appliquer à des produits spécifiques",
    COUPONS_associateGloabalPrices: "Appliquer à des prix globaux",

    COUPONS_fieldGeneralConditions: "Conditions générales",
    COUPONS_fieldPlaceholderGeneralConditions: "ex. Usage unique",

    COUPONS_discountDurationDescription: `Cette valeur détermine la période de validité du bon de
        réduction une fois utilisé pour un abonnement ou un client.`,

    COUPONS_duration: "Validité du bon de réduction une fois utilisé",
    COUPONS_expiration: "Expiration du bon de réduction",

    COUPONS_maxUses: "Définir le nombre maximal d'utilisation du bon de réduction",
    COUPONS_expirationPeriod: `Définir la période pendant laquelle les clients peuvent utiliser
        ce bon de réduction`,

    COUPONS_MaxUsesDescription: `Cette limite concerne plusieurs clients et n'empêchera donc pas un
        client spécifique d'utiliser son bon de réduction plusieurs fois.`,

    COUPONS_countUses: "{0} /",
    COUPONS_actionCustomers: "Clients",
    COUPONS_filter: "Bons de réduction",
    COUPONS_filterPlaceholder: "Filtrer par bon de réduction",
    COUPON_confirmCreate: "Vous ne pouvez pas modifier le coupon une fois qu'il est créé.",
    COUPON_duplicateCode: "Ce code du bon de réduction est déja utilisé."
};
