/* © IBS Group. See COPYRIGHT file for full copyright & licensing details. */

import DecimalJs from "decimal.js";
import { AnnotatedJSON, Decimal as IDecimal, Dictionary } from "@Internal/Contracts";
import { SerializedValue } from "@Internal/UseCases/Models";

DecimalJs.config({
    rounding: DecimalJs.ROUND_HALF_UP
});

export default class Decimal implements IDecimal {
    static Deserialize(
        value: SerializedValue,
        specifications?: Dictionary
    ): IDecimal | undefined {
        if( typeof value === "number" || typeof value === "string" )
            return new Decimal(
                value,
                specifications !== undefined ? specifications.Precision as number : undefined
            );

        return;
    }

    private value: DecimalJs;
    private precision: number;

    constructor( value: string | number, precision: number = 2 ) {
        this.precision = precision;
        this.value = new DecimalJs( value );
    }

    GreaterThanOrEqualTo( value: number | IDecimal ): boolean {
        return this.value.greaterThanOrEqualTo(
            typeof value === "number" ? value : ( value as Decimal ).value
        );
    }

    LessThanOrEqualTo( value: number | IDecimal ): boolean {
        return this.value.lessThanOrEqualTo(
            typeof value === "number" ? value : ( value as Decimal ).value
        );
    }

    Substract( value: number | IDecimal ): IDecimal {
        const record = new Decimal( 0, this.precision );
        record.value = this.value.minus(
            typeof value === "number" ? value : ( value as Decimal ).value
        );

        return record;
    }


    LessThan( value: number | IDecimal ): boolean {
        return this.value.lessThan(
            typeof value === "number" ? value : ( value as Decimal ).value
        );
    }

    GreaterThan( value: number | IDecimal ): boolean {
        return this.value.greaterThan(
            typeof value === "number" ? value : ( value as Decimal ).value
        );
    }

    Add( value: number | IDecimal ): IDecimal {
        const record = new Decimal( 0, this.precision );
        record.value = this.value.add(
            typeof value === "number" ? value : ( value as Decimal ).value
        );

        return record;
    }

    DividedBy( number: number | IDecimal ): IDecimal {
        const result = new Decimal( 0, this.precision );

        result.value = this.value.dividedBy(
            ( number instanceof Decimal) ? number.value : ( number as number )
        );

        return result;
    }

    MultiplyBy( value: number | IDecimal ): IDecimal {
        const record = new Decimal( 0, this.precision );

        record.value = this.value.times(
            typeof value === "number" ? value : ( value as Decimal ).value
        );

        return record;
    }

    Floor(): IDecimal {
        const record = new Decimal( 0, this.precision );
        record.value = this.value.floor();

        return record;
    }

    IsNaN(): boolean {
        return this.value.isNaN();
    }

    IsEqual( value: number | Decimal ): boolean {
        return this.value.equals( typeof value === "number" ? value : ( value as Decimal ).value );
    }

    ToFixed( precision: number ): string {
        this.precision = precision;

        /* Truncate values like `12.00` to `12`. */
        return this.value.decimalPlaces() > 0
            ? this.value.toFixed( precision )
            : this.value.toString();
    }

    ToNumber(): number {
        return this.value.toNumber();
    }

    ToFixedNumber(): number {
        return Number( this.ToFixed( this.precision ) );
    }

    toString(): string {
        return this.ToFixed( this.precision );
    }

    Serialize( Annotate: boolean = false ): AnnotatedJSON | string {
        const value: string = this.toString();

        if( !Annotate )
            return value;

        return {
            $Value: value,
            $Model: "Decimal",
            $Specifications: {
                Precision: this.precision
            }
        }
    }
}
