/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import Feathers from "@feathersjs/feathers";

import { Autobind } from "@IBS/Frontend/Utils";
import { Models } from "@Internal";
import { AnnotatedJSON, HandleListError } from "App/Contracts";
import {
    CustomError, GroupedLinesTargetByProduct, Subscription, SubscriptionLine
} from "App/Models";

import BaseService, { ProcessException } from "./Base";

export default class Subscriptions extends BaseService<Subscription> {
    private unsubscribeService: Feathers.Service<any>;
    private groupTargetLinesService: Feathers.Service<any>;

    constructor(
        service: Feathers.Service<any>,
        groupTargetLinesService: Feathers.Service<any>,
        unsubscribeService: Feathers.Service<any>
    ) {
        super( service );

        this.unsubscribeService = unsubscribeService;
        this.groupTargetLinesService = groupTargetLinesService;
    }

    @Autobind
    async GroupPriceTargetedLinesByProduct(
        record: Subscription
    ): HandleListError<GroupedLinesTargetByProduct> {
        let results: any;
        const groupedLines: GroupedLinesTargetByProduct[] = [];

        try {
            results = await this.groupTargetLinesService.get( record.Id );

            if( results.GroupedLines === undefined )
                return [ [], undefined ];

            for( const values of Object.values( results.GroupedLines ) ) {
                const priceTargetLines: SubscriptionLine[] = [];

                const [ lines, linesError ] =
                    Models.Deserialize<AnnotatedJSON>( values as AnnotatedJSON );

                if( linesError !== undefined || lines === undefined )
                    return [ [], linesError ];

                for( const value of Object.values( lines ) )
                    priceTargetLines.push( value );

                const [ line ] = priceTargetLines;

                const planName = line.Plan === undefined ? "" : ` (${line.Plan.Name}) `;

                groupedLines.push({
                    Title: line.Product.Name + planName,
                    LinesTarget: priceTargetLines
                });
            }

        } catch( exception ) {
            const error = await ProcessException( exception );

            return [ [], error ];
        }

        return [ groupedLines, undefined ];
    }

    @Autobind
    async Unsubscribe(
        record: Subscription,
        immediate: boolean = false
    ): Promise<CustomError | undefined> {
        try {
            const results = await this.unsubscribeService.update( record.Id, { immediate } );

            if( results.Error !== undefined )
                return new CustomError( results.Error.Id, results.Error.Message );

        } catch( error ) {
            return ProcessException( error );
        }
        return;
    }
}
