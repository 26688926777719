/* © IBS Group. See COPYRIGHT file for full copyright & licensing details. */

import Identifiable from "../Contracts/Identifiable";
import Named from "../Contracts/Named";

interface FieldSpecs {
    Required?: boolean;
    Size?: number;
    MinSize?: number;
    Internal?: boolean;
    Silent?: boolean;
}

export default class Field implements Identifiable, Named {
    static Number( id: string, name: string, specs?: FieldSpecs ): Field {
        return new Field( id, name, "number", specs );
    }

    static Year( id: string, name: string, specs?: FieldSpecs ): Field {
        return new Field( id, name, "year", specs );
    }

    /* This field relies on Underfined2Zero when checking for difference. */
    static AbsoluteNumber( id: string, name: string, specs?: FieldSpecs ): Field {
        return new Field( id, name, "absoluteNumber", specs );
    }

    static Decimal( id: string, name: string, specs?: FieldSpecs ): Field {
        return new Field( id, name, "decimal", specs );
    }

    static Text( id: string, name: string, specs?: FieldSpecs ): Field {
        return new Field( id, name, "text", specs );
    }

    static UppercaseAlphabets( id: string, name: string, specs?: FieldSpecs ): Field {
        return new Field( id, name, "uppercaseAlphabets", specs );
    }

    static MultiLineText( id: string, name: string, specs?: FieldSpecs ): Field {
        return new Field( id, name, "multiLineText", specs );
    }

    static GoogleMaps( id: string, name: string, specs?: FieldSpecs ): Field {
        return new Field( id, name, "googleMaps", specs );
    }

    static Identifiable( id: string, name: string, specs?: FieldSpecs ): Field {
        return new Field( id, name, "identifiable", specs );
    }

    static Dictionary( id: string, name: string, specs?: FieldSpecs ): Field {
        return new Field( id, name, "dictionary", specs );
    }

    static IdentifiableSet( id: string, name: string, specs?: FieldSpecs ): Field {
        return new Field( id, name, "identifiableSet", specs );
    }

    /* This is abbreviated to avoid conflict with native Boolean type. */
    static Boolean( id: string, name: string, specs?: FieldSpecs ): Field {
        return new Field( id, name, "boolean", specs );
    }

    static Selection( id: string, name: string, specs?: FieldSpecs ): Field {
        return new Field( id, name, "selection", specs );
    }

    static DateTime( id: string, name: string, specs?: FieldSpecs ): Field {
        return new Field( id, name, "dateTime", specs );
    }

    static Date( id: string, name: string, specs?: FieldSpecs ): Field {
        return new Field( id, name, "date", specs );
    }

    static Time( id: string, name: string, specs?: FieldSpecs ): Field {
        return new Field( id, name, "time", specs );
    }

    private id: string;
    private name: string;
    private type: string = "text";
    private required: boolean = false;

    /* Some fields follow a different update logic and don't need to signal their value update. */
    private silent: boolean = false;
    private size: number = Infinity;
    private minSize: number = 0;

    /* This property is sort of hackish, in that it helps us avoid exposing some fields to the
       outside world via the getDifference function, the reason we need this is because in
       "Property" model they have a field ("features" or "choices") that groups multiple other
       separate UI fields. If you look up Internal, you will understand how it was used to solve
       the issue. Note: this can also be used to hide internal fields from the UI if we ever
       build a fully autoamated form or list component. */
    private internal: boolean = false;

    private constructor( id: string, name: string, type: string, specs?: FieldSpecs ) {
        specs = specs || {};

        this.id = id;
        this.name = name;
        this.type = type;

        if( specs.Required !== undefined )
            this.required = specs.Required;

        if( specs.Size !== undefined && specs.Size > 0 )
            this.size = specs.Size;

        if( specs.MinSize !== undefined && specs.MinSize >= 0 )
            this.minSize = specs.MinSize;

        if( specs.Internal !== undefined )
            this.internal = specs.Internal;

        if( specs.Silent !== undefined )
            this.silent = specs.Silent;
    }

    get Id(): string { return this.id; }
    get Name(): string { return this.name; }
    get Required(): boolean { return this.required; }
    get Size(): number { return this.size; }
    get MinSize(): number { return this.minSize; }
    get Type(): string { return this.type; }
    get Internal(): boolean { return this.internal; }
    get Silent(): boolean { return this.silent; }
}
