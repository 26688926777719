/* © Facilogi. See LICENSE file for full copyright & licensing details. */

import { Dictionary } from "@Internal/Contracts";
import { Field } from "@Internal/Models";
import { $t } from "App/IOC";

let defaultFields: Dictionary<Field> = {};

async function Get(): Promise<Dictionary<Field>> {
    await Promise.resolve();

    if( Object.keys( defaultFields ).length === 0 )
        defaultFields = {
            YearlyCoupon: Field.Identifiable(
                "YearlyCoupon",
                $t( "GLOBAL_CONFIGURATION_fieldYearlyCoupon" )
            ),

            Trial: Field.Number( "Trial", $t( "GLOBAL_CONFIGURATION_fieldTrial" ) ),
            SubscriptionPlansNote: Field.MultiLineText(
                "SubscriptionPlansNote",
                $t( "GLOBAL_CONFIGURATION_fieldNote" )
            ),
            SubscriptionPaymentRetries: Field.Number(
                "SubscriptionPaymentRetries",
                $t( "GLOBAL_CONFIGURATION_fieldRetries" )
            ),

            MonthlySEPA: Field.Decimal(
                "MonthlySEPA",
                $t( "GLOBAL_CONFIGURATION_fieldMonthlySEPA" )
            ),

            YearlySEPA: Field.Decimal(
                "YearlySEPA",
                $t( "GLOBAL_CONFIGURATION_fieldYearlySEPA" )
            ),

            /* The payment statement description may be up to 22 characters. */
            PaymentStatementDescription: Field.Text(
                "PaymentStatementDescription",
                $t( "GLOBAL_CONFIGURATION_fieldPaymentStatementDescription" ),
                { Size: 22 }
            )
        };

    return defaultFields;
}

export default {
    Get
};
