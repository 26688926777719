/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import { Decimal as IDecimal } from "@Internal/Contracts";

import { Recurrence } from "./Misc";
import BaseTier from "./Tier";
import BaseModel from "./Model";
import BasePriceTarget from "./PriceTarget";
import BaseProduct from "./Product";
import BasePlan from "./Plan";

export type PriceTypes = "Unit" | "Package" | "Volume" | "Graduated";

export default interface BasePrice<
    P extends BasePriceTarget,
    K extends BaseProduct,
    T extends BaseTier,
    M extends BasePlan
> extends BaseModel {
    Sequence?: number;
    Amount?: number;
    Active?: boolean;
    Recurrence?: Recurrence;
    Type?: PriceTypes;
    FlatFee: boolean;
    PackageSize?: number;
    MinQuantity?: IDecimal;
    MaxQuantity?: IDecimal;
    QuantityStep?: number;
    Included: boolean;
    Target?: P;
    Product: K;
    Tiers: T[];
    Plan?: M;
}

export function IsTiered(
    record: BasePrice<BasePriceTarget, BaseProduct, BaseTier, BasePlan>
): boolean {
    return record.Type === "Volume" || record.Type === "Graduated";
}

export function IsUnitBased(
    record: BasePrice<BasePriceTarget, BaseProduct, BaseTier, BasePlan>
): boolean {
    return record.Type === "Unit";
}

export function IsPackage(
    record: BasePrice<BasePriceTarget, BaseProduct, BaseTier, BasePlan>
): boolean {
    return record.Type === "Package";
}
