/* © IBS Group. See COPYRIGHT file for full copyright & licensing details. */

import BaseFilter from "./Base";
import {
    Filter,
    FilterValue,
    FilterRequest,
    Identifiable,
    Named
} from "../../Contracts";

export default class SelectionFilter extends BaseFilter implements Filter {
    /* Static generators for readability purposes and to avoid overloading the constructor with
       arguments. */
    static Request(
        id: string,
        name: string,
        request: FilterRequest,
        placeholder?: string
    ): SelectionFilter {
        const filter = new SelectionFilter( id, name, [], placeholder );
        filter.request = request;

        return filter;
    }

    static MultiRequest(
        id: string,
        name: string,
        request: FilterRequest,
        placeholder?: string
    ): SelectionFilter {
        const filter = SelectionFilter.Request( id, name, request, placeholder );
        filter.multi = true;

        return filter;
    }

    static MultiUseRequest(
        id: string,
        name: string,
        request: FilterRequest,
        placeholder?: string
    ): SelectionFilter {
        const filter = SelectionFilter.Request( id, name, request, placeholder );
        filter.unique = false;
        filter.multi = true;

        return filter;
    }

    static Multi(
        id: string,
        name: string,
        values: FilterValue[],
        placeholder?: string
    ): SelectionFilter {
        const filter = new SelectionFilter( id, name, values, placeholder );

        filter.multi = true;

        return filter;
    }

    static MultiUse(
        id: string,
        name: string,
        values: FilterValue[],
        placeholder?: string
    ): SelectionFilter {
        const filter = new SelectionFilter( id, name, values, placeholder );

        filter.unique = false;
        filter.multi = true;

        return filter;
    }

    Value: FilterValue[] = [];
    Searchable: boolean = true;

    private values: FilterValue[];
    private request!: FilterRequest;
    private multi: boolean;

    constructor( id: string, name: string, values: FilterValue[], placeholder?: string ) {
        super( id, name, placeholder );

        this.multi = false;
        this.values = values;
    }

    get Multi(): boolean { return this.multi; }
    get Values(): FilterValue[] { return this.values; }
    get Request(): FilterRequest { return this.request; }

    FormatValue(): ( number | string )[] | number | string {
        if( this.Value.length === 0 )
            return [];

        if( this.Value.length === 1 )
            return ( this.Value[ 0 ] as Identifiable ).Id;

        return Array.from(
            this.Value as Identifiable[], ( item: Identifiable ): string | number => item.Id
        );
    }

    Format(): string {
        if( this.Value.length === 0 )
            return "";

        return Array.from(
            this.Value as Named[], ( item: Named ): string => item.Name
        ).join( $t( "FILTER_or" ) );
    }
}
