/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import {
    EventBus as _EventBus,
    Storage as IStorage
} from "App/Contracts";


import { CustomDateClass } from "@Internal/Contracts";

import { Decimal as IDecimal } from "@Internal/Contracts";

import { DeepCopy as IDeepCopy, DeepEqual as IDeepEqual } from "Shared/Contracts";

import { I18n as _I18n , Locale } from "@Internal/Models";

/* Externals. */
import LocalStorage from "App/Externals/LocalStorage";
import DateDayJS from "@Internal/Externals/DateDayJS";
import DecimalJS from "@Internal/Externals/DecimalJS";
import EventEmitter3 from "App/Externals/EventEmitter3";
import FastCopy from "Shared/Externals/FastCopy";
import FastDeepEqual from "Shared/Externals/FastDeepEqual";

/* Translations files of the supported Locales */
import { En, Fr } from "App/Externals/Translations";

import phoneCodeCountries from "App/Externals/PhoneCodeCountries";
import { Country } from "App/Models";

const supportedLocales: Locale[] = [
    new Locale( "fr", "FR", "French", Fr ),
    new Locale( "en", "EN", "English", En )
];

type DecimalClass = new ( number: number | string ) => IDecimal;

const CustomDate: CustomDateClass = DateDayJS;
const DataStore: IStorage = new LocalStorage();
const Decimal: DecimalClass = DecimalJS;
const EventBus: _EventBus = new EventEmitter3();
const I18n: _I18n = new _I18n( supportedLocales );
const $t = I18n.$t.bind( I18n );
const DeepCopy: IDeepCopy = FastCopy;
const DeepEqual: IDeepEqual = FastDeepEqual;
const PhoneCodeCountries: Country[] = phoneCodeCountries;

// @ts-ignore
global.$t = $t ;

export {
    $t,
    DeepCopy,
    DeepEqual,
    CustomDate,
    DataStore,
    Decimal,
    EventBus,
    I18n,
    PhoneCodeCountries
};
