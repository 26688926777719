/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import Feathers, { HookContext } from "@feathersjs/feathers";
import FeathersRest from "@feathersjs/rest-client";

import { Autobind } from "@Internal/Utils";

import Authentication from "App/IOC/AuthenticationService";

export default class FeathersJSServices {
    private application: Feathers.Application;

    constructor( url: string ) {
        this.application = Feathers();

        const client = FeathersRest( url ).fetch( window.fetch );

        this.application.configure( client );

        this.setHooks();
    }

    /* TODO: Strong typing. */
    Get( name: string ): Feathers.Service<any> {
        return this.application.service( name );
    }

    @Autobind
    setHooks(): void {
        this.application.hooks({
            before: {
                all: this.populateHeaders
            }
        });
    }

    @Autobind
    private populateHeaders( context: HookContext ): HookContext {
        if( Authentication.Token !== undefined )
            context.params.headers = {
                Authorization: `Bearer ${Authentication.Token}`
            }

        return context;
    }
}
