/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import { Customers } from "App/IOC/Services";
import { Filter, FilterValue, HandleListError, Identifiable, Named } from "@Internal/Contracts";
import {
    CustomError, CustomValue, PagerSorter, SelectionFilter, TextFilter
} from "@Internal/Models";
import { Customer } from "App/Models";

let defaultFilters: Filter[] = [];

async function getByCustomer( keyword?: string ): Promise<[ Customer[], CustomError | undefined ]> {
    const customerFilter = new TextFilter( "Name", "" );
    customerFilter.Value = [ keyword !== undefined ? keyword : "" ];

    const [ customers, , error ] = await Customers.Find(
        [ customerFilter ],
        new PagerSorter()
    );

    return [ customers, error ];
}

async function getByCompany( keyword?: string ): HandleListError<Named & Identifiable> {
    const companyFilter = new TextFilter( "Company", "" );
    companyFilter.Value = [ keyword !== undefined ? keyword : "" ];

    const [ customers, , error ] = await Customers.Find( [ companyFilter ], new PagerSorter() );

    const distinctCompanies = [ ...new Set(
        customers.map( ( customer: Customer ): string => customer.Company as string )
    )];

    const companies = distinctCompanies.map( ( company: string ): Named & Identifiable =>
        ({ Id: company as string, Name: company as string })
    );

    return [ companies, error ]
}

function getSubscriptionStatus(): FilterValue[] {
    return [
        new CustomValue<string>( $t( "SUBSCRIPTIONS_statusActive" ), "Active" ),
        new CustomValue<string>( $t( "SUBSCRIPTIONS_statusTrial" ), "Trial" ),
        new CustomValue<string>( $t( "SUBSCRIPTIONS_statusUnpaid" ), "Unpaid" ),
        new CustomValue<string>( $t( "SUBSCRIPTIONS_statusCanceled" ), "Canceled" ),
        new CustomValue<string>( $t( "SUBSCRIPTIONS_statusOffer" ), "Offer" )
    ]
}

function Get(): Filter[] {
    if( defaultFilters.length === 0 )
        defaultFilters = [
            SelectionFilter.Request(
                "Customer",
                $t( "GLOBAL_filterCustomerName" ),
                getByCustomer,
                $t( "GLOBAL_filterCustomerPlaceholder" )
            ),

            new SelectionFilter(
                "Status",
                $t( "GLOBAL_filterStatus" ),
                getSubscriptionStatus(),
                $t( "GLOBAL_filterStatusPlaceholder" )
            ),

            SelectionFilter.Request(
                "Company",
                $t( "CUSTOMERS_filterCompany" ),
                getByCompany,
                $t( "CUSTOMERS_filterCompanyPlaceholder" )
            )
        ];

    return defaultFilters;
}

export default {
    Get
};
