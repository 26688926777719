


import { Component, Prop, Vue } from "vue-property-decorator";

import { Configuration, Plans } from "App";
import { DeepCopy, EventBus } from "App/IOC";
import { Price, Product, Plan, GlobalConfiguration } from "App/Models";

import { ProvideReactive } from "Web/Libs/VuePropertyDecorators";
import PlanForm from "Web/Views/Products/Single/Plans/Form.vue";
import ProductPrices from "Web/Views/Products/Single/Prices/Index.vue";
import ProductPlan from "Web/Views/Products/Single/Plans/Plan.vue";

@Component({
    components: {
        PlanForm,
        ProductPrices,
        ProductPlan
    }
})
export default class PropductPlans extends Vue {
    @Prop() readonly Product!: Product;

    @ProvideReactive()
    private configuration?: GlobalConfiguration = {} as GlobalConfiguration;

    private showForm: boolean = false;
    private busy: boolean = false;

    async created(): Promise<void> {
        this.configuration = undefined;

        const [ globalConfiguration, error ] = await Configuration.Get();

        if( error !== undefined || globalConfiguration === undefined ) {
            EventBus.Publish( "Error", error );
            return;
        }

        this.configuration = globalConfiguration;
    }

    private async create( record: Plan ) {
        record.Product = DeepCopy( this.Product );

        this.$emit( "Create", record );
    }

    private createPrice( price: Price, plan: Plan ): void {
        this.$emit( "CreatePrice", price, plan );
    }

    private async moveUp( plan: Plan ): Promise<void> {
        if( this.busy )
            return;

        this.busy = true;

        const index = this.Product.Plans.indexOf( plan );
        if( index === -1 || index === 0 )
            return;

        const plans = DeepCopy( this.Product.Plans );

        [ plans[ index - 1 ], plans[ index ] ] = [ plans[ index ], plans[ index - 1 ] ];

        let counter = 1;

        for( const record of plans ) {
            /* Reset the sequence for everything. */
            record.Sequence = 0;

            const updatedRecord = DeepCopy( record );

            /* Increment the sequence for the next record. */
            updatedRecord.Sequence = counter++;

            const [ , error ] = await Plans.Update( record, updatedRecord );

            if( error !== undefined ) {
                EventBus.Publish( "Error", error );
                return;
            }
        }

        this.busy = false;

        this.$emit( "Refresh" );
    }

    private async setDefault( record: Plan ): Promise<void> {
        if( this.busy )
            return;

        this.busy = true;

        const updatedRecord = DeepCopy( record );
        updatedRecord.IsDefault = true;

        const [ , error ] = await Plans.Update( record, updatedRecord );


        if( error !== undefined ) {
            EventBus.Publish( "Error", error );
            return;
        }

        this.busy = false;

        this.$emit( "Refresh" );
    }
}
