/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import { Identifiable } from "@Internal/Contracts";

import BaseCustomer from "./Customer";

export default interface BasePaymentMethod<T extends BaseCustomer> extends Identifiable {
    PartialNumber?: string,
    Expiration?: string;
    Token: string;
    Brand?: string;
    Active?: boolean;
    Name?: string;
    Type?: string;
    Customer: T;
}
