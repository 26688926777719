


import { Component, Emit, Prop, Vue } from "vue-property-decorator";

import { Customers } from "App";
import { Customer, Field } from "App/Models";

import { Dictionary } from "App/Contracts";

import Tab from "Web/Components/Tabs/Tab.vue";
import Tabs from "Web/Components/Tabs/Index.vue";

import Form from "Web/Components/Form/Index.vue";
import FormField from "Web/Components/Form/Field.vue";
import FormFieldset from "Web/Components/Form/Fieldset.vue";

@Component({
    components: {
        Field: FormField,
        Fieldset: FormFieldset,
        Form,
        Tab,
        Tabs
    }
})
export default class CustomerCard extends Vue {
    @Prop()
    Customer?: Customer;

    customerFields: Dictionary<Field> = {};

    async mounted(): Promise<void> {
        this.customerFields = await Customers.Fields.Get();
    }

    @Emit( "Refresh" )
    private refresh() {}
}
