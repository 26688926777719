


import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { DeepCopy, EventBus } from "App/IOC";
import { Filter, FilterValue, Named } from "App/Contracts";
import { DateFilter, SelectionFilter, TextFilter } from "App/Models";

import DateFilterForm from "Web/Components/Common/MultiFilter/DateForm.vue";
import TextFilterForm from "Web/Components/Common/MultiFilter/TextForm.vue";
import SeparateSelect from "Web/Components/Select/Separate.vue";
import SortedSelect from "Web/Components/Select/Sorted/Simple.vue";

import Filters from "App/UseCases/Filters";

@Component({
    components: {
        DateFilterForm,
        SeparateSelect,
        SortedSelect,
        TextFilterForm
    }
})
export default class MultiFilter extends Vue {
    $refs!: Vue[ "$refs" ] & {
        AppliedFilters: HTMLElement[],
    };

    @Prop( { default: "" } )
    ModelId!: string;

    @Prop() public GetFilters!: () => Promise<Filter[]>;

    private appliedFilters: Filter[] = [];
    private showFilters: boolean = false;

    /* Index of the filter that is currently being edited, from appliedFilters. */
    private currentFilterIndex: number = -1;

    private filters: Filter[] = [];

    /* Whether currentFilter is already applied or not.
       Set to true if the currentFilter has yet to be explicitly applied
       in order to clear it completely in case it was canceled.  */
    private newFilter: boolean = false;

    private async mounted() {
        this.setup();

        /* Call setup when global filter map is upadted. */
        EventBus.Subscribe( "filters-" + this.ModelId, this.setup );
    }

    private async setup() {
        /* Reset values */
        this.currentFilterIndex = -1;
        this.filters = [];
        this.showFilters = false;
        this.appliedFilters = [];

        /* Get the default values from the usecase filters then initialize filters
           on the Filters usecase. */

        /* Get the saved filters from the Filter usecase. */
        this.filters = Filters.Get( this.ModelId ) as Filter[];

        if( this.filters === undefined )
            this.filters = DeepCopy( await this.GetFilters() );

        Filters.Set( this.ModelId, this.filters );

        /* TODO: Optimize this, the task/list endpoint gets called twice, first by this code and
           then by the quickfilter code. */
        this.filters.forEach( ( filter: Filter ) => {
            if( filter.Value.length === 0 )
                return;

            this.appliedFilters.push( DeepCopy( filter ) );
            this.currentFilterIndex = this.appliedFilters.length - 1;
        });

        this.apply();
    }

    /* Emit applied filters to be saved on usecase specific state. */
    private beforeDestroy() {
        EventBus.Unsubscribe( "filters-" + this.ModelId, this.setup );
        Filters.Save( this.ModelId, this.appliedFilters );
    }

    private get currentFilter(): Filter | undefined {
        if( this.currentFilterIndex === -1 )
            return undefined;

        return this.appliedFilters[ this.currentFilterIndex ];
    }

    private set currentFilter( filter: Filter | undefined ) {
        if( this.currentFilterIndex === -1 )
            return;

        /* We want to deselect the current filter. */
        if( filter === undefined ) {
            this.currentFilterIndex = -1;
            return;
        }

        this.appliedFilters[ this.currentFilterIndex ] = filter;
    }

    /* Used to reposition the current filter form right bellow it's corresponding applied
       filter. */
    private get filterFormPosition() {
        if( this.currentFilterIndex === -1 || this.$refs.AppliedFilters === undefined )
            return false;

        const currentFilter = this.$refs.AppliedFilters[ this.currentFilterIndex ];

        if( currentFilter === undefined )
            return false;

        return currentFilter.offsetLeft;
    }

    private add( filter: Filter ) {
        this.closeCurrent();

        this.showFilters = false;

        /* There are filters that are unique in the sense that we can't apply multiple
           variations of them, then there are other filters that can be applied
           multiple times with the `AND` operator. */
        // const index = this.appliedFilters.indexOf( filter );
        const appliedFilter = this.appliedFilters.find(
            appliedFilter => appliedFilter.Id === filter.Id
        );

        if( appliedFilter !== undefined && filter.Unique ) {
            const filterIndex = this.appliedFilters.indexOf( appliedFilter );
            this.appliedFilters.splice( filterIndex, 1, DeepCopy( filter ) );
            this.currentFilterIndex = filterIndex;

        } else {
            this.appliedFilters.push( DeepCopy( filter ) );
            this.currentFilterIndex = this.appliedFilters.length - 1;
            this.newFilter = true;
        }
    }

    private closeCurrent() {
        if( this.currentFilterIndex === -1 )
            return;

        if( this.currentFilter === undefined )
            return;

        if( this.currentFilter.Value.length === 0 || this.newFilter )
            this.remove( this.currentFilterIndex );

        this.currentFilterIndex = -1;
    }

    private isText( filter: Filter ): boolean {
        return filter instanceof TextFilter;
    }

    private isSelection( filter: Filter ): boolean {
        return filter instanceof SelectionFilter;
    }

    private isDate( filter: Filter ): boolean {
        return filter instanceof DateFilter;
    }

    private remove( index: number, event?: MouseEvent ) {
        if( event )
            event.stopPropagation();

        const filter = this.appliedFilters[ index ];

        filter.Value = [];

        this.appliedFilters.splice( index, 1 );

        this.currentFilterIndex = -1;

        Filters.Save( this.ModelId, this.appliedFilters );

        this.$emit( "Applied", this.appliedFilters );
    }

    private setValue( value: FilterValue[] ) {
        if( this.currentFilter === undefined )
            return;

        /* Some filters return singular values (ie: TextForm). */
        this.currentFilter.Value = Array.isArray( value ) ? value : [ value ];
    }

    private apply() {
        this.newFilter = false;

        if( this.currentFilter !== undefined ) {
            if( this.currentFilter.Value.length === 0 )
                this.remove( this.currentFilterIndex );
            else
                this.closeCurrent();
        }

        this.$emit( "Applied", this.appliedFilters );
    }

    private async selectionFilterOptions(
        keyword?: string
    ): Promise<FilterValue[]> {
        const filter = this.currentFilter as SelectionFilter;

        if( filter === undefined )
            return [];

        /* Basic array filtering. */
        if( filter.Request === undefined ) {
            if( keyword === undefined )
                return filter.Values;

            /* We're sure most selection filters will have Named values, so
               we force the typing to keep things coherent. */
            return ( filter.Values as unknown as Named[] ).filter(
                ( value: Named ) => value.Name.toLowerCase().includes(
                    keyword.toLowerCase()
                )
            ) as unknown as FilterValue[];
        }

        const [ values ] = await filter.Request( keyword );
        return values;
    }

    /* Reset filters when modelId changes, this allows refreshing
       records component without additional hacks. */
    @Watch( "ModelId" )
    private onModelIdUpdate() {
        this.setup();
    }
}
