/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

export default {
    PRICES_title: "Title",
    PRICES_description: "Description",
    PRICES_model: "Modèle",
    PRICES_value: "Valeur",
    PRICES_packageSize: "Taille du package",
    PRICES_recurrent: "Récurent",
    PRICES_tiers: "Niveaux",
    PRICES_tierMinUnits: "Quantité minimale",
    PRICES_tierMaxUnits: "Quantité maximale",
    PRICES_tierUnitPrice: "Prix unitaire",
    PRICES_unitName: "Nom de l'unité",
    PRICES_pluralUnitName: "Nom pluriel des unités",
    PRICES_addPrice: "Ajouter un prix",
    PRICES_create: "Créer un prix",
    PRICES_edit: "Modifier prix",
    PRICES_confirmCreate: "Créer le prix",
    PRICES_confirmEdit: "Modifier le prix",
    PRICES_addTier: "Ajouter un niveau",
    PRICES_removeTier: "Supprimer",

    PRICES_fieldName: "Nom",
    PRICES_fieldAmount: "Montant",
    PRICES_fieldCurrency: "Devise",
    PRICES_fieldDescription: "Description",
    PRICES_fieldType: "Modèle de tarification",
    PRICES_fieldTypePlaceholder: "-",
    PRICES_unlimitedSymbol: "∞",
    PRICES_unlimited: "Illimité",
    PRICES_fieldRecurrence: "Recurrence",
    PRICES_fieldFlatFee: "Prix unique",
    PRICES_fieldMinQuantity: "Quantité minimale",
    PRICES_fieldMaxQuantity: "Quantité maximale",
    PRICES_fieldIncluded: "Inclus par défaut",
    PRICES_fieldTiers: "Niveaux",
    PRICES_fieldQuantityStep: "Pas",

    Prices_columnName: "Npm",
    Prices_columnAmount: "Montant",
    Prices_columnCurrency: "Devise",
    Prices_columnDescription: "Description",
    Prices_columnIsRecurrent: "Recurrence",
    Prices_columnModel: "Modèle",

    PRICE_details: "Détails du prix",
    PRICE_fieldPlaceholderName: "Saisir le nom du prix",
    PRICE_fieldPlaceholderDescription: "Saisir la description du prix",
    PRICE_fieldPlaceholderUnit: "Saisir le nom de l'unité",
    PRICE_fieldPlaceholderPluralUnits: "Saisir le nom des unités",

    PRICES_volume: "Volume",
    PRICES_package: "Prix unique",
    PRICES_unit: "Prix à l’unité",
    PRICES_graduated: "Graduel",

    PRICES_monthly: "Mensuel",
    PRICES_yearly: "Annuel",

    PRICES_recurrenceNo: "Non",
    PRICES_recurrenceYes: "Oui",

    PRICES_tierFlatFee: "Prix unique",
    PRICE_updated: "Prix mis à jour avec succès",

    PRICES_fieldTypeUnit: "Prix à l’unité",
    PRICES_fieldTypePackage: "Prix unique",
    PRICES_fieldTypeVolume: "Volume",
    PRICES_fieldTypeGraduated: "Graduel",

    SUBSCRIPTIONS_linesRequired: "Veuillez sélectionner les options d'abonnement",
    SUBSCRIPTIONS_customerRequired: "Veuillez sélectionner un client",

    PRICES_type: "Sélectionner un type de prix",
    PRICES_TypeUsed: "Le type de prix sélectionné est utilisé",
    PRICE_targetNameGeneric: "Prix générique",
    PRICE_targetDescriptionGeneric: "Un prix générique",
    PRICE_archiveConfirm: "Êtes-vous sûr de vouloir archiver ce prix?"
};
