/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

export default {
    PRICE_TARGETS_title: "Type de prix",
    PRICES_description: "Description",
    PRICE_TARGETS_label: "Type de prix",
    PRICE_TARGETS_plural_label: "Types de prix",
    PRICE_TARGETS_create: "Créer un type de prix",
    PRICE_TARGETS_confirmCreate: "Créer le type de prix",
    PRICE_TARGETS_confirmUpdate: "Modifier le type de prix",

    PRICE_TARGETS_fieldName: "Nom technique",
    PRICE_TARGETS_fieldDisplayName: "Nom interne",
    PRICE_TARGETS_fieldDescription: "Description interne",
    PRICE_TARGETS_fieldPriceName: "Nom du prix",
    PRICE_TARGETS_fieldPriceDescription: "Description du prix",
    PRICE_TARGETS_fieldPriceUnitName: "Nom de l'unité de prix",
    PRICE_TARGETS_fieldPricePluralUnitName: "Nom des unités de prix",

    PRICE_TARGETS_fieldPlaceholderTechnicalName: "Saisir le nom technique du type",
    PRICE_TARGETS_fieldPlaceholderName: "Saisir le nom du type",
    PRICE_TARGETS_fieldPlaceholderDescription: "Saisir une description",

    PRICE_TARGETS_columnName: "Nom",
    PRICE_TARGETS_columnDescription: "Description",

    PRICE_TARGETS_deleteConfirm: "Êtes-vous certain de vouloir supprimer ce type de prix ? | " +
        "Êtes-vous certain de vouloir supprimer ces types de prix ?",
    PRICE_TARGETS_cannotDelete:
        "Certains des types de prix sélectionnés ne peuvent pas être supprimés",

    PRICE_TARGETS_splashScreenTitle: "Créez votre premier type de prix",
    PRICE_TARGETS_splashScreenDescription: "",

    PRICE_TARGETS_updated: "Type de prix mis à jour avec succés",

    PRICE_TARGET_details: "Details",
    PRICE_TARGET_formChanged: "Pas de changements | Vous avez modifié 1 champ du type de prix | \
        Vous avez modifié {count} champs du type de prix",

    PRICE_TARGETS_createSuccessTitle: "Notification",
    PRICE_TARGETS_createSuccessMessage: "Nouveau type de prix créé avec succés",

    PRICE_TARGET_cannotDeleteHasAssociatedPrices: "Vous ne pouvez pas supprimer ce type de prix",
    PRICE_TARGET_cannotUpdateHasAssociatedPrices: "Vous ne pouvez pas modifier ce type de prix",
    PRICE_TARGET_duplicatedName: "Vous ne pouvez pas dupliquer ce nom de type de prix"
};
