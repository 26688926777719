/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import { Identifiable } from "App/Contracts";

import { Password } from "App/Models";

export default interface SignIn extends Identifiable {

    Email?: string;
    Password?: Password;
}

export function NewSignIn(): SignIn {
    return {
        Id: "SI"
    };
}
