/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import { Users } from "App/IOC/Services";

export default {
    Authenticate: Users.Authenticate,
    GetCurrent: Users.GetCurrent,
    CheckAuthentication: Users.CheckAuthentication,
    RestoreSession: Users.RestoreSession,
    SignOut: Users.SignOut
};
