var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PopupWindow',{attrs:{"Scrollable":true,"Title":_vm.$t( _vm.edit ? 'PRICES_edit' : 'PRICES_create' ),"Actions":[ _vm.action ]},on:{"Close":_vm.close,"Action":_vm.create}},[(Object.keys( _vm.fields ).length > 0)?_c('Form',{ref:"form",staticClass:"c-price-form",attrs:{"Record":_vm.record,"ShowActions":false,"Model":"Price"},on:{"Change":_vm.canCreate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var Parent = ref.Parent;
return [(!_vm.isTranslatableFieldsVisible && _vm.selectedTarget !== undefined)?_c('Field',{staticClass:"c-price-form__target",attrs:{"Metadata":_vm.fields.Target,"Readonly":true}},[_c('p',{staticClass:"c-form__field-value c2-form__field-value"},[_vm._v(_vm._s(_vm.selectedTarget.DisplayName))])]):_vm._e(),(_vm.isTranslatableFieldsVisible)?_vm._l(([
                        _vm.fields.Name,
                        _vm.fields.Description
                ]),function(field){return _c('Field',{key:field.Id,staticClass:"c-price-form__name-description",attrs:{"Metadata":field}})}):_vm._e(),_c('Field',{attrs:{"Metadata":_vm.fields.Sequence}}),(_vm.isTranslatableFieldsVisible)?_c('FieldGroup',_vm._l(([
                    _vm.fields.UnitName,
                    _vm.fields.PluralUnitName ]),function(field){return _c('Field',{key:field.Id,attrs:{"Metadata":field}})}),1):_vm._e(),_c('FieldGroup',[_c('Field',{staticClass:"c-price-form__type",attrs:{"Metadata":_vm.fields.Type,"Readonly":_vm.edit,"Options":_vm.pricingTypes,"Placeholder":_vm.$t( 'PRICES_fieldTypePlaceholder' ),"Component":"Primitive"}}),(_vm.Global)?_c('Field',{staticClass:"c-price-form__recurrence",attrs:{"Metadata":_vm.fields.Recurrence,"Readonly":_vm.edit || _vm.isTiered( Parent ),"Options":_vm.recurrenceOptions,"Placeholder":_vm.$t( 'PRICES_fieldTypePlaceholder' ),"ForceValue":_vm.isTiered( Parent ) ? 'Monthly' : Parent.Recurrence,"Component":"Primitive"}}):_vm._e(),(_vm.isTiered( Parent ))?_c('div',{staticClass:"c-price-form__unlimited"},[_c('label',[_vm._v(_vm._s(_vm.$t( "PRICES_unlimited" )))]),_c('BooleanField',{attrs:{"Value":_vm.isUnlimited},on:{"Value":_vm.toggleUnlimitedOption}})],1):_vm._e()],1),_c('Field',{directives:[{name:"show",rawName:"v-show",value:(Parent.Type === 'Package'),expression:"Parent.Type === 'Package'"}],staticClass:"c-price-form__included",attrs:{"Metadata":_vm.fields.Included}}),_c('Field',{directives:[{name:"show",rawName:"v-show",value:(!_vm.edit && _vm.isTiered( Parent )),expression:"!edit && isTiered( Parent )"}],staticClass:"c-price-form__flat-fee",attrs:{"Metadata":_vm.fields.FlatFee,"Disabled":_vm.isUnlimited}}),_c('Field',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isTiered( Parent )),expression:"!isTiered( Parent )"}],attrs:{"Metadata":_vm.fields.Amount}}),(Parent.Type === 'Unit')?_c('FieldGroup',_vm._l(([
                    _vm.fields.MinQuantity,
                    _vm.fields.MaxQuantity ]),function(field){return _c('Field',{key:field.Id,staticClass:"c2-form__group-column",attrs:{"Metadata":field}})}),1):_vm._e(),_c('Field',{directives:[{name:"show",rawName:"v-show",value:(Parent.Type === 'Unit' && Parent.MinQuantity !== undefined),expression:"Parent.Type === 'Unit' && Parent.MinQuantity !== undefined"}],staticClass:"c-price-form__quantity-step",attrs:{"Metadata":_vm.fields.QuantityStep}}),(_vm.isTiered( Parent ))?_c('Field',{attrs:{"Metadata":_vm.fields.Tiers,"Label":false,"ForceValue":( _vm.isTiered( Parent ) && Parent.Tiers.length === 0 )
                    ? _vm.getDefaultTiers()
                    : Parent.Tiers},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var Value = ref.Value;
                    var Update = ref.Update;
return [_c('PriceTiers',{attrs:{"Tiers":Value,"FlatFee":Parent.FlatFee,"Unlimited":_vm.isUnlimited},on:{"Update":Update}})]}}],null,true)}):_vm._e()]}}],null,false,126090802)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }