


import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { IDecimal } from "App/Contracts";
import { GlobalConfiguration, PriceTier } from "App/Models";
import { Decimal, DeepCopy, DeepEqual } from "App/IOC";
import { Prices } from "App";

import { InjectReactive } from "Web/Libs/VuePropertyDecorators";
import NumberField from "Web/Components/Common/Input/FormNumber.vue";

@Component({
    components: {
        NumberField
    }
})
export default class PriceTiers extends Vue {
    @Prop( { default: () => [] } )
    Tiers!: PriceTier[];

    @Prop()
    FlatFee!: boolean;

    @Prop( { default: false } )
    Unlimited!: boolean;

    @InjectReactive( { from: "configuration", default: undefined } )
    private configuration?: GlobalConfiguration;

    /* Increment the value of unitPriceRenderKey, to force Vue re-render the NumberField
       components. */
    private unitPriceRenderKey: number = 0;

    private add(): void {
        /* For unlimited prices, there is no need to add another tier. */
        if( this.Unlimited )
            return;

        /* Get the last tier that we will use to compute the new tier's minimum quantity. */
        const [ lastTier ] = this.Tiers.slice( -1 )

        const tier: PriceTier = {
            Id: -1,
            UnitPrice: new Decimal( 0 ),
            MinQuantity: new Decimal( 0 ),
            MaxQuantity: new Decimal( 0 )
        };

        if( lastTier !== undefined ) {
            tier.MinQuantity = lastTier.MaxQuantity.Add( 1 );
            tier.MaxQuantity = tier.MinQuantity.Add( 1 );

        } else {
            tier.MinQuantity = new Decimal( 1 );
            tier.MaxQuantity = new Decimal( 1 );
        }

        const newTiers = DeepCopy( this.Tiers );
        newTiers.push( tier );

        this.$emit( "Update", newTiers );
    }

    private remove( index: number ): void {
        const nextTier = this.Tiers[ index + 1 ];

        if( nextTier !== undefined )
            nextTier.MinQuantity = this.Tiers[ index ].MinQuantity;

        const newTiers = DeepCopy( this.Tiers );

        newTiers.splice( index, 1 );

        this.$emit( "Update", newTiers );
    }

    private async updateMinQuantity(
        index: number,
        target: HTMLInputElement,
        value: string
    ): Promise<void> {
        const updatedTiers = DeepCopy( this.Tiers );
        const currentTier = updatedTiers[ index ];

        /* Return back to the previous value if the `MinQuantity` has an illegal value. */
        if( value === "" || value === "0" ) {
            target.value = String( currentTier.MinQuantity );
            return;
        }

        currentTier.MinQuantity = new Decimal( parseInt( value as string, 10 ) );

        if( !DeepEqual( updatedTiers, this.Tiers ) ) {
            this.$emit( "Update", updatedTiers );

            /* We need to wait for the update to propagate before continuing because
               it's possible we will be emiting again in the next block of code which
               means this emit will be ignored. */
            await this.$nextTick();
        }

        /* Make sure `MaxQuantity` has a legal value. */
        if(
            currentTier.MaxQuantity !== undefined &&
            currentTier.MinQuantity.GreaterThan( currentTier.MaxQuantity )
        )
            this.updateMaxQuantity( index, target, String( currentTier.MinQuantity.Add( 1 ) ) );
    }

    private updateMaxQuantity( index: number, target: HTMLInputElement, value: string ): void {
        const updatedTiers = DeepCopy( this.Tiers );
        const currentTier = updatedTiers[ index ];

        const maxQuantity = new Decimal( parseInt( value as string, 10 ) );

        /* Return back to the previous value if the `MaxQuantity` has an illegal value. */
        if( ( value === "" ) ||
            ( value === "0" ) ||
            currentTier.MinQuantity.GreaterThan( maxQuantity )
        ) {
            target.value = currentTier.MaxQuantity.toString();
            return;
        }

        currentTier.MaxQuantity = maxQuantity;

        /* Fix gaps and overlapping tiers due to the current change. */
        for( const [ nextIndex, tier ] of updatedTiers.slice( index + 1 ).entries() ) {
            /* `nextIndex` will be starting from zero, so we need to add the last index. */
            tier.MinQuantity = updatedTiers[ index + nextIndex ].MaxQuantity.Add( 1 );

            /* If the `MaxQuantity` is set, we need to make sure it's more than the
               `MinQuantity`. */
            if( tier.MinQuantity.GreaterThanOrEqualTo( tier.MaxQuantity ) )
                tier.MaxQuantity = tier.MinQuantity.Add( 1 );
        }

        /* Did something actually change? */
        if( !DeepEqual( updatedTiers, this.Tiers ) )
            this.$emit( "Update", updatedTiers );
    }

    private updateUnitPrice( index: number, value: string ): void {
        /* Use DeepCopy to avoide mutating the Tiers table. */
        const updatedTiers = DeepCopy( this.Tiers );
        const currentTier = updatedTiers[ index ];

        if( value === "" ) {
            /* HACK: Force the NumberField component to be rendered every time we update the unit
               price, so we need to force re-render each price component with a priceRenderKey. */
            this.unitPriceRenderKey++;
            currentTier.UnitPrice = updatedTiers[ index ].UnitPrice;

        } else {
            currentTier.UnitPrice = new Decimal( value );
        }

        if( !DeepEqual( updatedTiers, this.Tiers ) )
            this.$emit( "Update", updatedTiers );
    }

    @Watch( "Unlimited" )
    private async updateFirstTierMaxQuantity(): Promise<void> {
        if( this.configuration === undefined )
            return;

        let updateTiers = DeepCopy( this.Tiers );

        if( !this.Unlimited ) {
            updateTiers = Prices.GetDefaultTiers();

        } else {
            updateTiers = Prices.GetDefaultTiers();
            updateTiers[ 0 ].MaxQuantity = this.configuration.UnlimitedQuantity as IDecimal;
        }

        this.$emit( "Update", updateTiers );
    }
}
