/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import "url-search-params-polyfill";
import Notifications from "vue-notification";
import Vue, { VNode } from "vue";
import VueI18n from "vue-i18n";

// @ts-ignore
import VuePortal from "@linusborg/vue-simple-portal";

import {
    AdaptFont,
    Position,
    Snap,
    StrictSnap
} from "Web/Directives";

// eslint-disable-next-line
import App from "./App.vue";
import Router from "./Router";
import { Locales, Users } from "App";

import { I18n } from "App/IOC";
import { $t } from "App/IOC";

/* Externals. */
import DateDayJS, { DeserializeCustomDateTime } from "@Internal/Externals/DateDayJS";
import DecimalJS from "@Internal/Externals/DecimalJS";

import { Serialization } from "@Internal/UseCases/Models";
import FormatText from "App/Externals/TextFormat";

const TextFormat = new FormatText( I18n );

Serialization.SetDeserializer( "CustomDate", DateDayJS.Deserialize );
Serialization.SetDeserializer( "CustomDateTime", DeserializeCustomDateTime );
Serialization.SetDeserializer( "Decimal", DecimalJS.Deserialize );
Serialization.SetTranslator( $t );

Vue.use( Notifications );
Vue.use( VueI18n );
Vue.use( VuePortal, { name: "Portal" } );

Vue.directive( "position", Position );
Vue.directive( "snap", Snap );
Vue.directive( "strictSnap", StrictSnap );
Vue.directive( "adaptFont", AdaptFont );

/* Avoid Javascript scope pitfall, `Number` class method uses `this`
   to access other class properties, immediately calling the method preserves its
   scope. */
Vue.filter(
    "Number",
    ( value: string ): string => TextFormat.Number( value )
);

Vue.config.productionTip = false;

/* Check app configuration validity. */
if(
    !process.env.CONSOLE_API_ADDRESS ||
    !process.env.CONSOLE_APP_BASE_URL ||
    !process.env.APP_BRAND
)
    throw new Error( "Missing Environement variable configuration." );

/* Using a wrapper bootstrapping function is necessary so that our our async/await code is executed
   properly (Execute the next line once the Promise is resolved) */
( async (): Promise<void> => {
    /* This is our App logic i18n setup, which is separate from Vue's i18n. */
    const [ localeId, error ] = await Locales.GetCached();

    /* TODO: How to handle this? */
    if( error !== undefined )
        console.warn( error );

    if( localeId ) {
        const locale = I18n.GetLocaleById( localeId );
        if( locale )
            I18n.Locale = locale;
    }

    const i18n = new VueI18n({
        locale: I18n.Locale.Id,
        messages: I18n.Translations
    });

    await Users.RestoreSession();

    new Vue({
        i18n,
        router: Router.Get(),
        render: ( h ): VNode => h( App )
    }).$mount( "#app" );
})();
