/* © IBS Group. See LICENSE file for full copyright & licensing details. */

import { DirectiveOptions } from "vue";
import fitty, { FittyInstance } from "fitty";

let fittedElement: FittyInstance;

const directive: DirectiveOptions = {
    inserted: ( element: HTMLElement ): void => {
        fittedElement = fitty( element );
    },

    unbind(): void {
        fittedElement.unsubscribe();
    }
};

export default directive;
