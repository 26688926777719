


import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";

import { CustomError } from "App/Models";
import { Debounce, Sleep } from "@Internal/Utils";
import { EventBus } from "App/IOC";
import { Identifiable } from "App/Contracts";

import Modal from "Web/Components/Common/Modal.vue";
import { Option } from "Web/Contracts";

function getIndex( list: Identifiable[], item: Identifiable ): number {
    for( let i = 0; i < list.length; ++i )
        if( list[ i ].Id === item.Id )
            return i;

    return -1;
}

@Component( {
    components: {
        Modal
    }
})
export default class Autocomplete extends Vue {
    @Prop()
    readonly Id!: string;

    @Prop()
    readonly PopupClass?: string;

    @Prop()
    readonly GetOptions!: ( keyword?: string ) => Promise<[ Option[], CustomError | undefined ]>;

    @Prop()
    readonly Options?: Option[];

    @Prop()
    SelectedOption?: Option;

    @Prop( { default: () => [] } )
    SelectedOptions!: Option[];

    /* Placeholder for the filtering input. */
    @Prop( { default: "" } )
    Placeholder!: string;

    @Prop() NoResultMessage!: string;

    @Prop( { default: "" } )
    Keyword!: string;

    @Prop( { default: false } )
    readonly Readonly!: boolean;

    @Prop( { default: 1 } )
    readonly MaxSelections!: number;

    @Prop( { default: false } )
    readonly CanCreate!: boolean;

    @Prop() readonly SelectText!: string;
    @Prop() readonly CreateText!: string;

    @Ref( "container" )
    private readonly container?: HTMLElement;

    @Ref( "filter" )
    private readonly filter?: HTMLInputElement;

    @Ref( "popup" )
    private readonly popup?: HTMLElement;

    @Ref( "options" )
    private readonly options?: HTMLElement[];

    private selectedOptions: Option[] = [];

    private visibleOptions: Option[] = [];

    /* Filtering keyword. */
    private keyword: string = "";

    /* This variable is used to avoid using positive tabindex values. If you want to highlight
       nothing (For instance the first time we open options list) Set this to -1. */
    private highlighted: number = -1;

    private showOptions: boolean = false;

    private loading: boolean = false;

    /* This flag is necessary to prevent showing options after selecting an option, when selecting
       an option we reset `keyword` which ends by calling keywordChanged method. */
    private resetting: boolean = false;

    private debouncedGetOptions!: ( keyword?: string ) => void;

    /* If we scroll, we need to update the absolute position of the options dropdown
       list, otherwise it stays snapped to the previous location, to do this we simply
       force update the template using the `key` directive. */
    private scrollKey: number = 0;

    created(): void {
        this.keyword = this.Keyword;

        this.debouncedGetOptions = Debounce( this.getOptions, 300 );
    }

    beforeDestroy(): void {
        window.removeEventListener( "click", this.handleOutsideClick );
        document.removeEventListener( "scroll", this.updatePopupPosition, true );
    }

    /* Without the `immediate` flag, the watcher wouldn't be triggered for the initial assignment
        of SelectedOption prop. */
    @Watch( "SelectedOption", { immediate: true } )
    private onSelectedOptionChange(): void {
        /* To keep things simple we separate these props and fill the internal
           selectedOptions value depending on logical conditions. */
        if( this.SelectedOption !== undefined )
            this.selectedOptions = [ this.SelectedOption ];

        /* If we're operating on a single selection, then this is probably a deselection. */
        else if( this.MaxSelections === 1 )
            this.selectedOptions = [];
    }

    @Watch( "SelectedOptions" )
    private onSelectedOptionsChange(): void {
        /* We only mutate internal selections if we're operating on multiple maximum
           selected values. */
        if( this.MaxSelections > 1 )
            this.selectedOptions = this.SelectedOptions;
    }

    private async getOptions( keyword: string = "" ): Promise<void> {
        if( this.resetting )
            return;

        this.loading = true;
        this.visibleOptions = [];

        let options: Option[] = [];
        let error: CustomError | undefined;

        if( this.Options !== undefined )
            options = this.Options;

        else if( this.GetOptions !== undefined )
            [ options, error ] = await this.GetOptions( keyword );

        if( error !== undefined )
            EventBus.Publish( "Error", error );

        this.visibleOptions = options;

        this.loading = false;

        this.highlighted = -1;

        this.showOptions = true;

        /* Stay on the filter since we're probably typing. */
        if( this.filter !== undefined ) {
            /* Give time for the options list to show up first, otherwise focusing wont
               work. */
            await this.$nextTick();

            this.filter.focus();
        }
    }

    @Watch( "showOptions" )
    private showOptionsChanged( shown: boolean ): void {
        /* This is an optimization to avoid listening to the clicking event when
           the options popup is invisible. */

        if( shown ) {
            window.addEventListener( "click", this.handleOutsideClick );
            document.addEventListener( "scroll", this.updatePopupPosition, true );

            return;
        }

        window.removeEventListener( "click", this.handleOutsideClick );
        document.removeEventListener( "scroll", this.updatePopupPosition, true );
    }

    @Watch( "keyword" )
    private keywordChanged( keyword: string ): void {
        /* Doing this improves the UX. Since we are debouncing the search the previous results
           remain visible, which looks unnatural. */
        this.showOptions = false;

        this.debouncedGetOptions( keyword );
    }

    private select( option: Option ): void {
        this.highlighted = getIndex( this.visibleOptions, option );
        this.focusHighlighted();

        this.reset( true );

        this.showOptions = false;

        /* Single selection field. */
        if( this.MaxSelections === 1 ) {
            this.$emit( "Select", option );
            return;
        }

        const selectedOptions = [ ...this.selectedOptions ];

        /* Selected option is already there. */
        for( const selectedOption of selectedOptions )
            if( selectedOption.Id === option.Id )
                return;

        selectedOptions.push( option );

        this.$emit( "Select", selectedOptions );
    }

    private updateHighlighted( move: number ): void {
        const optionsLength = this.visibleOptions.length;

        /* Change highlighted option on arrow keys down, reset cycle when reaching extremes. */
        if( this.highlighted === 0 && move < 0 ) {
            this.highlighted = optionsLength - 1;

        } else if( this.highlighted === optionsLength - 1 && move > 0 ) {
            this.highlighted = -1;

        } else {
            this.highlighted = this.highlighted + move;
        }

        this.focusHighlighted();
    }

    private async focusHighlighted( index?: number ): Promise<void> {
        if( index !== undefined )
            this.highlighted = index;

        await this.$nextTick();

        if( this.filter !== undefined )
            this.filter.focus();

        else if( this.options !== undefined && this.options[ this.highlighted ] !== undefined )
            this.options[ this.highlighted ].focus();

        else
            this.highlighted = -1;
    }

    /* Handles emitted visibility changes,
       loads data if fetch function is provided. */
    private async toggleOptions( value?: boolean ): Promise<void> {
        if( this.Readonly )
            return;

        this.resetting = false;

        this.highlighted = -1;

        const showOptions = ( value !== undefined ? value : !this.showOptions );

        if( showOptions )
            await this.getOptions( this.keyword );

        this.showOptions = showOptions;

        if( this.filter === undefined )
            return;

        /* TODO: Why do we need this? Comment it. */
        await Sleep( 10 );

        this.filter.focus();
    }

    private isOptionSelected( option: Option ): boolean {
        if( this.SelectedOption === undefined )
            return false;

        return this.SelectedOption.Id === option.Id;
    }

    private async deselect( option: Option ): Promise<void> {
        this.highlighted = -1;

        this.showOptions = false;


        /* Single selection field. */
        if( this.MaxSelections === 1 ) {
            this.$emit( "Deselect", option );
            return;

        } else {
            const selectedOptions = [ ...this.selectedOptions ];

            let indexToRemove = -1;

            /* Find the selected options to remov. */
            selectedOptions.forEach( ( selectedOption: Option, index: number ): void => {
                if( selectedOption.Id === option.Id )
                    indexToRemove = index;
            });

            if( indexToRemove !== -1 )
                selectedOptions.splice( indexToRemove, 1 );

            this.$emit( "Select", selectedOptions );
        }

        this.reset();

        this.getOptions( this.keyword );
    }

    private handleOutsideClick( event: Event ): void {
        /* Ignore clicks that are coming from elements inside our component, otherwise we break
           their functionalities. */
        if( this.container === undefined || this.container.contains( event.target as HTMLElement ) )
            return;

        this.showOptions = false;
    }

    /* Clear the input and hide the popup. */
    private async close(): Promise<void> {
        this.reset( true );

        await this.$nextTick();

        this.showOptions = false;
    }

    /* We add `resetting` arguement to prevent showing options only when user select, if user
       deselect an options we show options again. */
    private reset( resetting: boolean = false ): void {
        this.keyword = "";
        this.visibleOptions = [];

        this.resetting = resetting;
    }

    /* TODO: Lots of code is repetitive here and in Simple.vue, abstract them away
       as composable behavior or mixins. */
    private updatePopupPosition( event: Event ): void {
        const target = event.target as HTMLElement;

        /* We're scrolling inside the dropdown, don't do anything. */
        if( this.popup === undefined || this.popup.contains( target ) )
            return;

        /* If we scroll, we need to update the absolute position of the options dropdown
           list, otherwise it stays snapped to the previous location, to do this we simply
           force update the template using the `key` directive. */
        if( this.scrollKey < 500000 )
            this.scrollKey++

        else
            this.scrollKey = 0;
    }

    private create() {
        this.$emit( "Create", this.keyword );

        this.close();
    }
}
