var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.Rows.length > 0)?_c('div',{staticClass:"table-actions"},[_c('button',{staticClass:"selection",on:{"click":_vm.clear}},[_c('i',{staticClass:"material-icons"},[_vm._v(_vm._s(_vm.Rows.length === _vm.TotalRows ? 'done' : 'remove'))])]),_c('span',[_vm._v("\n        "+_vm._s(_vm.Rows.length)+" "+_vm._s(_vm.Rows.length > 1 ? _vm.PluralLabel : _vm.Label)+"\n        "+_vm._s(_vm.$t( "GLOBAL_tableAction" + ( _vm.Rows.length > 1 ? "Multi" : ""  ) + "Selected" )))]),_vm._l((_vm.ActiveActions),function(action){return _c('button',{key:action.Id,on:{"click":function($event){return _vm.execute( action )}}},[_c('i',{staticClass:"material-icons"},[_vm._v(_vm._s(action.ShortName))]),_c('span',[_vm._v(_vm._s(action.Name))])])}),_vm._l((_vm.MassActions),function(massAction,index){return _c('div',{key:'mass-actions-' + massAction.Id,staticClass:"table-mass-actions"},[_c('Popup',{attrs:{"Alignment":"left"},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('span',{ref:"Triggers",refInFor:true,staticClass:"table-mass-actions__title"},[_vm._v(_vm._s(massAction.Name))])]},proxy:true},{key:"body",fn:function(ref){
var Hide = ref.Hide;
return [_c('div',{staticClass:"table-mass-actions__popup"},_vm._l((massAction.ActionGroups),function(actionGroup,groupIndex){return _c('ul',{key:'action-group-' + massAction.Id + '-' + groupIndex,staticClass:"table-mass-actions__group"},_vm._l((actionGroup),function(action){return _c('li',{key:action.Id,staticClass:"table-mass-actions__action",class:{
                                'table-mass-actions__action--disabled' :
                                    ( !action.Multi && _vm.Rows.length !== 1 ) || !_vm.isEnabled( action )
                            },on:{"click":function($event){return _vm.executeMassAction(action, Hide, index)}}},[_c('i',{staticClass:"material-icons"},[_vm._v(_vm._s(action.ShortName))]),_c('span',[_vm._v(_vm._s(( _vm.Rows.length > 1  && action.PluralName )
                                    ? action.PluralName
                                    : action.Name))])])}),0)}),0)]}}],null,true)})],1)})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }