/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import { Customer } from "App/Models";
import { Customers } from "App/IOC/Services";

import Columns from "./Columns";
import Fields from "./Fields";
import Filters from "./Filters";

function PrefixCustomerWithEmail( customers: Customer[] ): void {
    for( const customer of customers )
        customer.Name = customer.Email + " - " + customer.Name;
}

export default {
    Columns,
    Create: Customers.Create,
    EndTrial: Customers.EndTrial,
    Fields,
    Filters,
    Find: Customers.Find,
    FindByNameOrEmail: Customers.FindByNameOrEmail,
    Get: Customers.Get,
    PrefixCustomerWithEmail,
    Update: Customers.Update
};
