/* © IBS Group. See LICENSE file for full copyright & licensing details. */

import { Dictionary } from "App/Contracts";
import { Filter, FilterValue } from "App/Contracts";
import { DeepCopy, EventBus } from "App/IOC";

const filterMap: Dictionary<Filter[]> = {};

/* Assign copies of the values of "filters" argument to the entry matching the provided "id". */
function Save( id: string, filters: Filter[] ): void {
    filterMap[ id ].forEach( ( mappedFilter ): void => {
        /* Clear the filter values, ensuring the removal of anything
           saved previously, which removes non applied filters and prevents
           stacking saved values from each operation on top of each other. */
        mappedFilter.Value = [];

        /* Match filter from the two arrays. */
        const matchingFilter = filters.find( ( filter ): boolean =>
            mappedFilter.Id === filter.Id
        );

        /* Jump to the next iteration if there are no values to be processed. */
        if( matchingFilter === undefined || matchingFilter.Value.length === 0 )
            return;

        /* Copy values from the received filter to the mappedFilter. */
        matchingFilter.Value.forEach( ( value ): void => {
            if(
                typeof value === "string" ||
                typeof value === "number" ||
                typeof value === "boolean"
            )
                mappedFilter.Value.push( value );
            else
                mappedFilter.Value.push( DeepCopy( value ) );
        });
    });

    /* Notify subsribers of the update. */
    EventBus.Publish( "filters-" + id );
}

/* Get a copy of the filters matching the "id" argument from the local filters map. */
function Get( id: string ): Filter[] | undefined {
    /* To prevent mutating filterMap from the outside. Multifilter component processes
       the returned values directly in some cases. */
    const filters: Filter[] | undefined = filterMap[ id ];

    if( filters === undefined )
        return;

    return DeepCopy( filters );
}

/* Add an entry to the local filters map if none match the provided "id". */
function _Set( id: string, filters: Filter[] ): void {
    if( filterMap[ id ] !== undefined )
        return;

    filterMap[ id ] = DeepCopy( filters );
}

/* Assign the provided values to specific filter matching the argument "key" of a Filters
   collection matching the argument "id".

   If the override flag is false, update the local filters, otherwise save the provided filters
   argument. */
async function Assign(
    id: string,
    defaultFilters: Filter[],
    key: string,
    values: FilterValue[],
    override: boolean = false
): Promise<void> {
    /* Recover registered filters. */
    let filters: Filter[] | undefined;

    if( !override )
        filters = Get( id );

    /* In case there aren't any registered filters, fetch filters from the record's use case. */
    if( filters === undefined ) {
        filters = DeepCopy( defaultFilters );
        _Set( id, filters );
    }

    /* Update filter values. */
    const [ filter ] = filters.filter( ( filter: any ): boolean => filter.Id === key );

    filter.Value = values;

    /* Save the updates. */
    Save( id, filters );
}

export default {
    Get,
    Save,
    Set: _Set,
    Assign
};
