/* © IBS Group. See LICENSE file for full copyright & licensing details. */

import { RecordsNavigation } from "App/Models";

/* TODO: Refactor this (check issue #155). */
const Navigation = new RecordsNavigation();

export default {
    Navigation
};
