


import { Component, Vue } from "vue-property-decorator";

import { PriceTargets, Records } from "App";
import { PriceTarget, CustomError, PagerSorter, Column } from "App/Models";
import { Filter } from "App/Contracts";
import { EventBus, $t } from "App/IOC";

import { TableAction } from "Web/Contracts";

import RecordsComponent from "Web/Components/Records.vue";
import PriceTargetForm from "./Form.vue";

@Component({
    components: {
        PriceTargetForm,
        Records: RecordsComponent
    }
})
export default class PriceTargetsView extends Vue {
    $refs!: Vue[ "$refs" ] & {
        Records: RecordsComponent,
    };

    private columns: Column[] = [];
    private records: PriceTarget[] = [];

    private filters = PriceTargets.Filters.Get;

    private pagerSorter: PagerSorter = PagerSorter.ForColumn(
        PriceTargets.Columns.GetDefaultSort(), 15
    );

    private actions: TableAction<PriceTarget>[] = [
        {
            Id: "delete",
            ShortName: "delete",
            Name: $t( "GLOBAL_actionDelete" ),
            Multi: true
        }
    ];

    private showForm: boolean = false;

    private async created(): Promise<void> {
        const [ columns, error ] = await PriceTargets.Columns.Get();

        if( error !== undefined ) {
            EventBus.Publish( "Error", error );
            return;
        }

        this.columns = columns;
    }

    private async update(
        filters: Filter[],
        pagerSorter: PagerSorter,
        resetPagination?: boolean
    ): Promise<void> {
        await this.$nextTick();
        this.$refs.Records.Loading();

        if( resetPagination )
            this.pagerSorter.Page = 1;

        const [ records, updatedPagerSorter, error ] =
            await PriceTargets.Find( filters, pagerSorter );

        this.records = records;
        this.pagerSorter = updatedPagerSorter;

        if( error !== undefined ) {
            EventBus.Publish( "Error", error );
            return;
        }
    }

    private toggleColumn( column: Column ): void {
        PriceTargets.Columns.Toggle( column );
    }

    private executeAction( id: string, records: PriceTarget[] ): void {
        const urls: string[] = [];
        let index: number = 0;

        switch( id ) {
            case "open":
                this.records.forEach( record =>
                    urls.push( "/price-targets/" + record.Id )
                );

                index = this.records.findIndex( record => record.Id === records[ 0 ].Id );

                Records.Navigation.SetList( urls, index );

                this.$router.push( `/price-targets/${records[ 0 ].Id}` );

                break;

            case "delete":
                EventBus.Publish( "Confirmation", {
                    Props: {
                        Message: this.$tc( "PRICE_TARGETS_deleteConfirm", records.length ),

                        Actions: [{
                            Id: "delete",
                            Name: this.$t( "GLOBAL_actionDelete" ),
                            Active: true
                        }]
                    },

                    Events: { Action: () => this.delete( records ) }
                });

                break;

            default:
                EventBus.Publish( "UnderConstruction" );
                break;
        }
    }

    private async delete( records: PriceTarget[] ): Promise<void> {
        this.records = [];

        for( const record of records ) {
            const error = await PriceTargets.Delete( record );

            if( error !== undefined )
                EventBus.Publish(
                    "Error",
                    new CustomError( "Error", $t( "PRICE_TARGETS_cannotDelete" ) )
                );
        }

        this.refresh();
    }

    private refresh(): void {
        this.showForm = false;
        this.update( [], this.pagerSorter, true );

    }
}
