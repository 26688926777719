/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

export default {
    PRODUCTS_title: "Produits",
    PRODUCTS_create: "Créer un produit",
    PRODUCTS_splashScreenTitle: "Créez votre premier produit",
    PRODUCTS_splashScreenDescription:
        "Un produit est l'élément principal dans la gestion des abonnements",

    PRODUCTS_label: "Produit",
    PRODUCTS_plural_label: "Produits",

    PRODUCTS_createSuccessTitle: "Notification",
    PRODUCTS_createSuccessMessage: "Nouveau produit créé avec succès",

    PRODUCTS_columnName: "Nom",
    PRODUCTS_columnState: "Etat",
    PRODUCTS_columnDescription: "Description",
    PRODUCTS_fieldPlaceholderDescription: "Ajouter une description...",
    PRODUCTS_columnCreatedAt: "Créé le",
    PRODUCTS_columnCreatedBy: "Créé par",
    PRODUCTS_columnSequence: "Séquence",

    PRODUCTS_confirmCreate: "Créer un produit",
    PRODUCTS_fieldPlaceholderName: "Saisir le nom du produit",
    PRODUCTS_deleteConfirm: "Êtes-vous sûr de vouloir supprimer ce produit?",
    PRODUCTS_archiveConfirm: "Êtes-vous sûr de vouloir archiver ce produit?",

    PRODUCTS_formChanged: "Pas de changements | Vous avez modifié 1 champ de la propriété | \
    Vous avez modifié {count} champs de la propriété",

    PRODUCT_actions: "Actions",
    PRODUCT_of: "sur",

    PRODUCT_Details: "Détails",
    PRODUCT_Description: "Description",
    PRODUCT_plans: "Plans",
    PRODUCT_prix: "Prix",

    PRODUCTS_fieldName: "Nom",
    PRODUCTS_fieldActive: "Actif",
    PRODUCTS_fieldArchived: "Archivé",

    PRODUCTS_fieldDescription: "Description",
    PRODUCTS_fieldCreated: "Date de céation",
    PRODUCTS_fieldSequence: "Séquence",
    PRODUCTS_fieldPlaceholderSequence: "Saisir la séquence d'affichage...",
    PRODUCTS_addDescription: "Saisissez une description",
    PRODUCTS_addPlan: "Ajouter un plan",
    PRODUCTS_updated: "Produit mis à jour avec succès",
    PRODUCT_prices: "Prix globaux",

    PRODUCTS_recurrenceNo: "Non",
    PRODUCTS_recurrenceYes: "Oui",
    PRODUCTS_fieldRecurrence: "Recurrence"
};

