/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

export default {
    COUPONS_title: "Coupons",
    COUPONS_create: "Create a coupon",
    COUPONS_edit: "Update the coupon",
    COUPONS_fieldMaxUses: "Number of times",
    COUPONS_fieldName: "Coupon name",
    COUPONS_fieldFixedAmount: "Discount amount ",
    COUPONS_fieldPercentage: "Discount percentage ",
    COUPONS_fieldOnce: "Once",
    COUPONS_fieldDuration: "Number of months",
    COUPONS_fieldFrom: "Usable from",
    COUPONS_fieldTo: "Date limite d'utilisation",
    COUPONS_fieldCode: "Coupon code",
    COUPONS_fieldFirstSubscriptionOnly: "Can only be used on a first order",
    COUPONS_confirmCreate: "Create the coupon",
    COUPONS_confirmEdit: "Update the coupon",
    COUPONS_columnName: "Coupon Name",
    COUPONS_columnGeneralConditions: "General conditions",
    COUPONS_columnExpire: "Expires on",
    COUPONS_columnMaxUses: "Redemption limits",
    COUPONS_columnUses: "Uses",
    COUPONS_columnCreatedAt: "Created at",

    COUPONS_discountType: "Discount type",
    COUPONS_discountTypePercentage: "Discount percentage",
    COUPONS_discountTypeFix: "Fixed amount discount",
    COUPONS_discountByCustomers: "Limit to a specific customer",
    COUPONS_once: "Once",
    COUPONS_limited: "Multiple months",
    COUPONS_forever: "Forever",
    COUPONs_details: "Details",
    COUPON_discountNo: "No discount",
    COUPON_discountPercentage: "{percentage}% off for {duration} months",
    COUPON_discountFixedAmount: "{fixedAmount}€ off for {duration} months",
    COUPON_discountYearlyPercentage: "{percentage}% off yearly",
    COUPON_discountYearlyFixedAmount: "{fixedAmount}€ off yearly",
    COUPONS_fieldPlans: "Add plans",
    COUPONS_fieldGlobalPrices: "Add global prices",
    COUPONS_fieldCustomers: "Applies to all customers by default",
    COUPONS_fieldActive: "Active",
    COUPONS_applyOnProducts: "Apply to specific products",
    COUPONS_fieldPlaceholderMaxUses: "Maximum number of uses",
    COUPONS_fieldPlaceholderName: "Name",
    COUPONS_fieldPlaceholderFixedAmount: "Fixed amount discount",
    COUPONS_fieldPlaceholderPercentage: "Discount percentage",
    COUPONS_fieldPlaceholderOnce: "Once",
    COUPONS_fieldPlaceholderDuration: "ex. 1",
    COUPONS_fieldPlaceholderCode: "Unique redemption code",

    COUPONS_createSuccessTitle: "Notification",
    COUPONS_createSuccessMessage: "New coupon created successfully",

    COUPONS_deleteConfirm: "Are you sure you want to delete this coupon ? | " +
        "Are you sure you want to delete these coupons ?",

    COUPONS_cannotDelete: "This coupon cannot be deleted",
    COUPONS_cannotUpdate: "You cannot update the coupon",
    COUPONS_label: "Coupon",
    COUPONS_plural_label: "Coupons",
    COUPONS_splashScreenTitle: "Create your first coupon",

    COUPONS_splashScreenDescription: "Un coupon peut être échangé contre une remise financière | " +
        "ou une remise lors de l'achat d'un produit.",

    COUPONS_formChanged: "No changes | You have changed {} field of the coupon | " +
        "You have changed {count} fields of the coupon",

    COUPONS_updated: "Coupon updated",

    COUPONS_validity: "Validity",
    COUPONS_associatePlans: "Apply to specific products",
    COUPONS_associateGloabalPrices: "Apply to global prices",

    COUPONS_fieldGeneralConditions: "General conditions",
    COUPONS_fieldPlaceholderGeneralConditions: "ex. Single use",

    COUPONS_discountDurationDescription: `This value determines the validity period of the coupon
        once used for a subscription or customer.`,

    COUPONS_duration: "Validity of the coupon once used",
    COUPONS_expiration: "Coupon expiration",

    COUPONS_maxUses: "Define the maximum number of uses of the coupon",
    COUPONS_expirationPeriod: "Define the period during which customers can use this coupon",
    COUPONS_MaxUsesDescription: `This limit applies to multiple customers and therefore will not
        prevent a specific customer from using their coupon multiple times.`,

    COUPONS_countUses: "{0} /",
    COUPONS_actionCustomers: "Customers",
    COUPONS_filter: "Coupons",
    COUPONS_filterPlaceholder: "Filter by coupons",
    COUPON_confirmCreate: "You cannot edit the coupon once it is created.",
    COUPON_duplicateCode: "This coupon code is already used."
};
