var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table",class:{ 'loading': _vm.Loading }},[_c('div',{ref:"Wrapper",staticClass:"table-wrapper"},[_c('table',{ref:"Table"},[_c('TableHead',{ref:"Head",attrs:{"Actions":_vm.ActionsEnabled,"Columns":_vm.activeColumns,"Sorter":_vm.PagerSorter,"SelectAll":_vm.selectedRows.length > 0 &&
                    _vm.selectedRows.length === _vm.selectableRows.length},on:{"ToggleSorter":_vm.updatePagerSorter,"SelectAll":_vm.SelectAll}}),_c('TableBody',{ref:"Body",attrs:{"Rows":_vm.Rows,"Actions":_vm.ActionsEnabled,"Columns":_vm.activeColumns,"IsSelectable":_vm.IsSelectable},on:{"Select":_vm.selectRows,"RowAction":_vm.executeAction},scopedSlots:_vm._u([{key:"Cell",fn:function(ref){
                    var Row = ref.Row;
                    var Column = ref.Column;
                    var Value = ref.Value;
return [_vm._t("Cell",null,{"Row":Row,"Column":Column,"Value":Value})]}}],null,true)}),(_vm.ColumnConfig)?_c('ColumnConfig',{attrs:{"Columns":_vm.Columns},on:{"Toggle":_vm.toggleColumn}}):_vm._e()],1),(_vm.Loading)?_c('div',{staticClass:"loader"},[_c('LoadingDots')],1):_vm._e()]),(!_vm.scrolledToEnd && _vm.overflow)?_c('div',{staticClass:"scroll-overlay",class:{
            'scroll-overlay--no-footer': !_vm.Pagination
        }}):_vm._e(),(_vm.Pagination)?_c('TablePagination',{class:{ 'spaced': !_vm.Rows.length },attrs:{"Limits":_vm.PageLimits,"Pager":_vm.PagerSorter},on:{"Page":_vm.updatePagerSorter,"Limit":_vm.updatePagerSorter}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }