


import { Component, Vue } from "vue-property-decorator";

import { Users } from "App";
import { EventBus } from "App/IOC";
import { CustomError, CustomNotification } from "App/Models";
import { Sleep } from "@Internal/Utils";
import { Dictionary } from "@Internal/Contracts";

import PopupWindow from "Web/Components/Popup/Window.vue";
import { DynamicComponent } from "Web/Contracts";

@Component({
    components: {
        PopupWindow
    }
})
export default class App extends Vue {
    $refs!: Vue[ "$refs" ] & {
        ConfirmationPopup: Vue
    };

    private version: string = process.env.CONSOLE_APP_VERSION || "unknown";

    /* Some pages send multiple concurrent requests, we use this flag to avoid
       multiple notifications. */
    private authorizationInvalidatedNotification: boolean = false;

    private recordMutated: boolean = false;

    private popupProps: Dictionary<any> = {};

    /* TODO: Put the generic type in its own file if you search and find it more than twice. */
    private popupEvents: Dictionary<( ...args: any[] ) => any> = {};

    private confirmationPopup: boolean = false;

    private created() {
        EventBus.Subscribe( "InvalidAuthorization", this.authorizationInvalidated, this );
    }

    private mounted() {
        EventBus.Subscribe( "Notification", this.onNotification, this );
        EventBus.Subscribe( "UnderConstruction", this.underConstruction, this );
        EventBus.Subscribe( "Error", this.onError, this );
        EventBus.Subscribe( "SingleRecordMutated", this.onRecordMutated,this );
        EventBus.Subscribe( "Confirmation", this.onConfirmationPopup, this );
    }

    private async authorizationInvalidated() {
        if( this.authorizationInvalidatedNotification )
            return;

        const notification = CustomNotification.Failure(
            this.$t( "GLOBAL_warning" ).toString(),
            this.$t( "GLOBAL_sessionExpired" ).toString()
        );

        this.authorizationInvalidatedNotification = true;

        await Users.SignOut();

        this.$router.push( "/user/signin" );

        this.$notify({
            type: notification.Type,
            title: notification.Title,
            text: notification.Message,
            duration: 4000
        });

        await Sleep( 4000 );


        this.authorizationInvalidatedNotification = false;
    }

    private underConstruction() {
        const notification = CustomNotification.Failure(
            "Warning",
            "This feature is under construction."
        );

        this.$notify({
            type: notification.Type,
            title: notification.Title,
            text: notification.Message,
            duration: 4000
        });
    }

    private onNotification( notification: CustomNotification ) {
        this.$notify({
            type: notification.Type,
            title: notification.Title,
            text: notification.Message,
            duration: 4000
        });
    }

    private onError( error: CustomError ) {
        const notification = CustomNotification.Failure(
            this.$t( "GLOBAL_warning" ).toString(),
            error.Message
        );

        this.$notify({
            type: notification.Type,
            title: notification.Title,
            text: notification.Message,
            duration: 4000
        });
    }

    private onRecordMutated( value: boolean ) {
        this.recordMutated = value;
    }

    private onConfirmationPopup( component: DynamicComponent ) {
        this.popupProps = component.Props || {};

        /* Automatically close confirmation popups on main action execution. */
        this.popupProps.CloseOnAction = true;

        /* Add default confirmation styles to the provided ClassName prop */
        this.popupProps.ClassName = "c-window--confirmation " +
            ( this.popupProps.ClassName === undefined ? "" : this.popupProps.ClassName );

        this.popupEvents = component.Events || {};
        this.confirmationPopup = true;

        this.$nextTick( () => {
            this.$refs.ConfirmationPopup.$once( "Close", this.confirmationPopupClickOutside );
        });
    }

    private confirmationPopupClickOutside() {
        this.$nextTick( () => {
            this.$refs.ConfirmationPopup.$destroy();
            this.confirmationPopup = false;
        });
    }
}
