/* © IBS Group. See LICENSE file for full copyright & licensing details. */

import { DirectiveBinding } from "vue/types/options";
import { DirectiveOptions } from "vue";
import { Vue } from "vue-property-decorator";

interface Offsets {
    height: number;
    top: number;
    left: number;
}

/* Caution: We might need to add componentUpdated() in case something changes in the UI and we need
   to update the computed positioning. */

function getDirective( strict: boolean ): DirectiveOptions {
    return {
        inserted: ( element: HTMLElement, binding: DirectiveBinding ): void => {
            setPosition( element, binding, strict );
        },

        componentUpdated: ( element: HTMLElement, binding: DirectiveBinding ): void => {
            /* No need to update element positioning if binding argument doesn't change. */
            if( binding.arg === binding.oldArg )
                return;

            /* Reset offsets inline styling for target element. */
            element.style.removeProperty( "top" );
            element.style.removeProperty( "left" );
            element.style.removeProperty( "right" );
            element.style.removeProperty( "bottom" );
            setPosition( element, binding, strict );
        }
    };
}

function setPosition( element: HTMLElement, binding: DirectiveBinding, strict: boolean ): void {
    const anchor = binding.value.element;

    const offsetsValues: Offsets = binding.value.boundaries !== undefined
        ? binding.value.boundaries
        : {};

    const offsets: Offsets = {
        height: offsetsValues.height !== undefined ? offsetsValues.height : 0,
        top: offsetsValues.top !== undefined ? offsetsValues.top : 0,
        left: offsetsValues.left !== undefined ? offsetsValues.left : 0
    }

    /* return to avoid errors for optional directive use. */
    if( binding.value === undefined && !strict )
        return;

    /* Get coordinates and size details from the anchor element / component. */
    const anchorRectangle =
        ( anchor instanceof Vue ? anchor.$el : anchor ).getBoundingClientRect();

    /* If an item is positioned naturaly and is mounted in a large container it will take
       that container's width, potentially the entire window and not the width of the
       content inside, so to avoid this, we set the position to absolute so the browser can
       recompute the width and getBoundingClientRect returns the correct result. */
    element.style.position = "absolute";

    /* Get coordinates and size details from the directive's target. */
    const targetRectangle = element.getBoundingClientRect();

    let x = anchorRectangle.left + window.pageXOffset + offsets.left;
    let y = anchorRectangle.top + window.pageYOffset + offsets.top;

    /* Calculate the corresponding coordinates according to the passed argument. */
    switch( binding.arg ) {
        case "left":
            x -= targetRectangle.width;
            break;

        case "right":
            x += anchorRectangle.width;
            break;

        case "top":
            y -= targetRectangle.height;
            break;

        case "top-center":
            y -= targetRectangle.height;
            x -= targetRectangle.width / 2 - ( anchorRectangle.width / 2 );
            break;

        case "bottom":
        case "bottom-resize":
        case "bottom-to-top":
            y += anchorRectangle.height;
            break;

        case "bottom-center":
            y += anchorRectangle.height;
            x -= targetRectangle.width / 2 - ( anchorRectangle.width / 2 );
            break;

        case "bottom-left":
            y += anchorRectangle.height;
            x -= targetRectangle.width - ( anchorRectangle.width );
            break;

        case "bottom-right":
            y += anchorRectangle.height;
            x += anchorRectangle.width;
            break;

        case "to-right":
            /* Snap the element starting from anchor element right edge moving to left. */
            x = ( window.innerWidth - ( anchorRectangle.width + x ) );
            break;

        case "element":
            y += offsets.height;
            break;

        default:
            return;
    }

    /* Calculate the element's overflow relative to the window boundaries */
    const xOverflow = x + targetRectangle.width - window.innerWidth;
    const yOverflow = y + targetRectangle.height - window.innerHeight;

    /* Ensure the popup remains inside the window by substracting positive overflow
       from the calculated coordinates. */
    if( binding.arg === "to-right" )
        element.style.right = ( x - Math.max( xOverflow, 0 ) ) + "px";
    else
        element.style.left = ( x - Math.max( xOverflow, 0 ) ) + "px";

    /* Sometimes it's not a good idea to reposition our element if it overflows, instead its
       best if we reduce its height so that it stays exactly bellow the target, for instance
       in an autocomplete field with a filter, if we don't do this, the in the case of an
       overflow, the snapped popup will be rendered on top of the filter and we wont be able
       to see it or type in it. */
    if( binding.arg === "bottom-resize" ) {
        element.style.top = y + "px";

        /* We substract the overflow so that our target is snapped to the bottom edge of the
           window. */
        if( yOverflow > 0 )
            element.style.maxHeight =
                ( targetRectangle.height - Math.max( yOverflow, 0 ) ) + "px";

    } else {
        element.style.top = ( y - Math.max( yOverflow, 0 ) ) + "px";
    }

    if( binding.arg === "bottom-to-top" ) {
        element.style.top = y + "px";

        if( yOverflow > 0 ) {
            y -= targetRectangle.height + anchorRectangle.height;
            element.style.top = y + "px";
        }
    }
}

const Snap: DirectiveOptions = getDirective( false );
const StrictSnap: DirectiveOptions = getDirective( true );

export {
    Snap,
    StrictSnap
};
