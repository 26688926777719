/* © IBS Group. See COPYRIGHT file for full copyright & licensing details. */

import { Filter, FilterValue } from "../../Contracts/Filter";

export default abstract class BaseFilter implements Filter {
    protected id: string;
    protected name: string;
    protected shortName: string;
    protected placeholder: string = "";
    protected unique: boolean = true; /* Can this filter be reused multiple times ? */

    constructor( id: string, name: string, placeholder?: string ) {
        this.id = id;
        this.shortName = this.name = name;

        if( placeholder !== undefined )
            this.placeholder = placeholder;
    }

    get Id(): string { return this.id; }
    get Name(): string { return this.name; }
    get ShortName(): string { return this.name; }
    get Placeholder(): string { return this.placeholder; }
    get Unique(): boolean { return this.unique; }

    abstract Value: FilterValue[] = [];

    abstract FormatValue(): ( number | string | boolean )[] | number | string | boolean;
    abstract Format(): string | number | boolean;
}
