/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import { Coupon } from "App/Models";
import { Filter } from "@Internal/Contracts";
import { CustomError, PagerSorter, SelectionFilter, TextFilter } from "@Internal/Models";
import Coupons from "../Coupons";

let defaultFilters: Filter[] = [];

async function getByCoupon( keyword?: string ): Promise<[ Coupon[], CustomError | undefined ]> {
    const nameFilter = new TextFilter( "Name", "" );
    nameFilter.Value = [ keyword !== undefined ? keyword : "" ];

    const [ coupons, , error ] = await Coupons.Find( [ nameFilter ], new PagerSorter() );

    if( error !== undefined )
        return [ [], error ];

    return [ coupons, undefined ];
}

function Get(): Filter[] {
    if( defaultFilters.length === 0 )
        defaultFilters = [
            SelectionFilter.Request(
                "Coupon",
                $t( "COUPONS_filter" ),
                getByCoupon,
                $t( "COUPONS_filterPlaceholder" )
            ),

            new TextFilter(
                "Email",
                $t( "CUSTOMERS_filterEmail" ),
                $t( "CUSTOMERS_filterEmailPlaceholder" )
            )
        ];

    return defaultFilters;
}

export default {
    Get
};
