var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-menu"},[_c('router-link',{attrs:{"to":"/under-construction"}},[_c('div',{staticClass:"c-menu__brand"},[_c('img',{staticClass:"c-menu__logo c-menu__logo--large",attrs:{"alt":_vm.$t( 'GLOBAL_appBrand' ),"src":require("../../../Assets/Images/logo-white.svg"),"title":_vm.$t( 'GLOBAL_appBrand' )}}),_c('img',{staticClass:"c-menu__logo c-menu__logo--small",attrs:{"alt":"$t( 'GLOBAL_appBrand' )","src":require("../../../Assets/Images/generic-logo-white.png"),"title":_vm.$t( 'GLOBAL_appBrand' )}})])]),_c('router-link',{staticClass:"c-menu__link",attrs:{"to":"/under-construction"}},[_vm._v(_vm._s(_vm.$t( "MAIN_MENU_dashboard" )))]),_c('div',{staticClass:"c-menu__link c-menu__link--dropdown",class:{
            'router-link-active': [
                '/products',
                '/coupons'
            ].includes( _vm.$route.path )
        }},[_c('Popup',{attrs:{"Alignment":"left"},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('a',{staticClass:"c-menu__link-trigger"},[_vm._v(_vm._s(_vm.$t( "MAIN_MENU_products" )))])]},proxy:true},{key:"body",fn:function(ref){
        var Hide = ref.Hide;
return [_c('ul',{staticClass:"c-list"},[_c('li',{staticClass:"c-list__item"},[_c('a',{staticClass:"c-list__item-content",on:{"click":function () { _vm.navigate( '/products' ); Hide() }}},[_vm._v(_vm._s(_vm.$t( "MAIN_MENU_plans" )))])]),_c('li',{staticClass:"c-list__item"},[_c('a',{staticClass:"c-list__item-content",on:{"click":function () { _vm.navigate( '/coupons' ); Hide() }}},[_vm._v(_vm._s(_vm.$t( "MAIN_MENU_coupons" )))])]),_c('li',{staticClass:"c-list__item"},[_c('a',{staticClass:"c-list__item-content",on:{"click":function () { _vm.navigate( '/price-targets' ); Hide() }}},[_vm._v(_vm._s(_vm.$t( "MAIN_MENU_priceTargets" )))])])])]}}])})],1),_c('router-link',{staticClass:"c-menu__link",attrs:{"to":"/customers"}},[_vm._v(_vm._s(_vm.$t( "MAIN_MENU_customers" )))]),_c('router-link',{staticClass:"c-menu__link",attrs:{"to":"/subscriptions"}},[_vm._v(_vm._s(_vm.$t( "MAIN_MENU_subscriptions" )))]),_c('router-link',{staticClass:"c-menu__link",attrs:{"to":"/under-construction"}},[_vm._v(_vm._s(_vm.$t( "MAIN_MENU_invoicing" )))]),_c('div',{staticClass:"c-menu__create"}),_c('Popup',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('a',{staticClass:"c-menu__link-trigger c-menu__link c-menu__link--configuration"},[_vm._v(_vm._s(_vm.$t( "MAIN_MENU_configuration" )))])]},proxy:true},{key:"body",fn:function(ref){
        var Hide = ref.Hide;
return [_c('ul',{staticClass:"c-list"},[_c('li',{staticClass:"c-list__item"},[_c('a',{staticClass:"c-list__item-content",on:{"click":function () { _vm.navigate( '/configuration' ); Hide() }}},[_vm._v(_vm._s(_vm.$t( "CONFIGURATION_navigationGeneral" )))])]),_c('li',{staticClass:"c-list__item"},[_c('a',{staticClass:"c-list__item-content",on:{"click":function () { _vm.navigate( ("/coupons/" + _vm.yearlyCouponId) ); Hide() }}},[_vm._v(_vm._s(_vm.$t( "CONFIGURATION__yearlyCoupon" )))])])])]}}])}),_c('div',{staticClass:"c-menu__profile"},[_c('Popup',{ref:"SignoutPopup",attrs:{"Alignment":"right"},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('a',{staticClass:"c-menu__profile-image"},[_c('span',[_vm._v(_vm._s(_vm.user.Initials))])])]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"c-menu__profile-panel"},[_c('div',{staticClass:"c-menu__profile-content"},[_c('div',{staticClass:"c-menu__profile-image c-menu__profile-image--small"},[_c('span',[_vm._v(_vm._s(_vm.user.Initials))])]),_c('div',{staticClass:"c-menu__profile-data"},[_c('p',{staticClass:"c-menu__profile-text c-menu__profile-text--alias"},[_vm._v("\n                                @"+_vm._s(_vm.user.Name)+"\n                            ")]),_c('router-link',{staticClass:"c-menu__profile-link",attrs:{"to":"/user/settings"}},[_vm._v("\n                                My account\n                            ")])],1)]),_c('div',{staticClass:"c-menu__profile-action"},[_c('a',{on:{"click":_vm.signOut}},[_c('i',{staticClass:"material-icons"},[_vm._v("input")]),_vm._v(" Sign out")])])])]},proxy:true}])})],1),(_vm.quickCreationForm !== '')?_c(_vm.quickCreationForm,{tag:"Component",attrs:{"ShowProperty":false,"ShowContact":true,"Redirect":true},on:{"Create":_vm.notifyCreation,"Close":function($event){_vm.quickCreationForm = ''}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }