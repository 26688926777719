


import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

import { Identifiable } from "App/Contracts";
import { Field } from "App/Models";
import { DeepCopy, DeepEqual } from "App/IOC";

import FormField from "Web/Components/Form/Field.vue";

/* TODO: CountDifferentFields() should be an enforceable interface */
type FormRecord = Identifiable;

@Component
export default class Form extends Vue {
    @Prop() public Record!: FormRecord;

    @Prop( { default: true } )
    ShowActions!: boolean;

    @Prop()
    Model?: string;

    private record: FormRecord & any = {};

    private changed: boolean = false;

    private fields = new Set<FormField>();

    /* Track the number of changed fields to be displayed in the actions box. */
    private changedFields: number = 0;

    /* Freeze the form's change checking mechanism to prevent it from potentially executing
       multiple times, since when we reset the form each field's value is updated
       and emits the value afterwards which fires our update() method. */
    private freeze: boolean = false;

    GetRecord(): FormRecord {
        return this.record;
    }

    Register( field: FormField ) {
        /* Watch out for value updates. */
        field.$on( "Value", this.update );

        this.fields.add( field );

        field.OriginalValue = ( this.Record as any )[ field.Metadata.Id ];
        field.Value = this.record[ field.Metadata.Id ];
    }

    Unregister( field: FormField ) {
        field.$off( "Value", this.update );

        this.fields.delete( field );
    }

    Update( field: Field, value: any ) {
        this.update( field, value );
    }

    @Watch( "Record", { deep: true, immediate: true } )
    private onRecordChange( record: FormRecord ) {
        /* Either we received no valid record or we already have the same record setup. */
        if( !record )
            return;

        this.$nextTick( this.reset );
    }

    private async reset(): Promise<void> {
        /* Revert back to the original record and reset field components as well. */
        this.record = DeepCopy( this.Record ) as FormRecord;

        this.freeze = true;

        /* TODO: Double check if this triggers update() twice. */
        for( const [ field ] of this.fields.entries() ) {
            field.OriginalValue = ( this.Record as any )[ field.Metadata.Id ];
            field.Value = this.record[ field.Metadata.Id ];
        }

        await this.$nextTick();

        this.freeze = false;

        this.checkChanges();
    }

    /* TODO: Is there a better way to do this? for now it's only used with FormField
       @Value event. */
    private update( field: Field, value: any ) {
        if( field.Silent )
            return;

        let formField: FormField | undefined;

        for( const [ item ] of this.fields.entries() ) {
            if( item.Metadata.Id === field.Id ) {
                formField = item;
                break;
            }
        }

        if( formField === undefined )
            return;

        this.$set( this.record, field.Id, value );

        formField.OriginalValue = ( this.Record as any )[ field.Id ];
        formField.Value = this.record[ field.Id ];

        this.checkChanges();
    }

    private checkChanges() {
        if( this.freeze )
            return;

        this.changedFields = this.countDifferentFields();

        /* Prevent emitting unnecessary events if nothing actually changed. */
        this.changed = this.changedFields > 0;

        return this.$emit( "Change", this.changed, this.update );
    }

    @Emit( "Save" )
    private save() {
        return this.record;
    }

    private countDifferentFields(): number {
        let total: number = 0;

        for( const [ key, value ] of Object.entries( this.record ) ) {
            if( !DeepEqual(
                value as Record<string, any>,
                ( this.Record as Record<string, any> )[ key ]
            ))
                ++total;
        }

        return total;
    }
}
