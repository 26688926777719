/* © IBS Group. See COPYRIGHT file for full copyright & licensing details. */

import "dayjs/locale/en";
import "dayjs/locale/fr";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { Copiable, Equatable, ICustomDate, Dictionary, DateUnit } from "@Internal/Contracts";
import { I18n } from "@Internal/Models";
import { SerializedValue } from "@Internal/UseCases/Models";

dayjs.extend( customParseFormat );

const sourceFormat = "YYYY-MM-DD";

export default class CustomDateDayJS
implements Copiable<ICustomDate>, Equatable<ICustomDate>, ICustomDate {

    static Deserialize( value: SerializedValue ): ICustomDate | undefined {
        if( typeof value === "string" || typeof value === "number" || value instanceof Date )
            return new CustomDateDayJS( value ) as ICustomDate;

        return;
    }

    Time = false;

    private value: dayjs.Dayjs;
    private i18n?: I18n;
    private sourceFormat: string;

    constructor( date: string | Date | number, i18n?: I18n, format?: string ) {
        this.sourceFormat = format || sourceFormat;

        switch( typeof date ) {
            case "string":
                this.value = dayjs( date, this.sourceFormat );
                break;

            case "number":
                this.value = dayjs.unix( Number( date ) );
                break;

            default:
                this.value = dayjs( date );
                break;
        }

        this.i18n = i18n;
    }

    ToDate(): Date {
        return this.value.toDate();
    }

    ToTimestamp(): number {
        return this.value.unix();
    }

    Add( value: number, unit: "day" | "week" | "month" | "year" ): CustomDateDayJS {
        this.value = this.value.add( value, unit );
        return this;
    }

    Format( format?: string ): string {
        if( !this.value.isValid() )
            return "—";

        if( format === undefined )
            format = $t(
                this.Time ? "GLOBAL_defaultDateTimeFormat" : "GLOBAL_defaultDateFormat"
            );

        const value = this.i18n !== undefined
            ? this.value.locale( this.i18n.Locale.Id )
            : this.value;

        return value.format( format );
    }

    ToSourceFormat(): string {
        return this.Format( this.sourceFormat );
    }

    toString(): string {
        return this.Format();
    }

    Copy(): ICustomDate {
        const date = new CustomDateDayJS( this.ToDate(), this.i18n, this.sourceFormat );
        date.Time = this.Time;

        return date;
    }

    Equals( instance: ICustomDate | Date ): boolean {
        return this.value.isSame(
            instance instanceof Date ? instance : ( instance as CustomDateDayJS ).value
        );
    }

    /* By default CustomDateDayJS.Difference() computes difference in milliseconds returning back
       an integer. */
    Difference(
        instance: ICustomDate,
        unit: DateUnit = "millisecond",
        fractional: boolean = false
    ): number {
        return this.value.diff( ( instance as CustomDateDayJS ).value, unit, fractional );
    }

    SameDay( instance: ICustomDate ): boolean {
        const source = this.value;
        const target = ( instance as CustomDateDayJS ).value;

        return source.day() === target.day() &&
            source.month() === target.month() &&
            source.year() === target.year();
    }

    IsBefore( instance: CustomDateDayJS ): boolean {
        return this.value.isBefore( instance.value );
    }

    IsAfter( instance: CustomDateDayJS ): boolean {
        return this.value.isAfter( instance.value );
    }

    Serialize( Annotate: boolean = false ): Dictionary | string {
        const value: string = this.ToSourceFormat();

        if( !Annotate )
            return value;

        return {
            $Value: value,
            $Model: this.Time ? "CustomDateTime" : "CustomDate"
        }
    }
}

export function DeserializeCustomDateTime( value: SerializedValue ): ICustomDate | undefined {
    if( typeof value !== "number" && typeof value !== "string" && !( value instanceof Date ) )
        return;

    const instance = new CustomDateDayJS( value );
    instance.Time = true;

    return instance;
}
