


import { Component, Prop, Ref, Vue } from "vue-property-decorator";

import { PriceTargets } from "App";
import { Field, PriceTarget } from "App/Models";

import { Dictionary } from "App/Contracts";

import Tab from "Web/Components/Tabs/Tab.vue";
import Tabs from "Web/Components/Tabs/Index.vue";

import Form from "Web/Components/Form/Index.vue";
import FormField from "Web/Components/Form/Field.vue";
import FormFieldset from "Web/Components/Form/Fieldset.vue";
import FormFieldGroup from "Web/Components/Form/FieldGroup.vue";
import BooleanField from "Web/Components/Common/Input/Boolean.vue";
import PopupWindow from "Web/Components/Popup/Window.vue";

@Component({
    components: {
        BooleanField,
        Field: FormField,
        Fieldset: FormFieldset,
        FieldGroup: FormFieldGroup,
        Form,
        PopupWindow,
        Tab,
        Tabs
    }
})
export default class PriceTargetForm extends Vue {
    @Prop()
    Record!: PriceTarget;

    @Ref( "form" )
    readonly form!: Form;

    fields: Dictionary<Field> = {};

    mutated: boolean = false;

    async mounted(): Promise<void> {
        this.fields = await PriceTargets.Fields.Get();
    }

    private save( record: PriceTarget ): void {
        this.$emit( "Save", record );
    }
}
