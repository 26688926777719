/* © Facilogi.  See LICENSE file for full copyright & licensing details. */

import { PriceTargets } from "App/IOC/Services";
import Columns from "./Columns";
import Fields from "./Fields";
import Filters from "./Filters";

export default {
    Columns,
    Create: PriceTargets.Create,
    Delete: PriceTargets.Delete,
    Fields,
    Filters,
    Find: PriceTargets.Find,
    Get: PriceTargets.Get,
    Update: PriceTargets.Update
}
