/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import { Decimal as IDecimal } from "@Internal/Contracts";

export default interface BaseGlobalConfiguration<T> {
    Trial?: number;
    YearlyCoupon?: T;
    SubscriptionPlansNote?: string;
    SubscriptionPaymentRetries?: number;
    UnlimitedQuantity?: IDecimal;
    MonthlySEPA?: number;
    YearlySEPA?: number;
    /* This is the buisness name that will show up on our customer's bank or credit card
       statements, this is may be up to 22 characters. */
    PaymentStatementDescription?: string;
}
