


import { Component, Vue, Prop } from "vue-property-decorator";

import { Column } from "App/Models";

import Popup from "Web/Components/Common/Popup.vue";
import Modal from "Web/Components/Common/Modal.vue";

@Component( {
    components: {
        Modal,
        Popup
    }
})
export default class ColumnConfig extends Vue {
    $refs!: Vue[ "$refs" ] & {
        Trigger: HTMLElement
    }

    @Prop() public Columns!: Column[];

    private showPopup: boolean = false;

    private toggle( column: Column ) {
        this.$emit( "Toggle", column );
    }

    /* This function handles popup visibility since the trigger button was moved outside of
       the popup component to be used as an anchor for v-snap directive. */
    private togglePopup( value?: boolean ) {
        if( value !== undefined )
            this.showPopup = value;
        else
            this.showPopup = !this.showPopup;
    }
}
