/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import { BaseModel } from "@Internal/Models";

import { Identifiable, Named } from "Shared/Contracts";

export default class BaseUser extends BaseModel implements Identifiable, Named {
    Email?: string;
    FirstName?: string;
    LastName?: string;
    Locale?: string;

    constructor(
        id: number,
        email?: string,
        firstName?: string,
        lastName?: string,
        locale?: string
    ) {
        super( id );

        this.Email = email;
        this.FirstName = firstName;
        this.LastName = lastName;
        this.Locale = locale;
    }

    get Id(): number { return this.id; }

    get Name(): string {
        const name = [];

        if( this.FirstName !== undefined && this.FirstName !== "" )
            name.push( this.FirstName );

        if( this.LastName !== undefined && this.LastName !== "" )
            name.push( this.LastName );

        return name.join( " " );
    }

    get Initials(): string {
        let name = "";

        if( this.FirstName !== undefined )
            name += this.FirstName.trim().charAt( 0 );

        if( this.LastName !== undefined )
            name += this.LastName.trim().charAt( 0 );

        return name;
    }
}
