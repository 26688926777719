


import { Component, Emit, Prop, Ref, Vue } from "vue-property-decorator";

import { CustomValue, Field } from "@Internal/Models";
import {
    Dictionary,
    Activated,
    Identifiable,
    Named
} from "@Internal/Contracts";
import { $t, DeepCopy } from "App/IOC";
import {
    Price,
    PriceTier,
    PriceTarget,
    GlobalConfiguration
} from "App/Models";
import { IDecimal } from "App/Contracts";

import { IsTiered, PriceTypes, Recurrence } from "Shared/Models";

import { Prices, Products } from "App";

import { InjectReactive } from "Web/Libs/VuePropertyDecorators";
import BooleanField from "Web/Components/Common/Input/Boolean.vue";
import SimpleSelect from "Web/Components/V2/Select/Simple.vue";
import PopupWindow from "Web/Components/Popup/Window.vue";
import Form from "Web/Components/Form/Index.vue";
import FormField from "Web/Components/Form/Field.vue";
import FormFieldGroup from "Web/Components/Form/FieldGroup.vue";
import PriceTiers from "./Tiers.vue";

@Component({
    components: {
        BooleanField,
        PopupWindow,
        Form,
        Field: FormField,
        FieldGroup: FormFieldGroup,
        PriceTiers,
        SimpleSelect
    }
})
export default class PriceForm extends Vue {
    @Prop() readonly Record?: Price;

    @Prop( { default: false } )
    readonly Global!: boolean;

    @Prop() Target?: PriceTarget;

    @Ref( "form" )
    private Form!: Form;

    @InjectReactive( { from: "configuration", default: undefined } )
    private configuration?: GlobalConfiguration;

    private action: Identifiable & Named & Activated = {
        Id: "create",
        Name: "",
        Active: false
    };

    private createTier: Identifiable & Named & Activated = {
        Id: "create",
        Name: $t( "PRICES_addTier" ),
        Active: false
    };

    private fields: Dictionary<Field> = {};
    private record: Price = Prices.NewPrice({
        Id: -1,
        Plans: [],
        Prices: [],
        Sequence: 0,
        Active: true
    });

    private showPriceForm: boolean = false;

    private pricingTypes: CustomValue<PriceTypes>[] = Prices.Fields.GetTypes();

    private recurrenceOptions: CustomValue<Recurrence>[] = Products.Fields.GetRecurrenceOptions();

    private isUnlimited = false;
    private isTranslatableFieldsVisible: boolean = false;
    private selectedTarget?: PriceTarget;

    async created(): Promise<void> {
        this.isTranslatableFieldsVisible = this.showTranslatableFields();

        if( this.Record !== undefined && this.Record.Target !== undefined )
            this.selectedTarget = this.Record.Target;
        else
            this.selectedTarget = this.Target;

        this.action.Name = $t( this.edit ? "PRICES_confirmEdit" : "PRICES_confirmCreate" );
        this.record.Type = this.pricingTypes[ 0 ].Value;

        /* Default to "No" for global prices. */
        if( this.Global )
            this.record.Recurrence = this.recurrenceOptions[ 0 ].Value;

        if( this.Record !== undefined )
            this.record = DeepCopy( this.Record ) as Price;

        if(
            this.isTiered( this.record ) &&
            this.record.Tiers.length === 1 &&
            this.configuration !== undefined &&
            this.record.Tiers[ 0 ].MaxQuantity.IsEqual(
                this.configuration.UnlimitedQuantity as IDecimal
            )
        )
            this.isUnlimited = true;

        this.fields = await Prices.Fields.Get();
    }

    private isTiered( record: Price ): boolean {
        return IsTiered( record );
    }

    private canCreate( changed: boolean = true ): void {
        if( !changed )
            return;

        const record: Price = this.Form.GetRecord() as Price;

        /* Needed for in-template conditions. */
        const validTiers: boolean = !this.isTiered( record ) || record.Tiers.length > 0;

        this.action.Active = validTiers &&
            (
                !this.isTranslatableFieldsVisible ||
                ( record.Name !== undefined && /\S/.test( record.Name ) )
            );
    }

    private create(): void {

        const record: Price = this.Form.GetRecord() as Price;
        if( IsTiered( record ) ) {
            record.MinQuantity = undefined;
            record.MaxQuantity = undefined;

            for( const tier of record.Tiers )
                delete tier.Id;
        }

        if( this.Target !== undefined )
            record.Target = this.Target;

        this.$emit( "Create", record );

        this.close();
    }

    get edit(): boolean {
        return this.Record !== undefined;
    }

    @Emit( "Close" )
    private close() {}

    private getDefaultTiers(): PriceTier[] {
        return Prices.GetDefaultTiers();
    }

    private toggleUnlimitedOption(): void {
        this.isUnlimited = !this.isUnlimited;

        if( this.isUnlimited )
            this.Form.Update( this.fields.FlatFee, true );
    }

    private showTranslatableFields(): boolean {
        /* Hide translatable fields in case we're updating a non generic price. */
        if( this.edit && this.Record !== undefined && this.Record.Target !== undefined )
            return false;

        /* If this price meant to be generic, we need to display the translatable fields in order
           to enter values. */
        if( this.Target === undefined )
            return true;

        /* In case if the translatable fields not defined on the price target, let possibility to
           enter the translation values. */
        const targetTranslatableFields =
            [ "PriceName", "PriceDescription", "PriceUnitName", "PricePluralUnitName" ];

        const missingTargetTranslations = targetTranslatableFields.some( ( key: string ): boolean =>
            ( this.Target as any )[ key ] === undefined || ( this.Target as any )[ key ] === ""
        );

        return missingTargetTranslations;
    }
}
