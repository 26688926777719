/* © IBS Group. See COPYRIGHT file for full copyright & licensing details. */

import BaseFilter from "./Base";
import { ICustomDate } from "../../Contracts/CustomDate";
import { Filter } from "../../Contracts/Filter";

export default class DateFilter extends BaseFilter implements Filter {
    Value: ICustomDate[] = [];

    Copy(): DateFilter {
        const filter = new DateFilter( this.id, this.name, this.placeholder );

        this.Value.forEach( ( value: ICustomDate ): void => {
            filter.Value.push( value.Copy() );
        });

        return filter;
    }

    FormatValue(): string[] {
        if( this.Value.length === 0 )
            return [];

        return Array.from( this.Value, ( date: ICustomDate ): string => date.ToSourceFormat() );
    }

    Format(): string {
        if( this.Value.length === 0 )
            return "";

        let value = this.Value[ 0 ].Format();

        /* Formatting an interval date filter. */
        if( this.Value.length > 1 )
            value += " -> " + this.Value[ 1 ].Format();

        return value;
    }
}
