/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import { Coupon } from "App/Models";
import { Coupons } from "App/IOC/Services";

import Configuration from "../Configuration";
import Columns from "./Columns";
import Fields from "./Fields";
import Filters from "./Filters";
import { AsyncHandleError, Dictionary } from "@Internal/Contracts";

async function GenerateGeneralConditions( coupon: Coupon ): AsyncHandleError<string> {
    let conditions: string = "COUPON_discount";
    const values: Dictionary = {
        percentage: coupon.Percentage,
        fixedAmount: coupon.FixedAmount,
        duration: coupon.Duration
    };

    if( coupon.Percentage === undefined && coupon.FixedAmount === undefined )
        return [ $t( conditions + "No" ), undefined ];

    /* Get the yearly coupon defined in global configuration. */
    const [ configuration, error ] = await Configuration.Get();

    if(
        error !== undefined ||
        configuration === undefined ||
        configuration.YearlyCoupon === undefined
    )
        return [ undefined, error ];

    /* Since this coupon could be used as a yearly coupon, so in that case we don't
       need to show duration in months. */
    if( coupon.Id === configuration.YearlyCoupon.Id ) {
        conditions += "Yearly";
        delete values.duration;
    }

    conditions += coupon.Percentage !== undefined ? "Percentage" : "FixedAmount";

    return [ $t( conditions, values ), undefined ];
}

export default {
    Columns,
    Delete: Coupons.Delete,
    Create: Coupons.Create,
    Fields,
    Filters,
    Find: Coupons.Find,
    Get: Coupons.Get,
    GenerateGeneralConditions,
    Update: Coupons.Update
};
