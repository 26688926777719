/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import { CustomError } from "@Internal/Models";
import { Decimal as IDecimal } from "@Internal/Contracts";

import { Price, Subscription, SubscriptionLine } from "App/Models";
import { Decimal } from "App/IOC";
import { Subscriptions } from "App/IOC/Services";
import { IsTiered } from "Shared/Models";

function computeSubTotal( price: Price, rawQuantity: IDecimal ): IDecimal {
    /* TODO: Move this to shared. */
    let quantity = rawQuantity;

    if( !IsTiered( price ) )
        return quantity.MultiplyBy( price.Amount || 0 );

    if( price.Type === "Volume" ) {
        for( const tier of price.Tiers ) {
            if(
                quantity.GreaterThanOrEqualTo( tier.MinQuantity ) &&
                quantity.LessThanOrEqualTo( tier.MaxQuantity )
            ) {
                return price.FlatFee
                    ? tier.UnitPrice
                    : quantity.MultiplyBy( tier.UnitPrice );
            }
        }
    }

    if( price.Type === "Graduated" ) {
        let total = new Decimal( 0 );

        for( const tier of price.Tiers ) {
            const minQuantity = tier.MinQuantity;

            if( minQuantity.LessThanOrEqualTo( quantity ) ) {
                let difference = tier.MaxQuantity.Substract( tier.MinQuantity ).Add( 1 );

                if( quantity.LessThanOrEqualTo( difference ) )
                    difference = quantity;

                quantity = quantity.Substract( difference );

                const amount = price.FlatFee
                    ? tier.UnitPrice
                    : difference.MultiplyBy( tier.UnitPrice );

                total = total.Add( amount );

            } else if( !quantity.LessThanOrEqualTo( 0 ) ) {
                const amount = price.FlatFee
                    ? tier.UnitPrice
                    : quantity.MultiplyBy( tier.UnitPrice );

                total = total.Add( amount );
                break;
            }
        }

        return total;
    }

    return new Decimal( 0 );
}

function ComputeTotal( lines: SubscriptionLine[], recurrentOnly: boolean = false ): IDecimal {
    let total = new Decimal( 0 );

    for( const line of lines ) {
        const price = line.Price;

        if( price === undefined )
            continue;

        if( recurrentOnly && price.Recurrence === undefined )
            continue;

        total = total.Add( computeSubTotal( price, line.Quantity ) );
    }

    return total;
}

async function Create(
    subscription: Subscription
): Promise<[ Subscription | undefined, CustomError | undefined ]> {
    if( subscription.Lines.length === 0 )
        return [ undefined, new CustomError( -1, $t( "SUBSCRIPTIONS_linesRequired" ) ) ];

    return Subscriptions.Create( subscription );
}

export default {
    ComputeTotal,
    Create
}
