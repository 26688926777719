/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

export default {
    PLANS_title: "Title",
    PLANS_description: "Description",
    PLANS_pricesList: "Prices list",
    PLANS_addPrice: "Add price",
    PLANS_color: "Color",
    PLANS_create: "Create a plan",
    PLANS_edit: "Update the plan",
    PLANS_fieldName: "Name",
    PLANS_fieldDescription: "Description",
    PLANS_fieldPitchSubtitle: "Pitch subtitle",
    PLANS_fieldColor: "Color",
    PLANS_confirmCreate: "Create the plan",
    PLANS_confirmEdit: "Update the plan",

    PLANS_fieldCurrency: "Currency",
    PLANS_fieldStartingPrice: "Starting Price",
    PLANS_fieldPitch: "Pitch",

    PLANS_fieldCompatiblePlans: "Compatible plans",

    PLANS_compatiblePlans: "Compatible with",

    PLANS_fieldTrial: "Trial",
    PLANS_denySwitchType: "You cannot change the type of this plan, it is mandatory to have \
        three paid plans and one trial plan",

    PLANS_trialPlanExist: "There can only be 1 active trial plan",
    PLANS_trialPlanDoesNotExist:
        "You cannot have 4 paid plans, there needs to be at least 1 trial plan",
    PLAN_archiveConfirm: "Are you sure you want to archive this plan?"
};
