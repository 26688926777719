/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import { Dictionary } from "@Internal/Contracts";
import { Field, CustomValue } from "@Internal/Models";
import { $t } from "App/IOC";
import { PriceTypes } from "Shared/Models";

let defaultFields: Dictionary<Field> = {};

async function Get(): Promise<Dictionary<Field>> {
    await Promise.resolve();

    if( Object.keys( defaultFields ).length === 0 )
        defaultFields = {
            Name: Field.Translatable( "Name", $t( "PRICES_fieldName" ) ),
            Sequence: Field.Text( "Sequence", $t( "GLOBAL_fieldSequence" ) ),
            Amount: Field.Decimal( "Amount", $t( "PRICES_fieldAmount" ) ),
            Currency: Field.Text( "Currency", $t( "PRICES_fieldCurrency" ) ),
            Description: Field.Translatable( "Description", $t( "PRICES_fieldDescription" ) ),
            Type: Field.Text( "Type", $t( "PRICES_fieldType" ), { Required: true } ),
            Recurrence: Field.Selection( "Recurrence", $t( "PRODUCTS_fieldRecurrence" ) ),
            FlatFee: Field.Boolean( "FlatFee", $t( "PRICES_fieldFlatFee" ) ),
            UnitName: Field.Translatable( "UnitName", $t( "PRICES_unitName" ) ),
            PluralUnitName: Field.Translatable( "PluralUnitName", $t( "PRICES_pluralUnitName" ) ),
            MinQuantity: Field.Number( "MinQuantity", $t( "PRICES_fieldMinQuantity" ) ),
            MaxQuantity: Field.Number( "MaxQuantity", $t( "PRICES_fieldMaxQuantity" ) ),
            QuantityStep: Field.Number( "QuantityStep", $t( "PRICES_fieldQuantityStep" ) ),
            Included: Field.Boolean( "Included", $t( "PRICES_fieldIncluded" ) ),
            Tiers: Field.Selection( "Tiers", $t( "PRICES_fieldTiers" ) ),
            Target: Field.Text( "Target", $t( "PRICE_TARGETS_fieldDisplayName" ) )
        };

    return defaultFields;
}

function GetTypes(): CustomValue<PriceTypes>[] {
    return [
        new CustomValue<PriceTypes>( $t( "PRICES_unit" ), "Unit" ),
        new CustomValue<PriceTypes>( $t( "PRICES_package" ), "Package" ),
        new CustomValue<PriceTypes>( $t( "PRICES_volume" ), "Volume" ),
        new CustomValue<PriceTypes>( $t( "PRICES_graduated" ), "Graduated" )
    ];
}

export default {
    Get,
    GetTypes
};
