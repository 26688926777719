


import { Component, Emit, Prop, Vue } from "vue-property-decorator";

import { Configuration, Subscriptions } from "App";
import {
    Field, DiscountDetails, Subscription, Invoice, Coupon, GlobalConfiguration, CustomError
} from "App/Models";

import { EventBus } from "App/IOC";
import { Dictionary } from "App/Contracts";

import Tab from "Web/Components/Tabs/Tab.vue";
import Tabs from "Web/Components/Tabs/Index.vue";

import Form from "Web/Components/Form/Index.vue";
import FormField from "Web/Components/Form/Field.vue";
import FormFieldset from "Web/Components/Form/Fieldset.vue";

@Component({
    components: {
        Field: FormField,
        Fieldset: FormFieldset,
        Form,
        Tab,
        Tabs
    }
})
export default class SubscriptionDetails extends Vue {
    @Prop()
    Record?: Subscription;

    fields: Dictionary<Field> = {};
    configurationFields: Dictionary<Field> = {};
    Invoices: Invoice[] = [];
    appliedCoupon?: Coupon = {} as Coupon;
    configuration: GlobalConfiguration | undefined = {} as GlobalConfiguration;
    loading: boolean = true;

    created() {
        this.appliedCoupon = undefined;
    }

    async mounted(): Promise<void> {
        this.fields = await Subscriptions.Fields.Get();
        this.configurationFields = await Configuration.Fields.Get();

        if( this.Record !== undefined ) {
            this.Invoices = this.Record.Invoices !== undefined ? this.Record.Invoices : [];

            if( this.Record.Coupons !== undefined )
                this.appliedCoupon = this.Record.Coupons[ 0 ];
        }

        const [ configuration, error ] = await Configuration.Get();

        if( error !== undefined || configuration === undefined ) {
            EventBus.Publish( "Error", error );
            return;
        }

        this.configuration = configuration;

        this.loading = false;
    }

    private redirectToCoupon() {
        if( this.appliedCoupon === undefined )
            return;

        this.$router.push( `/coupons/${this.appliedCoupon.Id}` )
    }

    private get discount(): DiscountDetails | undefined {
        if( this.Record === undefined )
            return undefined;

        return this.Record.DiscountDetails;
    }

    private get discountExpired(): boolean | undefined {
        if( this.discount === undefined || this.discount.Expired === undefined )
            return undefined;

        return this.discount.Expired;
    }

    private get discountMessage(): string | undefined {
        if( this.Record === undefined )
            return undefined;

        if( this.discountExpired )
            return ( this.Record.Recurrence === "Yearly" )
                ? "SUBSCRIPTIONS_yearlyDiscountExpired"
                : "SUBSCRIPTIONS_discountExpired";

        else
            return ( this.Record.Recurrence === "Yearly" )
                ? "SUBSCRIPTIONS_yearlyDiscountActivated"
                : "SUBSCRIPTIONS_discountActivated";
    }

    private async DownloadInvoice( invoice: Invoice ) {
        if( invoice.DownloadURL === undefined ) {
            EventBus.Publish( "Error", CustomError.User( $t( "SUBSCRIPTION_invoiceUndefined" ) ) );
            return;
        }

        window.location.href = invoice.DownloadURL;
    }

    private getInvoiceStatus( status: string ): string {
        switch( true ) {
            case status === "Paid":
                return $t( "SUBSCRIPTION_paid" );

            case status === "Open":
                return $t( "SUBSCRIPTION_open" );

            case status === "Uncollectible":
                return $t( "SUBSCRIPTION_uncollectible" );

            default:
                return $t( "SUBSCRIPTION_void" );
        }
    }

    @Emit( "Refresh" )
    private refresh() {}
}
