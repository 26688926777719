


import { Component, Vue } from "vue-property-decorator";

import { CustomError, CustomNotification } from "@Internal/Models";

import { PriceTargets } from "App";
import { PriceTarget, Field } from "App/Models";
import { $t, EventBus } from "App/IOC";
import { TrimText } from "@Internal/Utils";

import Form from "Web/Components/Form/Index.vue";
import FormField from "Web/Components/Form/Field.vue";
import FormFieldGroup from "Web/Components/Form/FieldGroup.vue";
import FormFieldset from "Web/Components/Form/Fieldset.vue";

import PopupWindow from "Web/Components/Popup/Window.vue";
import BooleanField from "Web/Components/Common/Input/Boolean.vue";

import {
    Activated,
    Dictionary,
    Identifiable,
    Named
} from "App/Contracts";

@Component({
    components: {
        BooleanField,
        Field: FormField,
        FieldGroup: FormFieldGroup,
        Fieldset: FormFieldset,
        Form,
        PopupWindow
    }
})
export default class PriceTargetForm extends Vue {
    $refs!: Vue[ "$refs" ] & {
        Form: Form,
    };

    private record: PriceTarget = {
        Id: -1
    };

    private action: Identifiable & Named & Activated = {
        Id: "create",
        Name: $t( "PRICE_TARGETS_confirmCreate" ),
        Active: true
    };

    private fields: Dictionary<Field> = {};

    private async created(): Promise<void> {
        this.fields = await PriceTargets.Fields.Get();
    }

    private async create(): Promise<void> {
        this.action.Active = false;

        const record = this.$refs.Form.GetRecord() as PriceTarget;

        const [ createdRecord, error ] = await PriceTargets.Create( record );

        if( error !== undefined ) {
            if( error.Message === "GLOBAL_duplicatedKey" )
                EventBus.Publish(
                    "Error",
                    new CustomError( "", $t( "PRICE_TARGET_duplicatedName" ) )
                );

            else
                EventBus.Publish( "Error", error );

            this.action.Active = true;
            this.canCreate();

            return;
        }

        if( createdRecord === undefined )
            return;

        EventBus.Publish(
            "Notification",
            CustomNotification.Success(
                $t( "PRICE_TARGETS_createSuccessTitle" ),
                $t( "PRICE_TARGETS_createSuccessMessage" )
            )
        );

        this.$emit( "Create", createdRecord );
        this.$emit( "Close" );
    }

    private canCreate(): void {
        const record = this.$refs.Form.GetRecord() as PriceTarget;

        const supportedTypes = [ "translatable", "text" ];

        /* Make all fields of type text or translatable required. */
        const missingField = Object.keys( this.fields ).some( ( key: string ): boolean =>
            ( supportedTypes.includes( this.fields[ key ].Type ) ) &&
            (
                ( record as any )[ key ] === undefined ||
                TrimText( ( record as any )[ key ] ) === ""
            )
        );

        this.action.Active = !missingField;
    }
}
