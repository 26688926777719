


import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Accordion extends Vue {
    $refs!: Vue[ "$refs" ] & {
        Content: HTMLElement,
    };

    @Prop() Title!: string;

    @Prop( { default: true } )
    DefaultExpand!: boolean;


    @Prop() Tooltip!: string;

    private expand: boolean = true;

    private transitioning: boolean = false;

    private created() {
        this.expand = this.DefaultExpand;
    }

    private toggle() {
        this.expand = !this.expand;
    }

    private reflow( element: HTMLElement ) {
        return element.offsetHeight;
    }

    private enter( element: HTMLElement ) {
        element.style.height = "0";
        this.reflow( element );
        element.style.height = element.scrollHeight + "px";
        this.transitioning = true;
    }

    private afterEnter( element: HTMLElement ) {
        element.style.height = null;
        this.transitioning = false;
    }

    private leave( element: HTMLElement ) {
        element.style.height = "auto";
        element.style.display = "block";
        element.style.height = element.getBoundingClientRect().height + "px";
        this.reflow( element );
        this.transitioning = true;
        element.style.height = "0";
    }

    private afterLeave( element: HTMLElement ) {
        element.style.height = null;
        this.transitioning = false;
    }
}
