/* © IBS Group. See LICENSE file for full copyright & licensing details. */

export default class RecordsNavigation {
    private urls: string[] = [];
    private currentIndex: number;

    constructor() {
        this.urls = [];
        this.currentIndex = 0;
    }

    get CurrentIndex(): number {
        return this.currentIndex;
    }

    get Total(): number {
        return this.urls.length;
    }

    Next(): [ number, string ] {
        this.currentIndex++;
        return [ this.currentIndex, this.urls[ this.currentIndex ] ];
    }

    Previous(): [ number, string ] {
        this.currentIndex--;
        return [ this.currentIndex, this.urls[ this.currentIndex ] ];
    }

    SetList( urls: string[], index: number ): void {
        this.urls = urls;
        this.currentIndex = index;
    }

    /* Ensure list is clear after navigation. we don't want to persist
        data after leaving the single view page */
    Clear(): void {
        this.urls = [];
    }
}
