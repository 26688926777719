/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

export default {
    SUBSCRIPTIONS_startingFrom: "starting from {0}€/months",
    SUBSCRIPTIONS_startingFromFree: "Free",
    SUBSCRIPTIONS_recurrenceTitleMonthly: "Monthly cost",
    SUBSCRIPTIONS_recurrenceTitleYearly: "Yearly cost",
    SUBSCRIPTIONS_recurrenceMonthly: "Monthly",
    SUBSCRIPTIONS_recurrenceYearly: "Yearly",
    SUBSCRIPTIONS_recurrenceUnitMonthly: "month",
    SUBSCRIPTIONS_recurrenceUnitYearly: "year",

    SUBSCRIPTIONS_save: "Save {0}%",
    SUBSCRIPTIONS_recurrenceInvoicingMonthly: "Invoiced monthly",
    SUBSCRIPTIONS_recurrenceInvoicingYearly: "Invoiced yearly",
    SUBSCRIPTIONS_cartNote: "This is a message to alert you to timely information ...",
    SUBSCRIPTIONS_validateOffer: "Validate your offer",

    SUBSCRIPTIONS_salesNotice: "Are you unable to compose your offer or is it suited to your " +
        "needs?",

    SUBSCRIPTIONS_contactSales: "Talk to a salesperson",
    SUBSCRIPTIONS_showAllFeatures: "See all features",

    SUBSCRIPTIONS_title: "Subscriptions",
    SUBSCRIPTIONS_label: "subscription",
    SUBSCRIPTIONS_create: "Create a subscription",
    SUBSCRIPTIONS_plural_label: "subscriptions",
    SUBSCRIPTIONS_splashScreenTitle: "You have no subscriptions for the moment being",
    SUBSCRIPTIONS_splashScreenDescription:
        "Consider creating products and visiting the pricing page",

    SUBSCRIPTIONS_columnId: "Id",
    SUBSCRIPTIONS_columnCustomerEmail: "Account email",
    SUBSCRIPTIONS_columnState: "State",
    SUBSCRIPTIONS_columnLines: "Subscription plans",
    SUBSCRIPTIONS_columnCreatedAt: "Created at",

    SUBSCRIPTION_Details: "Subscription details",
    SUBSCRIPTION_PRODUCTS_PLANS: "Products and prices",
    SUBSCRIPTION_BILLING: "Billing",
    SUBSCRIPTION_QUOTA: "Quota",
    SUBSCRIPTIONS_fieldNote: "Note",
    SUBSCRIPTIONS_fieldCoupon: "Coupon",
    SUBSCRIPTION_functionalities: "Features included in this subscription.",
    SUBSCRIPTIONS_unlimitedQuantity: "Unlimited",

    SUBSCRIPTIONS_statusOffer: "Offer",
    SUBSCRIPTIONS_statusActive: "Active",
    SUBSCRIPTIONS_statusIncomplete: "Incomplete",
    SUBSCRIPTIONS_statusIncompleted_Expired: "Expired",
    SUBSCRIPTIONS_statusPastDue: "Past Due",
    SUBSCRIPTIONS_statusTrial: "Trial",
    SUBSCRIPTIONS_statusCanceled: "Canceled",
    SUBSCRIPTIONS_statusUnpaid: "Unpaid",

    SUBSCRIPTION_actionUnsubscribe: "Cancel at the end of the period",
    SUBSCRIPTION_actionCancel: "Cancel subscription immediately",
    SUBSCRIPTION_cancelWarning: "Attention !",
    SUBSCRIPTION_confirmCancellation: "Yes I want to cancel",
    SUBSCRIPTION_cancellationMessage: "You will cancel this customer's subscription.",
    SUBSCRIPTION_cancellationDeadlineMessage:
        "After cancellation, the last billing will stop on the date of {0}",
    SUBSCRIPTION_confirmCancellationMessage: "You are sure ?",

    SUBSCRIPTIONS_current: "Current subscription",

    SUBSCRIPTIONS_incompatiblePlan: "This plan is not compatible with your other selected plans.",

    SUBSCRIPTIONS_recommended: "Recommended",

    SUBSCRIPTION_download: "Download",
    SUBSCRIPTION_paid: "PAID",
    SUBSCRIPTION_open: "WAITING",
    SUBSCRIPTION_void: "CANCELED",
    SUBSCRIPTION_uncollectible: "UNCOLLECTIBLE",

    SUBSCRIPTION_nextPayment: "Next payment on",
    SUBSCRIPTION_cannotEndTrialForSubscription: "This customer does not have a trial subscription",
    SUBSCRIPTION_actionStripeRedirection: "Redirect to the subscription page",
    SUBSCRIPTION_unsubscribeSuccessTitle: "Notification",
    SUBSCRIPTION_unsubscribeSuccessMessage: "Your subscription has been scheduled for cancellation",
    SUBSCRIPTIONS_unsubscribeConfirm: "Are you sure you want to cancel this subscription? | \
        Are you sure you want to cancel these subscriptions?",
    SUBSCRIPTIONS_recurrentPriceLine: "{subtotal} {currency} / {recurrence}",
    SUBSCRIPTIONS_globalPriceLine: "{subtotal} {currency}",
    SUBSCRIPTIONS_subscribedPlansSubtitle: "{productName} - {planName}",
    SUBSCRIPTIONS_discountActivated:
        `This subscription benefits from a reduction of {discountValue} of {discountDuration}
        starting the {discountDate}`,
    SUBSCRIPTIONS_yearlyDiscountActivated:
        "This subscription benefits from a reduction of {discountValue}",
    SUBSCRIPTIONS_yearlyDiscountExpired:
        "This subscription has benefited from a reduction of {discountValue}",
    SUBSCRIPTIONS_discountExpired:
        `This subscription has benefited from a reduction of {discountValue} of {discountDuration}
        starting the {discountDate}`
};
