/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import { CustomError, Locale } from "Shared/Models";
import { DataStore } from "App/IOC";

async function GetCached(): Promise<[ string | undefined, CustomError | undefined ]> {
    return await DataStore.Get( "Locale" );
}

async function Cache( locale: Locale ): Promise<CustomError | undefined> {
    /* Set the local as current and store it locally. */
    return await DataStore.Set( "Locale", locale.Id );
}

export default {
    GetCached,
    Cache
};
