/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import { ICustomDate } from "@Internal/Contracts";
import BaseModel from "../Model";

import BaseCustomer from "../Customer";
import BasePlan from "../Plan";
import BasePrice from "../Price";
import BasePriceTarget from "../PriceTarget";
import BaseProduct from "../Product";
import BaseTier from "../Tier";

export default
interface BaseSubscriptionCoupon<
    P = BasePlan<BaseProduct>,
    T = BasePrice<BasePriceTarget, BaseProduct, BaseTier, BasePlan>,
    K = BaseCustomer
> extends BaseModel {
    Name: string;
    Active?: boolean;
    GeneralConditions?: string;
    FixedAmount?: number;
    Percentage?: number;
    /* Duration in months.  */
    Duration?: number;
    From?: ICustomDate;
    To?: ICustomDate;
    MaxUses?: number;
    Uses?: number;
    Code?: string;
    FirstSubscriptionOnly?: boolean;
    Plans: P[];
    GlobalPrices: T[];
    Customers: K[];
}
