


import { Component, Prop, Vue } from "vue-property-decorator";

import { EventBus } from "App/IOC";

@Component
export default class Modal extends Vue {
    @Prop( { default: false } )
    Overlay!: boolean;

    /* Check `authorizationInvalidated()` */
    closed: boolean = false;

    mounted() {
        EventBus.Subscribe( "InvalidAuthorization", this.authorizationInvalidated, this );
    }

    /* It's possible that our backend authorization expires while a modal is still open. */
    private authorizationInvalidated() {
        this.closed = true;
    }
}
