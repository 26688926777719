


import { Component, Prop, Vue } from "vue-property-decorator";

import { IsTiered } from "Shared/Models";

import { IDecimal } from "App/Contracts";
import { EventBus } from "App/IOC";
import { GlobalConfiguration, Price } from "App/Models";
import { Prices } from "App";

import { InjectReactive } from "Web/Libs/VuePropertyDecorators";
import PriceForm from "Web/Views/Products/Single/Prices/Form.vue";
import NumberField from "Web/Components/Common/Input/FormNumber.vue";

@Component({
    components: {
        NumberField,
        PriceForm
    }
})
export default class PlanPrice extends Vue {
    @Prop() readonly Record?: Price;

    @Prop( { default: false } )
    readonly Global!: boolean;

    @InjectReactive( { from: "configuration", default: undefined } )
    private configuration?: GlobalConfiguration;

    private showForm: boolean = false;

    private get typeLabel(): string {
        if( this.Record === undefined )
            return "";

        let label: string = this.$t( "PRICES_fieldType" + this.Record.Type ).toString();

        if( !IsTiered( this.Record ) )
            return label;


        label += " / " + this.$t( this.Record.FlatFee
            ? "PRICES_fieldTypePackage"
            : "PRICES_fieldTypeUnit"
        ).toString();

        return label;
    }


    private async remove(): Promise<void> {
        EventBus.Publish( "Confirmation", {
            Props: {
                Message: this.$tc( "PRICE_archiveConfirm", 1 ),
                Actions: [{
                    Id: "archive",
                    Name: this.$t( "GLOBAL_actionArchive" ),
                    Active: true
                }]
            },

            Events: { Action: this.delete }
        });
    }

    private async delete(): Promise<void> {
        if( this.Record === undefined )
            return;

        const error = await Prices.Delete( this.Record );

        if( error !== undefined ) {
            EventBus.Publish( "Error", error );
            return;
        }

        this.$emit( "Refresh" );
    }

    private async edit( record: Price ) {
        const [ , error ] = await Prices.Update( this.Record as Price, record );

        if( error !== undefined )
            EventBus.Publish( "Error", error );

        this.$emit( "Refresh" );
    }

    private isTiered( price: Price ): boolean {
        return IsTiered( price );
    }

    private get isUnlimited(): boolean {
        if( this.Record === undefined || this.configuration === undefined )
            return false;

        return this.isTiered( this.Record ) &&
            this.Record.Tiers.length === 1 &&
            this.Record.Tiers[ 0 ].MaxQuantity.IsEqual(
                this.configuration.UnlimitedQuantity as IDecimal
            );
    }
}
