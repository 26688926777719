


import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import { Identifiable } from "App/Contracts";
import { PagerSorter } from "App/Models";

import SimpleSelect from "Web/Components/V2/Select/Simple.vue";

@Component({
    components: {
        SimpleSelect
    }
})
export default class TablePagination extends Vue {
    @Prop( { default: () => new PagerSorter() } )
    Pager!: PagerSorter;

    @Prop( { default: [ 10 ] } )
    Limits!: number[];

    private maxPages: number = 5;
    private remainingPages: number = 0;

    get currentLimit(): Identifiable {
        return { Id: this.Pager.Limit };
    }

    get limitOptions(): Identifiable[] {
        const limitOptions: Identifiable[] = [];

        this.Limits.forEach( ( limit: number ) => {
            limitOptions.push( { Id: limit } );
        });

        return limitOptions;
    }

    private loadPage( page: number ) {
        const pager = this.Pager.Copy();

        pager.Page = page;

        this.$emit( "Page", pager );
    }

    private updateLimit( limit: Identifiable ) {
        const pager = this.Pager.Copy();

        pager.Page = 1;
        pager.Limit = Number( limit.Id );

        this.$emit( "Limit", pager );
    }

    /* Start from current page and append adjacent page numbers to the available space on the sides
       until maxPages is reached. */
    private get shownPages(): number[] {
        const pages: number[] = [ this.Pager.Page ];

        /* A flag to alternate between appending to the left and the right of the current page. */
        let leftSide: boolean = true;

        while( this.remainingPages > 0 && pages.length < this.Pager.Pages ) {
            /* Attempt to add a page number on the left. */
            if( leftSide && pages[ 0 ] - 1 > 0 ) {
                pages.unshift( pages[ 0 ] - 1 );
                this.remainingPages--;

            /* Attempt to add a page number on the right. */
            } else if( !leftSide && pages[ pages.length - 1 ] + 1 <= this.Pager.Pages ) {
                pages.push( pages[ pages.length - 1 ] + 1 );
                this.remainingPages--;
            }

            /* Switch the position of the next page addition. */
            leftSide = !leftSide;
        }

        return pages;
    }

    @Watch( "Pager", { immediate: true } )
    private onPagerChange() {
        this.remainingPages = this.maxPages - 1;
    }

    private get showRightEllipsis(): boolean {
        return ( this.Pager.Page + ( ( this.maxPages - 1 ) / 2 ) ) < this.Pager.Pages;
    }

    private get showLeftEllipsis(): boolean {
        return ( this.Pager.Page - ( ( this.maxPages - 1 ) / 2 ) ) > 1;
    }
}
