


import { Component, Vue, Watch } from "vue-property-decorator";

import { PriceTargets } from "App";

import { Coupon, CustomError, CustomNotification, Field, PriceTarget } from "App/Models";

import { Dictionary } from "App/Contracts";

import { EventBus, $t } from "App/IOC";

import { RecordActions, RecordAction } from "Web/Contracts";

import PriceTargetForm from "./Form.vue";

import Record from "Web/Components/Record.vue";
import Accordion from "Web/Components/Accordion.vue";

@Component({
    components: {
        Accordion,
        PriceTargetForm,
        Record
    }
})
export default class SinglePriceTargetView extends Vue {
    private record: PriceTarget = {
        Id: -1
    };

    private fields: Dictionary<Field> = {};

    private editing: boolean = false;
    private loading: boolean = true;

    private actions: RecordActions<Coupon> = [
        [
            {
                Id: "Delete",
                Title: $t( "GLOBAL_actionDelete" ),
                Icon: "delete_forever"
            }
        ]
    ];

    private async created(): Promise<void> {
        await this.setup();
    }

    private async setup(): Promise<void> {
        this.fields = await PriceTargets.Fields.Get();

        /* TODO: Handle invalid ID format. */
        await this.getPriceTarget( true );

        if( this.record.Name === undefined )
            return;

        document.title = this.record.Name;
    }

    /* Reset data on Product change*/
    private resetData(): void {
        this.fields = {};
        this.record = {
            Id: -1,
            Name: ""
        };
    }

    /* Refresh data if navigated to a different record diractly from the current page. */
    @Watch( "$route" )
    private onRouteParametersChange( from: any, to: any ): void {
        if( to.name !== "price-targets" || from.params.id === to.params.id )
            return;

        this.resetData();
        this.setup();
    }

    private async save( record: PriceTarget ): Promise<void> {
        if( this.record === undefined || record === undefined )
            return;

        const [ updatedRecord, error ] = await PriceTargets.Update( this.record, record );

        if( error !== undefined ) {
            if( error.Message === "GLOBAL_duplicatedKey" )
                EventBus.Publish(
                    "Error",
                    new CustomError( "", $t( "PRICE_TARGET_duplicatedName" ) )
                );

            else
                EventBus.Publish( "Error", error );

            return;
        }

        this.record = updatedRecord as PriceTarget;

        this.showUpdateNotification();
    }

    private async getPriceTarget( silent: boolean = false ): Promise<void> {
        const [ record, error ] = await PriceTargets.Get( Number( this.$route.params.id ) );

        if( error !== undefined || record === undefined ) {
            EventBus.Publish( "Error", error );
            return;
        }

        this.record = record;

        if( !silent )
            this.showUpdateNotification();
    }

    private showUpdateNotification(): void {
        EventBus.Publish( "Notification", CustomNotification.Success(
            this.$t( "GLOBAL_PRICE_TARGETS" ).toString(),
            this.$t( "PRICE_TARGETS_updated" ).toString()
        ));
    }

    private underConstruction(): void {
        EventBus.Publish( "UnderConstruction" );
    }

    private update( coupon: Coupon, silent: boolean = false ): void {
        this.record = coupon;

        if( !silent )
            this.showUpdateNotification();
    }

    private execute( action: RecordAction ): void {
        switch( action.Id ) {
            case "Delete":
                this.delete( this.record as PriceTarget );
                break;

            default:
                EventBus.Publish( "UnderConstruction" );
                break;
        }
    }

    private delete( record: PriceTarget ): void {
        EventBus.Publish( "Confirmation", {
            Props: {
                Message: this.$tc( "PRICE_TARGETS_deleteConfirm", 1 ),
                Actions: [{
                    Id: "delete",
                    Name: this.$t( "GLOBAL_actionDelete" ),
                    Active: true
                }]
            },

            Events: {
                Action: async () => {
                    const error = await PriceTargets.Delete( record );

                    if( error !== undefined ) {
                        EventBus.Publish( "Error", error );
                        return;
                    }

                    this.$router.replace( "/price-targets" );
                }
            }
        })
    }

    @Watch( "record" )
    private onRecordChange( record?: Coupon ): void {
        if( record === undefined )
            return;

        setTimeout( () => this.loading = record.Name === undefined, 200 );
    }
}
