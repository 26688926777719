


import { Component, Vue } from "vue-property-decorator";

import {
    BooleanFilter, CustomError, CustomNotification, PagerSorter, TextFilter
} from "@Internal/Models";
import { HandleListError } from "@Internal/Contracts";

import { Coupons, Customers, Plans, Prices, Products } from "App";
import { Coupon, Customer, Field, Plan, Price } from "App/Models";
import { $t, EventBus } from "App/IOC";

import Form from "Web/Components/Form/Index.vue";
import FormField from "Web/Components/Form/Field.vue";
import FormFieldGroup from "Web/Components/Form/FieldGroup.vue";

import PopupWindow from "Web/Components/Popup/Window.vue";
import BooleanField from "Web/Components/Common/Input/Boolean.vue";

import {
    Activated,
    Dictionary,
    Identifiable,
    Named
} from "App/Contracts";

@Component({
    components: {
        BooleanField,
        Field: FormField,
        FieldGroup: FormFieldGroup,
        Form,
        PopupWindow
    }
})
export default class CouponForm extends Vue {
    $refs!: Vue[ "$refs" ] & {
        Form: Form,
    };

    private record: Coupon = {
        Id: -1,
        Name: "",
        Plans: [],
        GlobalPrices: [],
        Customers: [],
        Duration: 1
    };

    private isPercentage: boolean = true;
    private associatePlans: boolean = false;
    private associateGlobalPrices: boolean = false;
    private showConfirmation: boolean = false;
    private setExpirationPeriod: boolean = false;
    private setMaxUses: boolean = false;

    private createAction: Identifiable & Named & Activated = {
        Id: "create",
        Name: $t( "GLOBAL_confirmAction" ),
        Active: true
    };

    private confirmAction: Identifiable & Named & Activated = {
        Id: "confirm",
        Name: $t( "COUPONS_confirmCreate" ),
        Active: true
    }

    private fields: Dictionary<Field> = {};

    private async created(): Promise<void> {
        this.fields = await Coupons.Fields.Get();
    }

    private async create(): Promise<void> {
        this.createAction.Active = false;

        const record = this.$refs.Form.GetRecord() as Coupon;

        const [ createdRecord, error ] = await Coupons.Create( record );

        if( error !== undefined ) {
            EventBus.Publish( "Error", error );
            this.createAction.Active = true;
            this.canCreate();

            return;
        }

        EventBus.Publish(
            "Notification",
            CustomNotification.Success(
                $t( "COUPONS_createSuccessTitle" ),
                $t( "COUPONS_createSuccessMessage" )
            )
        );

        this.$emit( "Create", createdRecord );
        this.$emit( "Close" );
    }

    private canCreate(): void {
        const record = this.$refs.Form.GetRecord() as Coupon;

        this.confirmAction.Active = record.Name !== undefined &&
            record.Code !== undefined &&
            ( record.Percentage !== undefined || record.FixedAmount !== undefined );
    }

    private async getCustomers( keyword?: string ): HandleListError<Customer> {
        const [ customers, error ] =
            await Customers.FindByNameOrEmail( keyword === undefined ? "" : keyword );

        if( error !== undefined ) {
            EventBus.Publish( "Error", error );
            return [ [], error ];
        }

        /* Prefix customers with their respective email. */
        Customers.PrefixCustomerWithEmail( customers );

        return [ customers, undefined ];
    }

    private async getPlans( keyword?: string ): Promise<[ Plan[], CustomError | undefined ]> {
        const filter = new TextFilter( "Name", "" );
        filter.Value = [ keyword || "" ];

        const [ plans, , error ] = await Plans.Find( [ filter ], new PagerSorter( 100 ) );

        /* Format plan names with their respective product as prefix. */
        Products.PrefixWithProductName( plans );

        return [ plans, error ];
    }

    private async getGlobalPrices(): HandleListError<Price> {
        const filter = new BooleanFilter( "Global", "" );
        filter.Value = [ true ];

        const [ globalPrices, , error ] = await Prices.Find( [ filter ], new PagerSorter( 100 ) );

        /* Prefix global prices with their respective product name. */
        Products.PrefixWithProductName( globalPrices );

        return [ globalPrices, error ];
    }

    private toggleAssociatedPlans(
        value: boolean,
        update: ( field: Field, value: any ) => void
    ): void {
        if( !this.associatePlans && this.associateGlobalPrices ) {
            this.associateGlobalPrices = false;

            /* Remove selected prices when toggle to associate plans. */
            update( this.fields.GlobalPrices, [] );
        }

        this.associatePlans = value;

        /* Remove all associated plans. */
        if( !value )
            update( this.fields.Plans, [] );
    }

    private toggleAssociatedGlobalPrices(
        value: boolean,
        update: ( field: Field, value: any ) => void
    ): void {
        if( !this.associateGlobalPrices && this.associatePlans ) {
            this.associatePlans = false;

            /* Remove selected plans when toggle to associate global prices. */
            update( this.fields.Plans, [] );
        }

        this.associateGlobalPrices = value;

        /* Remove all associated global prices. */
        if( !value )
            update( this.fields.GlobalPrices, [] );
    }
}
