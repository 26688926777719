/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import { Plan, Price, Product } from "App/Models";
import { PricesService } from "App/Contracts";
import { CustomError } from "@Internal/Models";
import { Autobind } from "@Internal/Utils";
import { DeepCopy } from "App/IOC";

import BaseService from "./Base";

export default class Prices extends BaseService<Price> implements PricesService {

    @Autobind
    async CreateForPlan(
        record: Price,
        product: Product,
        plan: Plan
    ): Promise<[ Price | undefined, CustomError | undefined ]> {
        record = DeepCopy( record );
        record.Product = product;
        record.Plan = plan;

        const newRecord = await this.service.create( this.serialize( record, true ) );

        return this.deserialize( newRecord );
    }

    @Autobind
    async CreateForProduct(
        record: Price,
        product: Product
    ): Promise<[ Price | undefined, CustomError | undefined ]> {
        record = DeepCopy( record );
        record.Product = product;

        const newRecord = await this.service.create( this.serialize( record, true ) );

        return this.deserialize( newRecord );
    }
}
