


import { Component, Ref, Vue, Watch } from "vue-property-decorator";

import { Filter } from "@Internal/Contracts";
import { CustomValue } from "@Internal/Models";
import { Subscriptions, Records, Filters, Customers } from "App";
import { EventBus } from "App/IOC";

import RecordsComponent from "Web/Components/Records.vue";

import {
    Column,
    PagerSorter,
    Subscription
} from "App/Models";

@Component( {
    components: {
        Records: RecordsComponent
    }
})
export default class SubscriptionsView extends Vue {
    @Ref( "records" )
    private readonly $records!: RecordsComponent;

    private columns: Column[] = [];
    private records: Subscription[] = [];
    private pagerSorter: PagerSorter = PagerSorter.ForColumn(
        Subscriptions.Columns.GetDefaultSort(),
        undefined,
        true
    );

    private filters = Subscriptions.Filters.Get;
    private id?: string = {} as string;

    private async created(): Promise<void> {
        this.id = undefined;

        const [ columns, error ] = await Subscriptions.Columns.Get();

        if( error !== undefined ) {
            EventBus.Publish( "Error", error );
            return;
        }

        this.columns = columns;

        /* We need to set the id manually in order to assign the desired filters values before
           render the records component so multifilter component don't get a chance to apply default
           filters. */
        this.setId();
    }

    private async update(
        filters: Filter[],
        pagerSorter: PagerSorter,
        resetPagination?: boolean
    ): Promise<void> {
        await this.$nextTick();

        this.$records.Loading();

        if( resetPagination )
            this.pagerSorter.Page = 1;

        const [ records, updatedPagerSorter, error ] = await Subscriptions.Find(
            filters,
            pagerSorter
        );

        this.records = records;
        this.pagerSorter = updatedPagerSorter;

        if( error !== undefined ) {
            EventBus.Publish( "Error", error );
            return;
        }
    }

    private toggleColumn( column: Column ) {
        Subscriptions.Columns.Toggle( column );
    }

    private executeAction( id: string, rows: Subscription[] ): void {
        const urls: string[] = [];
        let index: number = 0;

        switch( id ) {
            case "open":
                this.records.forEach( record =>
                    urls.push( "/subscriptions/" + record.Id )
                );

                index = this.records.findIndex( record => record.Id === rows[ 0 ].Id );

                Records.Navigation.SetList( urls, index );

                this.$router.push( `/subscriptions/${ rows[ 0 ].Id }` );

                break;

            default:
                EventBus.Publish( "UnderConstruction" );
                break;
        }
    }

    private reset(): void {
        this.update( this.$records.AppliedFilters, this.pagerSorter, false );
    }

    private setActiveFilter(): void {
        const activeFilter = new CustomValue<string>(
            $t( "SUBSCRIPTIONS_statusActive" ),
            "Active"
        );

        Filters.Assign( "Subscriptions", this.filters(), "Status", [ activeFilter ] );

        this.id = "Subscriptions";
    }

    private async setCustomerSubscriptionsFilters( id: string ) {
        const [ customer, error ] = await Customers.Get( Number( id ) );

        if( error !== undefined || customer === undefined ) {
            EventBus.Publish( "Error", error );
            return;
        }

        await Filters.Assign( "CustomerSubscriptions", this.filters(), "Customer", [ customer ] );

        this.id = "CustomerSubscriptions";
    }

    @Watch( "$route" )
    private async setId(): Promise<void> {
        if( this.$route.query.customer !== undefined ) {
            await this.setCustomerSubscriptionsFilters( String( this.$route.query.customer ) );

            return;
        }

        this.setActiveFilter();
    }
}
