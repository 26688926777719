


import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class TextField extends Vue {
    @Prop() public Id!: string;
    @Prop() public Placeholder!: string;
    @Prop() public Value!: string;

    @Prop( { default: false } )
    Readonly!: boolean;

    @Prop( { default: false } )
    MultiLine!: boolean;

    @Prop( { default: Infinity } )
    Size!: number;

    @Prop() MinSize?: number;

    /* An optional regular expression to restrict allowed values to a specific format. */
    @Prop() Format?: string;

    private value: string = "";

    @Watch( "Value" )
    private onValueChange( value: string ) {
        this.value = value;
    }

    @Watch( "value" )
    private onInternalValueChange( value: string, previousValue: string ) {
        /* No need to emit the value if it didn't change. */
        const validator = new RegExp( this.Format! );

        if( this.Value === value )
            return;

        if( value !== "" && this.Format !== undefined && validator.exec( value ) === null ) {
            this.value = previousValue;
            return;
        }

        if( this.MinSize !== undefined && this.MinSize > 0 ) {
            /* If a minimum size defined on this field, not emit the value until the length of
               the input will be greater than or equal to the MinSize prop. */
            this.$emit( "Value", value.length < this.MinSize ? undefined : value );

        } else {
            /* If the input field is empty send an undefined value, just like we the value we
               receive from the relevant prop (Value). */
            this.$emit( "Value", value === "" ? undefined : value );
        }
    }
}
