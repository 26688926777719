


import { Component, Vue } from "vue-property-decorator";

import { Coupons, Records } from "App";
import { Coupon, CustomError, PagerSorter, Column } from "App/Models";
import { Filter } from "App/Contracts";
import { EventBus, $t } from "App/IOC";

import { TableAction } from "Web/Contracts";

import RecordsComponent from "Web/Components/Records.vue";
import CouponForm from "./Form.vue";

@Component({
    components: {
        CouponForm,
        Records: RecordsComponent
    }
})
export default class CouponsView extends Vue {
    $refs!: Vue[ "$refs" ] & {
        Records: RecordsComponent,
    };

    private columns: Column[] = [];
    private records: Coupon[] = [];

    private filters = Coupons.Filters.Get;

    private pagerSorter: PagerSorter = PagerSorter.ForColumn(
        Coupons.Columns.GetDefaultSort(), 15, true
    );

    private actions: TableAction<Coupon>[] = [
        {
            Id: "delete",
            ShortName: "delete",
            Name: $t( "GLOBAL_actionDelete" ),
            Multi: false
        }
    ];

    private showForm: boolean = false;

    private async created() {
        const [ columns, error ] = await Coupons.Columns.Get();

        if( error !== undefined ) {
            EventBus.Publish( "Error", error );
            return;
        }

        this.columns = columns;
    }

    private async update(
        filters: Filter[],
        pagerSorter: PagerSorter,
        resetPagination?: boolean
    ) {
        await this.$nextTick();
        this.$refs.Records.Loading();

        if( resetPagination )
            this.pagerSorter.Page = 1;

        const [ records, updatedPagerSorter, error ] = await Coupons.Find( filters, pagerSorter );

        this.records = records;
        this.pagerSorter = updatedPagerSorter;

        if( error !== undefined ) {
            EventBus.Publish( "Error", error );
            return;
        }
    }

    private toggleColumn( column: Column ) {
        Coupons.Columns.Toggle( column );
    }

    private executeAction( id: string, coupons: Coupon[] ) {
        const urls: string[] = [];
        let index: number = 0;

        switch( id ) {
            case "open":
                this.records.forEach( record =>
                    urls.push( "/coupons/" + record.Id )
                );

                index = this.records.findIndex( record => record.Id === coupons[ 0 ].Id );

                Records.Navigation.SetList( urls, index );

                this.$router.push( `/coupons/${coupons[ 0 ].Id}` );

                break;

            case "delete":
                EventBus.Publish( "Confirmation", {
                    Props: {
                        Message: this.$tc( "COUPONS_deleteConfirm", coupons.length ),

                        Actions: [{
                            Id: "delete",
                            Name: this.$t( "GLOBAL_actionDelete" ),
                            Active: true
                        }]
                    },

                    Events: { Action: () => this.delete( coupons ) }
                });

                break;

            default:
                EventBus.Publish( "UnderConstruction" );
                break;
        }
    }

    private async delete( records: Coupon[] ) {
        this.records = [];

        for( const record of records ) {
            const error = await Coupons.Delete( record );

            if( error !== undefined )
                EventBus.Publish(
                    "Error",
                    new CustomError( "Error", $t( "COUPONS_cannotDelete" ) )
                );
        }

        this.refresh();
    }

    private refresh(): void {
        this.showForm = false;
        this.update( [], this.pagerSorter, true );

    }
}
