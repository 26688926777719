/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import { ICustomDate, Identifiable } from "@Internal/Contracts";

export default interface BaseModel extends Identifiable {
    Id: number;

    Created?: ICustomDate;
    Creator?: string;
    LastUpdated?: ICustomDate;
    UpdatedBy?: string;
}
