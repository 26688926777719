/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import { Subscriptions } from "App/IOC/Services";
import { DeepCopy } from "App/IOC";
import { GroupedLinesTargetByProduct, Plan, Subscription, SubscriptionLine } from "App/Models";
import { HandleListError, SubscriptionGroupedLines } from "App/Contracts";
import { DistinctSet } from "App/Utils";

import Columns from "./Columns";
import Fields from "./Fields";
import Filters from "./Filters";
import Cart from "./Cart";

function GenerateTitle( record: Subscription ): string {
    if( record.Plans === undefined || record.Plans.length === 0 )
        return "";

    const title: string[] = [];

    const distinctPlans = DistinctSet<Plan>( record.Plans, ( record: Plan ): number => record.Id );

    for( const plan of distinctPlans )
        title.push(
            $t(
                "SUBSCRIPTIONS_subscribedPlansSubtitle",
                {
                    productName: plan.Product.Name || "",
                    planName: plan.Name || ""
                }
            )
        );

    return title.join( ", " );
}

function GroupLines( lines: SubscriptionLine[] ): SubscriptionGroupedLines[] {
    let groupedLines: SubscriptionGroupedLines[] = [];

    lines.forEach( ( line: SubscriptionLine ): void => {
        let existingGroup: SubscriptionGroupedLines | undefined;
        const priceRecurrence = line.Price.Recurrence

        for( const group of groupedLines ) {
            if(
                group.Product.Id === line.Price.Product.Id &&
                group.Plan !== undefined &&
                line.Plan !== undefined &&
                group.Plan.Id === ( line.Price.Plan as Plan ).Id &&
                group.Recurrence === priceRecurrence
            )
                existingGroup = group;
        }

        if( existingGroup )
            existingGroup.Group.push( line );

        else {
            groupedLines.push({
                Product: DeepCopy( line.Price.Product ),
                Plan: DeepCopy( line.Price.Plan ),
                Group: [ line ],
                Recurrence: priceRecurrence
            });
        }
    });

    groupedLines = groupedLines.sort(
        ( a: SubscriptionGroupedLines, b: SubscriptionGroupedLines ): number => {

            /* Sort none plan lines last. */
            if( a.Plan === undefined && b.Plan !== undefined ) return 1;
            else if( a.Plan !== undefined && b.Plan === undefined ) return -1;
            else if( a.Plan === b.Plan === undefined ) return 0;

            if( a.Product.Sequence === b.Product.Sequence ) return 0;

            else if( a.Product.Sequence > b.Product.Sequence ) return 1;

            else return -1;
        }
    );

    return groupedLines;
}

function IsCanceled( record: Subscription ): boolean {
    if(
        ( record.Status === "Active" && record.CancellationDate !== undefined ) ||
        ( record.Status === "Canceled" && record.Trial === undefined )
    )
        return true;

    return false;
}

function GroupPriceTargetedLinesByProduct(
    subscription: Subscription
): HandleListError<GroupedLinesTargetByProduct> {
    return Subscriptions.GroupPriceTargetedLinesByProduct( subscription );
}

export default {
    ...Cart,
    Columns,
    Fields,
    Filters,
    Find: Subscriptions.Find,
    Get: Subscriptions.Get,
    IsCanceled,
    Unsubscribe: Subscriptions.Unsubscribe,
    GroupLines,
    GroupPriceTargetedLinesByProduct,
    GenerateTitle
};
