


import { Component, Vue, Prop } from "vue-property-decorator";
import { TrimText } from "@Internal/Utils";

@Component
export default class FieldText extends Vue {
    @Prop() public Value!: string | number;

    get value(): string | number {
        if( this.Value === undefined )
            return "—";

        if( typeof this.Value === "string" )
            return TrimText( this.Value ) as string;

        return this.Value;
    }
}
