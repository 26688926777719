/* © IBS Group. See COPYRIGHT file for full copyright & licensing details. */

import { Autobind, JsonUndefind2Null } from "@IBS/Frontend/Utils";
import { Models } from "@Internal";
import { PagerSorter } from "@Internal/Models";
import { AsyncHandleError, HandlePaginatedError } from "@Internal/Contracts";
import { CustomError, ModelFieldValueTranslation } from "App/Models";

import BaseService, { ProcessException } from "./Base";

export default class ModelFieldValueTranslations extends BaseService<ModelFieldValueTranslation> {
    @Autobind
    async Update(
        original: ModelFieldValueTranslation,
        updated: ModelFieldValueTranslation
    ): AsyncHandleError<ModelFieldValueTranslation> {
        const record: ModelFieldValueTranslation = {
            /* In order to update a translation for a specific language, we need to pass all
               information related to Model, Field, Language, Value. The ordinary
               `countDifferentFields()` logic will not serve us. */
            Id: original.RecordId as number,
            Model: original.Model,
            Field: original.Field,
            LanguageId: updated.LanguageId,
            Value: updated.Value
        };

        if( Object.keys( record ).length === 0 )
            return [ undefined, new CustomError( -1, $t( "GLOBAL_nothingToUpdate" ) ) ];

        const payload = Models.Serialize( record, true );

        const results =
            await this.service.update( original.RecordId as number, JsonUndefind2Null( payload ) );

        return Models.Deserialize( results );
    }

    @Autobind
    async FindByModelAndField(
        translatableField: ModelFieldValueTranslation
    ): HandlePaginatedError<ModelFieldValueTranslation> {
        let results: any;

        try {
            results = await this.service.find( { query: translatableField } );

        } catch( error ) {
            return [ [], new PagerSorter( 999 ), await ProcessException( error ) ];
        }

        return Models.DeserializeRecords( results, [], new PagerSorter( 999 ) );
    }
}
