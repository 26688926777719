


import { Component, Prop, Vue } from "vue-property-decorator";

import {
    Activated, HandleListError, HandlePaginatedError, Identifiable, Named
} from "@Internal/Contracts";

import { EventBus } from "App/IOC";
import { Filter } from "App/Contracts";
import { Column, PagerSorter } from "App/Models";

import PopupWindow from "./Window.vue";
import Records from "Web/Components/Records.vue";

type Action = Identifiable & Named & Activated;

@Component({
    components: {
        PopupWindow,
        Records
    }
})
export default class PopupRecords extends Vue {
    $refs!: Vue[ "$refs" ] & {
        Records: Records
    };

    @Prop( { default: "" } )
    readonly Id!: string;

    @Prop( { default: "" } )
    readonly Title!: string;

    @Prop()
    readonly Record!: Identifiable;

    /* We need this flag to allow multi filter in `Records` component for assigned filters. */
    @Prop( { default: false } )
    readonly MultiFilter!: boolean;

    @Prop( { default: () => [] } )
    Actions!: Action[];

    @Prop( { default: true })
    Pagination!: boolean;

    /* We rely on props in order to avoid losing class attribute to Modal wrapper since it doesn't
       render a node of its own. */
    @Prop( { default: "" } )
    ClassName!: string;

    @Prop()
    readonly Find!: (
        filters: Filter[], pagerSorter: PagerSorter, record?: Identifiable
    ) => HandlePaginatedError<Identifiable>;

    @Prop()
    readonly GetColumns!: () => HandleListError<Column>;

    @Prop()
    readonly PagerSorter!: PagerSorter;

    private records: Identifiable[] = [];
    private columns: Column[] = [];
    private pagerSorter: PagerSorter = this.PagerSorter;

    private async created(): Promise<void> {
        [ this.columns ] = await this.GetColumns();
    }

    private async update(
        filters: Filter[],
        pagerSorter: PagerSorter,
        resetPagination?: boolean,
    ): Promise<void> {
        await this.$nextTick();
        this.$refs.Records.Loading();

        if( resetPagination )
            this.pagerSorter.Page = 1;

        const [ records, updatedPagerSorter, error ] = await this.Find(
            filters, pagerSorter, this.Record
        );

        this.records = records;
        this.pagerSorter = updatedPagerSorter;

        if( error !== undefined ) {
            EventBus.Publish( "Error", error );
            return;
        }
    }
}
