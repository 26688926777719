/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import Feathers from "@feathersjs/feathers";

import { AsyncHandleError } from "@Internal/Contracts";
import { CustomError } from "@Internal/Models";
import { Models } from "@Internal";
import { Autobind } from "@Internal/Utils";

import { DeepEqual } from "App/IOC";
import { GlobalConfiguration } from "App/Models";
import { GlobalConfigurationService as IGlobalConfigurationService } from "App/Contracts";
import { ProcessException } from "./Base";

export default class GlobalConfigurationService implements IGlobalConfigurationService {
    protected service: Feathers.Service<any>;

    constructor( service: Feathers.Service<any> ) {
        this.service = service;
    }

    @Autobind
    async Get(): AsyncHandleError<GlobalConfiguration> {
        try {
            const record = await this.service.find();

            if( record === undefined )
                return [ undefined, undefined ];

            return Models.Deserialize<GlobalConfiguration>( record );

        } catch( error ) {
            return [ undefined, await ProcessException( error ) ];
        }
    }

    @Autobind
    async Update(
        original: GlobalConfiguration,
        updated: GlobalConfiguration
    ): AsyncHandleError<GlobalConfiguration> {
        const record = this.countDifferentFields( original, updated );

        if( Object.keys( record ).length === 0 )
            return [ undefined, new CustomError( -1, $t( "GLOBAL_nothingToUpdate" ) ) ];

        const payload = Models.Serialize( record, true );

        /* HACK: `undefined` values are unfortunately ommitted by JSON.stringify which is used
           by Featherjs REST client, so we force any `undefined` value to `null` so that
           it can be sent. */
        const replacer = ( key: string, value: any ): boolean =>
            typeof value === "undefined" ? null : value;

        const results = await this.service.create(
            JSON.parse( JSON.stringify( payload, replacer ) )
        );

        return Models.Deserialize( results );
    }

    private countDifferentFields(
        original: GlobalConfiguration,
        updated: GlobalConfiguration
    ): GlobalConfiguration {
        const newRecord: Record<string, any> = {};

        for( const [ key, value ] of Object.entries( updated ) ) {
            if( DeepEqual(
                value as Record<string, any>,
                ( original as Record<string, any> )[ key ]
            ))
                continue;

            newRecord[ key ] = ( updated as Record<string, any> )[ key ];
        }

        return newRecord as GlobalConfiguration;
    }
}
