


import { Component, Vue } from "vue-property-decorator";

import {
    CustomNotification,
    Field,
    GlobalConfiguration
} from "App/Models";

import { Configuration } from "App";

import { Dictionary } from "App/Contracts";

import { EventBus } from "App/IOC";

import Form from "Web/Components/Form/Index.vue";
import FormField from "Web/Components/Form/Field.vue";
import FormFieldset from "Web/Components/Form/Fieldset.vue";

@Component({
    components: {
        Field: FormField,
        Form,
        Fieldset: FormFieldset
    }
})
export default class GeneralGlobalConfiguration extends Vue {
    private configuration: GlobalConfiguration | undefined = {} as GlobalConfiguration;

    private fields: Dictionary<Field> = {};

    private async created(): Promise<void> {
        const [ configuration, error ] = await Configuration.Get();

        if( error !== undefined || configuration === undefined ) {
            EventBus.Publish( "Error", error );
            return;
        }

        this.configuration = configuration;

        this.fields = await Configuration.Fields.Get();
    }

    private async save( record: GlobalConfiguration ) {
        const [ configuration, error ] = await Configuration.Update(
            this.configuration as GlobalConfiguration, record
        );

        if( error !== undefined || configuration === undefined ) {
            EventBus.Publish( "Error", error );
            return;
        }

        EventBus.Publish( "Notification", CustomNotification.Success(
            this.$t( "GLOBAL_CONFIGURATION_notificationTitle" ).toString(),
            this.$t( "GLOBAL_CONFIGURATION_updated" ).toString()
        ));

        this.configuration = configuration;
    }
}
