


import { Component, Emit, Prop, Vue } from "vue-property-decorator";

import { Identifiable } from "App/Contracts";

import { TableAction, MassActions } from "Web/Contracts";

import Popup from "Web/Components/Common/Popup.vue";

@Component( {
    components: {
        Popup
    }
})
export default class TableActions extends Vue {
    $refs!: Vue[ "$refs" ] & {
        Triggers: HTMLElement[]
    }

    @Prop( { default: () => [] } )
    Rows!: Identifiable[];

    @Prop( { default: 0 } )
    TotalRows!: number;

    /* Name or designation of the selected resources. */
    @Prop() public Label!: string;
    @Prop() public PluralLabel!: string;

    @Prop( { default: () => [] } )
    Actions!: TableAction[];

    @Prop() public MassActions!: MassActions;

    // eslint-disable-next-line
    @Prop( { default: () => ( row: Identifiable, action: Identifiable ) => true } )
    IsActionAllowed!: ( row: Identifiable, action: Identifiable ) => boolean;

    private get ActiveActions(): TableAction[] {
        return this.Actions.filter( ( action: TableAction ) => {
            const active = !action.Multi ? ( this.Rows.length === 1 ) : true;

            if( !active )
                return false;

            return this.Rows.every(
                ( row: Identifiable ) => this.IsActionAllowed( row, action )
            );
        });
    }

    @Emit( "Clear" )
    private clear() { return; }

    private execute( action: TableAction ) {
        this.$emit( "Action", action.Id, [] );
    }

    /* Emit the action ID alongside a reference to the trigger element
       to be used as a snapping target. */
    private async executeMassAction(
        action: TableAction,
        hide: () => void,
        index: number
    ) {
        /* Abort execution if multiple rows were selected for a single item action. */
        if( ( !action.Multi && this.Rows.length !== 1 ) || !this.isEnabled( action ) )
            return;

        await this.$nextTick();

        const actionTrigger: HTMLElement = this.$refs.Triggers[ index ];

        this.$emit( "Action", action.Id, [], actionTrigger );

        hide();
    }

    private isEnabled( action: TableAction ): boolean {
        return action.Enabled === undefined || action.Enabled( this.Rows );
    }
}
