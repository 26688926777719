/* © IBS Group. See COPYRIGHT file for full copyright & licensing details. */

import { ICustomDate } from "Shared/Contracts";
import { $t, CustomDate, I18n } from "App/IOC";

const sourceDateTimeFormat: string = "YYYY-MM-DD HH:mm";

function Parse( date: string , format?: string ): ICustomDate {
    return new CustomDate( date, I18n, format );
}

function FromDate( date: Date ): ICustomDate {
    return new CustomDate( date, I18n );
}

function FromDateTime( date: Date ): ICustomDate {
    return DateTime( date );
}

function FromLocaleFormat( date: string ): ICustomDate {
    return new CustomDate( date, I18n, $t( "GLOBAL_default_standard_date_format" ) );
}

function DateTime( date: string | Date, format?: string ): ICustomDate {
    format = format || sourceDateTimeFormat;

    const dateTime = new CustomDate( date, I18n, format );
    dateTime.Time = true;

    return dateTime;
}

function Now(): ICustomDate {
    return FromDateTime( new Date() );
}

export default {
    FromDate,
    FromDateTime,
    FromLocaleFormat,
    Parse,
    Now,
    DateTime
};
