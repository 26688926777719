/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import Feathers from "@feathersjs/feathers";

import { PlansService } from "App/Contracts";
import { Plan } from "App/Models";
import { CustomError } from "@Internal/Models";
import { Autobind } from "@Internal/Utils";

import BaseService, { ProcessException } from "./Base";

export default class Plans extends BaseService<Plan> implements PlansService {
    private duplicateService: Feathers.Service<any>;

    constructor(
        service: Feathers.Service<any>,
        duplicateService: Feathers.Service<any>
    ) {
        super( service );

        this.duplicateService = duplicateService;
    }

    @Autobind
    async Archive( record: Plan ): Promise<CustomError | undefined> {
        try {
            const results = await this.service.update( record.Id, {
                Active: false
            });

            if( results.Error !== undefined )
                return new CustomError( results.Error.Id, results.Error.Message );

        } catch( error ) {
            return ProcessException( error );
        }

        return undefined;
    }

    @Autobind
    async Duplicate( record: Plan ): Promise<[ Plan | undefined, CustomError | undefined ]> {
        try {
            const results = await this.duplicateService.create( this.serialize( record , true ) );

            if( results.Error !== undefined )
                return [ undefined, new CustomError( results.Error.Id, results.Error.Message ) ];

            return this.deserialize( results );
        } catch( error ) {
            return [ undefined, await ProcessException( error ) ];
        }
    }
}
