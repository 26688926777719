


import { Component, Vue } from "vue-property-decorator";

import Menu from "./Components/Menu.vue";

@Component({
    components: {
        Menu
    }
})
export default class Main extends Vue {}
