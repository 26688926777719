/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import Feathers from "@feathersjs/feathers";

import { Users } from "App/Contracts";
import { CustomError, Password } from "@Internal/Models";
import { Autobind, Copy } from "@Internal/Utils";
import { Storage } from "@Internal/Contracts";
import { User } from "App/Models";

import Authentication from "App/IOC/AuthenticationService";
import { ProcessException } from "./Base";

export default class UsersService implements Users {
    private service: Feathers.Service<any>;
    private userService: Feathers.Service<any>;
    private dataStore: Storage;

    private token: string | undefined;
    private current: User | undefined;

    constructor(
        service: Feathers.Service<any>,
        accountService: Feathers.Service<any>,
        dataStore: Storage
    ) {
        this.service = service;
        this.userService = accountService;
        this.dataStore = dataStore;
    }

    @Autobind
    async Authenticate( email: string, password: Password ): Promise<CustomError | undefined> {
        /* TODO: Error handling. */
        const results = await this.service.create({
            email,
            password: password.Value
        });

        if( results.error !== undefined )
            return new CustomError( 504, results.error );

        this.setToken( results.AccessToken );

        const error = await this.dataStore.Set( "AuthenticationToken", this.token as string );
        if( error !== undefined )
            return error;

        this.current = new User( results.Id, results.Email );
        this.current.FirstName = results.FirstName;
        this.current.LastName = results.LastName;

        return undefined;
    }

    @Autobind
    async CheckAuthentication(): Promise<CustomError | undefined> {
        if( this.token === undefined )
            return CustomError.Unknown();

        return undefined;
    }

    @Autobind
    GetCurrent(): User | undefined {
        return Copy( this.current );
    }

    @Autobind
    setToken( value?: string ): void {
        if( value === undefined )
            return;

        this.token = value;
        Authentication.Token = value;
    }

    @Autobind
    async RestoreSession(): Promise<CustomError | undefined> {
        let error: CustomError | undefined;
        let token: string | undefined;

        [ token, error ] = await this.dataStore.Get( "AuthenticationToken" );

        if( error !== undefined )
            return error;

        if( token === "" )
            token = undefined;

        if( token === undefined )
            return new CustomError( 403, $t( "GLOBAL_invalidSession" ) );

        this.setToken( token );

        [ this.current, error ] = await this.get();

        return error;
    }

    @Autobind
    async SignOut(): Promise<CustomError | undefined> {
        const error = await this.dataStore.Remove( "AuthenticationToken" );

        if( error !== undefined )
            return error;

        this.token = undefined;
        this.current = undefined;

        return undefined;
    }

    private async get(): Promise<[ User | undefined, CustomError | undefined ]> {
        try {
            const results = await this.userService.find();

            if( results.error !== undefined ) {
                return [ undefined, new CustomError( 504, results.error ) ];
            }

            const user = new User( results.Id, results.Email );
            user.FirstName = results.FirstName;
            user.LastName = results.LastName;

            return [ user, undefined ];

        } catch( error ) {
            return [ undefined, await ProcessException( error ) ];
        }
    }
}
