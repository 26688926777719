


import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

import { Column, PagerSorter } from "App/Models";
import { Identifiable } from "App/Contracts";

import ColumnConfig from "./ColumnConfig.vue";
import TableHead from "./Head.vue";
import TableBody from "./Body.vue";
import TablePagination from "./Pagination.vue";
import LoadingDots from "Web/Components/Common/LoadingDots.vue";

@Component( {
    components: {
        ColumnConfig,
        TableBody,
        TableHead,
        TablePagination,
        LoadingDots
    }
} )
export default class Table extends Vue {
    $refs!: Vue[ "$refs" ] & {
        Table: HTMLElement,
        Head: TableHead,
        Body: TableBody,
        Wrapper: HTMLElement
    };

    @Prop() public Rows!: Identifiable[];
    @Prop() public Columns!: Column[];
    @Prop() public Loading!: boolean;

    @Prop( { default: [ 10 ] } )
    PageLimits!: number[];

    @Prop( { default: () => new PagerSorter() } )
    PagerSorter!: PagerSorter;

    /* Name or designation of the selected resources. */
    @Prop() public Label!: string;
    @Prop() public PluralLabel!: string;

    @Prop( { default: false } )
    ActionsEnabled!: boolean;

    @Prop( { default: true })
    Pagination!: boolean;

    // eslint-disable-next-line
    @Prop( { default: () => ( row: Identifiable ) => true } )
    IsSelectable!: ( row: Identifiable ) => boolean

    // eslint-disable-next-line
    @Prop( { default: () => ( row: Identifiable, action: Identifiable ) => true } )
    IsActionAllowed!: ( row: Identifiable, action: Identifiable ) => boolean;

    @Prop( { default: true } )
    ColumnConfig!: boolean;

    private selectedRows: Identifiable[] = [];

    /* Used to display table scroll overlay, it becomes true if there is overflow and
       we scrolled to the end of the scroll bar, which means we don't have to display
       the scroll overlay. */
    private scrolledToEnd: boolean = false;

    /* The table does not fit the wrapper, so we have to display the scroll overlay,
       potentially, depending on `scrolledToEnd` value. */
    private overflow: boolean = false;

    mounted(): void {
        /* Track scrolling position. */
        this.$refs.Wrapper.addEventListener( "scroll", this.updateScrolledToEnd );

        /* Update overflow property in case the window was resized. */
        window.addEventListener( "resize", this.updateOverflow );

        this.updateOverflow();
    }

    /* Clean up the reference dependent event listener. */
    beforeDestroy(): void {
        this.$refs.Wrapper.removeEventListener( "scroll", this.updateScrolledToEnd );
        window.removeEventListener( "resize", this.updateOverflow );
    }

    get activeColumns() {
        return this.Columns.filter( ( column: Column ) => column.Active );
    }

    get selectableRows(): Identifiable[] {
        return this.Rows.filter( ( row: Identifiable ) => this.IsSelectable( row ) );
    }

    SelectAll( yes: boolean ) {
        this.$refs.Body.SelectAll( yes );
    }

    @Watch( "Rows" )
    private async onRowsChange(): Promise<void> {
        /* Since the final width of `$refs.Table` depends on the loaded Rows, which
           can have arbitrary sizes, we watch for changes to this data source and
           update our overflow flag accordingly, otherwise we would only get the
           initial width of the table post mounting which can be empty if the data takes
           time to load, which prevents the scroll overlay from showing. */

        await this.$nextTick();

        this.updateOverflow();
    }

    @Emit( "SelectRows" )
    private selectRows( rows: Identifiable[] ) {
        /* Replace all existing rows with the newly selected ones, splice helps maintain
           reactivity. */
        this.selectedRows.splice( 0, this.selectedRows.length , ...rows );
        return this.selectedRows;
    }

    private toggleColumn( column: Column ) {
        this.SelectAll( false );

        this.$emit( "ToggleColumn", column );
    }

    private updatePagerSorter( pagerSorter: PagerSorter ) {
        this.SelectAll( false );

        this.$emit( "PagerSorter", pagerSorter );
    }

    private executeAction( action: string, rows: Identifiable[], actionTrigger?: HTMLElement ) {
        this.$emit(
            "RowAction",
            action,
            rows,
            actionTrigger
        );
    }

    private updateOverflow() {
        this.overflow = this.$refs.Table.clientWidth > this.$refs.Wrapper.clientWidth;
    }

    private updateScrolledToEnd(): void {
        const { clientWidth, scrollLeft } = this.$refs.Wrapper;

        this.scrolledToEnd = clientWidth + scrollLeft === this.$refs.Table.clientWidth;
    }
}
