


import { Component, Mixins } from "vue-property-decorator";

import BaseSelect from "Web/Components/Select/Base.vue";

@Component
export default class SimpleSelect extends Mixins( BaseSelect ) {

}
