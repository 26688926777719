/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import { Identifiable } from "App/Contracts";
import { CountryCode } from "App/Utils";

export default interface Country extends Identifiable {
    Id: string;
    ShortName: CountryCode;
    Name: string;
    DialCode: string;
}
