/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import { Plans } from "App/IOC/Services";

import Columns from "./Columns";
import Fields from "./Fields";
import Filters from "./Filters";

export default {
    Columns,
    Create: Plans.Create,
    Delete: Plans.Delete,
    Fields,
    Filters,
    Find: Plans.Find,
    Get: Plans.Get,
    Update: Plans.Update,
    Archive: Plans.Archive,
    Duplicate: Plans.Duplicate
};
