


import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { RegexEscape } from "@Internal/Utils";

/* Lots of hackery in this component. */

@Component
export default class NumberField extends Vue {
    @Prop() public Id!: string;
    @Prop() public Placeholder!: string;
    @Prop() public Value!: number | undefined;

    @Prop( { default: false } )
    public readonly Readonly!: boolean;

    @Prop( { default: 0 } )
    Precision!: number;

    private value: string = "";

    created(): void {
        if( this.Value !== undefined )
            this.onValueChange( this.Value );
    }

    private format( value: string ): string {
        const decimalSeparator = this.$t( "GLOBAL_decimalSeparator" ).toString();
        const thousandSeparator = this.$t( "GLOBAL_decimalThousandSeparator" ).toString();

        return value.replace( ".", decimalSeparator )
            .replace( /\B(?=(\d{3})+(?!\d))/g, thousandSeparator );
    }

    @Watch( "Value" )
    private onValueChange( value: number ) {
        /* Replace decimal separator with the locale one. */
        this.value = value === undefined ? "" : this.format( String( value ) );
    }

    @Watch( "value" )
    private onInternalValueChange( value: string, previousValue: string ) {
        const thousandSeparator = this.$t( "GLOBAL_decimalThousandSeparator" ).toString();

        /* Remove thousand separator formatting. */
        value = value.replace( new RegExp( RegexEscape( thousandSeparator ), "g" ), "" );

        /* Limit lent to trillion to prevent the number from being formatted using exponent. */
        if( value.length > 12 ) {
            this.value = previousValue;
            return;
        }

        /* No need to emit the value if it didn't change. */
        if( String( this.Value ) === value )
            return;

        const rawDecimalSeparator = this.$t( "GLOBAL_decimalSeparator" ).toString();

        /* Support both locale separator and JS decimal separator. */
        const separator = "(" + RegexEscape( rawDecimalSeparator ) + "|\\.)";

        const regex = new RegExp(
            "^([0-9]+|([0-9]+" + separator + "[0-9]{0," + this.Precision + "}))$", "g"
        );
        const decimalRegex = new RegExp( separator, "g" );

        /* Black magic to only accept numbers of the following format: 12, 12., 12.5, 12.55 */
        const dots = ( value.match( decimalRegex ) || [] ).length > ( this.Precision > 0 ? 1 : 0 );
        const invalidNumber = value !== "" && ( value.match( regex ) || [] ).length === 0;

        if( dots || invalidNumber ) {
            /* replace() is used to cover the case where we for instance type "5.", the dot gets
               replaced with the locale version. */
            this.value = previousValue;
            return;
        }

        this.value = this.format( value );

        /* If the input field is empty send an undefined value, just like the value we receive
           from the relevant prop (Value).
           We make sure to replace the locale separator with the dot that JS understands. */
        this.$emit(
            "Value",
            value === "" ? undefined : Number( value.replace( rawDecimalSeparator, "." ) )
        );
    }
}
