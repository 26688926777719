


import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";

import { CustomError } from "App/Models";
import { EventBus } from "App/IOC";
import { Identifiable } from "App/Contracts";

import Modal from "Web/Components/Common/Modal.vue";
import { Option } from "Web/Contracts";

function getIndex( list: Identifiable[], item: Identifiable ): number {
    for( let i = 0; i < list.length; ++i )
        if( list[ i ].Id === item.Id )
            return i;

    return -1;
}

@Component( {
    components: {
        Modal
    }
})
export default class SimpleSelect extends Vue {
    @Prop() public Id!: string;

    @Prop()
    readonly GetOptions?: ( keyword?: string ) => Promise<[ Option[], CustomError | undefined ]>;

    @Prop()
    readonly Options?: Option[];

    @Prop()
    SelectedOption?: Option;

    @Prop( { default: "" } )
    Placeholder!: string;

    @Prop() public NoResultMessage!: string;

    @Prop( { default: false } )
    readonly Disabled!: boolean;

    @Ref( "container" )
    private readonly container?: HTMLElement;

    @Ref( "popup" )
    private readonly popup?: HTMLElement;

    @Ref( "options" )
    private readonly options?: HTMLElement[];

    private visibleOptions: Option[] = [];

    /* This variable is used to avoid using positive tabindex values. If you want to highlight
       nothing (For instance the first time we open options list) Set this to -1. */
    private highlighted: number = -1;

    private showOptions: boolean = false;

    private loading: boolean = false;

    /* If we scroll, we need to update the absolute position of the options dropdown
       list, otherwise it stays snapped to the previous location, to do this we simply
       force update the template using the `key` directive. */
    private scrollKey: number = 0;

    beforeDestroy(): void {
        window.removeEventListener( "click", this.handleOutsideClick );
        document.removeEventListener( "scroll", this.updatePopupPosition, true );
    }

    private async getOptions(): Promise<void> {
        this.loading = true;

        let options: Option[] = [];
        let error: CustomError | undefined;

        if( this.Options !== undefined )
            options = this.Options;

        else if( this.GetOptions !== undefined )
            [ options, error ] = await this.GetOptions();

        if( error !== undefined )
            EventBus.Publish( "Error", error );

        this.visibleOptions = options;

        this.loading = false;

        this.highlighted = -1;

        this.showOptions = true;
    }

    @Watch( "showOptions" )
    private showOptionsChanged( shown: boolean ): void {
        /* This is an optimization to avoid listening to the clicking event when
           the options popup is invisible. */

        if( shown ) {
            window.addEventListener( "click", this.handleOutsideClick );
            document.addEventListener( "scroll", this.updatePopupPosition, true );

            return;
        }

        window.removeEventListener( "click", this.handleOutsideClick );
        document.removeEventListener( "scroll", this.updatePopupPosition, true );
    }

    private select( option: Option ): void {
        this.highlighted = getIndex( this.visibleOptions, option );
        this.focusHighlighted();

        this.$emit( "Select", option );

        this.showOptions = false;
    }

    private updateHighlighted( move: number ): void {
        const optionsLength = this.visibleOptions.length;

        /* Change highlighted option on arrow keys down, reset cycle when reaching extremes. */
        if( this.highlighted === 0 && move < 0 ) {
            this.highlighted = optionsLength - 1;

        } else if( this.highlighted === optionsLength - 1 && move > 0 ) {
            this.highlighted = -1;

        } else {
            this.highlighted = this.highlighted + move;
        }

        this.focusHighlighted();
    }

    private async focusHighlighted( index?: number ): Promise<void> {
        if( index !== undefined )
            this.highlighted = index;

        await this.$nextTick();

        if( this.options !== undefined && this.options[ this.highlighted ] !== undefined )
            this.options[ this.highlighted ].focus();

        else
            this.highlighted = -1;
    }

    /* Handles emitted visibility changes,
       loads data if fetch function is provided. */
    private async toggleOptions( value?: boolean ) {
        if( this.Disabled )
            return;

        this.highlighted = -1;

        const showOptions = ( value !== undefined ? value : !this.showOptions );

        if( showOptions )
            await this.getOptions();

        this.showOptions = showOptions;
    }

    private isOptionSelected( option: Option ): boolean {
        if( this.SelectedOption === undefined )
            return false;

        return this.SelectedOption.Id === option.Id;
    }

    private handleOutsideClick( event: Event ): void {
        /* Ignore clicks that are coming from elements inside our component, otherwise we break
           their functionalities. */
        if( this.container === undefined || this.container.contains( event.target as HTMLElement ) )
            return;

        this.showOptions = false;
    }

    private reset(): void {
        this.visibleOptions = [];
    }

    /* TODO: Lots of code is repetitive here and in Simple.vue, abstract them away
       as composable behavior or mixins. */
    private updatePopupPosition( event: Event ): void {
        const target = event.target as HTMLElement;
        /* We're scrolling inside the dropdown, or not in an ancestor of the element,
           don't do anything. */
        if(
            this.popup === undefined ||
            this.popup.contains( target ) ||
            !target.contains( this.$el )
        )
            return;

        /* If we scroll, we need to update the absolute position of the options dropdown
           list, otherwise it stays snapped to the previous location, to do this we simply
           force update the template using the `key` directive. */
        if( this.scrollKey < 500000 )
            this.scrollKey++;

        else
            this.scrollKey = 0;
    }
}
