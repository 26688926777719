


import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Tab extends Vue {
    /* TODO: This can only work for pure strings, we might need to send
       complex components instead in the future, find a better solution. */
    @Prop() public Suffix!: string;

    @Prop() public Title!: string;

    @Prop( { default: false } )
    Default!: boolean;

    @Prop( { default: false } )
    Disabled!: boolean;

    Active: boolean = false;
    Visible: boolean = true;
}
