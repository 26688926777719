/* © Facilogi. See LICENSE file for full copyright & licensing details. */

import { Dictionary } from "@Internal/Contracts";
import { Field } from "@Internal/Models";
import { $t } from "App/IOC";

let defaultFields: Dictionary<Field> = {};

async function Get(): Promise<Dictionary<Field>> {
    await Promise.resolve();

    if( Object.keys( defaultFields ).length === 0 )
        defaultFields = {
            Name: Field.Text( "Name", $t( "CUSTOMERS_fieldName" ) ),
            Company: Field.Text( "Company", $t( "CUSTOMERS_fieldCompany" ) ),
            Email: Field.Text( "Email", $t( "CUSTOMERS_fieldEmail" ) ),
            Street: Field.Text( "Street", $t( "CUSTOMERS_fieldStreet" ) ),
            City: Field.Text( "City", $t( "CUSTOMERS_fieldCity" ) ),
            PostalCode: Field.Text( "PostalCode", $t( "CUSTOMERS_fieldPostalCode" ) ),
            Note: Field.MultiLineText( "Note", $t( "CUSTOMERS_fieldNote" ) ),
            Country: Field.Selection( "Country", $t( "CUSTOMERS_fieldCountry" ) ),
            VAT: Field.Text( "VAT", $t( "CUSTOMERS_fieldVAT" ) ),
            IsStaff: Field.Boolean( "IsStaff", $t( "CUSTOMERS_fieldIsStaff" ) ),
            InvoicePrefix: Field.UppercaseAlphabets(
                "InvoicePrefix",
                $t( "CUSTOMERS_fieldInvoicePrefix"),
                { MinSize: 3, Size: 6 }
            )
        };

    return defaultFields;
}

export default {
    Get
};
