/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

export function DistinctSet<T>( records: T[], keySet: ( record: T ) => string | number ): T[] {
    return [
        ...new Map(
            records.map(
                ( record: T ): [ number | string, T ] =>
                    [ keySet( record ), record ]
            )
        ).values()
    ]
}
