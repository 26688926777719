/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

export default {
    GLOBAL_CONFIGURATION_title: "Configuration générale",
    GLOBAL_CONFIGURATION_fieldYearlyCoupon: "Bon de réduction annuel",
    GLOBAL_CONFIGURATION_fieldTrial: "Période d'essaie (En jours)",
    GLOBAL_CONFIGURATION_fieldNote: "Note",
    GLOBAL_CONFIGURATION_addNote: "Saisissez une note",
    GLOBAL_CONFIGURATION_fieldRetries: "Tentatives de paiement d'abonnement",
    GLOBAL_CONFIGURATION_fieldMonthlySEPA:
        "Le coût minimum pour utiliser un prélèvement SEPA mensuellement",
    GLOBAL_CONFIGURATION_fieldYearlySEPA:
        "Le coût minimum pour utiliser un prélèvement SEPA annuellement",

    GLOBAL_CONFIGURATION_fieldPaymentStatementDescription:
        "Le nom de l'entreprise qui apparaîtra sur les relevés bancaires de vos clients",

    GLOBAL_CONFIGURATION_formChanged: "Pas de changements | Vous avez modifié 1 champ de la " +
        "configuration global | Vous avez modifié {count} champs de la configuration global",

    GLOBAL_CONFIGURATION_notificationTitle: "Configuration globale",
    GLOBAL_CONFIGURATION_updated: "Configuration globale mise à jour avec succès"
};
