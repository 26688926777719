


import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Calendar from "primevue/calendar";

import { ICustomDate } from "App/Contracts";
import { Dates } from "App";

// @ts-ignore
@Component({
    // @ts-ignore
    components: {
        Calendar
    }
})
export default class DateFilterForm extends Vue {
    @Prop() public From!: ICustomDate;

    @Prop() public To!: ICustomDate;

    private internalValue: ( Date | undefined )[] = [];

    private created() {
        this.onFromDateChange( this.From );
        this.onToDateChange( this.To );
    }

    @Watch( "From" )
    private onFromDateChange( from?: ICustomDate ) {
        /* If we received no value, it's best to leave internalValue as is, otherwise Calendar
           component will fail. */
        if( from === undefined )
            return;

        const internalFromDate = this.internalValue[ 0 ];

        /* We are probably receiving an originally emitted value, so to avoid an infinite recursion,
            we stop it here. */
        if( internalFromDate !== undefined && from.Equals( Dates.FromDate( internalFromDate ) ) )
            return;

        this.internalValue[ 0 ] = from.ToDate();
    }

    @Watch( "To" )
    private onToDateChange( to?: ICustomDate ) {
        const internalToDate = this.internalValue[ 1 ];

        /* Both values are null, no need to change anything. */
        if( to === internalToDate )
            return;

        /* We are probably receiving an originally emitted value, so to avoid an infinite recursion,
            we stop it here. */
        if(
            to !== undefined &&
            internalToDate !== undefined &&
            to.Equals( Dates.FromDate( internalToDate ) )
        )
            return;

        this.internalValue[ 1 ] = to !== undefined ? to.ToDate() : to;
    }

    @Watch( "internalValue" )
    private setValue( internalValue: Date[] ) {
        const from =
            internalValue[ 0 ] !== undefined ? Dates.FromDate( internalValue[ 0 ] ) : undefined;

        const value = [ from ];

        /* An interval was selected. Note: null is used on purpose here because that's what the
           Calendar component uses. */
        if( internalValue[ 1 ] !== null )
            value.push( Dates.FromDate( internalValue[ 1 ] ) );

        this.$emit( "Value", value );
    }
}
