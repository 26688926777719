


import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { Records } from "App";
import { EventBus } from "App/IOC";

import Popup from "Web/Components/Common/Popup.vue";
import { RecordActions } from "Web/Contracts";

@Component({
    components: {
        Popup
    }
})
export default class RecordComponent extends Vue {
    @Prop( { default: () => [] } )
    public Actions!: RecordActions;

    @Prop( { default: false } )
    Editable!: boolean;

    @Prop( { default: false } )
    Editing!: boolean;

    private total: number = 0;
    private recordIndex: number = 0;

    created(): void {
        this.total = Records.Navigation.Total;
        this.recordIndex = Records.Navigation.CurrentIndex;
    }

    @Watch( "Editing" )
    private onEditing( value: boolean ): void {
        EventBus.Publish( "SingleRecordMutated", value );
    }

    private navigate( next: boolean ): void {
        let nextUrl: string;
        [ this.recordIndex, nextUrl ] = next ? Records.Navigation.Next() :
            Records.Navigation.Previous();

        this.$router.replace( nextUrl );
    }
}
