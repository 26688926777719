var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-select c-select--simple",class:{ 'c-select--is-expanded': _vm.showOptions }},[_c('div',{staticClass:"c-select__selected-options c-select__selected-options",on:{"click":function($event){return _vm.toggleOptions()}}},[_vm._t("SelectedOptions",[(_vm.SelectedOptions.length > 0)?_vm._l((_vm.SelectedOptions),function(option){return _c('span',{key:option.Id,staticClass:"c-select__selected-option",class:[ 'c-select__selected-option--' + option.Id ]},[_vm._v("\n                    "+_vm._s(( _vm.ShortName && option.ShortName ) || option.Name || option.Id)+"\n                ")])}):_c('span',{staticClass:"c-select__no-selected-options"},[_vm._v(_vm._s(_vm.Placeholder))])],{"SelectedOptions":_vm.SelectedOptions})],2),_vm._t("Trigger",[_c('button',{staticClass:"c-select__trigger",attrs:{"id":_vm.Id,"tabindex":"0"},on:{"click":function($event){return _vm.toggleOptions()}}})]),_c('Popup',{ref:"popup",staticClass:"c-select__popup",attrs:{"Shown":_vm.showOptions,"OutsideClick":_vm.OutsideClick,"Alignment":"left"},on:{"Update":_vm.toggleOptions},scopedSlots:_vm._u([{key:"body",fn:function(){return [(_vm.Filter)?_c('div',{staticClass:"c-select__filter"},[_vm._t("Filter",[(_vm.SelectAll)?_c('button',{staticClass:"c-select__select-all-options",class:{
                            'c-select__select-all-options--is-checked':
                                _vm.visibleOptions.length === _vm.SelectedOptions.length
                        },on:{"click":function($event){return _vm.selectAll()}}}):_vm._e(),_c('div',{staticClass:"c-select__filter-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.keyword),expression:"keyword"}],ref:"filter",attrs:{"type":"text","name":"filter","placeholder":_vm.Placeholder},domProps:{"value":(_vm.keyword)},on:{"click":function($event){return _vm.toggleOptions( true )},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }$event.stopPropagation();$event.preventDefault();return _vm.updateHighlighted( -1 )},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }$event.stopPropagation();$event.preventDefault();return _vm.updateHighlighted( 1 )},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();$event.preventDefault();return _vm.select( _vm.visibleOptions[ _vm.highlighted ] )}],"input":function($event){if($event.target.composing){ return; }_vm.keyword=$event.target.value}}})]),_c('button',{staticClass:"c-select__close",on:{"click":function($event){return _vm.toggleOptions( false )}}},[_c('i',{staticClass:"material-icons c-select__close-icon"},[_vm._v("close")])])])],2):_vm._e(),_c('div',{staticClass:"c-select__options",attrs:{"id":_vm.Id + 'options'}},[_vm._l((( _vm.noOption.Id ?
                        [ _vm.noOption ].concat( _vm.visibleOptions ) : _vm.visibleOptions )),function(option,index){return _c('div',{key:option.Id,ref:"options",refInFor:true,staticClass:"c-select__option",class:{
                        'c-select__option--is-selected': _vm.isOptionSelected( option ),
                        'c-select__option--is-highlighted': index === _vm.highlighted
                    },attrs:{"tabindex":0},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }$event.stopPropagation();$event.preventDefault();return _vm.updateHighlighted( -1 )},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }$event.stopPropagation();$event.preventDefault();return _vm.updateHighlighted( 1 )},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();$event.preventDefault();return _vm.select( option )},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }$event.stopPropagation();$event.preventDefault();return _vm.$refs.popup.Hide()}],"click":function($event){return _vm.select( option )},"mouseover":function($event){return _vm.focusHighlighted( index )}}},[_vm._t("option",[_vm._v(_vm._s(option.Name || option.Id))],{"option":option,"isOptionSelected":_vm.isOptionSelected})],2)}),(_vm.visibleOptions.length === 0)?_c('div',{staticClass:"c-select__no-options"},[_vm._v(_vm._s(_vm.$t( "GLOBAL_noResults" )))]):_vm._e()],2)]},proxy:true}],null,true)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }