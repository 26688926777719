


import { Component, Vue, Prop } from "vue-property-decorator";

import { Coupon } from "App/Models";
import { Coupons } from "App";
import { EventBus } from "@/App/IOC";

@Component
export default class FieldCouponGeneralConditions extends Vue {
    @Prop() public Row!: Coupon;
    private generalConditions: string = "";

    async created() {
        await this.generateConditions();
    }

    private async generateConditions(): Promise<void> {
        const [ condition, error ] = await Coupons.GenerateGeneralConditions( this.Row );

        if( error !== undefined || condition === undefined ) {
            EventBus.Publish( "Error", error );
            return;
        }

        this.generalConditions = condition;
    }
}
