/* © Facilogi. See LICENSE file for full copyright & licensing details. */

import { EventBus, VoidFunction } from "App/Contracts";
import EventEmitter from "eventemitter3";

export default class EventEmitter3 implements EventBus {
    private bus: EventEmitter;

    constructor() {
        this.bus = new EventEmitter();
    }

    Publish( eventName: string, payload?: any ): void {
        this.bus.emit( eventName, payload );
    }

    Subscribe( eventName: string, callback: VoidFunction, context?: any ): void {
        this.bus.on( eventName, callback, context );
    }

    Unsubscribe( eventName: string, callback: VoidFunction, context?: any ): void {
        this.bus.removeListener( eventName, callback, context );
    }

    UnsubscribeAll( eventName: string ): void {
        this.bus.removeAllListeners( eventName );
    }
}
