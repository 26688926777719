/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import { BasePlan } from "Shared/Models";

import Price from "./Price";
import Product, { NewProduct } from "./Product";

export default interface Plan extends BasePlan<Product> {
    Prices: Price[];
    Name?: string;
    Description?: string;
    /* Main attraction of this plan. */
    Pitch?: string;
    PitchSubtitle?: string;
}

export function NewPlan( id: number = -1 ): Plan {
    return {
        Id: id,
        Prices: [],
        Sequence: 1,
        IsDefault: false,
        CompatiblePlans: [],
        Product: NewProduct(),
        Trial: false
    };
}
