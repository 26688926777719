


import { Component, Mixins } from "vue-property-decorator";

import BaseSelect from "./Base.vue";

@Component
export default class SortedSimpleSelect extends Mixins( BaseSelect ) {

}
