var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c2-record"},[_c('div',{staticClass:"c2-record__header"},[_c('div',{staticClass:"c2-record__avatar"},[_vm._t("Avatar")],2),_c('div',{staticClass:"c2-record__header-content"},[_c('h3',{staticClass:"c2-record__headline",class:{
                    'c2-record__headline--editable': _vm.Editable,
                    'c2-record__headline--disable-edit': _vm.Editing
                },on:{"click":function($event){_vm.Editing || _vm.$emit( 'Edit' )}}},[_vm._t("Headline")],2),_c('div',{staticClass:"c2-record__extra-actions"},[_vm._t("ExtraActions")],2),_c('div',{staticClass:"c2-record__actions"},[(_vm.Actions.length > 0)?_c('Popup',{attrs:{"Alignment":"right"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
                var Shown = ref.Shown;
return [_c('button',{staticClass:"\n                            c-button\n                            c-button--secondary\n                            c-button--dropdown\n                            c2-record__show-actions\n                        ",class:{ 'c-button--dropdown-open': Shown },attrs:{"disabled":_vm.Editing}},[_vm._v(_vm._s(_vm.$t( "PRODUCT_actions" )))])]}},{key:"body",fn:function(){return [_c('div',{staticClass:"c-list"},[_vm._l((_vm.Actions),function(group,groupIndex){return _vm._l((group),function(action,index){return _c('li',{key:action.Id,staticClass:"c-list__item",class:{
                                    'c-list__item--underlined':
                                        ( index === ( group.length - 1 ) ) &&
                                        ( groupIndex < _vm.Actions.length - 1 ),

                                    'c-list__item--disabled':
                                        action.Enabled !== undefined && !action.Enabled()
                                },on:{"click":function($event){return _vm.$emit( 'Action', action )}}},[_c('div',{staticClass:"c-list__item-content"},[_c('i',{staticClass:"material-icons"},[_vm._v(_vm._s(action.Icon))]),_c('span',[_vm._v(_vm._s(action.Title))])])])})})],2)]},proxy:true}],null,false,2446611818)}):_vm._e()],1),_c('div',{staticClass:"c2-record__secondary-headline"},[_vm._t("SecondaryHeadline")],2),_c('div',{staticClass:"c2-record__miscellaneous"},[_vm._t("Miscellaneous"),(_vm.total !== 0)?_c('div',{staticClass:"c2-record__navigation",class:{
                        'c2-record__navigation--disabled': _vm.Editing
                    }},[_c('p',{staticClass:"c2-record__navigation-pages"},[_vm._v(_vm._s(( _vm.recordIndex + 1 )  + " " + _vm.$t( "PRODUCT_of" ) + " " + _vm.total))]),_c('button',{staticClass:"c2-record__navigation-button",attrs:{"disabled":( _vm.recordIndex === 0 ) || _vm.Editing},on:{"click":function($event){return _vm.navigate( false )}}}),_c('button',{staticClass:"c2-record__navigation-button c2-record__navigation-button--next",attrs:{"disabled":( _vm.recordIndex === _vm.total - 1 ) || _vm.Editing},on:{"click":function($event){return _vm.navigate( true )}}})]):_vm._e()],2)])]),_c('div',{staticClass:"c2-record__body"},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }