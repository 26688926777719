


import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { Password, CustomError } from "App/Models";

@Component
export default class PasswordField extends Vue {
    @Prop() public Id!: string;
    @Prop() public Placeholder!: string;
    @Prop() public Value!: Password;

    @Prop( { default: false } )
    readonly Toggle!: boolean;

    private value: string = "";
    private passwordShown: boolean = false;
    private password?: Password;
    private validation?: CustomError;

    private state: "untouched" | "valid" | "invalid" = "untouched";

    private validate() {
        if( this.value === "" ) {
            this.password = undefined;

            /* Required fields are validated by Form field component when needed.
            This condition ignores password Validation if the value is empty. */
            this.validation = undefined;

            return;
        }

        this.password = new Password( this.value );

        this.validation = this.password.Validate();

        this.state = this.validation instanceof CustomError ? "invalid" : "valid";
    }

    @Watch( "Value" )
    private onValueChange( value: Password | undefined ) {
        if( value !== undefined )
            this.value = value.Value;
    }

    @Watch( "value" )
    private onInternalValueChange( value: string ) {
        /* No need to emit the value if it didn't change. */
        if( this.Value !== undefined && this.Value.Value === value )
            return;

        this.validate();

        /* If the input field is empty send an undefined value, just like we the value we receive
           from the relevant prop (Value). */
        this.$emit( "Value", this.password, this.validation );
    }
}
