


import { Component, Prop, Vue } from "vue-property-decorator";

import {
    Product,
    Plan,
    Price
} from "App/Models";
import { Plans } from "App";
import { DeepCopy, EventBus } from "App/IOC";

import PlanForm from "Web/Views/Products/Single/Plans/Form.vue";
import PriceTargetForm from "Web/Views/Products/Single/Prices/PriceTarget.vue";
import ProductPrices from "Web/Views/Products/Single/Prices/Index.vue";

@Component({
    components: {
        PriceTargetForm,
        ProductPrices,
        PlanForm
    }
})
export default class ProductPlan extends Vue {
    @Prop() readonly Product!: Product;
    @Prop() readonly Record!: Plan;

    @Prop( { default: false } )
    readonly ReadOnly!: boolean;

    @Prop( { default: false } )
    readonly First!: boolean;

    private showForm: boolean = false;
    private showPlanForm: boolean = false;

    private async duplicate(): Promise<void> {
        if( this.ReadOnly )
            return;

        this.Record.Product = DeepCopy( this.Product );

        const [ duplicatedPlan, error ] = await Plans.Duplicate( this.Record );

        if( error !== undefined || duplicatedPlan === undefined )
            EventBus.Publish( "Error", error );

        this.$emit( "Refresh" );
    }

    private async archivePlan(): Promise<void> {
        if( this.ReadOnly )
            return;

        EventBus.Publish( "Confirmation", {
            Props: {
                Message: this.$tc( "PLAN_archiveConfirm", 1 ),
                Actions: [{
                    Id: "archive",
                    Name: this.$t( "GLOBAL_actionArchive" ),
                    Active: true
                }]
            },

            Events: { Action: this.archive }
        });
    }

    private async archive(): Promise<void> {
        const error = await Plans.Archive( this.Record );

        if( error !== undefined )
            EventBus.Publish( "Error", error );

        this.$emit( "Refresh" );
    }

    private async update( plan: Plan ): Promise<void> {
        if( this.ReadOnly )
            return;

        const [ , error ] = await Plans.Update( this.Record as Plan, plan );

        if( error !== undefined )
            EventBus.Publish( "Error", error );

        this.$emit( "Refresh" );
    }

    private createPrice( price: Price ): void {
        this.$emit( "CreatePrice", price, this.Record );
    }

    private setDefault(): void {
        if( this.Record.IsDefault )
            return;

        this.$emit( "Default" );
    }
}
