/* © IBS Group. See COPYRIGHT file for full copyright & licensing details. */

import { AnnotatedJSON, Dictionary, Filter } from "@Internal/Contracts";
import { CustomError } from "App/Models";
import { Null2Undefined } from "@Internal/Utils";
import { PagerSorter } from "@Internal/Models";

export type SerializedValue = string | number | boolean | Dictionary;
type Deserializer = ( value: SerializedValue, specifications?: Dictionary ) => any | undefined;
type Translator = ( value: string, ...values: ( string | number )[] ) => string;

export abstract class Serialization {
    static SetDeserializer( id: string, deserializer: Deserializer ): void {
        this.deserializers.set( id, deserializer );
    }

    static GetDeserializer( id: string ): Deserializer {
        return this.deserializers.get( id );
    }

    static SetTranslator( translator: Translator ): void {
        Serialization.translator = translator;
    }

    static Serialize(
        object: Record<string, any>,
        idOnly: boolean = false,
        locale: string = "fr"
    ): AnnotatedJSON {
        const result: AnnotatedJSON = {};
        let serializedValue: any;

        for( const [ key, _value ] of Object.entries( object ) ) {
            const value = Null2Undefined( _value );

            if( value !== undefined && value.Serialize !== undefined )
                serializedValue = value.Serialize( true );

            else if( value !== undefined && ( value instanceof Array ) ) {
                serializedValue = [];

                for( const [ index, item ] of value.entries() ) {
                    serializedValue[ index ] = ( item.Id !== undefined && item.Id !== -1 && idOnly )
                        ? { Id: item.Id }
                        : Serialization.Serialize( item, idOnly, locale );
                }

            } else if( value !== undefined && typeof value === "object" ) {
                /* Send only the value with the appropriate translation regarding to the user
                   locale language. */
                if( value.Translations !== undefined && locale !== undefined ) {
                    result[ key ] = value.Translations[ locale ];
                    continue;
                }

                /* When sending Identifiables, we only need to send their ID to the backend. */
                serializedValue = ( value.Id !== undefined && value.Id !== -1 && idOnly )
                    ? { Id: value.Id }
                    : Serialization.Serialize( value, idOnly, locale );

            } else if( key !== "Id" || ( key === "Id" && value !== -1 ) ) {
                /* -1 IDs are just placeholders, we don't need to send them. */
                serializedValue = value;
            }

            result[ key ] = serializedValue;
        }

        return result;
    }

    static Deserialize<T>(
        object: AnnotatedJSON,
        id?: string | number,
        locale?: string,
        translatableFields: string[] = [],
        specifications?: AnnotatedJSON
    ): [ T | undefined, CustomError | undefined ] {
        const deserializedRecord: any = {};

        /* Sometimes we get serialized data without the ID and we want to assign it, for
           example in REST service updates. */
        if( id !== undefined ) {
            id = Number( id );
            deserializedRecord.Id = id;
        }

        /* This is mostly useful when this use case is used in the frontend. */
        if( object.Error !== undefined ) {
            if( object.Error.Reference !== undefined )
                return [ undefined, CustomError.Internal( object.Error.Message ) ];

            else
                return [
                    undefined,
                    new CustomError(
                        object.Error.Id,
                        Serialization.translator( object.Error.Message )
                    )
                ]
        }

        for( const [ key, value ] of Object.entries( object ) ) {
            if( Null2Undefined( value ) === undefined ) {
                deserializedRecord[ key ] = Null2Undefined( value );
                continue;
            }

            let deserializedValue: any;

            /* We get the value of translated field as string, so we check if this field is
               translatable, we deserialize it as follow. */
            if( translatableFields.includes( key ) && locale !== undefined ) {
                deserializedRecord[ key ] = {
                    Translations: {
                        [ locale ]: value
                    }
                }

                continue;
            }

            /* This is a specific model/class that implements its own deserialization logic. */
            if( value.$Model !== undefined ) {
                if(
                    value.$Specifications !== undefined &&
                    value.Precision !== undefined &&
                    value.$Model === "Decimal"
                )
                    deserializedValue = ( Serialization.GetDeserializer( value.$Model ) )(
                        value.$Value,
                        specifications !== undefined ? specifications : value.$Specifications
                    );

                else
                    deserializedValue =
                        ( Serialization.GetDeserializer( value.$Model ) )( value.$Value );

            /* This is a list of items that need to be deserialized recursively. */
            } else if( value instanceof Array ) {
                deserializedValue = [];

                for( const item of value ) {
                    const [ subResult , error ] = Serialization.Deserialize( item );

                    if( error !== undefined )
                        return [ undefined, error ];

                    deserializedValue.push( subResult );
                }

            /* This is another item within our main item that needs to be deserialized
               recursively. */
            } else if( typeof value === "object" ) {
                const [ subResult, error ] = this.Deserialize( value );

                if( error !== undefined )
                    return [ undefined, error ];

                deserializedValue = subResult;

            } else {
                deserializedValue = value;
            }

            deserializedRecord[ key ] = deserializedValue;
        }

        return [ deserializedRecord as T, undefined ];
    }

    static DeserializeRecords<T>(
        /* eslint-disable-next-line */
        results: any,
        filters: Filter[],
        pagerSorter: PagerSorter,
        id?: string | number
    ): [ T[], PagerSorter, CustomError | undefined ] {
        if( results.Error !== undefined )
            return [
                [],
                pagerSorter,
                new CustomError(
                    results.Error.Id,
                    Serialization.translator( results.Error.Message )
                )
            ];

        const records: T[] = [];
        pagerSorter = pagerSorter.Copy();
        pagerSorter.Total = results.Pagination.Total;

        for( const record of results.Items ) {
            const [ instance, error ] = Serialization.Deserialize<T>( record, id );

            if( error !== undefined )
                return [ [], pagerSorter, error ];

            records.push( instance as T );
        }

        if( ( filters.length > 0 ) || ( pagerSorter.Limit === 1 ) )
            return [ records, pagerSorter, undefined ];

        return [ records, pagerSorter, undefined ];
    }

    private static translator: Translator;

    private static deserializers = new Map<string, any>();
}

export default {
    Deserialize: Serialization.Deserialize,
    DeserializeRecords: Serialization.DeserializeRecords,
    Serialize: Serialization.Serialize
}
