


import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class FormFieldset extends Vue {
    @Prop() public Title!: string;

    @Prop( { default: false } )
    @Prop() Disabled!: boolean;
}
