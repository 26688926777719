


import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { Dictionary, Filter, HandlePaginatedError, Identifiable } from "@Internal/Contracts";
import { CustomNotification, Field, PagerSorter } from "@Internal/Models";

import { ModelFieldValueTranslation } from "App/Models";
import { ModelFieldValueTranslations } from "App";
import { DeepCopy, EventBus } from "App/IOC";

import PopupRecords from "Web/Components/Popup/Records.vue";
import PopupWindow from "Web/Components/Popup/Window.vue";
import TextField from "Web/Components/Common/Input/Text.vue";

@Component({
    components: {
        PopupRecords,
        PopupWindow,
        TextField
    }
})
export default class Translation extends Vue {
    @Prop()
    readonly Record?: Identifiable;

    @Prop()
    readonly Model?: string;

    @Prop()
    readonly Field!: string;

    @Prop()
    readonly Value!: string;

    @Prop()
    readonly Placeholder!: string;

    @Prop()
    readonly Id!: string;

    @Prop( { default: Infinity } )
    readonly Size!: number;

    @Prop( { default: false } )
    readonly Readonly!: boolean;

    /* Necessary to refresh Translations popup after updating a translation. */
    private translationsKey: number = 0;
    private showUpdateTranslation = false;
    private showTranslations = false;
    private translation?: ModelFieldValueTranslation = {} as ModelFieldValueTranslation;
    private updatedTranslationValue: string = "";
    private fields: Dictionary<Field> = {};

    private pagerSorter: PagerSorter = PagerSorter.ForColumn(
        ModelFieldValueTranslations.Columns.GetDefaultSort(), 10
    );

    private getColumns = ModelFieldValueTranslations.Columns.Get;
    private filters = ModelFieldValueTranslations.Filters.Get;

    private find?: (
        filter: Filter[], pagerSorter: PagerSorter
    ) => HandlePaginatedError<Identifiable>;

    private action = {
        Id: "update",
        Name: $t( "GLOBAL_actionUpdate" ),
        Active: true
    }

    async created(): Promise<void> {
        this.find = undefined;
        this.fields = await ModelFieldValueTranslations.Fields.Get();

        if( this.Record === undefined || this.Model === undefined )
            return;

        const translatableField: ModelFieldValueTranslation = {
            Id: -1,
            Model: this.Model,
            Field: this.Field,
            RecordId: Number( this.Record.Id )
        };

        this.find =
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            ( filter: Filter[], pagerSorter: PagerSorter ): HandlePaginatedError<Identifiable> =>
                ModelFieldValueTranslations.FindByModelAndField( translatableField );
    }

    private updateTranslation( record: ModelFieldValueTranslation ): void {
        if( this.Record === undefined )
            return;

        /* Fill up translation with corresponding Model, Field and RecordId of the selected row. */
        this.translation = DeepCopy( record );
        this.translation.RecordId = Number( this.Record.Id );
        this.translation.Model = this.Model;
        this.translation.Field = this.Field;

        this.updatedTranslationValue = record.Value || "";

        this.showUpdateTranslation = true;
    }

    private async update(): Promise<void> {
        if( this.updatedTranslationValue === undefined || this.translation === undefined )
            return;

        const updatedTranslation: ModelFieldValueTranslation = DeepCopy( this.translation );
        updatedTranslation.Value = this.updatedTranslationValue;

        const [ , error ] =
            await ModelFieldValueTranslations.Update( this.translation, updatedTranslation );

        if( error !== undefined )
            EventBus.Publish( "Error", error );

        else
            EventBus.Publish( "Notification", CustomNotification.Success(
                this.$t( "TRANSLATIONS_notificationTitle" ).toString(),
                this.$t( "TRANSLATIONS_updated" ).toString()
            ));

        this.showUpdateTranslation = false;
        this.translationsKey++;
    }

    @Watch( "updatedTranslationValue" )
    private onTranslationChange() {
        /* Disable update action once the translation value cleared. */
        this.action.Active = this.updatedTranslationValue !== "";
    }

    private done(): void {
        this.showTranslations = false;

        this.$emit( "Refresh" );
    }
}
