/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import { Dictionary } from "@Internal/Contracts";
import { Field } from "@Internal/Models";
import { $t } from "App/IOC";

let defaultFields: Dictionary<Field> = {};

async function Get(): Promise<Dictionary<Field>> {
    await Promise.resolve();

    if( Object.keys( defaultFields ).length === 0 )
        defaultFields = {
            Name: Field.Text( "Name", $t( "COUPONS_fieldName" ) ),

            GeneralConditions: Field.MultiLineText(
                "GeneralConditions",
                $t( "COUPONS_fieldGeneralConditions" )
            ),

            Code: Field.Text( "Code", $t( "COUPONS_fieldCode" ) ),
            Active: Field.Boolean( "Active", $t( "COUPONS_fieldActive" ) ),
            FixedAmount: Field.Decimal( "FixedAmount", $t( "COUPONS_fieldFixedAmount" ) ),
            Percentage: Field.Decimal( "Percentage", $t( "COUPONS_fieldPercentage" ) ),
            Duration: Field.Number( "Duration", $t( "COUPONS_fieldDuration" ) ),
            From: Field.Date( "From", $t( "COUPONS_fieldFrom" ) ),
            To: Field.Date( "To", $t( "COUPONS_fieldTo" ) ),
            MaxUses: Field.Number( "MaxUses", $t( "COUPONS_fieldMaxUses" ) ),
            Plans: Field.IdentifiableSet( "Plans", $t( "COUPONS_fieldPlans" ) ),
            Customers: Field.IdentifiableSet( "Customers", $t( "COUPONS_fieldCustomers" ) ),
            FirstSubscriptionOnly: Field.Boolean(
                "FirstSubscriptionOnly",
                $t( "COUPONS_fieldFirstSubscriptionOnly" )
            ),
            GlobalPrices: Field.IdentifiableSet( "GlobalPrices", $t( "COUPONS_fieldGlobalPrices" ) )
        };

    return defaultFields;
}

export default {
    Get
};
