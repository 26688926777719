/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import { $t, DataStore } from "App/IOC";
import { User, Column, CustomError, PagerSorter } from "App/Models";
import { Dictionary, Identifiable } from "App/Contracts";
import Users from "../Users";

async function Toggle(
    id: string,
    existingColumn: Column,
    defaultColumns: Column[]
): Promise<CustomError | undefined> {
    const user: User | undefined = await Users.GetCurrent();
    const [ columns, error ] = await Get( id, defaultColumns );

    if( error !== undefined )
        return error;

    if( columns.indexOf( existingColumn ) === -1 || user === undefined )
        return new CustomError( 1, "Column " + existingColumn.Name + " does not exist." );

    existingColumn.Active = !existingColumn.Active;

    const storedColumns: Dictionary = {};

    columns.forEach( ( column: Column ): void => {
        storedColumns[ column.Id ] = column.Active;
    });

    return await DataStore.Set( user.Id + "_" + id + "_Columns", JSON.stringify( storedColumns ) );
}

async function Get(
    id: string, columns: Column[]
): Promise<[ Column[], CustomError | undefined ]> {
    const user: User | undefined = await Users.GetCurrent();

    if( !user )
        return [ columns, new CustomError( 1, $t( "GLOBAL_authenticationProblem" ) ) ];

    try {
        const [ raw, error ] = await DataStore.Get( user.Id + "_" + id + "_Columns" );

        if( error !== undefined )
            return [ columns, error ];

        const storedColumns: Dictionary = JSON.parse( raw as string );

        columns.forEach( ( column: Column ): void => {
            if( !( column.Id in storedColumns ) )
                return;

            column.Active = storedColumns[ column.Id ] as boolean;
        });

    /* The exception is coming from JSON.parse in the case where we have never stored column
        data, that is why we ignore it. */
    // eslint-disable-next-line
    } catch( exception ) {}

    return [ columns, undefined ];
}

function Sort<T>(
    records: ( T & Identifiable )[],
    pagerSorter: PagerSorter
): [ ( T & Identifiable )[], PagerSorter ] {
    const column: Column | undefined = pagerSorter.Column;

    if( column === undefined )
        return [ records, pagerSorter ];

    switch( column.Type ) {
        case "text":
            records.sort( ( a: any, b: any): number =>
                ( a[ column.Id ] as string ).localeCompare( b[ column.Id ] as string ) );
            break;

        case "dateTime":
            records.sort( (a: any, b: any): number => sortDateColumn( a, b, column ) );
            break;

        case "date":
            records.sort( (a: any, b: any): number => sortDateColumn( a, b, column ) );
            break;

        default:
            break;
    }

    if( !pagerSorter.Descending )
        records.reverse();

    return [ records, pagerSorter ];
}

function sortDateColumn( instance1: any, instance2: any, column: Column ): number {
    const dateA = instance1[ column.Id ];
    const dateB = instance2[ column.Id ];

    if( dateA.IsBefore( dateB ) )
        return -1;
    else if( dateB.IsAfter( dateB ) )
        return 1;
    else
        return 0;
}

export default {
    Get,
    Toggle,
    Sort
};
