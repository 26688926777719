/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import { Customer } from "App/Models";
import { AnnotatedJSON, HandleError, HandleListError } from "@Internal/Contracts";
import { Models } from "@Internal";
import { CustomError } from "@Internal/Models";
import { Autobind } from "@Internal/Utils";
import { CustomersService } from "App/Contracts";
import { CountryCode, GetCountryByCode } from "App/Utils";

import BaseService, { ProcessException } from "./Base";

export default class Customers extends BaseService<Customer> implements CustomersService {
    @Autobind
    async EndTrial( record: Customer ): Promise<CustomError | undefined> {
        try {
            const results = await this.service.update( record.Id, {
                EndTrial: true
            });

            if( results.Error !== undefined )
                return new CustomError( results.Error.Id, $t( results.Error.Message ) );

        } catch( error ) {
            return ProcessException( error );
        }
        return;
    }

    @Autobind
    async FindByNameOrEmail( keyword: string ): HandleListError<Customer> {
        let results: any;

        try {
            results = await this.service.find({
                query: {
                    keyword
                }
            });

        } catch( error ) {
            return [ [], await ProcessException( error ) ];
        }

        if( results.Error !== undefined )
            return [ [], new CustomError( results.Error.Id, results.Error.Message ) ];

        const customers: Customer[] = [];

        for( const record of results.Items ) {
            const [ customer, error ] = this.deserialize( record );

            if( error !== undefined || customer === undefined )
                return [ [], error ];

            customers.push( customer );
        }

        return [ customers, undefined ];
    }

    protected deserialize( result: AnnotatedJSON ): HandleError<Customer> {
        const [ customer, error ] = Models.Deserialize<Customer>( result );

        if( error !== undefined || customer === undefined )
            return [ undefined, error ];

        /* Get a country object by the country code provided by the server. */
        if( customer.Country !== undefined )
            customer.Country = GetCountryByCode( customer.Country as unknown as CountryCode );

        return [ customer, undefined ];
    }
}
