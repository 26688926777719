/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import { BaseSubscription } from "Shared/Models";

import Coupon from "../Coupon";
import Customer from "../Customer";
import SubscriptionLine from "./Line";
import Plan from "../Plan";
import Invoice from "../Invoice";
import PaymentMethod from "../PaymentMethod";

export interface GroupedLinesTargetByProduct {
    Title: string;
    LinesTarget: SubscriptionLine[];
}

type Subscription =
    BaseSubscription<Plan, Invoice, Customer, PaymentMethod, Coupon, SubscriptionLine>;
export default Subscription;
