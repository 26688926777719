


import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";

import Calendar from "primevue/calendar";

import { Equals } from "@Internal/Utils"

import { ICustomDate } from "App/Contracts";
import { CustomDate } from "App/IOC";

import { Dates } from "App";

import Modal from "Web/Components/Common/Modal.vue";

// @ts-ignore
@Component({
    // @ts-ignore
    components: {
        Calendar,
        Modal
    }
})
export default class DateTimeField extends Vue {
    @Prop() public Id!: string;
    @Prop() public Placeholder!: string;
    @Prop() public Value?: ICustomDate;

    @Prop( { default: 1 } )
    Months!: number;

    @Prop( { default: true } )
    readonly ShowTime!: boolean;

    @Prop() readonly MinDate!: ICustomDate;

    @Prop() readonly MaxDate!: ICustomDate;

    @Prop( { default: false } )
    readonly Readonly!: boolean;

    @Ref( "container" )
    private readonly container?: HTMLElement;

    @Ref( "calendar" )
    private readonly calendar?: Vue;

    @Ref( "wrapper" )
    private readonly wrapper?: HTMLElement;

    private value: Date = new Date();
    private showCalendar: boolean = false;
    private year: number = 0;

    beforeDestroy(): void {
        window.removeEventListener( "mousedown", this.handleOutsideClick );
    }

    created() {
        const currentDate = new Date();
        this.year = currentDate.getFullYear();
    }

    @Watch( "showCalendar" )
    private showOptionsChanged( opened: boolean ): void {
        /* This is an optimization to avoid listening to the clicking event when
           the calendar popup is invisible. */

        if( opened ) {
            window.addEventListener( "mousedown", this.handleOutsideClick );
            return;
        }

        window.removeEventListener( "mousedown", this.handleOutsideClick );
    }

    @Watch( "Value" )
    private onValueChange( value: ICustomDate ) {
        if( value instanceof CustomDate )
            this.value = value.ToDate();
    }

    @Watch( "value" )
    private onInternalValueChange( value: Date ) {
        /* No need to emit the value if it didn't change. */
        if( Equals( this.Value, Dates.FromDate( value ) ) )
            return;

        /* If the input field is empty send an undefined value, just like we the value we receive
           from the relevant prop (Value). */
        this.$emit(
            "Value",
            !value
                ? undefined
                : ( this.ShowTime ? Dates.FromDateTime( value ) : Dates.FromDate( value ) )
        );
    }

    private onCalendarOpened( opened: boolean ): void {
        this.showCalendar = opened;
    }

    private handleOutsideClick( event: Event ): void {
        const target = event.target as HTMLElement;

        /* Ignore clicks that are coming from elements inside our component or inside
           our dependency calendar component, otherwise we break their functionalities. */
        if( this.container === undefined || this.container.contains( target ) )
            return;

        if( this.wrapper === undefined || this.wrapper.contains( target ) )
            return;

        if( this.calendar === undefined || this.calendar.$el.contains( target ) )
            return;

        this.showCalendar = false;
    }

    private get yearOptions(): number[] {
        const options: number[] = [];

        for( let i = 2017; i <= 2050; i++ ) {
            options.push( i );
        }

        return options;
    }

    @Watch( "year" )
    private onYearSelections() {
        if( this.calendar !== undefined )
            this.calendar.$data.currentYear = this.year;
    }

    private openCalendar(): void {
        if( this.Readonly )
            return;

        this.showCalendar = true;
    }
}
