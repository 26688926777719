


/* TODO: Split this component into smaller components. */
import { Component, Vue, Watch } from "vue-property-decorator";

import { PagerSorter, TextFilter } from "@Internal/Models";

import { Plans, Prices, Products } from "App";

import {
    NewProduct,
    Product,
    CustomNotification,
    Field
} from "App/Models";

import { Dictionary } from "App/Contracts";

import { EventBus, $t } from "App/IOC";

import { RecordActions, RecordAction } from "Web/Contracts";

import ProductForm from "./Form.vue";

import Record from "Web/Components/Record.vue";
import Accordion from "Web/Components/Accordion.vue";

@Component({
    components: {
        Accordion,
        ProductForm,
        Record
    }
})
export default class SingleProductView extends Vue {
    private record: Product = NewProduct();

    private fields: Dictionary<Field> = {};

    private showScenarios: boolean = false;
    private editing: boolean = false;
    private loading: boolean = true;
    private plansPagerSorter: PagerSorter = PagerSorter.ForColumn(
        Products.Columns.GetDefaultSort()
    );

    private pricesPagerSorter: PagerSorter = PagerSorter.ForColumn(
        Prices.Columns.GetDefaultSort()
    );

    private actions: RecordActions<Product> = [
        [
            {
                Id: "Archive",
                Title: $t( "GLOBAL_actionArchive" ),
                Icon: "delete_forever"
            }
        ]
    ];

    private created() {
        this.setup();
    }

    private async setup() {
        this.fields = await Products.Fields.Get();

        /* TODO: Handle invalid ID format. */
        await this.getProduct( true );

        if( this.record.Name === undefined )
            return;

        document.title = this.record.Name;
    }

    /* Reset data on Product change*/
    private resetData() {
        this.fields = {};
        this.record = NewProduct();
    }

    /* Refresh data if navigated to a different Product
        diractly from the current page. */
    @Watch( "$route" )
    private onRouteParametersChange( from: any, to: any ) {
        if( to.name !== "product" || from.params.id === to.params.id )
            return;
        this.resetData();
        this.setup();
    }

    private async save( record: Product ) {
        if( this.record === undefined || record === undefined )
            return;

        const [ , error ] = await Products.Update( this.record, record );

        if( error !== undefined ) {
            EventBus.Publish( "Error", error );
            return;
        }

        await this.getProduct();
    }


    private async getProduct( silent: boolean = false ) {
        const [ record, error ] = await Products.Get( Number( this.$route.params.id ) );

        if( error !== undefined || record === undefined ) {
            EventBus.Publish( "Error", error );
            return;
        }

        /* Get plans. */
        let filter = new TextFilter( "ProductId", "" );
        filter.Value = [ record.Id ];

        const [ plans, , planError ] = await Plans.Find(
            [ filter ],
            this.plansPagerSorter
        );

        if( planError !== undefined ) {
            EventBus.Publish( "Error", planError );
            return;
        }

        /* Get product prices. */
        filter = new TextFilter( "ProductId", "" );
        filter.Value = [ record.Id ];
        const scopefilter = new TextFilter( "Global", "" );
        scopefilter.Value = [ true ];

        const [ prices, , priceError ] = await Prices.Find(
            [ filter, scopefilter ],
            this.pricesPagerSorter
        );

        if( priceError !== undefined ) {
            EventBus.Publish( "Error", priceError );
            return;
        }

        record.Prices = prices;

        for( const plan of plans ) {
            /* Get prices. */
            filter = new TextFilter( "PlanId", "" );
            filter.Value = [ plan.Id ];

            const [ prices, , priceError ] = await Prices.Find(
                [ filter ],
                this.pricesPagerSorter
            );

            if( priceError !== undefined ) {
                EventBus.Publish( "Error", priceError );
                return;
            }

            plan.Prices = prices;
            record.Prices = record.Prices.concat( prices );
        }

        record.Plans = plans;

        this.record = record as Product;

        if( !silent )
            this.showUpdateNotification();
    }

    private showUpdateNotification() {
        EventBus.Publish( "Notification", CustomNotification.Success(
            this.$t( "GLOBAL_Product" ).toString(), this.$t( "PRODUCTS_updated" ).toString()
        ));
    }

    private underConstruction() {
        EventBus.Publish( "UnderConstruction" );
    }

    private update( product: Product, silent: boolean = false ) {
        this.record = product;

        if( !silent )
            this.showUpdateNotification();
    }

    private execute( action: RecordAction ): void {
        switch( action.Id ) {
            case "Archive":
                this.archive( this.record as Product );
                break;

            default:
                EventBus.Publish( "UnderConstruction" );
                break;
        }
    }

    private async archive( product: Product ) {
        EventBus.Publish( "Confirmation", {
            Props: {
                Message: this.$t( "PRODUCTS_archiveConfirm"),
                Actions: [{
                    Id: "Archive",
                    Name: this.$t( "GLOBAL_actionArchive" ),
                    Active: true
                }]
            },

            Events: {
                Action: async () => {
                    const error = await Products.Archive( product );

                    if( error !== undefined )
                        EventBus.Publish( "Error", error );

                    this.$router.replace( "/products" );
                }
            }
        });
    }

    @Watch( "record" )
    private onRecordChange( record?: Product ) {
        if( record === undefined )
            return;

        setTimeout( () => this.loading = record.Name === undefined, 200 );
    }
}
