


import { Component, Vue } from "vue-property-decorator";

import { Filter } from "@Internal/Contracts";
import { Filters, Products, Records } from "App";
import { EventBus, $t } from "App/IOC";

import ProductForm from "./Form.vue";

import { TableAction } from "Web/Contracts";

import RecordsComponent from "Web/Components/Records.vue";

import {
    Column,
    Product,
    PagerSorter,
    CustomError
} from "App/Models";
import { CustomValue } from "@Internal/Models";

@Component( {
    components: {
        ProductForm,
        Records: RecordsComponent
    }
})
export default class ProductsView extends Vue {
    $refs!: Vue[ "$refs" ] & {
        Records: RecordsComponent,
    };

    private columns: Column[] = [];
    private records: Product[] = [];
    private pagerSorter: PagerSorter = PagerSorter.ForColumn(
        Products.Columns.GetDefaultSort(),
        undefined,
        false
    );

    private filters = Products.Filters.Get;

    private actions: TableAction<Product>[] = [
        {
            Id: "Archive",
            ShortName: "delete",
            Name: $t( "GLOBAL_actionArchive" ),
            Multi: true
        }
    ];

    private showForm: boolean = false;

    private async created(): Promise<void> {
        this.setActiveFilter();

        const [ columns, error ] = await Products.Columns.Get();

        if( error !== undefined ) {
            EventBus.Publish( "Error", error );
            return;
        }

        this.columns = columns;
    }

    private setActiveFilter(): void {
        const statusActive = new CustomValue<boolean>( $t( "PRODUCTS_fieldActive" ), true );
        Filters.Assign( "Products", this.filters(), "Active", [ statusActive ] );
    }

    private async update(
        filters: Filter[],
        pagerSorter: PagerSorter,
        resetPagination?: boolean
    ): Promise<void> {
        await this.$nextTick();
        this.$refs.Records.Loading();

        if( resetPagination )
            this.pagerSorter.Page = 1;

        const [ records, updatedPagerSorter, error ] = await Products.Find( filters, pagerSorter );

        this.records = records;
        this.pagerSorter = updatedPagerSorter;

        if( error !== undefined ) {
            EventBus.Publish( "Error", error );
            return;
        }
    }

    private toggleColumn( column: Column ) {
        Products.Columns.Toggle( column );
    }

    private executeAction( id: string, rows: Product[] ): void {
        const urls: string[] = [];
        let index: number = 0;

        switch( id ) {
            case "open":
                this.records.forEach( record =>
                    urls.push( "/products/" + record.Id )
                );

                index = this.records.findIndex( record => record.Id === rows[ 0 ].Id );

                Records.Navigation.SetList( urls, index );

                this.$router.push( `/products/${ rows[ 0 ].Id }` );

                break;

            case "Archive":
                EventBus.Publish( "Confirmation", {
                    Props: {
                        Message: this.$tc( "PRODUCTS_archiveConfirm", rows.length ),

                        Actions: [{
                            Id: "Archive",
                            Name: this.$t( "GLOBAL_actionArchive" ),
                            Active: true
                        }]
                    },

                    Events: {
                        Action: () => this.archive( rows )
                    }
                });

                break;

            default:
                EventBus.Publish( "UnderConstruction" );
                break;
        }
    }

    private async archive( records: Product[] ): Promise<void> {
        this.records = [];

        for( const record of records ) {
            const error = await Products.Archive( record );

            if( error !== undefined )
                EventBus.Publish(
                    "Error",
                    new CustomError( "Error", $t( "PRODUCTS_cannotArchive" ) )
                );
        }

        this.refresh();
    }

    private refresh(): void {
        this.showForm = false;
        this.update( this.$refs.Records.AppliedFilters, this.pagerSorter, true );

    }
}
