/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import BaseModel from "./Model";

export default interface BaseModelFieldValueTranslation extends BaseModel {
    Model?: string;
    Field?: string;
    RecordId?: number;
    LanguageId?: string;
    Value?: string;
}
