/* © IBS Group. See COPYRIGHT file for full copyright & licensing details. */

import Copiable from "../Contracts/Copiable";
import Identifiable from "../Contracts/Identifiable";
import Named from "../Contracts/Named";

export default class Column implements Identifiable, Named, Copiable<Column> {
    static Static( id: string, name: string, type?: string, active?: boolean ): Column {
        const column = new Column( id, name, type, active );
        column.Sortable = false;

        return column;
    }

    Active: boolean = true;
    Sortable: boolean = true;

    private id: string;
    private name: string;
    private type: string = "text";

    constructor( id: string, name: string, type?: string, active?: boolean ) {
        this.id = id;
        this.name = name;

        if( type !== undefined )
            this.type = type;

        if( active !== undefined )
            this.Active = active;
    }

    get Id(): string { return this.id; }
    get Name(): string { return this.name; }
    get Type(): string { return this.type; }

    Copy(): Column {
        const column = new Column( this.id, this.name, this.type, this.Active );
        column.Sortable = this.Sortable;

        return column;
    }
}
