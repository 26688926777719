/* © IBS Group. See COPYRIGHT file for full copyright & licensing details. */

import { CustomError } from "../Models";
import { AnnotatedJSON, Copiable } from "../Contracts";

export function Autobind(
    // eslint-disable-next-line
    target: any,
    key: string,
    descriptor: PropertyDescriptor
): PropertyDescriptor {
    return {
        get(): void {
            return descriptor.value.bind( this );
        }
    };
}

export async function Sleep( millisecondsDelay: number ): Promise<void> {
    return new Promise<void>( ( resolve ): number => setTimeout( resolve, millisecondsDelay ) );
}

/* Since we can't control received values from the outside world, its best we change them to
   undefined if we get `null`. */
export function Null2Undefined<T = any>( value: T | null ): T | undefined {
    return value === null ? undefined : value;
}

export function ValidateEmail( value: string ): CustomError | undefined {
    // eslint-disable-next-line max-len, no-useless-escape, @typescript-eslint/no-inferrable-types
    const ValidationRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;

    if( value.length <= 0 || !ValidationRegex.test( value ) )
        return new CustomError( 100, "" );

    return undefined;
}

export function Copy( instance: Copiable<any> | undefined ): any {
    return instance !== undefined ? instance.Copy() : undefined;
}

// eslint-disable-next-line
export type CountryCode = "AC" | "AD" | "AE" | "AF" | "AG" | "AI" | "AL" | "AM" | "AO" | "AR" | "AS" | "AT" | "AU" | "AW" | "AX" | "AZ" | "BA" | "BB" | "BD" | "BE" | "BF" | "BG" | "BH" | "BI" | "BJ" | "BL" | "BM" | "BN" | "BO" | "BQ" | "BR" | "BS" | "BT" | "BW" | "BY" | "BZ" | "CA" | "CC" | "CD" | "CF" | "CG" | "CH" | "CI" | "CK" | "CL" | "CM" | "CN" | "CO" | "CR" | "CU" | "CV" | "CW" | "CX" | "CY" | "CZ" | "DE" | "DJ" | "DK" | "DM" | "DO" | "DZ" | "EC" | "EE" | "EG" | "EH" | "ER" | "ES" | "ET" | "FI" | "FJ" | "FK" | "FM" | "FO" | "FR" | "GA" | "GB" | "GD" | "GE" | "GF" | "GG" | "GH" | "GI" | "GL" | "GM" | "GN" | "GP" | "GQ" | "GR" | "GT" | "GU" | "GW" | "GY" | "HK" | "HN" | "HR" | "HT" | "HU" | "ID" | "IE" | "IL" | "IM" | "IN" | "IO" | "IQ" | "IR" | "IS" | "IT" | "JE" | "JM" | "JO" | "JP" | "KE" | "KG" | "KH" | "KI" | "KM" | "KN" | "KP" | "KR" | "KW" | "KY" | "KZ" | "LA" | "LB" | "LC" | "LI" | "LK" | "LR" | "LS" | "LT" | "LU" | "LV" | "LY" | "MA" | "MC" | "MD" | "ME" | "MF" | "MG" | "MH" | "MK" | "ML" | "MM" | "MN" | "MO" | "MP" | "MQ" | "MR" | "MS" | "MT" | "MU" | "MV" | "MW" | "MX" | "MY" | "MZ" | "NA" | "NC" | "NE" | "NF" | "NG" | "NI" | "NL" | "NO" | "NP" | "NR" | "NU" | "NZ" | "OM" | "PA" | "PE" | "PF" | "PG" | "PH" | "PK" | "PL" | "PM" | "PR" | "PS" | "PT" | "PW" | "PY" | "QA" | "RE" | "RO" | "RS" | "RU" | "RW" | "SA" | "SB" | "SC" | "SD" | "SE" | "SG" | "SH" | "SI" | "SJ" | "SK" | "SL" | "SM" | "SN" | "SO" | "SR" | "SS" | "ST" | "SV" | "SX" | "SY" | "SZ" | "TA" | "TC" | "TD" | "TG" | "TH" | "TJ" | "TK" | "TL" | "TM" | "TN" | "TO" | "TR" | "TT" | "TV" | "TW" | "TZ" | "UA" | "UG" | "US" | "UY" | "UZ" | "VA" | "VC" | "VE" | "VG" | "VI" | "VN" | "VU" | "WF" | "WS" | "XK" | "YE" | "YT" | "ZA" | "ZM" | "ZW";

export const SupportedTaxTypes: Record<string, string[]> = {
    /* Prioritize European VAT number, in case if a country has multiple Tax types. */
    eu_vat: [
        "at", "be", "bg", "hr", "cy", "cz", "dk", "ee", "fi", "fr", "de", "gr", "hu", "ie",
        "it", "lv", "lt", "lu", "mt", "nl", "pl", "pt", "ro", "sk", "si", "es", "se", "gb"
    ],
    ae_trn: [ "ae" ],
    au_abn: [ "au" ],
    br_cnpj: [ "br" ],
    br_cpf: [ "br" ],
    ca_bn: [ "ca" ],
    ca_qst: [ "ca" ],
    ch_vat: [ "ch" ],
    cl_tin: [ "cl" ],
    es_cif: [ "es" ],
    gb_vat: [ "gb" ],
    hk_br: [ "hk" ],
    id_npwp: [ "id" ],
    in_gst: [ "in" ],
    jp_cn: [ "jp" ],
    jp_rn: [ "jp" ],
    kr_brn: [ "kr" ],
    li_uid: [ "li" ],
    mx_rfc: [ "mx" ],
    my_frp: [ "my" ],
    my_itn: [ "my" ],
    my_sst: [ "my" ],
    no_vat: [ "no" ],
    nz_gst: [ "nz" ],
    ru_inn: [ "ru" ],
    ru_kpp: [ "ru" ],
    sa_vat: [ "sa" ],
    sg_gst: [ "sg" ],
    sg_uen: [ "sg" ],
    th_vat: [ "th" ],
    tw_vat: [ "tw" ],
    us_ein: [ "us" ],
    za_vat: [ "za" ]
};

export const UnsupportedTaxCountriesCode: string[] =
    [ "gp", "mq", "yt", "nc", "pf", "re", "tf", "gy", "wf", "bl", "mf", "pm" ];

export function JsonUndefind2Null( payload: AnnotatedJSON ): AnnotatedJSON {
    /* HACK: `undefined` values are unfortunately ommitted by JSON.stringify which is used
       by most web servers (example: Featherjs REST client), so we force any `undefined` value
       to `null` so it can be sent. */
    const replacer = ( key: string, value: any ): boolean =>
        typeof value === "undefined" ? null : value;

    return JSON.parse( JSON.stringify( payload, replacer ) );
}
