/* © IBS Group. See COPYRIGHT file for full copyright & licensing details. */

import Locale from "./Locale";
import Dictionary from "../Contracts/Dictionary";

export default class I18n {
    Locale: Locale;

    private supportedLocales: Locale[];

    private translations: Dictionary<Dictionary<string>> = {};

    constructor( supportedLocales: Locale[] ) {
        this.supportedLocales = supportedLocales;
        this.Locale = supportedLocales[ 0 ];

        supportedLocales.forEach(
            ( locale: Locale ): void => { this.translations[ locale.Id ] = locale.Translations; }
        );
    }

    get SupportedLocales(): Locale[] {
        return this.supportedLocales;
    }

    get Translations(): Dictionary<Dictionary<string>> {
        return this.translations;
    }

    /* TODO: Refactor this, very rough implementation. */
    $t( value: string, ...values: ( string | number | Dictionary )[] ): string {
        const existingTranslation = this.translations[ this.Locale.Id ][ value ];

        if( existingTranslation === undefined )
            console.log( "Translation key", value, "does not exist" );

        value = existingTranslation !== undefined ? existingTranslation : value;

        if( values.length === 0 )
            return value;

        if(
            values.length === 1 &&
            ( typeof values[ 0 ] === "string" || typeof values[ 0 ] === "number" )
        ) {
            for( const item of values )
                value = value.replace( "{}", String( item ) );

            return value
        }

        const curlyBracesValue = new RegExp( "{\\w+}", "g" );
        const valueFormatRegex = new RegExp( "^{|}$", "g" );

        let valuesToFormat = value.match( curlyBracesValue ) || [];

        valuesToFormat = valuesToFormat.map(
            ( item: string ): string => item.replace( valueFormatRegex , "" )
        );

        for( const item of valuesToFormat )
            value = value.replace(
                `{${item}}`,
                String( ( values[ 0 ] as Dictionary )[ item ] )
            );

        return value;
    }

    GetLocaleById( id: string ): Locale | undefined {
        const result = this.supportedLocales.filter(
            ( locale: Locale ): boolean => locale.Id === id
        );

        return result.length ? result[ 0 ] : undefined;
    }
}
