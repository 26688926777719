


import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import MultiFilter from "Web/Components/Common/MultiFilter/Index.vue";
import Table from "Web/Components/Table/Index.vue";
import { MassActions } from "Web/Contracts";
import TableActions from "Web/Components/Table/Actions.vue";

import { Filter, Identifiable, Named } from "App/Contracts";

import {
    Column,
    PagerSorter
} from "App/Models";

import { DeepCopy } from "App/IOC";

@Component( {
    components: {
        MultiFilter,
        TableActions,
        Table
    }
})
export default class RecordsComponent extends Vue {
    $refs!: Vue[ "$refs" ] & {
        Table: Table
    }

    @Prop( { default: "" } )
    Title!: string;

    @Prop( { default: "" } )
    Label!: string;

    @Prop( { default: "" } )
    PluralLabel!: string;

    @Prop( { default: "" } )
    CreateLabel!: string;

    @Prop() public Columns!: Column[];
    @Prop() public Rows!: Identifiable[];

    @Prop( { default: () => [ 15, 50, 100 ] } )
    PageLimits!: number[];

    @Prop( { default: () => new PagerSorter() } )
    PagerSorter!: PagerSorter;

    @Prop( { default: () => [] } )
    Actions!: ( Identifiable & Named )[];

    @Prop( { default: () => [] } )
    MassActions!: MassActions;

    @Prop( { default: () => [] } )
    QuickFilters!: Filter[];

    @Prop( { default: "" } )
    Id!: string;

    @Prop( { default: () => [] } )
    GetFilters!: () => Promise<Filter[]>;

    @Prop( { default: true })
    MultiFilter!: boolean;

    // eslint-disable-next-line
    @Prop( { default: () => ( row: Identifiable ) => true } )
    IsSelectable!: ( row: Identifiable ) => boolean;

    // eslint-disable-next-line
    @Prop( { default: () => ( row: Identifiable, action: Identifiable ) => true } )
    IsActionAllowed!: ( row: Identifiable, action: Identifiable ) => boolean;

    @Prop( { default: true })
    Pagination!: boolean;

    @Prop( { default: true } )
    ColumnConfig!: boolean;

    private loading: boolean = true;

    private appliedQuickFilter: Filter = {} as Filter;
    private appliedFilters: Filter[] = [];

    private selectedRows: Identifiable[] = [];

    public Loading() {
        this.loading = true;
    }

    get AppliedFilters(): Filter[] {
        const filters = this.appliedFilters;

        if( this.appliedQuickFilter.Id !== undefined )
            filters.push( this.appliedQuickFilter );

        return filters;
    }

    created() {
        if( !this.MultiFilter )
            this.applyFilters( [] );
    }

    @Watch( "QuickFilters" )
    private onQuickFiltersChange() {
        if( this.QuickFilters.length === 0 )
            return;

        [ this.appliedQuickFilter ] = this.QuickFilters.filter( ( f: Filter ) =>
            f.Value.length > 0
        );
    }

    /* Stop loading when records are updated. */
    @Watch( "Rows" )
    private onRowsChange() {
        this.loading = false;
    }

    private applyFilters( filters: Filter[] ) {
        /* Cloning filters prevents mutating applied filters by accident. */
        this.appliedFilters = [ ...DeepCopy( filters ) ];

        /* This helps us reset pagination to 1 in case the filtered results total pages are less
           that the potentially currently selected page. */
        const pagerSorter = this.PagerSorter.Copy();
        pagerSorter.Page = 1;

        this.update( pagerSorter );
    }

    private applyQuickFilter( filter: Filter ) {
        this.appliedQuickFilter = filter;

        /* This helps us reset pagination to 1 in case the filtered results total pages are less
           that the potentially currently selected page. */
        const pagerSorter = this.PagerSorter.Copy();
        pagerSorter.Page = 1;

        this.update( pagerSorter );
    }

    private update( pagerSorter?: PagerSorter ) {
        this.loading = true;
        this.$emit( "Update", this.AppliedFilters, pagerSorter || this.PagerSorter );
    }


    private execute( action: string, rows: Identifiable[], actionTrigger: HTMLElement ) {
        this.$emit(
            "RowAction",
            action,
            rows.length > 0 ? rows : this.selectedRows,
            actionTrigger
        );
    }
}
