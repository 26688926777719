/* © IBS Group. See COPYRIGHT file for full copyright & licensing details. */

import Identifiable from "../Contracts/Identifiable";

export const INTERNAL_ERROR = 1;
export const USER_ERROR = 2;

export default class CustomError implements Identifiable {
    static Unknown( id?: number | string ): CustomError {
        return new CustomError( id || 1000, "" );
    }

    static GenerateUid(): string {
        const randomIntegerPart = Math.trunc( ( Math.random() * 0xfffff * 1000000 ) );
        const firstPartIdentifier = randomIntegerPart.toString( 16 ).slice( 0, 10 ).toUpperCase();
        const hexaTimestamp = ( new Date() ).getTime().toString( 16 ).toUpperCase();

        return `${firstPartIdentifier}-${hexaTimestamp}`;
    }

    static Internal( message: string, subId?: string ): CustomError {
        const error = new CustomError( INTERNAL_ERROR, message, subId );
        error.Reference = CustomError.GenerateUid();
        return error;
    }

    static User( message: string, subId?: string ): CustomError {
        return new CustomError( USER_ERROR, message, subId );
    }

    readonly Id: number | string;
    readonly Message: string;
    readonly SubId: string | undefined;
    Reference?: string;

    constructor( id: number | string, message: string, subId?: string ) {
        if( id === INTERNAL_ERROR ) {
            /* TODO Integrate Logger dependency and expose class through IOC. */
            console.log( message );
            console.trace();
        }

        this.Id = id;
        this.Message = message;
        this.SubId = subId;
    }

    Format(): string {
        return "Id: " + this.Id +
            "\nMessage: " + this.Message;
    }
}
