/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

export default {
    PLANS_title: "Titre",
    PLANS_description: "Description",
    PLANS_pricesList: "Liste des prix",
    PLANS_addPrice: "Ajouter un prix",
    PLANS_color: "Couleur",
    PLANS_create: "Créer un plan",
    PLANS_edit: "Modifier le plan",
    PLANS_fieldName: "Nom",
    PLANS_fieldDescription: "Description",
    PLANS_fieldPitchSubtitle: "Sous-titre accroche",
    PLANS_fieldColor: "Couleur",
    PLANS_confirmCreate: "Créer le plan",
    PLANS_confirmEdit: "Modifier le plan",

    PLANS_fieldCurrency: "Devise",
    PLANS_fieldStartingPrice: "Prix de départ",
    PLANS_fieldPitch: "Accroche",

    PLANS_fieldCompatiblePlans: "Plans compatibles",

    PLANS_compatiblePlans: "Compatible avec",

    PLANS_fieldTrial: "Période d'essaie",
    PLANS_denySwitchType: "Vous ne pouvez pas changer le type de ce plan, il est obligatoire \
        d'avoir trois plans payants et un plan d'essai",

    PLANS_trialPlanExist: "Il ne peut y avoir qu'un seul plan d'essai actif",
    PLANS_trialPlanDoesNotExist:
        "Vous ne pouvez pas avoir 4 plans payants, il doit y avoir au moins 1 plan d'essai",
    PLAN_archiveConfirm: "Êtes-vous sûr de vouloir archiver ce plan?"
};
