/* © IBS Group. See COPYRIGHT file for full copyright & licensing details. */

type notificationType = "failure" | "success";

export default class CustomNotification {
    static Failure( title: string, message: string ): CustomNotification {
        return new CustomNotification( title, message, "failure" );
    }

    static Success( title: string, message: string ): CustomNotification {
        return new CustomNotification( title, message, "success" );
    }

    private title: string;
    private message: string;
    private type: notificationType = "success";

    constructor( title: string, message: string, type: notificationType ) {
        this.title = title;
        this.message = message;
        this.type = type;
    }

    get Title(): string { return this.title; }
    get Message(): string { return this.message; }
    get Type(): notificationType { return this.type; }
}
