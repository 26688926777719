


import { Component, Emit, Prop, Ref, Vue } from "vue-property-decorator";

import { HandleListError } from "@Internal/Contracts";
import { BooleanFilter, CustomError } from "@Internal/Models";

import { Configuration, Coupons, Customers, Plans, Prices, Products } from "App";
import { Field, Coupon, TextFilter, PagerSorter, Plan, Price } from "App/Models";

import { Dictionary } from "App/Contracts";
import { EventBus } from "App/IOC";

import Tab from "Web/Components/Tabs/Tab.vue";
import Tabs from "Web/Components/Tabs/Index.vue";

import Form from "Web/Components/Form/Index.vue";
import FormField from "Web/Components/Form/Field.vue";
import FormFieldset from "Web/Components/Form/Fieldset.vue";
import FormFieldGroup from "Web/Components/Form/FieldGroup.vue";
import BooleanField from "Web/Components/Common/Input/Boolean.vue";

@Component({
    components: {
        BooleanField,
        Field: FormField,
        Fieldset: FormFieldset,
        FieldGroup: FormFieldGroup,
        Form,
        Tab,
        Tabs
    }
})
export default class CouponForm extends Vue {
    @Prop()
    Record!: Coupon;

    @Ref( "form" )
    readonly form!: Form;

    fields: Dictionary<Field> = {};

    mutated: boolean = false;

    private isPercentage: boolean = true;
    private associatePlans: boolean = false;
    private associateGlobalPrices: boolean = false;
    private setExpirationPeriod: boolean = false;
    private setMaxUses: boolean = false;
    private isYearlyCoupon: boolean = false;

    async mounted(): Promise<void> {
        await this.checkYearlyCoupon();

        this.isPercentage = this.Record.Percentage !== undefined;

        this.associatePlans = this.Record.Plans !== undefined && this.Record.Plans.length > 0;

        this.associateGlobalPrices =
            this.Record.GlobalPrices !== undefined && this.Record.GlobalPrices.length > 0;

        if( this.Record.Plans.length > 0 )
            Products.PrefixWithProductName( this.Record.Plans );

        if( this.Record.GlobalPrices.length > 0 )
            Products.PrefixWithProductName( this.Record.GlobalPrices );

        if( this.Record.Customers.length > 0 )
            Customers.PrefixCustomerWithEmail( this.Record.Customers );

        this.setExpirationPeriod = this.Record.From !== undefined && this.Record.To !== undefined;
        this.setMaxUses = this.Record.MaxUses !== undefined;

        this.fields = await Coupons.Fields.Get();
    }

    @Emit( "Refresh" )
    private refresh() {}

    private formChanged(): void {
        const record = this.form.GetRecord() as Coupon;

        this.associatePlans = record.Plans.length > 0;
    }

    private async checkYearlyCoupon(): Promise<void> {
        const [ configuration, error ] = await Configuration.Get();

        if(
            error !== undefined ||
            configuration === undefined ||
            configuration.YearlyCoupon === undefined
        ) {
            EventBus.Publish( "Error", error );
            return;
        }

        this.isYearlyCoupon = configuration.YearlyCoupon.Id === this.Record.Id;
    }

    private async getPlans( keyword?: string ): Promise<[ Plan[], CustomError | undefined ]> {
        const filter = new TextFilter( "Name", "" );
        filter.Value = [ keyword || "" ];

        const [ plans, , error ] = await Plans.Find( [ filter ], new PagerSorter( 100 ) );

        /* Format plan names with their respective product as prefix. */
        Products.PrefixWithProductName( plans );

        return [ plans, error ];
    }

    private async getGlobalPrices(): HandleListError<Price> {
        const filter = new BooleanFilter( "Global", "" );
        filter.Value = [ true ];

        const [ globalPrices, , error ] = await Prices.Find( [ filter ], new PagerSorter( 100 ) );

        /* Prefix global prices with their respective product name. */
        Products.PrefixWithProductName( globalPrices );

        return [ globalPrices, error ];
    }

    private toggleAssociatedPlans(
        value: boolean,
        update: ( field: Field, value: any ) => void
    ): void {
        if( !this.associatePlans && this.associateGlobalPrices ) {
            this.associateGlobalPrices = false;

            /* Remove selected prices when toggle to associate plans. */
            update( this.fields.GlobalPrices, [] );
        }

        this.associatePlans = value;

        /* Remove all associated plans. */
        if( !value )
            update( this.fields.Plans, [] );
    }

    private toggleAssociatedGlobalPrices(
        value: boolean,
        update: ( field: Field, value: any ) => void
    ): void {
        if( !this.associateGlobalPrices && this.associatePlans ) {
            this.associatePlans = false;

            /* Remove selected plans when toggle to associate global prices. */
            update( this.fields.Plans, [] );
        }

        this.associateGlobalPrices = value;

        /* Remove all associated global prices. */
        if( !value )
            update( this.fields.GlobalPrices, [] );
    }

    @Emit( "Save" )
    private save( record: Coupon ): Coupon {
        return record;
    }
}
